import React from 'react';
import {Grid, Header, Label} from 'semantic-ui-react';
import NumberFormat from 'react-number-format';
import {connect} from 'react-redux';
import './index.css';
import {getPurchaseRate, isIS6OrIS6Pro} from "../../../../common/common";
import {SPINDEX_MAPPING, TOOL_NAME_MAPPING} from "../../../../reducers/actions";

const SP500 = (props) => {

    let adminDatabase = props.database.get('adminDatabase');
    let data = props.database.toJS();
    const selectedTool = props.uiReducer.toJS().selectedTool;
    let purchasePayment = data.annuityPurchaseAmount.value;

    return (
        <Grid columns='equal' className="low-padding-rows">
            <Grid.Row>
                <Grid.Column width={8}><Header as="h5">S&P 500</Header></Grid.Column>
                <Grid.Column><Header as="h5">Allocation %</Header></Grid.Column>
                <Grid.Column><Header as="h5">{TOOL_NAME_MAPPING[selectedTool]} Rate Applied</Header></Grid.Column>
            </Grid.Row>
            {isIS6OrIS6Pro(selectedTool) && <React.Fragment>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Label basic>1-Year 50% Downside Participation Rate with Upside Participation Rate</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>
                            <NumberFormat
                                value={data[SPINDEX_MAPPING[selectedTool]].oneYRTermPat.value}
                                displayType={'text'}
                                suffix={'%'}
                            />
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{getPurchaseRate(purchasePayment, [SPINDEX_MAPPING[selectedTool], "oneYRTermPat"], adminDatabase, selectedTool)}%</Label>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Label basic>1-Year 50% Downside Participation Rate with Cap</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>
                            <NumberFormat
                                value={data[SPINDEX_MAPPING[selectedTool]].oneYRTermCap.value}
                                displayType={'text'}
                                suffix={'%'}
                            />
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{getPurchaseRate(purchasePayment, [SPINDEX_MAPPING[selectedTool], "oneYRTermCap"], adminDatabase, selectedTool)}%</Label>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Label basic>1-yr 10% Buffer with Cap</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>
                            <NumberFormat
                                value={data[SPINDEX_MAPPING[selectedTool]].oneYRBufferCap.value}
                                displayType={'text'}
                                suffix={'%'}
                            />
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{getPurchaseRate(purchasePayment, [SPINDEX_MAPPING[selectedTool], "oneYRBufferCap"], adminDatabase, selectedTool)}%</Label>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Label basic>1-yr -10% Floor with Cap</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>
                            <NumberFormat
                                value={data[SPINDEX_MAPPING[selectedTool]].oneYRMinusTenFloorCap.value}
                                displayType={'text'}
                                suffix={'%'}
                            />
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{getPurchaseRate(purchasePayment, [SPINDEX_MAPPING[selectedTool], "oneYRMinusTenFloorCap"], adminDatabase, selectedTool)}%</Label>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Label basic>1-yr 0% Floor with Cap</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>
                            <NumberFormat
                                value={data[SPINDEX_MAPPING[selectedTool]].oneYRZeroFloorCap.value}
                                displayType={'text'}
                                suffix={'%'}
                            />
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{getPurchaseRate(purchasePayment, [SPINDEX_MAPPING[selectedTool], "oneYRZeroFloorCap"], adminDatabase, selectedTool)}%</Label>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Label basic>2-Year 50% Downside Participation Rate with Upside Participation Rate</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>
                            <NumberFormat
                                value={data[SPINDEX_MAPPING[selectedTool]].twoYRTermPat.value}
                                displayType={'text'}
                                suffix={'%'}
                            />
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{getPurchaseRate(purchasePayment, [SPINDEX_MAPPING[selectedTool], "twoYRTermPat"], adminDatabase, selectedTool)}%</Label>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Label basic>2-Year 50% Downside Participation Rate with Cap</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>
                            <NumberFormat
                                value={data[SPINDEX_MAPPING[selectedTool]].twoYRTermCap.value}
                                displayType={'text'}
                                suffix={'%'}
                            />
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{getPurchaseRate(purchasePayment, [SPINDEX_MAPPING[selectedTool], "twoYRTermCap"], adminDatabase, selectedTool)}%</Label>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Label basic>6-Year 10% Buffer with Upside Participation Rate</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>
                            <NumberFormat
                                value={data[SPINDEX_MAPPING[selectedTool]].sixYrTermPatTenBuffer.value}
                                displayType={'text'}
                                suffix={'%'}
                            />
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{getPurchaseRate(purchasePayment, [SPINDEX_MAPPING[selectedTool], "sixYrTermPatTenBuffer"], adminDatabase, selectedTool)}%</Label>
                    </Grid.Column>
                </Grid.Row>
            </React.Fragment>}
            {!isIS6OrIS6Pro(selectedTool) && <React.Fragment>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Label basic>10% Buffer Indexed Strategy</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>
                            <NumberFormat
                                value={data[SPINDEX_MAPPING[selectedTool]].tenPercentBufferIdxStrategy.value}
                                displayType={'text'}
                                suffix={'%'}
                            />
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{getPurchaseRate(purchasePayment, [SPINDEX_MAPPING[selectedTool], "tenPercentBufferIdxStrategy"], adminDatabase, selectedTool)}%</Label>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Label basic>-10% Floor Indexed Strategy</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>
                            <NumberFormat
                                value={data[SPINDEX_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value}
                                displayType={'text'}
                                suffix={'%'}
                            />
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{getPurchaseRate(purchasePayment, [SPINDEX_MAPPING[selectedTool], "minustenPercentBufferIdxStrategy"], adminDatabase, selectedTool)}%</Label>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Label basic>0% Floor Indexed Strategy</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>
                            <NumberFormat
                                value={data[SPINDEX_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value}
                                displayType={'text'}
                                suffix={'%'}
                            />
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{getPurchaseRate(purchasePayment, [SPINDEX_MAPPING[selectedTool], "zeroPercentFloorIdxStrategy"], adminDatabase, selectedTool)}%</Label>
                    </Grid.Column>
                </Grid.Row>
            </React.Fragment>}
        </Grid>
    );
};


const mapStateToProps = (state) => {
    return {
        database: state.get("databaseReducer"),
        uiReducer: state.get('uiReducer')
    }
};

export default connect(mapStateToProps, null)(SP500);