import React from 'react';
import {Container, Header, Grid, Segment, Input} from "semantic-ui-react";
import NumberFormat from 'react-number-format';
import {connect} from 'react-redux';
import * as actionTypes from '../../../reducers/actions';

const ClientName = (props) => {

    let data = props.database.toJS();

    let inputClass = "ui input";

    let ux = props.uxReducer.toJS();
    
    if (!data.clientsName.isValid || !ux.isClientsNameValid || !ux.isClientsNameNotEmpty) inputClass = "ui error input";
    
    return (
        <Segment>
            <Grid>

                <Grid.Row>
                    <Grid.Column>
                        <Container textAlign='center'>
                            <Header size='huge'>What is your client's full name?</Header>
                        </Container>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <div className={inputClass}>
                            <Input      
                                className={inputClass}                           
                                style={{width: "25rem"}}
                                type="text"
                                maxLength="50"
                                placeholder={"Client's full name"}                                
                                onChange={(event) => props.updateClientsName(event.target.value)} 
                                onBlur={() => props.validateClientsName()}
                            />
                        </div>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

const mapStateToProps = (state) => {
    return {
        database: state.get("databaseReducer"),
        uiReducer: state.get('uiReducer'),
        uxReducer: state.get("uxReducer"),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateClientsName: (value) => dispatch({
            type: actionTypes.DB_SET_CLIENTS_NAME,
            value: value
        }),
        validateClientsName: () => dispatch({
            type: actionTypes.DB_VALIDATE_CLIENTS_NAME
        })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientName);