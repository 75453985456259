import React from 'react';
import {Container, Header, Grid, Button} from "semantic-ui-react";
import MASS_MUTUAL_LOGO from '../assets/MassMutualAscend-Logo_white-305.png'
import NavigationHeader from './NavigationHeader'
import './index.css';
import {connect} from 'react-redux';

// Header should go accross the entire screen
// Header should have the GAIG Logo on it.
// GAIG Logo should link to GAIG Home page.

const header = (props) => {
    document.title="MassMutual Ascend RILA";
    return (
        <Container textAlign="center" className="gaig__header">
            <Grid>
                <Grid.Row>
                    <Grid.Column width={4} className="gaig__header__logo__col">
                        <div className="gaig__header__logo">
                            <a href="https://www.massmutualascend.com" target="_blank" rel="noopener noreferrer">
                                <img src={MASS_MUTUAL_LOGO} alt="MassMutual Ascend"/>
                            </a>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Header className="gaig__header__title" as="h2" block>
                            <div>Explore MassMutual Ascend's</div>
                            <div>Registered Index-Linked Annuities</div>
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                <NavigationHeader/>
            </Grid>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        uiReducer: state.get('uiReducer')
    }
};

export default connect(mapStateToProps, null)(header);
