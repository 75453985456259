import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Loader, Modal } from 'semantic-ui-react';
import './index.css';

export class LoadingModal extends Component {
    render() {
        return (
            <Modal
                open={this.props.uiState.get("showLoadingModal")}
            >
                <div className="LoadingModalContent">
                    <Loader>Please Wait...</Loader>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        uiState: state.get("uiReducer")
    }
};

export default connect(mapStateToProps)(LoadingModal);