import {
    CETERA,
    CETERA_CODE, HUNTINGTON,
    HUNTINGTON_CODE, LPL,
    LPL_CODE, MMFA,
    MMFA_CODE, RAYMOND_JAMES,
    RAYMOND_JAMES_CODE, RETAIL_FI,
    RETAIL_FI_CODE, WELLS_FARGO, WELLS_FARGO_CODE
} from "../reducers/actions";

export const getInputClass = (isValid) => {
    return isValid ? "ui input" : "ui input error";
};

/*
This code was adapted from the inStream Advisor Portal Legacy System.
*/
export const formatCurrency = (str) => {
    let num, decIndex, end, tempStr,
        counter, i, tempChar,
        isNegative;

    isNegative = (Number(str) < 0);
    // if this is a negative number, make it positive temporarily
    // we will prepend a "-" later
    if (isNegative) {
        str *= -1;
    }

    num = (str + "").replace(/\$/g, "").replace(/,/g, "");
    if (isNaN(Number(num))) {
        return;
    }

    decIndex = num.indexOf(".");
    end = ((decIndex > -1) ? "" + num.substring(decIndex, num.length) : ".00");
    num = "" + parseInt(num, 10);

    if (end.length > 3) end = end.substring(0, 3);
    else if (end.length == 2) end += "0";
    else if (end.length == 1) end += "00";
    else if (end === "") end += ".00";

    tempStr = "";

    // put in the commas (going backwards)
    counter = 0;
    for (i = num.length - 1; i >= 0; i -= 1) {
        tempChar = num.charAt(i);
        if (counter === 3) {
            tempStr += ",";
            tempStr += tempChar;
            counter = 1;
        } else {
            tempStr += tempChar;
            counter += 1;
        }
    }

    // reverse the string
    tempStr = tempStr.split("").reverse().join("");

    return ((isNegative ? "-" : "") + "$" + tempStr + end).replace(".00", "");
};

export const formatPercentage = (number, places) => {
    if (number == null)
        return "";

    number = number || 0;
    places = !isNaN(places = Math.abs(places)) ? places: 2;
    var negative = number < 0 ? "-": "",
        i = parseInt(number = Math.abs( + number || 0).toFixed(places), 10) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;
    return negative + (j ? i.substr(0, j) + ",": "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + ",") + (places ? "." + Math.abs(number - i).toFixed(places).slice(2) : "") + "%";
};

/* Gets the correct rate based on the annuity payment */
export const getPurchaseRate = (annuityPayment, key, database, selectedTool) => {
    if (isIS6OrIS6Pro(selectedTool)) {
        if (annuityPayment < 100000) {
            key = key.concat(['paymentUnder100K', 'value']);
        } else {
            key = key.concat(['paymentOver100K', 'value']);
        }
    } else {
        if (annuityPayment < 250000) {
            key = key.concat(['paymentUnder250K', 'value']);
        } else {
            key = key.concat(['paymentOver250K', 'value']);
        }
    }

    return database.getIn(key);
};

export const isIS6OrIS6Pro = (type) => {
    return type.indexOf("IS6") !== -1
};

export const isIS5OrIS5Pro = (type) => {
    return type.indexOf("IS5") !== -1
};

export const isIS7OrIS7Pro = (type) => {
    return type.indexOf("IS7") !== -1
};

export const isIS5OrIS7 = (type) => {
    return type.indexOf("IS6") === -1 && !type.endsWith("Pro");
};

export const isIS5ProOrIS7Pro = (type) => {
    return type.indexOf("IS6") === -1 && type.endsWith("Pro");
};

export const isPro = (type) => {
    return type.endsWith("Pro");
}

export const getInstitution = () => {
    let institution;
    const pathname = window.location.pathname;
    const params = window.location.search;
    if (pathname.toLowerCase().indexOf(RAYMOND_JAMES_CODE) !== -1 || params.indexOf(RAYMOND_JAMES_CODE) !== -1) {
        institution = RAYMOND_JAMES;
    } else if (pathname.toLowerCase().indexOf(MMFA_CODE) !== -1 || params.indexOf(MMFA_CODE) !== -1) {
        institution = MMFA;
    } else if (pathname.toLowerCase().indexOf(CETERA_CODE) !== -1 || params.indexOf(CETERA_CODE) !== -1) {
        institution = CETERA;
    } else if (pathname.toLowerCase().indexOf(HUNTINGTON_CODE) !== -1 || params.indexOf(HUNTINGTON_CODE) !== -1) {
        institution = HUNTINGTON;
    } else if (pathname.toLowerCase().indexOf(LPL_CODE) !== -1 || params.indexOf(LPL_CODE) !== -1) {
        institution = LPL;
    } else if (pathname.toLowerCase().indexOf(WELLS_FARGO_CODE) !== -1 || params.indexOf(WELLS_FARGO_CODE) !== -1) {
        institution = WELLS_FARGO;
    } else {
        institution = RETAIL_FI;
    }
    return institution;
}