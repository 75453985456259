import React, {useEffect, useState} from 'react';
import {Grid, Container, Table, Popup, Segment, Header, Button} from 'semantic-ui-react';
import './index.css';
import {connect} from 'react-redux';
import SideBar from "../SideBar/index";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import {
    get_admin_institute_products,
    get_business_intelligence,
    get_business_intelligence_total_time_spent, get_business_intelligence_visit_completed
} from "../../../api/api";
import {
    INDEX_FRONTIER_5,
    INDEX_FRONTIER_5_PRO, INDEX_FRONTIER_7,
    INDEX_FRONTIER_7_PRO,
    INDEX_SUMMIT_6, INDEX_SUMMIT_6_PRO,
    PRODUCT_TYPE_LIST
} from "../../../reducers/actions";
import moment from "moment";

const BusinessIntelligence = (props) => {

    const [state, setState] = useState({
        loading: true,
        totalTimeSpendFrom: moment('2022-05-01', 'YYYY-MM-DD').toDate(),
        totalTimeSpendTo: moment().subtract(1, 'days').toDate(),
        visitCompletedFrom: moment('2022-05-01', 'YYYY-MM-DD').toDate(),
        visitCompletedTo: moment().subtract(1, 'days').toDate()
    });

    useEffect(() => {
        const promiseList = [
            get_business_intelligence_total_time_spent("2022-05-01", moment().subtract(1, 'days').format('YYYY-MM-DD')),
            get_business_intelligence_visit_completed("2022-05-01", moment().subtract(1, 'days').format('YYYY-MM-DD')),
        ];
        Promise.all(promiseList).then((data) => {
            const totalTimeSpent = data[0];
            const visitCompleted = data[1];
            setState({
                ...state,
                loading: false,
                ...totalTimeSpent,
                ...visitCompleted
            });
        });
    }, []);

    const searchTotalTimeSpent = () => {
        get_business_intelligence_total_time_spent(moment(state.totalTimeSpendFrom).format('YYYY-MM-DD'), moment(state.totalTimeSpendTo).format('YYYY-MM-DD'))
            .then((response) => {
                setState({
                    ...state,
                    loading: false,
                    ...response
                });
            });
    }

    const searchVisitCompleted = () => {
        get_business_intelligence_visit_completed(moment(state.visitCompletedFrom).format('YYYY-MM-DD'), moment(state.visitCompletedTo).format('YYYY-MM-DD'))
            .then((response) => {
                setState({
                    ...state,
                    loading: false,
                    ...response
                });
            });
    }

    const displayProductConclusion = (instituteId, productType) => {
        const selectedTool = state.gaigVisitComplete
            .filter(institution => institution.instituteId === instituteId)[0].productVisit
            .filter(product => product.productType === productType);
        if (selectedTool.length === 0) {
            return (
                <React.Fragment>
                    <Table.Cell className={"border-left"}></Table.Cell>
                    <Table.Cell></Table.Cell>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <Table.Cell className={"border-left"}>
                    <label basic>{selectedTool[0].totalNumberOfVisit}</label>
                </Table.Cell>
                <Table.Cell>
                    <Popup content={selectedTool[0].completionRate + '%'} trigger={<label basic>{selectedTool[0].totalNumberOfCompleted}</label>} />
                </Table.Cell>
            </React.Fragment>
        );
    }

    return (
        <Container>
            <Grid>
                {!state.loading &&
                <Grid.Row className={'business-intelligence-page'}>
                    <Grid.Column width={4}>
                        <SideBar/>
                    </Grid.Column>
                    <Grid.Column width={12} className={"business-intelligence-page-content"}>
                        <Segment raised>
                            <Header as="h1">Business Intelligence</Header>
                            <Grid columns='equal'>
                                <Grid.Row>
                                    <Grid.Column>
                                        <label basic className={"bold"}>Time Spent</label>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className={"date-picker-row"}>
                                    <Grid.Column width={4} className={"right label"}>
                                        <label basic>Time Period</label>
                                    </Grid.Column>
                                    <Grid.Column width={1} className={"label"}>
                                        <label basic>From</label>
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <SemanticDatepicker
                                            clearOnSameDateClick={true}
                                            datePickerOnly={false}
                                            format={"MM/DD/YYYY"}
                                            inline={false}
                                            maxDate={moment().subtract(1, 'days').toDate()}
                                            minDate={moment('2022-05-01', 'YYYY-MM-DD').toDate()}
                                            onChange={(event, data) => {
                                                const startDate = data.value;
                                                const endDate = state.totalTimeSpendTo;
                                                if (startDate.getTime() > endDate.getTime()) {
                                                    setState({
                                                        ...state,
                                                        totalTimeSpendFrom: data.value,
                                                        totalTimeSpendTo: data.value
                                                    });
                                                } else {
                                                    setState({
                                                        ...state,
                                                        totalTimeSpendFrom: data.value
                                                    });
                                                }
                                            }}
                                            readOnly={false}
                                            showToday={false}
                                            type={"basic"}
                                            value={state.totalTimeSpendFrom}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={1} className={"label"}>
                                        <label basic>To</label>
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <SemanticDatepicker
                                            clearOnSameDateClick={true}
                                            datePickerOnly={false}
                                            format={"MM/DD/YYYY"}
                                            inline={false}
                                            maxDate={moment().subtract(1, 'days').toDate()}
                                            minDate={moment('2022-05-01', 'YYYY-MM-DD').toDate()}
                                            onChange={(event, data) => {
                                                const startDate = state.totalTimeSpendFrom;
                                                const endDate = data.value;
                                                if (startDate.getTime() > endDate.getTime()) {
                                                    setState({
                                                        ...state,
                                                        totalTimeSpendFrom: data.value,
                                                        totalTimeSpendTo: data.value
                                                    });
                                                } else {
                                                    setState({
                                                        ...state,
                                                        totalTimeSpendTo: data.value
                                                    });
                                                }
                                            }}
                                            readOnly={false}
                                            showToday={false}
                                            type={"basic"}
                                            value={state.totalTimeSpendTo}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={1}>
                                        <Button onClick={searchTotalTimeSpent}>Search</Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={5}>
                                        <label basic>Total Time Spent on RILA</label>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <label basic>{state.totalTimeSpentOnRila == null ? 0 : state.totalTimeSpentOnRila} minutes</label>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={5}>
                                        <label basic># of Total Users Across RILA products</label>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <label basic>{state.totalNoOfUsers == null ? 0 : state.totalNoOfUsers} users</label>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={5}>
                                        <label basic>Average Time on RILA, per user</label>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <label basic>{state.averageTimePerUser == null ? 0 : state.averageTimePerUser} min/user</label>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <label basic className={"bold"}>Usage by product, per institution & Completion
                                            Rate</label>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className={"date-picker-row"}>
                                    <Grid.Column width={4} className={"right label"}>
                                        <label basic>Time Period</label>
                                    </Grid.Column>
                                    <Grid.Column width={1} className={"label"}>
                                        <label basic>From</label>
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <SemanticDatepicker
                                            clearOnSameDateClick={true}
                                            datePickerOnly={false}
                                            format={"MM/DD/YYYY"}
                                            inline={false}
                                            maxDate={moment().subtract(1, 'days').toDate()}
                                            minDate={moment('2022-05-01', 'YYYY-MM-DD').toDate()}
                                            onChange={(event, data) => {
                                                const startDate = data.value;
                                                const endDate = state.visitCompletedTo;
                                                if (startDate.getTime() > endDate.getTime()) {
                                                    setState({
                                                        ...state,
                                                        visitCompletedFrom: data.value,
                                                        visitCompletedTo: data.value
                                                    });
                                                } else {
                                                    setState({
                                                        ...state,
                                                        visitCompletedFrom: data.value
                                                    });
                                                }
                                            }}
                                            readOnly={false}
                                            showToday={false}
                                            type={"basic"}
                                            value={state.visitCompletedFrom}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={1} className={"label"}>
                                        <label basic>To</label>
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <SemanticDatepicker
                                            clearOnSameDateClick={true}
                                            datePickerOnly={false}
                                            format={"MM/DD/YYYY"}
                                            inline={false}
                                            maxDate={moment().subtract(1, 'days').toDate()}
                                            minDate={moment('2022-05-01', 'YYYY-MM-DD').toDate()}
                                            onChange={(event, data) => {
                                                const startDate = state.visitCompletedFrom;
                                                const endDate = data.value;
                                                if (startDate.getTime() > endDate.getTime()) {
                                                    setState({
                                                        ...state,
                                                        visitCompletedFrom: data.value,
                                                        visitCompletedTo: data.value
                                                    });
                                                } else {
                                                    setState({
                                                        ...state,
                                                        visitCompletedTo: data.value
                                                    });
                                                }
                                            }}
                                            readOnly={false}
                                            showToday={false}
                                            type={"basic"}
                                            value={state.visitCompletedTo}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={1}>
                                        <Button onClick={searchVisitCompleted}>Search</Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Table>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell></Table.Cell>
                                                    <Table.Cell colSpan={2} className={"border-left"}>
                                                        <label basic>IF 5</label>
                                                    </Table.Cell>
                                                    <Table.Cell colSpan={2} className={"border-left"}>
                                                        <label basic>IF 5 Pro</label>
                                                    </Table.Cell>
                                                    <Table.Cell colSpan={2} className={"border-left"}>
                                                        <label basic>IF 7</label>
                                                    </Table.Cell>
                                                    <Table.Cell colSpan={2} className={"border-left"}>
                                                        <label basic>IF 7 Pro</label>
                                                    </Table.Cell>
                                                    <Table.Cell colSpan={2} className={"border-left"}>
                                                        <label basic>Summit 6</label>
                                                    </Table.Cell>
                                                    <Table.Cell colSpan={2} className={"border-left"}>
                                                        <label basic>Summit 6 Pro</label>
                                                    </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell></Table.Cell>
                                                    <Table.Cell className={"border-left"}>
                                                        <label basic>Visits</label>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <label basic>Completed</label>
                                                    </Table.Cell>
                                                    <Table.Cell className={"border-left"}>
                                                        <label basic>Visits</label>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <label basic>Completed</label>
                                                    </Table.Cell>
                                                    <Table.Cell className={"border-left"}>
                                                        <label basic>Visits</label>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <label basic>Completed</label>
                                                    </Table.Cell>
                                                    <Table.Cell className={"border-left"}>
                                                        <label basic>Visits</label>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <label basic>Completed</label>
                                                    </Table.Cell>
                                                    <Table.Cell className={"border-left"}>
                                                        <label basic>Visits</label>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <label basic>Completed</label>
                                                    </Table.Cell>
                                                    <Table.Cell className={"border-left"}>
                                                        <label basic>Visits</label>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <label basic>Completed</label>
                                                    </Table.Cell>
                                                </Table.Row>
                                                {state.gaigVisitComplete.map(institution => {
                                                    return (
                                                        <Table.Row>
                                                            <Table.Cell>{institution.name}</Table.Cell>
                                                            {displayProductConclusion(institution.instituteId, INDEX_FRONTIER_5)}
                                                            {displayProductConclusion(institution.instituteId, INDEX_FRONTIER_5_PRO)}
                                                            {displayProductConclusion(institution.instituteId, INDEX_FRONTIER_7)}
                                                            {displayProductConclusion(institution.instituteId, INDEX_FRONTIER_7_PRO)}
                                                            {displayProductConclusion(institution.instituteId, INDEX_SUMMIT_6)}
                                                            {displayProductConclusion(institution.instituteId, INDEX_SUMMIT_6_PRO)}
                                                        </Table.Row>
                                                    );
                                                })}
                                            </Table.Body>
                                        </Table>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                }
            </Grid>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        uiReducer: state.get('uiReducer'),
        uxReducer: state.get("uxReducer"),
        database: state.get('databaseReducer'),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessIntelligence);