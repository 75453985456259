import React from 'react';
import {Container, Grid, Header, Segment} from 'semantic-ui-react';
import {connect} from 'react-redux';
import './index.css';
import {formatPercentage} from "../../../../common/common";

const Index = (props) => {

    const data = props.database.toJS();

    const formatValue = (number) => {
        return Number.isInteger(number) ? formatPercentage(number, 0) : formatPercentage(number, 2);
    }

    return (
        <Segment className="overall-cap-and-protection">
            <Container>
                <Header as="h1">Overall Upside Limit and Protection</Header>
                <Grid columns='equal' className="low-padding-rows">
                    <Grid.Row>
                        <Grid.Column width={2} />
                        <Grid.Column width={6}>
                            <Header>Overall Upside Limit: {data.results.planB.allocationModel.overallCap ? formatValue(data.results.planB.allocationModel.overallCap) : "N/A"}</Header>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Header>Overall Protection: {data.results.planB.allocationModel.overallProtection ? formatValue(data.results.planB.allocationModel.overallProtection) : "N/A"}</Header>
                        </Grid.Column>
                        <Grid.Column width={2} />
                    </Grid.Row>
                </Grid>
            </Container>
        </Segment>
    );
};


const mapStateToProps = (state) => {
    return {
        database: state.get("databaseReducer")
    }
};

export default connect(mapStateToProps, null)(Index);