import React from 'react';
import {Grid, Button, Message} from 'semantic-ui-react';
import { connect } from 'react-redux';
import * as actionTypes from '../../../reducers/actions';
import ConditionsSlider from './ConditionsSlider/index';
import PlanCompare from './PlanCompare/index';
import WealthGraph from './WealthGraph/index';
import OutputSummary from './OutputSummary/index';
import CapAndProtection from './CapAndProtection/index';
import ToolTip from '../../../common/ToolTip/ToolTip';
import MarketConditionsLegend from './MarketConditionsLegend/';
import moment from 'moment';
import {PRODUCT_TYPE, TOOL_NAME_MAPPING} from "../../../reducers/actions";
import {create_client_visit} from "../../../api/api";
import {isIS5OrIS7, isIS5ProOrIS7Pro, isIS6OrIS6Pro} from "../../../common/common";

export const getPercentileData = (data, percentileIndex, label) => {
    let percentileData = data.wealthGraph
        .map((entry) => {
            // get the correct percentile entry
            return {
                label: label,
                year: entry.year,
                percentileValue: entry.percentileAndPortfolioValues[percentileIndex].portfolioAmount
            }
        });
    percentileData.unshift({
        label: label,
        year: (new Date()).getFullYear() - 1,
        percentileValue: parseFloat(data.beginingPortfolioValue)
    });
    return percentileData;
};

const outputStep = (props) => {

    let data = props.database.toJS();
    let ux = props.uxState.toJS();
    let adminData = props.adminDatabase.toJS();
    const selectedTool = props.uiReducer.toJS().selectedTool;

    const startOver = () => {
        const isTools = JSON.parse(sessionStorage.getItem('isTools'));
        props.resetData();
        create_client_visit(isTools.institution, PRODUCT_TYPE[selectedTool])
            .then((response) => {
                props.updateVisitId(response.data);
                props.keepProductSelection(selectedTool);
            });
    };

    return (
        <Grid className={"planOutput"}>
            <Grid.Row>
                <Grid.Column>
                    <Message>
                        Report run on {moment().format("MM/DD/YYYY")}. Caps and participation rates listed below are effective <br/>
                        for terms beginning on {moment(adminData.lastUpdateDate).format("MM/DD/YYYY")}, and may differ from the caps <br/>
                        and participation rates offered for terms starting on any other date.
                    </Message>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column>
                    <Button
                        primary
                        onClick={startOver}
                    >
                        Start Over
                    </Button>
                    <Button
                        primary
                        onClick={() => props.openPreviewModal()}
                    >
                        Download
                    </Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    Review the {TOOL_NAME_MAPPING[selectedTool]} allocation summary and market condition information below, then refer to the Simulation Highlights to see how caps and participation rates can affect the performance of your {TOOL_NAME_MAPPING[selectedTool]} strategies. If you would like to change the purchase payment or allocations you entered, you can select “Start Over” above.
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <OutputSummary />
                </Grid.Column>
            </Grid.Row>

            {!isIS6OrIS6Pro(selectedTool) && (
            <Grid.Row>
                <Grid.Column>
                    <CapAndProtection />
                </Grid.Column>
            </Grid.Row>
            )}

            <Grid.Row>
                <Grid.Column>
                    <p>By default, the Tool assumes normal market conditions will exist for each year of every ten-year simulation. You can adjust the market conditions applied to the first three years of each simulation by using the slider below and selecting "Recalculate".</p>
                    <p>Please refer to the Important Information section for more information about how market conditions are calculated and integrated into the simulations.<ToolTip position="bottom"><MarketConditionsLegend/></ToolTip></p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <ConditionsSlider/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Button
                        primary
                        onClick={() => props.recalculatePlans()}
                    >
                        Recalculate
                    </Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    {isIS6OrIS6Pro(selectedTool) &&
                    <p>The following charts summarize the results of the Monte Carlo simulations that were generated
                        based on your selections. To allow you to evaluate the impact of
                        the {TOOL_NAME_MAPPING[selectedTool]}'s features, information has been provided for your
                        allocations under two scenarios: one scenario, {TOOL_NAME_MAPPING[selectedTool]} Results,
                        reflects the inclusion of the {TOOL_NAME_MAPPING[selectedTool]}'s caps, participation rates and
                        contract fees, while the other scenario, Combined Index/ETF Results, does not
                        include {TOOL_NAME_MAPPING[selectedTool]} features, such as caps and participation rates. </p>
                    }
                    {isIS5OrIS7(selectedTool) &&
                    <p>The following charts summarize the results of the Monte Carlo simulations that were generated
                        based on your selections. To allow you to evaluate the impact of
                        the {TOOL_NAME_MAPPING[selectedTool]}'s features, information has been provided for your
                        allocations under two scenarios: one scenario, {TOOL_NAME_MAPPING[selectedTool]} Results,
                        reflects the inclusion
                        of the {TOOL_NAME_MAPPING[selectedTool]}'s caps, buffers, and floors, while the other scenario,
                        Combined Index/ETF
                        Results, does not include {TOOL_NAME_MAPPING[selectedTool]}'s caps, buffers, and floors. </p>
                    }
                    {isIS5ProOrIS7Pro(selectedTool) &&
                    <p>The following charts summarize the results of the Monte Carlo simulations that were generated
                        based on your selections. To allow you to evaluate the impact of
                        the {TOOL_NAME_MAPPING[selectedTool]}'s features, information has been provided for your
                        allocations under two scenarios: one scenario, {TOOL_NAME_MAPPING[selectedTool]} Results,
                        reflects the
                        inclusion of the {TOOL_NAME_MAPPING[selectedTool]}'s caps, buffers, floors, and contract fees,
                        while the other
                        scenario, Combined Index/ETF Results, does not include {TOOL_NAME_MAPPING[selectedTool]}'s caps,
                        buffers,
                        floors and contract fees. </p>
                    }
                    <p><span style={{"fontStyle": "italic"}}>You cannot invest directly in an index.</span></p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <PlanCompare/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <WealthGraph
                        title={"Performance Comparison - 50th Percentile"}
                        topMessage={"Half of the simulations ended in a cumulative value higher than this value, and half resulted in a cumulative value lower than this value."}
                        bottomMessage={"*Strategy values were calculated at the end of each term."}
                        baseWealth={getPercentileData(data.results.planB, 1, "base")}
                        compareWealth={getPercentileData(data.results.planA, 1, "compare")}
                        toggleBaseGraph={props.toggleBase50Graph}
                        toggleCompareGraph={props.toggleCompare50Graph}
                        isBaseOn={ux.baseGraphs.percentile50thActive}
                        isCompareOn={ux.compareGraphs.percentile50thActive}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <WealthGraph
                        title={"Performance Comparison - 90th Percentile"}
                        topMessage={"Represents consistently better than expected investment returns. Only 10% of the simulations ended in a cumulative value higher than this value, and 90% of the simulations resulted in a cumulative value lower than this value."}
                        bottomMessage={"*Strategy values were calculated at the end of each term."}
                        baseWealth={getPercentileData(data.results.planB, 2, "base")}
                        compareWealth={getPercentileData(data.results.planA, 2, "compare")}
                        toggleBaseGraph={props.toggleBase90Graph}
                        toggleCompareGraph={props.toggleCompare90Graph}
                        isBaseOn={ux.baseGraphs.percentile90thActive}
                        isCompareOn={ux.compareGraphs.percentile90thActive}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <WealthGraph
                        title={"Performance Comparison - 10th Percentile"}
                        topMessage={"Represents consistently worse than expected investment returns. Only 10% of the simulations ended in a cumulative value lower than this value, and 90% of the simulations results in a cumulative value higher than this value."}
                        bottomMessage={"*Strategy values were calculated at the end of each term."}
                        baseWealth={getPercentileData(data.results.planB, 0, "base")}
                        compareWealth={getPercentileData(data.results.planA, 0, "compare")}
                        toggleBaseGraph={props.toggleBase10Graph}
                        toggleCompareGraph={props.toggleCompare10Graph}
                        isBaseOn={ux.baseGraphs.percentile10thActive}
                        isCompareOn={ux.compareGraphs.percentile10thActive}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Button
                        primary
                        onClick={() => props.openPreviewModal()}
                    >
                        Download
                    </Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        database: state.get("databaseReducer"),
        uxState: state.get("uxReducer"),
        adminDatabase: state.getIn(["databaseReducer", "adminDatabase"]),
        uiReducer: state.get('uiReducer')
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleBase10Graph: (value) => dispatch({
            type: actionTypes.UX_BASE_TOGGLE10,
            value: value
        }),
        toggleBase50Graph: (value) => dispatch({
            type: actionTypes.UX_BASE_TOGGLE50,
            value: value
        }),
        toggleBase90Graph: (value) => dispatch({
            type: actionTypes.UX_BASE_TOGGLE90,
            value: value
        }),
        toggleCompare10Graph: (value) => dispatch({
            type: actionTypes.UX_COMPARE_TOGGLE10,
            value: value
        }),
        toggleCompare50Graph: (value) => dispatch({
            type: actionTypes.UX_COMPARE_TOGGLE50,
            value: value
        }),
        toggleCompare90Graph: (value) => dispatch({
            type: actionTypes.UX_COMPARE_TOGGLE90,
            value: value
        }),
        recalculatePlans: () => dispatch({
            type: actionTypes.SAGA_RECALCULATE_PLAN
        }),
        openPreviewModal: () => dispatch({
            type: actionTypes.UI_UPDATE_PREVIEW_MODAL,
            value: true
        }),
        resetData: () => dispatch({
            type: actionTypes.SAGA_RESET_DATA
        }),
        keepProductSelection: (value)=>dispatch({
            type: actionTypes.UI_SET_TOOL,
            value: value
        }),
        updateVisitId: (value) => dispatch({
            type: actionTypes.DB_UPDATE_VISIT_ID_DATA,
            value: value
        })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(outputStep);
