import {fromJS} from 'immutable';
import * as actionTypes from './actions';

export const MAX_PAGES = 1;

// INCREMENT current wizard step to a maximum of MAX PAGES
// DECREMENT current wizard step to a minimum of zero.
// TOGGLE isValid when needed
export const initialState = fromJS({
    currentWizardStep: 0,
    isValid: true,
    hasBlank: false,
    inValidPassword: false,
    isStratTotalValid: true,
    isAuthorized: false,
    isRequestSuccesful: false,
    isClientsNameValid: true,
    isClientsNameNotEmpty: true,
    baseGraphs: {
        percentile10thActive: true,
        percentile50thActive: true,
        percentile90thActive: true
    },
    compareGraphs: {
        percentile10thActive: true,
        percentile50thActive: true,
        percentile90thActive: true
    }
});

let incrementPage = (state, action) => {
    return state.update("currentWizardStep", (currentWizardStep) => {
        return Math.min(currentWizardStep + 1, MAX_PAGES);
    });
};

let decrementPage = (state, action) => {
    return state.update("currentWizardStep", (currentWizardStep) => {
        return Math.max(currentWizardStep - 1, 0);
    });
};

let setIsValid = (state, action) => {
    return state.set("isValid", action.value);
};

let setHasBlank = (state, action) => {
    return state.set("hasBlank", action.value);
};

const resetData = () => {
    return initialState;
};

const setBasePercentile10 = (state, action) => {
    return state.setIn(["baseGraphs", "percentile10thActive"], action.value);
};

const setBasePercentile50 = (state, action) => {
    return state.setIn(["baseGraphs", "percentile50thActive"], action.value);
};

const setBasePercentile90 = (state, action) => {
    return state.setIn(["baseGraphs", "percentile90thActive"], action.value);
};

const setComparePercentile10 = (state, action) => {
    return state.setIn(["compareGraphs", "percentile10thActive"], action.value);
};

const setComparePercentile50 = (state, action) => {
    return state.setIn(["compareGraphs", "percentile50thActive"], action.value);
};

const setComparePercentile90 = (state, action) => {
    return state.setIn(["compareGraphs", "percentile90thActive"], action.value);
};

const setStratIsValid = (state, action) => {
    return state.setIn(["isStratTotalValid"], action.value);
};

const setIsAuthorized = (state, action) => {
    return state.setIn(["isAuthorized"], action.value);
};

const setInValidPassword = (state, action) => {
    return state
        .setIn(["inValidPassword"], true);
};

const setRequestSuccessful = (state, action) => {
    return state
        .setIn(["isRequestSuccesful"], action.value);
};

const setIsClientsNameValid = (state, action) => {
    return state.setIn(["isClientsNameValid"], action.value);
};

const setIsClientsNotNameEmpty = (state, action) => {
    return state.setIn(["isClientsNameNotEmpty"], action.value);
};

let reducerFunctions = {};

reducerFunctions[actionTypes.UX_NEXT] = incrementPage;
reducerFunctions[actionTypes.UX_PREV] = decrementPage;
reducerFunctions[actionTypes.UX_SET_VALID] = setIsValid;
reducerFunctions[actionTypes.UX_SET_HAS_BLANK] = setHasBlank;
reducerFunctions[actionTypes.UX_RESET] = resetData;
reducerFunctions[actionTypes.UX_BASE_TOGGLE10] = setBasePercentile10;
reducerFunctions[actionTypes.UX_BASE_TOGGLE50] = setBasePercentile50;
reducerFunctions[actionTypes.UX_BASE_TOGGLE90] = setBasePercentile90;
reducerFunctions[actionTypes.UX_COMPARE_TOGGLE10] = setComparePercentile10;
reducerFunctions[actionTypes.UX_COMPARE_TOGGLE50] = setComparePercentile50;
reducerFunctions[actionTypes.UX_COMPARE_TOGGLE90] = setComparePercentile90;
reducerFunctions[actionTypes.UX_SET_STRAT_VALID] = setStratIsValid;
reducerFunctions[actionTypes.UX_SET_AUTHORIZATION] = setIsAuthorized;
reducerFunctions[actionTypes.UX_SET_INVALID_PASSWORD] = setInValidPassword;
reducerFunctions[actionTypes.UX_SET_REQUEST_SUCCESSFUL] = setRequestSuccessful;
reducerFunctions[actionTypes.UX_SET_CLIENTS_NAME_VALID] = setIsClientsNameValid;
reducerFunctions[actionTypes.UX_SET_IS_CLIENTS_NOT_NAME_EMPTY] = setIsClientsNotNameEmpty;

export const uxReducer = (state = initialState, action) => {
    if (reducerFunctions.hasOwnProperty(action.type)) return reducerFunctions[action.type](state, action);
    return state;
};

