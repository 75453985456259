import React from 'react';
import {connect} from 'react-redux';
import LoginPage from '../components/Admin/LoginPage';
import * as actionTypes from '../reducers/actions';

const AdvisorAuthorization = (WrappedComponent) => {

    const AuthorizationController = (props) => {

        let content = null;
        let wasLoggedInAndRefreshed = sessionStorage.getItem('45R11');
        if(wasLoggedInAndRefreshed) props.updateIsAdminAuthorized(true)
        if (props.isAuthorized) {
            content = <WrappedComponent {...props}/>
        } else {
            content = <LoginPage/>
        }

        return (
            <div>{content}</div>
        );

    };

    const mapStateToProps = (state) => {
        return {
            isAuthorized: state.getIn(["uxReducer", "isAuthorized"])
        }
    };

    const mapDispatchToProps = (dispatch) => {
        return {
            updateIsAdminAuthorized: (value) => dispatch({
                type: actionTypes.UX_SET_AUTHORIZATION,
                value: value
            }),
        }
    };

    return connect(mapStateToProps, mapDispatchToProps)(AuthorizationController);

};

export default AdvisorAuthorization;