import {fromJS} from 'immutable';
import * as actionTypes from './actions';
import {UI_SET_IS_TOOLS} from "./actions";

export const initialState = fromJS({
    showLoadingModal: false,
    showNextButton: true,
    showPrevButton: false,
    showValidationMessage: false,
    showErrorModal: false,
    showPreviewModal: false,
    showNavigationRowButtons: false,
    showBackToIntroductionButton: true,
    adminSelectedTool: actionTypes.UI_SET_ADMIN_IS6,
    selectedTool: actionTypes.UI_SET_IS6,
    isTools: {
        institution: null,
        products: []
    }
});

// SET showLoadingModal STATE
// Setting showLoadingModal to true should set showErrorModal to false
// SET showNextButton
// SET showPrevButton
// SET showValidationMessage
// SET showErrorModal
// SET showErrorModal to true should set showLoadingModal to false

const setLoadingModal = (state, action) => {
    return state
        .update("showLoadingModal", () => (action.value))
        .update("showErrorModal", (showErrorModal) => {
            if (action.value) return false;
            return showErrorModal
        });
};

const setShowNext = (state, action) => {
    return state.update("showNextButton", () => (action.value));
};

const setShowPrev = (state, action) => {
    return state.update("showPrevButton", () => (action.value));
};

const setShowValidationMSG = (state, action) => {
    return state.update("showValidationMessage", () => (action.value));
};

const setErrorModal = (state, action) => {
    return state
        .update("showErrorModal", () => (action.value))
        .update("showLoadingModal", (showLoadingModal) => {
            if (action.value) return false;
            return showLoadingModal;
        });
};

const resetData = () => {
    return initialState;
};

const updatePreviewModal = (state, action) => {
    return state
        .set("showPreviewModal", action.value);
};

const updateNavRowButtonsVisibility = (state, action) => {
    return state
        .set("showNavigationRowButtons", action.value);
};

const updateIsTools = (state, action) => {
    sessionStorage.setItem('isTools', JSON.stringify(action.value));
    return state.set("isTools", action.value);
};

const updateTool = (state, action) => {
    sessionStorage.setItem('selectedTool', action.value);
    return state.set("selectedTool", action.value);
};

const updateAdminTool = (state, action) => {
    sessionStorage.setItem('adminSelectedTool', action.value);
    return state.set("adminSelectedTool", action.value);
};

const hideBackToIntroductionButton = (state, action) => {
    sessionStorage.setItem('showBackToIntroductionButton', false);
    return state.set("showBackToIntroductionButton", false);
};

const showBackToIntroductionButton = (state, action) => {
    sessionStorage.setItem('showBackToIntroductionButton', true);
    return state.set("showBackToIntroductionButton", true);
};

let reducerFunctions = {};

reducerFunctions[actionTypes.UI_SET_LOADING_MODAL] = setLoadingModal;
reducerFunctions[actionTypes.UI_SET_SHOW_NEXT] = setShowNext;
reducerFunctions[actionTypes.UI_SET_SHOW_PREV] = setShowPrev;
reducerFunctions[actionTypes.UI_SET_SHOW_VALIDATION] = setShowValidationMSG;
reducerFunctions[actionTypes.UI_SET_SHOW_ERROR_MODAL] = setErrorModal;
reducerFunctions[actionTypes.UI_RESET] = resetData;
reducerFunctions[actionTypes.UI_UPDATE_PREVIEW_MODAL] = updatePreviewModal;
reducerFunctions[actionTypes.UI_SET_NAVIGATION_ROW_BUTTONS_VISIBILITY] = updateNavRowButtonsVisibility;
reducerFunctions[actionTypes.UI_SET_TOOL] = updateTool;
reducerFunctions[actionTypes.UI_SET_ADMIN_TOOL] = updateAdminTool;
reducerFunctions[actionTypes.UI_HIDE_BACKTOINTRODUCTION] = hideBackToIntroductionButton;
reducerFunctions[actionTypes.UI_SHOW_BACKTOINTRODUCTION] = showBackToIntroductionButton;
reducerFunctions[actionTypes.UI_SET_IS_TOOLS] = updateIsTools;

export const uiReducer = (state = initialState, action) => {
    if (reducerFunctions.hasOwnProperty(action.type)) return reducerFunctions[action.type](state, action);
    return state;
};
