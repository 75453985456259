import React, {Component} from 'react';
import {Icon} from "semantic-ui-react";
import './ToolTip.css';

class ToolTip extends Component {

    state = {
        showMessage: false
    };

    mouseEnter = () => {
        let newState = Object.assign({}, this.state);
        newState.showMessage = true;
        this.setState(newState);
    };

    mouseLeave = () => {
        let newState = Object.assign({}, this.state);
        newState.showMessage = false;
        this.setState(newState);
    };

    render() {
        let messageDiv = null;

        if (this.state.showMessage) {
            let messageClassName = "message ";
            if (this.props.position != null) {
                messageClassName += this.props.position
            }else {
                messageClassName += "right";
            }
            messageDiv = (
                <div className={messageClassName}>{this.props.children}</div>
            );
        }

        return (
            <div className="ToolTip">
                <div
                    className="icon"
                    onMouseEnter={this.mouseEnter}
                    onMouseLeave={this.mouseLeave}
                >
                    <Icon name={'question circle'} />
                </div>
                {messageDiv}
            </div>
        );
    }
}

export default ToolTip;