import axios from 'axios';
import {PRINCE_URL, SERVER_URL, API_KEY} from "../config";


let advisorId = null;
let token = ''
let adminToken = ''

axios.interceptors.request.use(function (config) {
    config.withCredentials = false;
    return config;
}, function (error) {
    return Promise.reject(error);
});


// export const login_and_get_assets = () => {

//     let now = new Date();

//     let loginToken = null;

//     return axios.get(`${SERVER_URL}/j_spring_security_logout?timestamp=${now.getTime().toString()}`)
//         .then(() => {
//             return axios.post(`${SERVER_URL}/gaig-calculator/registration`,
//                 {"email":"raymondjames@raymondjames.com","firstName":"Raymond","lastName":"James","phone":"123456789","firmName":"Raymond James","noOfAdvisors":1,"noOfClients":1});
//         })
//         .then((response) => {
//             let accessToken = response.data.payload.accessToken;

//             return axios.post(`${SERVER_URL}/gaig-calculator/getauthorizationtoken`, {
//                 accessToken: accessToken
//             } );
//         })
//         .then( (response) => {
//             loginToken = 'j_username=' + response.data.payload.authorizationToken + '&j_password=' + "wealth123!";
//             return axios.get(`${SERVER_URL}/gaig-calculator/user/current`, {withCredentials: true});
//         })
//         .then(() => {
//             return axios({
//                 method: 'post',
//                 url: `${SERVER_URL}/j_spring_security_check`,
//                 data: loginToken,
//                 headers: {'Content-Type': 'application/x-www-form-urlencoded'}
//             });
//         })
//         .then((response) => {

//             advisorId = response.data.user.advisor.advisorId;

//             return axios.get(`${SERVER_URL}/gaig-calculator/advisor/${advisorId}/assetClassInfo`)
//         });
// };

export const login_and_get_assets = (produceType) => {
    let accessToken = ''
    return axios.post(`${SERVER_URL}/gaig-calculator/registration`,
    {"email":"raymondjames@raymondjames.com","firstName":"Raymond","lastName":"James","phoneNumber":"123456789","firmName":"Raymond James","noOfAdvisors":1,"noOfClients":1},
    {
        headers: { Authorization: `APIKEY ${API_KEY}` }
    })
    .then((response) => {
        accessToken = response.data.accessToken;
        token = accessToken;
        return axios.get(`${SERVER_URL}/gaig-calculator/user-info`, {
            headers: {Authorization: `Bearer ${accessToken}`}
        } );
    })
    .then( (response) => {
        advisorId = response.data.advisorId;
        return axios.get(`${SERVER_URL}/gaig-calculator/advisor/${advisorId}/assetClassInfo?product-Type=${produceType}`,{
            headers: {Authorization: `Bearer ${accessToken}`}
        })
    })
};

export const create_user = (visitId) => {
    return axios(
        {
            method: 'post',
            url:  `${SERVER_URL}/gaig-calculator/advisor/${advisorId}/client/create`,
            data: {
                "clientId": null,
                "advisorId": advisorId,
                "visitId": visitId,
                "status": "Active",
                "dateOfBirth": "1981-01-01",
                "firstName": "firstname",
                "gender": "Male",
                "lastName": "lastname",
                "middleName": ""
            },
            headers: {Authorization: `Bearer ${token}`}
        })
}

export const create_plans = (userId, basePlan, comparePlan) => {

     let basePlanPromise = axios(
        {
            method: 'post',
            url:  `${SERVER_URL}/gaig-calculator/advisor/${advisorId}/client/${userId}/plan/create`,
            data: basePlan,
            headers: {Authorization: `Bearer ${token}`}
        })

    return Promise.all([basePlanPromise]);
};

// export const create_is6pro_plans = (userId, basePlan, comparePlan) => {
//
//      let basePlanPromise = axios(
//         {
//             method: 'post',
//             url:  `${SERVER_URL}/gaig-calculator/is6-pro/advisor/${advisorId}/client/${userId}/plan/create`,
//             data: basePlan,
//             headers: {Authorization: `Bearer ${token}`}
//         })
//
//     return Promise.all([basePlanPromise]);
// };

export const calculate_plan = (userId, planId, basePlanId, visitId) => {

    let url = `${SERVER_URL}/gaig-calculator/advisor/${advisorId}/client/${userId}/plan/${planId}/visit/${visitId}/calculate`;

    if (basePlanId) {
        url = `${url}?basePlanId=${basePlanId}`;
    }

    return axios(
        {
            method: 'get',
            url:  url,
            headers: {Authorization: `Bearer ${token}`}
        })
};

export const complete_plan = (clientId, planId, visitId) => {

    let url = `${SERVER_URL}/gaig-calculator/advisor/${advisorId}/client/${clientId}/plan/${planId}/visit/${visitId}/plan-completed`;

    return axios(
        {
            method: 'post',
            url:  url,
            headers: {Authorization: `Bearer ${token}`}
        })
};

export const update_market_risk = (userId, equity) => {
    let url = `${SERVER_URL}/gaig-calculator/advisor/${advisorId}/client/${userId}/marketRisk`;
    return axios(
        {
            method: 'post',
            url:  url,
            data: {
                equity: equity
            },
            headers: {Authorization: `Bearer ${token}`}
        })
};

export const generate_pdf = (userId, pdfOutput) => {
    let url = `${SERVER_URL}/gaig-calculator/advisor/${advisorId}/client/${userId}/pdfgen`;

    let htmlText = '<!DOCTYPE html>' +
        '<html>' +
        '<head lang="en">' +
        '    <meta charset="UTF-8">' +
        '    <title>PDF Report</title>' +
        '    <link rel="stylesheet" href="' + PRINCE_URL + '/semantic-ui.css"/>' +
        '    <link rel="stylesheet" href="' + PRINCE_URL + '/prince.css" />' +
        '</head>' +
        '<body>' +
        '   <div class="planOutput">';
    pdfOutput = pdfOutput.replace(/ui grid/g, '');

    let output = htmlText + pdfOutput + "   </div></body></html>";

    return axios.post(url, output, {'headers': {'Content-Type': 'application/json;charset=UTF-8', Authorization: `Bearer ${token}`}});
};

export const get_is6_admin_data = () => {
    return axios.get(`${SERVER_URL}/gaig-calculator/asset-class/index-strategies?productType=INDEX_SUMMIT_6`);
};

export const get_is5_admin_data = () => {
    return axios.get(`${SERVER_URL}/gaig-calculator/asset-class/index-strategies?productType=INDEX_FRONTIER_5`);
};

export const get_is7_admin_data = () => {
    return axios.get(`${SERVER_URL}/gaig-calculator/asset-class/index-strategies?productType=INDEX_FRONTIER_7`);
};

export const get_is6pro_admin_data = () => {
    return axios.get(`${SERVER_URL}/gaig-calculator/asset-class/index-strategies?productType=INDEX_SUMMIT_6_PRO`);
};

export const get_is5pro_admin_data = () => {
    return axios.get(`${SERVER_URL}/gaig-calculator/asset-class/index-strategies?productType=INDEX_FRONTIER_5_PRO`);
};

export const get_is7pro_admin_data = () => {
    return axios.get(`${SERVER_URL}/gaig-calculator/asset-class/index-strategies?productType=INDEX_FRONTIER_7_PRO`);
};

export const save_is6_admin_data = (adminData) => {
    let getTokenIfPageRefreshed = sessionStorage.getItem('2LBY');
    if(getTokenIfPageRefreshed) adminToken = getTokenIfPageRefreshed;
    let url = `${SERVER_URL}/gaig-calculator/admin/asset-class/index-strategies/update`;
    return axios(
        {
            method: 'post',
            url:  url,
            data: adminData,
            headers: {Authorization: `Bearer ${adminToken}`}
        }  
    )
}

export const save_is5_admin_data = (adminData) => {
    let getTokenIfPageRefreshed = sessionStorage.getItem('2LBY');
    if(getTokenIfPageRefreshed) adminToken = getTokenIfPageRefreshed;
    let url = `${SERVER_URL}/gaig-calculator/admin/asset-class/index-strategies/update`;
    return axios(
        {
            method: 'post',
            url:  url,
            data: adminData,
            headers: {Authorization: `Bearer ${adminToken}`}
        }
    )
}

export const save_is7_admin_data = (adminData) => {
    let getTokenIfPageRefreshed = sessionStorage.getItem('2LBY');
    if(getTokenIfPageRefreshed) adminToken = getTokenIfPageRefreshed;
    let url = `${SERVER_URL}/gaig-calculator/admin/asset-class/index-strategies/update`;
    return axios(
        {
            method: 'post',
            url:  url,
            data: adminData,
            headers: {Authorization: `Bearer ${adminToken}`}
        }
    )
}

export const save_is6pro_admin_data = (is6ProAdminData)=>{
    let getTokenIfPageRefreshed = sessionStorage.getItem('2LBY');
    if(getTokenIfPageRefreshed) adminToken = getTokenIfPageRefreshed;
    let url = `${SERVER_URL}/gaig-calculator/admin/asset-class/index-strategies/update`;
    return axios(
        {
            method: 'post',
            url:  url,
            data: is6ProAdminData,
            headers: {Authorization: `Bearer ${adminToken}`}
        }  
    )
}

export const save_is5pro_admin_data = (is6ProAdminData)=>{
    let getTokenIfPageRefreshed = sessionStorage.getItem('2LBY');
    if(getTokenIfPageRefreshed) adminToken = getTokenIfPageRefreshed;
    let url = `${SERVER_URL}/gaig-calculator/admin/asset-class/index-strategies/update`;
    return axios(
        {
            method: 'post',
            url:  url,
            data: is6ProAdminData,
            headers: {Authorization: `Bearer ${adminToken}`}
        }
    )
}

export const save_is7pro_admin_data = (is6ProAdminData)=>{
    let getTokenIfPageRefreshed = sessionStorage.getItem('2LBY');
    if(getTokenIfPageRefreshed) adminToken = getTokenIfPageRefreshed;
    let url = `${SERVER_URL}/gaig-calculator/admin/asset-class/index-strategies/update`;
    return axios(
        {
            method: 'post',
            url:  url,
            data: is6ProAdminData,
            headers: {Authorization: `Bearer ${adminToken}`}
        }
    )
}

export const get_institute_products = (instituteName) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${SERVER_URL}/gaig-calculator/institute-products?instituteName=${instituteName}`
        }).then((response) => {
            resolve(response.data);
        });
    });
}

export const get_admin_institute_products = () => {
    let getTokenIfPageRefreshed = sessionStorage.getItem('2LBY');
    if(getTokenIfPageRefreshed) adminToken = getTokenIfPageRefreshed;
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${SERVER_URL}/gaig-calculator/admin/institute-products`,
            headers: {Authorization: `Bearer ${adminToken}`}
        }).then((response) => {
            resolve(response.data);
        });
    });
}

export const save_admin_institute_products = (data) => {
    let getTokenIfPageRefreshed = sessionStorage.getItem('2LBY');
    if(getTokenIfPageRefreshed) adminToken = getTokenIfPageRefreshed;
    let url = `${SERVER_URL}/gaig-calculator/admin/institute-product/update`;
    return axios(
        {
            method: 'post',
            url:  url,
            data: data,
            headers: {Authorization: `Bearer ${adminToken}`}
        }
    )
}

export const get_business_intelligence_total_time_spent = (from, to) => {
    let getTokenIfPageRefreshed = sessionStorage.getItem('2LBY');
    if(getTokenIfPageRefreshed) adminToken = getTokenIfPageRefreshed;
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${SERVER_URL}/gaig-calculator/admin/total-time-spent?From=${from}&To=${to}`,
            headers: {Authorization: `Bearer ${adminToken}`}
        }).then((response) => {
            resolve(response.data);
        });
    });
}

export const get_business_intelligence_visit_completed = (from, to) => {
    let getTokenIfPageRefreshed = sessionStorage.getItem('2LBY');
    if(getTokenIfPageRefreshed) adminToken = getTokenIfPageRefreshed;
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${SERVER_URL}/gaig-calculator/admin/visit-completed?From=${from}&To=${to}`,
            headers: {Authorization: `Bearer ${adminToken}`}
        }).then((response) => {
            resolve(response.data);
        });
    });
}

export const create_client_visit = (institute, productType) => {
    let url = `${SERVER_URL}/gaig-calculator/visit/create`;
    return axios(
        {
            method: 'post',
            url:  url,
            data: {
                gaigInstitute: institute,
                gaigProductType: productType
            }
        })
}

export const login_admin = (username, password) => {
    let url = `${SERVER_URL}/gaig-calculator/admin-login`;
    let data = {
            userName: username,
            password: password,
        }
    return axios(
        {
            method: 'post',
            url:  url,
            data: data,
        })
        .then((response)=>{
            sessionStorage.setItem('45R11', '2001')
            adminToken = response.data.accessToken;
            sessionStorage.setItem('2LBY', adminToken)
            let terAt = (Date.now() / 1000) + 1200;
            sessionStorage.setItem('terAt', terAt)
            return true;
        })
        .catch(()=>{
            return false;
        })
};

export const logout_admin = () => {
    sessionStorage.setItem('showAdminIS6Pro','0');
    sessionStorage.removeItem('2LBY');
    sessionStorage.removeItem('45R11');
    let url = `${SERVER_URL}/gaig-calculator/admin-logout`;
    return axios(
        {
            method: 'post',
            url:  url,
            headers: {Authorization: `Bearer ${adminToken}`}
        })
        .then((response)=>{
            return true;
        })
        .catch(()=>{
            return false;
        })
};