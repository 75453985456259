import React from 'react';
import { Segment, Grid, Label, Header } from "semantic-ui-react";
import { connect } from 'react-redux';
import NumberFormat from "react-number-format";
import { getInputClass } from "../../../../common/common";
import * as actionTypes from '../../../../reducers/actions';
import {
    SPDRGOLDSHARES_MAPPING
} from "../../../../reducers/actions";

const spdrGoldSharesBox = (props) => {

    let data = props.database.toJS();
    const selectedTool = props.uiReducer.toJS().selectedTool;
    let total = data[SPDRGOLDSHARES_MAPPING[selectedTool]].oneYRMinusTenFloorCap.value;
    if (isNaN(total)) total = 0.0;

    return (
        <Segment raised>
            <Header as="h1">SPDR Gold Shares</Header>

            <Grid columns='equal'>

                <Grid.Row>
                    <Grid.Column></Grid.Column>
                    <Grid.Column></Grid.Column>
                    <Grid.Column>
                        <Label basic>Purchase Payments $100,000 and Over</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>Purchase Payments under $100,000</Label>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Label basic>1-yr -10% Floor with Cap</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data[SPDRGOLDSHARES_MAPPING[selectedTool]].oneYRMinusTenFloorCap.isValid)}>
                            <NumberFormat
                                displayType={'input'}
                                suffix={'%'}
                                value={data[SPDRGOLDSHARES_MAPPING[selectedTool]].oneYRMinusTenFloorCap.value}
                                onValueChange={(values) => props.updateSpdrGoldShares(selectedTool, "oneYRMinusTenFloorCap", values.value)}
                                onClick={() => props.updateSpdrGoldShares(selectedTool, "oneYRMinusTenFloorCap", "")}
                                onBlur={() => props.validateSpdrGoldShares(selectedTool)}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SPDRGOLDSHARES_MAPPING[selectedTool]].oneYRMinusTenFloorCap.paymentOver100K.value}%</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SPDRGOLDSHARES_MAPPING[selectedTool]].oneYRMinusTenFloorCap.paymentUnder100K.value}%</Label>
                    </Grid.Column>

                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Label basic>SPDR Gold Shares Total:</Label>
                    </Grid.Column>
                    <Grid.Column>
                        {total}%
                    </Grid.Column>
                    <Grid.Column></Grid.Column>
                    <Grid.Column></Grid.Column>
                </Grid.Row>

            </Grid>

        </Segment>
    )
};

const mapStateToProps = (state) => {
    return {
        database: state.get('databaseReducer'),
        uiReducer: state.get('uiReducer'),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSpdrGoldShares: (selectedTool, key, value) => dispatch({
            type: actionTypes.DB_UPDATE_SPDRGOLDSHARES,
            selectedTool: selectedTool,
            key: key,
            value: value
        }),
        validateSpdrGoldShares: (selectedTool) => dispatch({
            type: actionTypes.DB_VALIDATE_SPDRGOLDSHARES,
            selectedTool: selectedTool
        })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(spdrGoldSharesBox);