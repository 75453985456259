import React from 'react';
import {Segment, Label, Header, Grid} from "semantic-ui-react";
import { connect } from 'react-redux';
import {
    DECLAREDSTRATEGY_MAPPING,
    SHAREMCSI_MAPPING,
    SHAREREALESTATE_MAPPING, SPDRGOLDSHARES_MAPPING,
    SPINDEX_MAPPING
} from "../../../../reducers/actions";
import {isIS5OrIS7, isIS6OrIS6Pro} from "../../../../common/common";


const strategiesTotalBox = (props) => {

    let data = props.database.toJS();
    const selectedTool = props.uiReducer.toJS().selectedTool;
    let total = 0;
    if (isIS6OrIS6Pro(selectedTool)) {
        total = data[SPINDEX_MAPPING[selectedTool]].oneYRTermCap.value
            + data[SPINDEX_MAPPING[selectedTool]].oneYRTermPat.value
            + data[SPINDEX_MAPPING[selectedTool]].oneYRBufferCap.value
            + data[SPINDEX_MAPPING[selectedTool]].oneYRMinusTenFloorCap.value
            + data[SPINDEX_MAPPING[selectedTool]].oneYRZeroFloorCap.value
            + data[SPINDEX_MAPPING[selectedTool]].twoYRTermCap.value
            + data[SPINDEX_MAPPING[selectedTool]].twoYRTermPat.value
            + data[SPINDEX_MAPPING[selectedTool]].sixYrTermPatTenBuffer.value
            + data[SHAREMCSI_MAPPING[selectedTool]].oneYRTermPat.value
            + data[SHAREMCSI_MAPPING[selectedTool]].oneYRTermCap.value
            // + data[SHAREMCSI_MAPPING[selectedTool]].twoYRTermPat.value
            + data[SHAREREALESTATE_MAPPING[selectedTool]].oneYRTermPat.value
            + data[SHAREREALESTATE_MAPPING[selectedTool]].oneYRTermCap.value
            // + data[SHAREREALESTATE_MAPPING[selectedTool]].twoYRTermPat.value
            + data[SPDRGOLDSHARES_MAPPING[selectedTool]].oneYRMinusTenFloorCap.value
            + data[DECLAREDSTRATEGY_MAPPING[selectedTool]].value;
    } else {
        total = data[SPINDEX_MAPPING[selectedTool]].tenPercentBufferIdxStrategy.value
            + data[SPINDEX_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value
            + data[SPINDEX_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value
            + data[SHAREMCSI_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value
            + data[SHAREMCSI_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value
            + data[SHAREREALESTATE_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value
            + data[SHAREREALESTATE_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value
            + data[DECLAREDSTRATEGY_MAPPING[selectedTool]].value;
        if (isIS5OrIS7(selectedTool)) {
            total = total
                + data[SPDRGOLDSHARES_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value
                + data[SPDRGOLDSHARES_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value;
        }
    }

    if (isNaN(total)) total = 0;

    return (
        <Segment raised>
            <Grid columns='equal'>

                <Grid.Row>
                    <Grid.Column>
                        <Header as="h1">Total</Header>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{total}%</Label>
                    </Grid.Column>
                    <Grid.Column>
                    </Grid.Column>
                    <Grid.Column>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

const mapStateToProps = (state) => {
    return {
        database: state.get("databaseReducer"),
        uiReducer: state.get('uiReducer'),
    }
};

export default connect(mapStateToProps, null)(strategiesTotalBox);