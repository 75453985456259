import React from 'react';
import {Grid, Button, Container, Message, Segment, List} from 'semantic-ui-react';
import ShareMCSI from './ShareMCSI/index';
import ShareMCSI5And7 from './ShareMCSI5And7/index';
import ShareRealEstate from './ShareRealEstate/index';
import ShareRealEstate5And7 from './ShareRealEstate5And7/index';
import SPIndex from './SPIndex/index';
import SPIndex5And7 from './SPIndex5And7/index';
import SpdrGoldShares from './SpdrGoldShares/index';
import SpdrGoldShares5And7 from './SpdrGoldShares5And7/index';
import IS5ProFees from './IS5ProFees/index';
import IS6ProFees from './IS6ProFees/index';
import IS7ProFees from './IS7ProFees/index';
import SideBar from '../SideBar/index';
import './index.css';
import {connect} from 'react-redux';
import * as actionTypes from '../../../reducers/actions';
import {isIS5OrIS7, isIS6OrIS6Pro} from "../../../common/common";

const adminPage = (props) => {
    let adminSelectedTool = sessionStorage.getItem('adminSelectedTool');
    adminSelectedTool = adminSelectedTool ? adminSelectedTool : actionTypes.UI_SET_ADMIN_IS6;
    props.updateAdminShowISTool(adminSelectedTool);
    const db = props.database.toJS().adminDatabase
    let ux = props.uxReducer.toJS();

    let invValidMessage = null;

    if (!ux.isValid && !ux.hasBlank) {
        invValidMessage = (
            <Grid.Row>
                <Grid.Column>
                    <Message negative>
                        <Message.Content>
                            Please re-check your inputs.
                        </Message.Content>
                    </Message>
                </Grid.Column>
            </Grid.Row>
        );
    }

    if (ux.hasBlank) {
        invValidMessage = (
            <Grid.Row>
                <Grid.Column>
                    <Message negative>
                        <Message.Content>
                            You cannot have blank fields. Please recheck.
                        </Message.Content>
                    </Message>
                </Grid.Column>
            </Grid.Row>
        );
    }

    if (ux.isRequestSuccesful) {
        invValidMessage = (
            <Grid.Row>
                <Grid.Column>
                    <Message positive>
                        <Message.Content>
                            <Grid columns='equal' verticalAlign='middle'>
                                <Grid.Row>
                                    <Grid.Column></Grid.Column>
                                    <Grid.Column width={11}>Your changes have been saved!</Grid.Column>
                                    <Grid.Column><Button onClick={()=>{props.closeSuccesfulRequest(false)}} positive>✔</Button></Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Message.Content>
                    </Message>
                </Grid.Column>
    </Grid.Row>
        );
    }

    return (
        <Container>
            <Grid>
                <Grid.Row className={'admin-index-page'}>
                    <Grid.Column width={4}>
                        <SideBar />
                    </Grid.Column>
                    <Grid.Column width={12}>
                        {/*<Grid.Row>*/}
                        {/*    <Grid.Column>*/}
                        {/*        <ProductSelection/>*/}
                        {/*    </Grid.Column>*/}
                        {/*</Grid.Row>*/}
                        {invValidMessage}
                        {adminSelectedTool === actionTypes.UI_SET_ADMIN_IS6Pro
                        &&<Grid.Row>
                            <Grid.Column>
                                <IS6ProFees/>
                            </Grid.Column>
                        </Grid.Row>}
                        {adminSelectedTool === actionTypes.UI_SET_ADMIN_IS5Pro
                        &&<Grid.Row>
                            <Grid.Column>
                                <IS5ProFees/>
                            </Grid.Column>
                        </Grid.Row>}
                        {adminSelectedTool === actionTypes.UI_SET_ADMIN_IS7Pro
                        &&<Grid.Row>
                            <Grid.Column>
                                <IS7ProFees/>
                            </Grid.Column>
                        </Grid.Row>}
                        <Grid.Row>
                            <Grid.Column>
                                {isIS6OrIS6Pro(adminSelectedTool) && <SPIndex/>}
                                {!isIS6OrIS6Pro(adminSelectedTool) && <SPIndex5And7/>}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                {isIS6OrIS6Pro(adminSelectedTool) && <ShareMCSI/>}
                                {!isIS6OrIS6Pro(adminSelectedTool) && <ShareMCSI5And7/>}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                {isIS6OrIS6Pro(adminSelectedTool) && <ShareRealEstate/>}
                                {!isIS6OrIS6Pro(adminSelectedTool) && <ShareRealEstate5And7/>}
                            </Grid.Column>
                        </Grid.Row>
                        {isIS6OrIS6Pro(adminSelectedTool) &&
                        <Grid.Row>
                            <Grid.Column>
                                <SpdrGoldShares/>
                            </Grid.Column>
                        </Grid.Row>}
                        {isIS5OrIS7(adminSelectedTool) &&
                        <Grid.Row>
                            <Grid.Column>
                                <SpdrGoldShares5And7/>
                            </Grid.Column>
                        </Grid.Row>}
                        <Grid.Row>
                            <Grid.Column>
                                <Button onClick={() => {props.save(); window.scrollTo(0, 0)}}>Save</Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        uiReducer: state.get('uiReducer'),
        uxReducer: state.get("uxReducer"),
        database: state.get('databaseReducer'),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        save: () => dispatch({
            type: actionTypes.SAGA_SAVE_ADMIN_DATA
        }),
        closeSuccesfulRequest: () => dispatch({
            type: actionTypes.UX_SET_REQUEST_SUCCESSFUL
        }),
        updateAdminShowISTool: (value) => dispatch({
            type: actionTypes.UI_SET_ADMIN_TOOL,
            value: value
        })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(adminPage);