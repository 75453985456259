import React , { useEffect }from 'react';
import IndexStrategiesStep from './IndexStrategiesStep/index';
import OutputStep from './OutputStep/index';
import {Container} from "semantic-ui-react";
import {connect} from 'react-redux';
import * as actionTypes from '../../reducers/actions';

// Show the component associated with the current wizard step
// change the component when the wizard step changes.
// perhaps show a nice transition animation when this happens

const getWizardStep = (stepNumber) => {
    switch(stepNumber) {
        case 0:
            return (<IndexStrategiesStep />);
        case 1:
            return (<OutputStep />);
        default:
            return null;
    }
};

const wizard = (props) => {

    let selectedTool = sessionStorage.getItem('selectedTool');
    if(selectedTool) props.keepProductSelection(selectedTool);
    let currentWizardStep = props.uxReducer.toJS().currentWizardStep;
    let currentWizardComponent = getWizardStep(currentWizardStep);
    props.updateNavRowButtonsVisibility(true)

    return (
        <Container>
            {currentWizardComponent}
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        uxReducer: state.get('uxReducer')
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateNavRowButtonsVisibility: (value) => dispatch({
            type: actionTypes.UI_SET_NAVIGATION_ROW_BUTTONS_VISIBILITY,
            value: value
        }),
        keepProductSelection: (value)=>dispatch({
            type: actionTypes.UI_SET_TOOL,
            value: value
        }) 
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(wizard);