import React from 'react';
import { Icon } from 'semantic-ui-react';
import './index.css';

// arrow alternate circle up

const changeIndicator = (props) => {

    let upArrowName = "arrow alternate circle up";
    let downArrowDown = "arrow alternate circle down";
    let sameArrow = "arrows alternate horizontal";

    let baseValue = parseFloat(props.baseValue);
    let compareValue = parseFloat(props.compareValue);

    let arrowName = "";
    if (baseValue === compareValue) {
        arrowName = sameArrow;
    } else if (baseValue < compareValue) {
        arrowName = upArrowName;
    } else {
        arrowName = downArrowDown;
    }

    let percentDiff = (Math.abs(baseValue - compareValue) / baseValue) * 100;

    return (
        <span>
            <Icon name={arrowName} />
            {percentDiff.toFixed(2)}%
        </span>


    );
};

export default changeIndicator;