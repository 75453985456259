import React, {Component} from 'react';
import { Segment, Divider, Grid, Header, Container } from 'semantic-ui-react';
import * as d3 from "d3";
import {formatCurrency} from "../../../../common/common";
import { connect } from 'react-redux';
import './index.css';
import {TOOL_NAME_MAPPING} from "../../../../reducers/actions";

class WealthChart extends Component {

    constructor(props){
        super(props);
        this.createLineChart = this.createLineChart.bind(this);
    }

    componentDidMount() {
        this.createLineChart();
    }

    componentDidUpdate() {
        this.createLineChart();
    }

    createLineChart() {
        let margin = { left:90, right:20, top:10, bottom:50 };

        let width = 680 - margin.left - margin.right,
            height = 400 - margin.top - margin.bottom;

        // stops react from appending copies of the graph.
        // might need a better solution for this in the future.
        this.node.innerHTML = "";

        let svg = d3.select(this.node)
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left
                + ", " + margin.top + ")");

        let xScale = d3.scaleLinear()
            .domain(d3.extent(this.props.baseWealth, (d) => d.year)) // input
            .range([0, width]); // output

        let base_yMin = d3.min(this.props.baseWealth, (d) => parseFloat(d.percentileValue));
        let compare_yMin = d3.min(this.props.compareWealth, (d) => parseFloat(d.percentileValue));

        let base_yMax = d3.max(this.props.baseWealth, (d) => parseFloat(d.percentileValue));
        let compare_yMax = d3.max(this.props.compareWealth, (d) => parseFloat(d.percentileValue));

        let yMin = Math.min(base_yMin, compare_yMin) - Math.min(base_yMin, compare_yMin) * 0.1;
        let yMax = Math.max(base_yMax, compare_yMax) + Math.max(base_yMax, compare_yMax) * 0.1;

        let yScale = d3.scaleLinear()
            .domain([0, yMax]) // input
            .range([height, 0]); // output

        let line = d3.line()
            .x(function(d) {
                return xScale(d.year);
            })
            .y(function(d) {
                return yScale(parseFloat(d.percentileValue));
            })
            .curve(d3.curveMonotoneX);

        svg.append("g")
            .attr("class", "x axis")
            .attr("transform", "translate(0," + height + ")")
            .call(
                d3.axisBottom(xScale)
                    .tickFormat((d, i) => {
                        let year = d - (new Date()).getFullYear();
                        return "Year " + (year + 1);
                    })
            );

        svg.append("g")
            .attr("class", "y axis")
            .call(
                d3.axisLeft(yScale)
                    .tickFormat((d, i) => {
                        return formatCurrency(d);
                    })
            );

        // Tool-tip
        let div = d3.select("#tooltip");

        let circleSet = [];

        if (this.props.isBaseOn) {
            svg.append("path")
                .datum(this.props.baseWealth)
                .attr("class", "line basePlan")
                .attr("d", line);

            circleSet = circleSet.concat(this.props.baseWealth);
        }

        if (this.props.isCompareOn) {
            svg.append("path")
                .datum(this.props.compareWealth)
                .attr("class", "line comparePlan")
                .attr("d", line);

            circleSet = circleSet.concat(this.props.compareWealth);
        }


        svg.selectAll(".dot")
            .data(circleSet)
            .enter()
            .append("circle")
            .attr("class", (d) => {
                if (d.label === "base") {
                    return "dot basePlan";
                } else {
                    return "dot comparePlan"
                }
            })
            .attr("cx", function(d, i) { return xScale(d.year) })
            .attr("cy", function(d) {
                return yScale(parseFloat(d.percentileValue));
            })
            .attr("r", 4)
            .on("mouseover", function(d) {
                let year = d.year < (new Date()).getFullYear() ? "" : "Year: " + d.year;
                div.transition()
                    .duration(200)
                    .style("opacity", .9);
                div.html(year + "<br/>Amount: " + formatCurrency(d.percentileValue))
                    .style("left", (d3.event.pageX) + "px")
                    .style("top", (d3.event.pageY - 28) + "px");
            })
            .on("mouseout", function(d) {
                div.transition()
                    .duration(500)
                    .style("opacity", 0);
            });

    }


    render() {
        return (
            <Segment>
                <Header as="h1">{this.props.title}</Header>
                <Container className="graph-message">
                    {this.props.topMessage}
                </Container>
                <div ref={node => this.node = node}></div>
                <Container className="graph-message">
                    {this.props.bottomMessage}
                </Container>
                <Divider/>
                <Grid className="graph-legend"  columns='equal'>
                    <Grid.Row>
                        <Grid.Column width={2}>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <div className="graph-legend__entry">
                                <div className="graph-legend__box blue"></div>
                                <label> {TOOL_NAME_MAPPING[this.props.uiReducer.toJS().selectedTool]} Results</label>
                            </div>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <div className="graph-legend__entry">
                                <div className="graph-legend__box black"></div>
                                <label> Combined Index/ETF Results</label>
                            </div>
                        </Grid.Column>
                        <Grid.Column width={2}></Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        uiReducer: state.get('uiReducer')
    }
};

export default connect(mapStateToProps, null)(WealthChart);