import React from 'react';
import {Segment, Grid, Table} from "semantic-ui-react";
import { connect } from 'react-redux';
import {
    SHAREMCSI_MAPPING,
    SHAREREALESTATE_MAPPING, SPDRGOLDSHARES_MAPPING,
    SPINDEX_MAPPING, TOOL_NAME_MAPPING
} from "../../../../reducers/actions";
import {isIS5OrIS7} from "../../../../common/common";


const IndexFrontierCapAndProtection = (props) => {

    let data = props.database.toJS();
    const selectedTool = props.uiReducer.toJS().selectedTool;

    let total = data[SPINDEX_MAPPING[selectedTool]].tenPercentBufferIdxStrategy.value
        + data[SPINDEX_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value
        + data[SPINDEX_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value
        + data[SHAREMCSI_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value
        + data[SHAREMCSI_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value
        + data[SHAREREALESTATE_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value
        + data[SHAREREALESTATE_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value;
    if (isIS5OrIS7(selectedTool)) {
        total = total
            + data[SPDRGOLDSHARES_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value
            + data[SPDRGOLDSHARES_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value;
    }

    if (isNaN(total)) total = 0;

    const calculateOverallProtection = () => {
        let overallProtection =
            data[SPINDEX_MAPPING[selectedTool]].tenPercentBufferIdxStrategy.value * 0.1 +
            data[SPINDEX_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value * 0.9 +
            data[SPINDEX_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value * 1 +
            data[SHAREMCSI_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value * 0.9 +
            data[SHAREMCSI_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value * 1 +
            data[SHAREREALESTATE_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value * 0.9 +
            data[SHAREREALESTATE_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value * 1;
        if (isIS5OrIS7(selectedTool)) {
            overallProtection = overallProtection +
                data[SPDRGOLDSHARES_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value * 0.9 +
                data[SPDRGOLDSHARES_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value * 1;
        }
        return overallProtection.toFixed(2);
    };

    const calculateOverallCapUnder250K = () => {
        const type = "paymentUnder250K";
        let overallCap =
            data.adminDatabase[SPINDEX_MAPPING[selectedTool]].tenPercentBufferIdxStrategy[type].value * data[SPINDEX_MAPPING[selectedTool]].tenPercentBufferIdxStrategy.value / 100 +
            data.adminDatabase[SPINDEX_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy[type].value * data[SPINDEX_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value / 100 +
            data.adminDatabase[SPINDEX_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy[type].value * data[SPINDEX_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value / 100 +
            data.adminDatabase[SHAREMCSI_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy[type].value * data[SHAREMCSI_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value / 100 +
            data.adminDatabase[SHAREMCSI_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy[type].value * data[SHAREMCSI_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value / 100 +
            data.adminDatabase[SHAREREALESTATE_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy[type].value * data[SHAREREALESTATE_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value / 100 +
            data.adminDatabase[SHAREREALESTATE_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy[type].value * data[SHAREREALESTATE_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value / 100;
        if (isIS5OrIS7(selectedTool)) {
            overallCap = overallCap +
                data.adminDatabase[SPDRGOLDSHARES_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy[type].value * data[SPDRGOLDSHARES_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value / 100 +
                data.adminDatabase[SPDRGOLDSHARES_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy[type].value * data[SPDRGOLDSHARES_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value / 100;
        }
        return overallCap.toFixed(2);
    };

    const calculateOverallCapOver250K = () => {
        const type = "paymentOver250K";
        let overallCap =
            data.adminDatabase[SPINDEX_MAPPING[selectedTool]].tenPercentBufferIdxStrategy[type].value * data[SPINDEX_MAPPING[selectedTool]].tenPercentBufferIdxStrategy.value / 100 +
            data.adminDatabase[SPINDEX_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy[type].value * data[SPINDEX_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value / 100 +
            data.adminDatabase[SPINDEX_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy[type].value * data[SPINDEX_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value / 100 +
            data.adminDatabase[SHAREMCSI_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy[type].value * data[SHAREMCSI_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value / 100 +
            data.adminDatabase[SHAREMCSI_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy[type].value * data[SHAREMCSI_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value / 100 +
            data.adminDatabase[SHAREREALESTATE_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy[type].value * data[SHAREREALESTATE_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value / 100 +
            data.adminDatabase[SHAREREALESTATE_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy[type].value * data[SHAREREALESTATE_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value / 100;
        if (isIS5OrIS7(selectedTool)) {
            overallCap = overallCap +
                data.adminDatabase[SPDRGOLDSHARES_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy[type].value * data[SPDRGOLDSHARES_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value / 100 +
                data.adminDatabase[SPDRGOLDSHARES_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy[type].value * data[SPDRGOLDSHARES_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value / 100;
        }
        return overallCap.toFixed(2);
    };

    return (
        <Segment raised>
            <Grid columns='equal' className={'plan-input-index-frontier-cap-and-protection'}>
                <Grid.Row>
                    {data.annuityPurchaseAmount.value >= 250000 && (
                    <Grid.Column>
                        <Table>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell colSpan={3}>
                                        <label basic className={'bold'}>{TOOL_NAME_MAPPING[selectedTool]} Total Premium $250,000+</label>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell width={5}>
                                        <label basic>Strategy</label>
                                    </Table.Cell>
                                    <Table.Cell width={5}>
                                        <label basic>Cap</label>
                                    </Table.Cell>
                                    <Table.Cell width={5}>
                                        <label basic>Allocation %</label>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell className={'left'}>
                                        <label basic>S&P 500 10% Buffer</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].tenPercentBufferIdxStrategy.paymentOver250K.value}%</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data[SPINDEX_MAPPING[selectedTool]].tenPercentBufferIdxStrategy.value}%</label>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell className={'left'}>
                                        <label basic>S&P 500 -10% Floor</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.paymentOver250K.value}%</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data[SPINDEX_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value}%</label>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell className={'left'}>
                                        <label basic>S&P 500 0%Floor</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.paymentOver250K.value}%</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data[SPINDEX_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value}%</label>
                                    </Table.Cell>
                                </Table.Row>
                                {isIS5OrIS7(selectedTool) && <React.Fragment>
                                    <Table.Row>
                                        <Table.Cell className={'left'}>
                                            <label basic>SPDR GLD -10% Floor</label>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <label basic>{data.adminDatabase[SPDRGOLDSHARES_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.paymentOver250K.value}%</label>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <label basic>{data[SPDRGOLDSHARES_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value}%</label>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell className={'left'}>
                                            <label basic>SPDR GLD 0% Floor</label>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <label basic>{data.adminDatabase[SPDRGOLDSHARES_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.paymentOver250K.value}%</label>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <label basic>{data[SPDRGOLDSHARES_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value}%</label>
                                        </Table.Cell>
                                    </Table.Row>
                                </React.Fragment>}
                                <Table.Row>
                                    <Table.Cell className={'left'}>
                                        <label basic>iShares EFA -10% Floor</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data.adminDatabase[SHAREMCSI_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.paymentOver250K.value}%</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data[SHAREMCSI_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value}%</label>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell className={'left'}>
                                        <label basic>iShares EFA 0% Floor</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data.adminDatabase[SHAREMCSI_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.paymentOver250K.value}%</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data[SHAREMCSI_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value}%</label>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell className={'left'}>
                                        <label basic>iShares IYR -10% Floor</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data.adminDatabase[SHAREREALESTATE_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.paymentOver250K.value}%</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data[SHAREREALESTATE_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value}%</label>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell className={'left'}>
                                        <label basic>iShares IYR 0% Floor</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data.adminDatabase[SHAREREALESTATE_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.paymentOver250K.value}%</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data[SHAREREALESTATE_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value}%</label>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell colSpan={2} className={'left'}>
                                        <label basic className={'bold'}>TOTAL ALLOCATION</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{total}%</label>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell colSpan={2} className={'left'}>
                                        <label basic className={'bold'}>OVERALL UPSIDE LIMIT</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{calculateOverallCapOver250K()}%</label>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell colSpan={2} className={'left'}>
                                        <label basic className={'bold'}>OVERALL PROTECTION</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{calculateOverallProtection()}%</label>
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                    )}
                    {data.annuityPurchaseAmount.value < 250000 && (
                    <Grid.Column>
                        <Table>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell colSpan={3}>
                                        <label basic className={'bold'}>{TOOL_NAME_MAPPING[selectedTool]} Total Premium Less Than $250,000</label>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell width={5}>
                                        <label basic>Strategy</label>
                                    </Table.Cell>
                                    <Table.Cell width={5}>
                                        <label basic>Cap</label>
                                    </Table.Cell>
                                    <Table.Cell width={5}>
                                        <label basic>Allocation %</label>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell className={'left'}>
                                        <label basic>S&P 500 10% Buffer</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].tenPercentBufferIdxStrategy.paymentUnder250K.value}%</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data[SPINDEX_MAPPING[selectedTool]].tenPercentBufferIdxStrategy.value}%</label>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell className={'left'}>
                                        <label basic>S&P 500 -10% Floor</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.paymentUnder250K.value}%</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data[SPINDEX_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value}%</label>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell className={'left'}>
                                        <label basic>S&P 500 0%Floor</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.paymentUnder250K.value}%</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data[SPINDEX_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value}%</label>
                                    </Table.Cell>
                                </Table.Row>
                                {isIS5OrIS7(selectedTool) && <React.Fragment>
                                    <Table.Row>
                                        <Table.Cell className={'left'}>
                                            <label basic>SPDR GLD -10% Floor</label>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <label basic>{data.adminDatabase[SPDRGOLDSHARES_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.paymentUnder250K.value}%</label>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <label basic>{data[SPDRGOLDSHARES_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value}%</label>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell className={'left'}>
                                            <label basic>SPDR GLD 0% Floor</label>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <label basic>{data.adminDatabase[SPDRGOLDSHARES_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.paymentUnder250K.value}%</label>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <label basic>{data[SPDRGOLDSHARES_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value}%</label>
                                        </Table.Cell>
                                    </Table.Row>
                                </React.Fragment>}
                                <Table.Row>
                                    <Table.Cell className={'left'}>
                                        <label basic>iShares EFA -10% Floor</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data.adminDatabase[SHAREMCSI_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.paymentUnder250K.value}%</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data[SHAREMCSI_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value}%</label>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell className={'left'}>
                                        <label basic>iShares EFA 0% Floor</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data.adminDatabase[SHAREMCSI_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.paymentUnder250K.value}%</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data[SHAREMCSI_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value}%</label>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell className={'left'}>
                                        <label basic>iShares IYR -10% Floor</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data.adminDatabase[SHAREREALESTATE_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.paymentUnder250K.value}%</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data[SHAREREALESTATE_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value}%</label>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell className={'left'}>
                                        <label basic>iShares IYR 0% Floor</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data.adminDatabase[SHAREREALESTATE_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.paymentUnder250K.value}%</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{data[SHAREREALESTATE_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value}%</label>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell colSpan={2} className={'left'}>
                                        <label basic className={'bold'}>TOTAL ALLOCATION</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{total}%</label>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell colSpan={2} className={'left'}>
                                        <label basic className={'bold'}>OVERALL UPSIDE LIMIT</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{calculateOverallCapUnder250K()}%</label>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell colSpan={2} className={'left'}>
                                        <label basic className={'bold'}>OVERALL PROTECTION</label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <label basic>{calculateOverallProtection()}%</label>
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                    )}
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

const mapStateToProps = (state) => {
    return {
        database: state.get("databaseReducer"),
        uiReducer: state.get('uiReducer'),
    }
};

export default connect(mapStateToProps, null)(IndexFrontierCapAndProtection);