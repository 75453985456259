import React from 'react';
import {Grid, Segment, Header, Label, Container} from 'semantic-ui-react';
import {connect} from 'react-redux';
import AnnuityPayment from './annuityPayment';
import SP500 from './sp500';
import SharesMSCI from './iSharesMSCI';
import SharesUS from './iSharesUS';
import SharesSpdrGold from "./spdrGoldShares";
import './index.css';
import {TOOL_NAME_MAPPING} from "../../../../reducers/actions";
import {isIS5OrIS5Pro, isIS5OrIS7, isIS6OrIS6Pro, isIS7OrIS7Pro, isPro} from "../../../../common/common";


const outputSummary = (props) => {

    let adminData = props.adminDatabase.toJS();
    let data = props.database.toJS();
    const selectedTool = props.uiReducer.toJS().selectedTool;

    const getProRate = (tool) => {
        let rate = 0;
        if (isPro(tool)) {
            if (isIS6OrIS6Pro(tool)) {
                rate = data.annuityPurchaseAmount.value > 100000 ? adminData.is6ProFees.paymentOver100K.value : adminData.is6ProFees.paymentUnder100K.value;
            } else if (isIS5OrIS5Pro(tool)) {
                rate = data.annuityPurchaseAmount.value > 250000 ? adminData.is5ProFees.paymentOver250K.value : adminData.is5ProFees.paymentUnder250K.value;
            } else if (isIS7OrIS7Pro(tool)) {
                rate = data.annuityPurchaseAmount.value > 250000 ? adminData.is7ProFees.paymentOver250K.value : adminData.is7ProFees.paymentUnder250K.value;
            }
        }
        return rate + "%";
    }

    return (
        <Segment className="output-summary adjusted-space-bottom" raised>
            <Header as="h1" className="adjusted-header">{TOOL_NAME_MAPPING[selectedTool]} Allocation Summary</Header>

            <Container textAlign='center'>
                <Grid columns='equal' celled>
                    <Grid.Row>
                        <Grid.Column>
                            <AnnuityPayment />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <SP500 />
                        </Grid.Column>
                        <Grid.Column>
                            <SharesMSCI />
                        </Grid.Column>
                        <Grid.Column>
                            <SharesUS />
                        </Grid.Column>
                        {(isIS5OrIS7(selectedTool) || isIS6OrIS6Pro(selectedTool)) && <Grid.Column>
                            <SharesSpdrGold />
                        </Grid.Column>}
                    </Grid.Row>
                </Grid>
            </Container>

            {isIS6OrIS6Pro(selectedTool) && !isPro(selectedTool) && (
                <Label basic>In addition to the indexed strategies covered by the Tool, the Index Summit 6 also offers a declared rate strategy that earns interest over 1-year terms at a fixed rate we set before each term begins. Note: The declared rate strategy is not available for contracts issued in Missouri.</Label>
            )}
            {isIS6OrIS6Pro(selectedTool) && isPro(selectedTool) && (
                <Label basic>The Index Summit 6 Pro has an annual fee of {getProRate(selectedTool)}.</Label>
            )}
            {isIS5OrIS5Pro(selectedTool) && isPro(selectedTool) && (
                <Label basic>The Index Frontier 5 Pro has an annual fee of {getProRate(selectedTool)}.</Label>
            )}
            {isIS7OrIS7Pro(selectedTool) && isPro(selectedTool) && (
                <Label basic>The Index Frontier 7 Pro has an annual fee of {getProRate(selectedTool)}.</Label>
            )}
        </Segment>
    );
};

const mapStateToProps = (state) => {
    return {
        database: state.get("databaseReducer"),
        adminDatabase: state.getIn(["databaseReducer", "adminDatabase"]),
        uiReducer: state.get('uiReducer')
    }
};

export default connect(mapStateToProps, null)(outputSummary);