import React from 'react';
import {connect} from 'react-redux';
import {Modal, Grid, Button, Header, Container} from 'semantic-ui-react';
import PlanCompare from '../PlanCompare/index';
import WealthGraph from '../WealthGraph/index';
import OutputSummary from '../OutputSummary/index';
import Disclosures from '../../../App/Disclosures';
import './index.css';
import {getPercentileData} from "../index";
import * as actionTypes from "../../../../reducers/actions";
import MASS_MUTUAL_LOGO from '../../../App/assets/MassMutualAscend-Logo_white-305.png'
import * as config from '../../../../config';
import {TOOL_NAME_MAPPING} from "../../../../reducers/actions";
import {isIS5OrIS7, isIS5ProOrIS7Pro, isIS6OrIS6Pro} from "../../../../common/common";
import CapAndProtection from "../CapAndProtection";

const OutputModal = (props) => {
    let data = props.database.toJS(); 
    let ux = props.uxState.toJS();
    const selectedTool = props.uiState.toJS().selectedTool;

    return (
        <Modal
            open={props.uiState.get("showPreviewModal")}
            size={'fullscreen'}
        >

            <Modal.Header>

                <Grid columns='equal'>
                    <Grid.Row>
                        <Grid.Column>Report Preview</Grid.Column>
                        <Grid.Column>
                            <Button onClick={() => props.downloadPdf()}>Download PDF</Button>
                        </Grid.Column>
                        <Grid.Column>
                            <Button primary onClick={() => props.closePreviewModal()}>Close</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            </Modal.Header>
            <Modal.Content>

                <div id={"annuity-calculator-results"}>
                    <Container textAlign="center" className="gaig__header pdf-header">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={4}>
                                    <div className="gaig__header__logo">
                                        <a href="https://www.massmutualascend.com" target="_blank" rel="noopener noreferrer">
                                            <img src={MASS_MUTUAL_LOGO} alt="MassMutual Ascend" />
                                        </a>
                                    </div>
                                </Grid.Column>
                                <Header className="gaig__header__title" as="h2" block>
                                    <div>Explore MassMutual Ascend's</div>
                                    <div>Registered Index-Linked Annuities</div>
                                </Header>
                            </Grid.Row>
                        </Grid>
                    </Container>

                    <Grid className={"annuity-calculator-results page"}>
                        <Grid.Row className={'center desc space-bottom'}>
                            <Grid.Column>
                                <p className={'line-height-15'}>Review the {TOOL_NAME_MAPPING[selectedTool]} allocation summary and market condition information below, then refer to the Simulation Highlights to see how caps and participation rates can affect the performance of your {TOOL_NAME_MAPPING[selectedTool]} strategies.</p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Grid className={"annuity-calculator-results page"}>
                        <Grid.Row>
                            <Grid.Column>
                                <OutputSummary/>
                            </Grid.Column>
                        </Grid.Row>
                        {!isIS6OrIS6Pro(selectedTool) && (
                            <Grid.Row>
                                <Grid.Column>
                                    <CapAndProtection />
                                </Grid.Column>
                            </Grid.Row>
                        )}
                        <Grid.Row className={'center desc space-top space-bottom'}>
                            <Grid.Column>
                                {isIS6OrIS6Pro(selectedTool) &&
                                <p className={'line-height-15'}>The following charts summarize the results of the Monte
                                    Carlo simulations that were generated
                                    based on your selections. To allow you to evaluate the impact of
                                    the {TOOL_NAME_MAPPING[selectedTool]}'s features, information has been provided for
                                    your
                                    allocations under two scenarios: one
                                    scenario, {TOOL_NAME_MAPPING[selectedTool]} Results,
                                    reflects the inclusion of the {TOOL_NAME_MAPPING[selectedTool]}'s caps,
                                    participation rates and
                                    contract fees, while the other scenario, Combined Index/ETF Results, does not
                                    include {TOOL_NAME_MAPPING[selectedTool]} features, such as caps and participation
                                    rates.
                                </p>
                                }
                                {isIS5OrIS7(selectedTool) &&
                                <p className={'line-height-15'}>The following charts summarize the results of the Monte
                                    Carlo simulations that were generated
                                    based on your selections. To allow you to evaluate the impact of
                                    the {TOOL_NAME_MAPPING[selectedTool]}'s features, information has been provided for
                                    your
                                    allocations under two scenarios: one
                                    scenario, {TOOL_NAME_MAPPING[selectedTool]} Results,
                                    reflects the inclusion
                                    of the {TOOL_NAME_MAPPING[selectedTool]}'s caps, buffers, and floors, while the other
                                    scenario,
                                    Combined Index/ETF
                                    Results, does not include {TOOL_NAME_MAPPING[selectedTool]}'s caps, buffers, and
                                    floors.
                                </p>
                                }
                                {isIS5ProOrIS7Pro(selectedTool) &&
                                <p className={'line-height-15'}>The following charts summarize the results of the Monte
                                    Carlo simulations that were generated
                                    based on your selections. To allow you to evaluate the impact of
                                    the {TOOL_NAME_MAPPING[selectedTool]}'s features, information has been provided for
                                    your
                                    allocations under two scenarios: one
                                    scenario, {TOOL_NAME_MAPPING[selectedTool]} Results,
                                    reflects the
                                    inclusion of the {TOOL_NAME_MAPPING[selectedTool]}'s caps, buffers, floors, and
                                    contract fees,
                                    while the other
                                    scenario, Combined Index/ETF Results, does not
                                    include {TOOL_NAME_MAPPING[selectedTool]}'s caps,
                                    buffers,
                                    floors and contract fees.
                                </p>
                                }
                                <p style={{"textAlign": "center"}}><span style={{"fontStyle": "italic"}}>You cannot invest directly in an index.</span></p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <PlanCompare/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Grid className={"annuity-calculator-results page"}>
                        <Grid.Row className={'section center'}>
                            <Grid.Column>
                                <WealthGraph
                                    title={"Performance Comparison - 50th Percentile"}
                                    topMessage={"Half of the simulations ended in a cumulative value higher than this value, and half resulted in a cumulative value lower than this value."}
                                    bottomMessage={"*Strategy values were calculated at the end of each term."}
                                    baseWealth={getPercentileData(data.results.planB, 1, "base")}
                                    compareWealth={getPercentileData(data.results.planA, 1, "compare")}
                                    toggleBaseGraph={props.toggleBase50Graph}
                                    toggleCompareGraph={props.toggleCompare50Graph}
                                    isBaseOn={ux.baseGraphs.percentile50thActive}
                                    isCompareOn={ux.compareGraphs.percentile50thActive}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className={'section center'}>
                            <Grid.Column>
                                <WealthGraph
                                    title={"Performance Comparison - 90th Percentile"}
                                    topMessage={"Represents consistently better than expected investment returns. Only 10% of the simulations ended in a cumulative value higher than this value, and 90% of the simulations resulted in a cumulative value lower than this value."}
                                    bottomMessage={"*Strategy values were calculated at the end of each term."}
                                    baseWealth={getPercentileData(data.results.planB, 2, "base")}
                                    compareWealth={getPercentileData(data.results.planA, 2, "compare")}
                                    toggleBaseGraph={props.toggleBase90Graph}
                                    toggleCompareGraph={props.toggleCompare90Graph}
                                    isBaseOn={ux.baseGraphs.percentile90thActive}
                                    isCompareOn={ux.compareGraphs.percentile90thActive}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className={'section center'}>
                            <Grid.Column>
                                <WealthGraph
                                    title={"Performance Comparison - 10th Percentile"}
                                    topMessage={"Represents consistently worse than expected investment returns. Only 10% of the simulations ended in a cumulative value lower than this value, and 90% of the simulations results in a cumulative value higher than this value."}
                                    bottomMessage={"*Strategy values were calculated at the end of each term."}
                                    baseWealth={getPercentileData(data.results.planB, 0, "base")}
                                    compareWealth={getPercentileData(data.results.planA, 0, "compare")}
                                    toggleBaseGraph={props.toggleBase10Graph}
                                    toggleCompareGraph={props.toggleCompare10Graph}
                                    isBaseOn={ux.baseGraphs.percentile10thActive}
                                    isCompareOn={ux.compareGraphs.percentile10thActive}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Grid className={"page"}>
                        <Grid.Row className={'section'}>
                            <Disclosures />
                        </Grid.Row>
                    </Grid>

                </div>

            </Modal.Content>

        </Modal>
    )
};

const mapStateToProps = (state) => {
    return {
        uiState: state.get("uiReducer"),
        database: state.get("databaseReducer"),
        uxState: state.get("uxReducer"),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        closePreviewModal: () => dispatch({
            type: actionTypes.UI_UPDATE_PREVIEW_MODAL,
            value: false
        }),
        downloadPdf: () => dispatch({
            type: actionTypes.SAGA_DOWNLOAD_PDF
        })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OutputModal);