// INCREMENT current wizard step to a maximum of MAX PAGES
// DECREMENT current wizard step to a minimum of zero.
// TOGGLE isValid when needed


export const RETAIL_FI = "RETAIL_FI";
export const RAYMOND_JAMES = "RAYMOND_JAMES";
export const MMFA = "MMFA";
export const CETERA = "CETERA";
export const HUNTINGTON = "HUNTINGTON";
export const LPL = "LPL";
export const WELLS_FARGO = "WELLS_FARGO";
export const RETAIL_FI_CODE = "retailfi";
export const RAYMOND_JAMES_CODE = "raymondjames";
export const MMFA_CODE = "mmfa";
export const CETERA_CODE = "cetera";
export const HUNTINGTON_CODE = "huntington";
export const LPL_CODE = "lpl";
export const WELLS_FARGO_CODE = "wellsfargo";

export const UX_NEXT = "UX_NEXT";
export const UX_PREV = "UX_PREV";
export const UX_SET_VALID = "UX_SET_VALID";
export const UX_SET_STRAT_VALID = "UX_SET_STRAT_VALID";
export const UX_SET_CLIENTS_NAME_VALID = "UX_SET_CLIENTS_NAME_VALID";
export const UX_SET_IS_CLIENTS_NOT_NAME_EMPTY = "UX_SET_IS_CLIENTS_NOT_NAME_EMPTY";
export const UX_RESET = "UX_RESET";
export const UX_BASE_TOGGLE10 = "UX_BASE_TOGGLE10";
export const UX_BASE_TOGGLE50 = "UX_BASE_TOGGLE50";
export const UX_BASE_TOGGLE90 = "UX_BASE_TOGGLE90";
export const UX_COMPARE_TOGGLE10 = "UX_COMPARE_TOGGLE10";
export const UX_COMPARE_TOGGLE50 = "UX_COMPARE_TOGGLE50";
export const UX_COMPARE_TOGGLE90 = "UX_COMPARE_TOGGLE90";
export const UX_SET_AUTHORIZATION = "UX_SET_AUTHORIZATION";
export const UX_SET_INVALID_PASSWORD = "UX_SET_INVALID_PASSWORD";
export const UX_SET_REQUEST_SUCCESSFUL = "UX_SET_REQUEST_SUCCESSFUL";
export const UX_SET_HAS_BLANK = "UX_SET_HAS_BLANK"

// SET showLoadingModal STATE
// Setting showLoadingModal to true should set showErrorModal to false
// SET showNextButton
// SET showPrevButton
// SET showValidationMessage
// SET showErrorModal
// SET showErrorModal to true should set showLoadingModal to false
export const UI_SET_LOADING_MODAL = "UI_SET_LOADING_MODAL";
export const UI_SET_SHOW_NEXT = "UI_SET_SHOW_NEXT";
export const UI_SET_SHOW_PREV = "UI_SET_SHOW_PREV";
export const UI_SET_SHOW_VALIDATION = "UI_SET_SHOW_VALIDATION";
export const UI_SET_SHOW_ERROR_MODAL = "UI_SET_SHOW_ERROR_MODAL";
export const UI_RESET = "UI_RESET";
export const UI_UPDATE_PREVIEW_MODAL = "UI_UPDATE_PREVIEW_MODAL";
export const UI_SET_NAVIGATION_ROW_BUTTONS_VISIBILITY = "UI_SET_NAVIGATION_ROW_BUTTONS_VISIBILITY";
export const UI_SET_IS5 = "UI_SET_IS5";
export const UI_SET_IS5Pro = "UI_SET_IS5Pro";
export const UI_SET_ADMIN_IS5 = "UI_SET_ADMIN_IS5";
export const UI_SET_ADMIN_IS5Pro = "UI_SET_ADMIN_IS5Pro";
export const UI_SET_IS6 = "UI_SET_IS6";
export const UI_SET_IS6Pro = "UI_SET_IS6Pro";
export const UI_SET_ADMIN_IS6 = "UI_SET_ADMIN_IS6";
export const UI_SET_ADMIN_IS6Pro = "UI_SET_ADMIN_IS6Pro";
export const UI_SET_IS7 = "UI_SET_IS7";
export const UI_SET_IS7Pro = "UI_SET_IS7Pro";
export const UI_SET_ADMIN_IS7 = "UI_SET_ADMIN_IS7";
export const UI_SET_ADMIN_IS7Pro = "UI_SET_ADMIN_IS7Pro";
export const UI_SET_TOOL = "UI_SET_TOOL";
export const UI_SET_ADMIN_TOOL = "UI_SET_ADMIN_TOOL";
export const UI_HIDE_BACKTOINTRODUCTION = "UI_HIDE_BACKTOINTRODUCTION";
export const UI_SHOW_BACKTOINTRODUCTION = "UI_SHOW_BACKTOINTRODUCTION";
export const INDEX_SUMMIT_6 = "INDEX_SUMMIT_6";
export const INDEX_SUMMIT_6_PRO = "INDEX_SUMMIT_6_PRO";
export const INDEX_FRONTIER_5 = "INDEX_FRONTIER_5";
export const INDEX_FRONTIER_5_PRO = "INDEX_FRONTIER_5_PRO";
export const INDEX_FRONTIER_7 = "INDEX_FRONTIER_7";
export const INDEX_FRONTIER_7_PRO = "INDEX_FRONTIER_7_PRO";
export const UI_SET_IS_TOOLS = "UI_SET_IS_TOOLS";

export const DB_RESET = "DB_RESET";
export const DB_SET_ANNUITY_PAYMENT = "DB_SET_ANNUITY_PAYMENT";
export const DB_VALIDATE_ANNUITY_PAYMENT = "DB_VALIDATE_ANNUITY_PAYMENT";
export const DB_UPDATE_SP_500 = "DB_UPDATE_SP_500";
export const DB_VALIDATE_SP_500 = "DB_VALIDATE_SP_500";
export const DB_UPDATE_MCSI = "DB_UPDATE_MCSI";
export const DB_VALIDATE_MCSI = "DB_VALIDATE_MCSI";
export const DB_UPDATE_REALESTATE = "DB_UPDATE_REALESTATE";
export const DB_VALIDATE_REALESTATE = "DB_VALIDATE_REALESTATE";
export const DB_UPDATE_SPDRGOLDSHARES = "DB_UPDATE_SPDRGOLDSHARES";
export const DB_VALIDATE_SPDRGOLDSHARES = "DB_VALIDATE_SPDRGOLDSHARES";
export const DB_UPDATE_STRATEGY = "DB_UPDATE_STRATEGY";
export const DB_VALIDATE_STRATEGY = "DB_VALIDATE_STRATEGY";
export const DB_UPDATE_CONDITIONS = "DB_UPDATE_CONDITIONS";
export const DB_UPDATE_RESULTS = "DB_UPDATE_RESULTS";
export const DB_UPDATE_ASSET_CLASSES = "DB_UPDATE_ASSET_CLASSES";
export const DB_UPDATE_USER_ID = "DB_UPDATE_USER_ID";
export const DB_UPDATE_LOGIN_DATA = "DB_UPDATE_LOGIN_DATA";
export const DB_VALIDATE_LOGIN_DATA = "DB_VALIDATE_LOGIN_DATA";
export const DB_SET_CLIENTS_NAME = "DB_SET_CLIENTS_NAME";
export const DB_VALIDATE_CLIENTS_NAME = "DB_VALIDATE_CLIENTS_NAME";
export const DB_UPDATE_VISIT_ID_DATA = "DB_UPDATE_VISIT_ID_DATA";

// admin database
export const DB_ADMIN_UPDATE = "DB_ADMIN_UPDATE";
export const DB_ADMIN_VALIDATE = "DB_ADMIN_VALIDATE";
export const DB_ADMIN_SET_DATA = "DB_ADMIN_SET_DATA";
export const DB_ADMIN_SET_DATE = "DB_ADMIN_SET_DATE";

// SAGA ACTIONS
export const SAGA_NEXT_PAGE = "SAGA_NEXT_PAGE";
export const SAGA_PREV_PAGE = "SAGA_PREV_PAGE";
export const SAGA_RESET_DATA = "SAGA_RESET_DATA";
export const SAGA_VALIDATE_STEP_1 = "SAGA_VALIDATE_STEP_1";
export const SAGA_RECALCULATE_PLAN = "SAGA_RECALCULATE_PLAN";
export const SAGA_DOWNLOAD_PDF = "SAGA_DOWNLOAD_PDF";
export const SAGA_DOWNLOAD_DISCLOSURE = "SAGA_DOWNLOAD_DISCLOSURE";
export const SAGA_SET_ADMIN_DATA = "SAGA_SET_ADMIN_DATA";
export const SAGA_LOGIN_ADMIN = "SAGA_LOGIN_ADMIN";
export const SAGA_SAVE_ADMIN_DATA = "SAGA_SAVE_ADMIN_DATA";
export const SAGA_LOGOUT_ADMIN = "SAGA_LOGOUT_ADMIN";

export const ADMIN_IS_TOOL_OPTIONS = [
    { key: UI_SET_ADMIN_IS6, value: UI_SET_ADMIN_IS6, text: 'INDEX SUMMIT 6' },
    { key: UI_SET_ADMIN_IS6Pro, value: UI_SET_ADMIN_IS6Pro, text: 'INDEX SUMMIT 6 Pro' },
    { key: UI_SET_ADMIN_IS5, value: UI_SET_ADMIN_IS5, text: 'INDEX FRONTIER 5' },
    { key: UI_SET_ADMIN_IS5Pro, value: UI_SET_ADMIN_IS5Pro, text: 'INDEX FRONTIER 5 Pro' },
    { key: UI_SET_ADMIN_IS7, value: UI_SET_ADMIN_IS7, text: 'INDEX FRONTIER 7' },
    { key: UI_SET_ADMIN_IS7Pro, value: UI_SET_ADMIN_IS7Pro, text: 'INDEX FRONTIER 7 Pro' },
];

export const IS_TOOL_OPTIONS = [
    { key: UI_SET_IS6, value: UI_SET_IS6, text: 'INDEX SUMMIT 6' },
    { key: UI_SET_IS6Pro, value: UI_SET_IS6Pro, text: 'INDEX SUMMIT 6 Pro' },
    { key: UI_SET_IS5, value: UI_SET_IS5, text: 'INDEX FRONTIER 5' },
    { key: UI_SET_IS5Pro, value: UI_SET_IS5Pro, text: 'INDEX FRONTIER 5 Pro' },
    { key: UI_SET_IS7, value: UI_SET_IS7, text: 'INDEX FRONTIER 7' },
    { key: UI_SET_IS7Pro, value: UI_SET_IS7Pro, text: 'INDEX FRONTIER 7 Pro' },
];

export const ADMIN_SPINDEX_MAPPING = {
    [UI_SET_ADMIN_IS6]: "is6SpIndex",
    [UI_SET_ADMIN_IS6Pro]: "is6ProSpIndex",
    [UI_SET_ADMIN_IS5]: "is5SpIndex",
    [UI_SET_ADMIN_IS5Pro]: "is5ProSpIndex",
    [UI_SET_ADMIN_IS7]: "is7SpIndex",
    [UI_SET_ADMIN_IS7Pro]: "is7ProSpIndex",
};

export const ADMIN_SHAREMCSI_MAPPING = {
    [UI_SET_ADMIN_IS6]: "is6ISharesMSCI",
    [UI_SET_ADMIN_IS6Pro]: "is6ProISharesMSCI",
    [UI_SET_ADMIN_IS5]: "is5ISharesMSCI",
    [UI_SET_ADMIN_IS5Pro]: "is5ProISharesMSCI",
    [UI_SET_ADMIN_IS7]: "is7ISharesMSCI",
    [UI_SET_ADMIN_IS7Pro]: "is7ProISharesMSCI",
};

export const ADMIN_SHAREREALESTATE_MAPPING = {
    [UI_SET_ADMIN_IS6]: "is6ISharesRealState",
    [UI_SET_ADMIN_IS6Pro]: "is6ProISharesRealState",
    [UI_SET_ADMIN_IS5]: "is5ISharesRealState",
    [UI_SET_ADMIN_IS5Pro]: "is5ProISharesRealState",
    [UI_SET_ADMIN_IS7]: "is7ISharesRealState",
    [UI_SET_ADMIN_IS7Pro]: "is7ProISharesRealState",
};

export const ADMIN_SPDRGOLDSHARES_MAPPING = {
    [UI_SET_ADMIN_IS6]: "is6SPDRGoldShares",
    [UI_SET_ADMIN_IS6Pro]: "is6ProSPDRGoldShares",
    [UI_SET_ADMIN_IS5]: "is5SPDRGoldShares",
    [UI_SET_ADMIN_IS5Pro]: "is5ProSPDRGoldShares",
    [UI_SET_ADMIN_IS7]: "is7SPDRGoldShares",
    [UI_SET_ADMIN_IS7Pro]: "is7ProSPDRGoldShares",
};

export const ADMIN_DECLAREDSTRATEGY_MAPPING = {
    [UI_SET_ADMIN_IS6]: "is6DeclaredRate",
    [UI_SET_ADMIN_IS6Pro]: "is6ProDeclaredRate",
    [UI_SET_ADMIN_IS5]: "is5DeclaredRate",
    [UI_SET_ADMIN_IS5Pro]: "is5ProDeclaredRate",
    [UI_SET_ADMIN_IS7]: "is7DeclaredRate",
    [UI_SET_ADMIN_IS7Pro]: "is7ProDeclaredRate",
};

export const ADMIN_FEES_MAPPING = {
    [UI_SET_ADMIN_IS6Pro]: "is6ProFees",
    [UI_SET_ADMIN_IS5Pro]: "is5ProFees",
    [UI_SET_ADMIN_IS7Pro]: "is7ProFees",
};

export const TOOL_NAME_MAPPING = {
    [UI_SET_IS6]: "Index Summit 6",
    [UI_SET_IS6Pro]: "Index Summit 6 Pro",
    [UI_SET_IS5]: "Index Frontier 5",
    [UI_SET_IS5Pro]: "Index Frontier 5 Pro",
    [UI_SET_IS7]: "Index Frontier 7",
    [UI_SET_IS7Pro]: "Index Frontier 7 Pro",
}

export const SPINDEX_MAPPING = {
    [UI_SET_IS6]: "is6SpIndex",
    [UI_SET_IS6Pro]: "is6ProSpIndex",
    [UI_SET_IS5]: "is5SpIndex",
    [UI_SET_IS5Pro]: "is5ProSpIndex",
    [UI_SET_IS7]: "is7SpIndex",
    [UI_SET_IS7Pro]: "is7ProSpIndex",
};

export const SHAREMCSI_MAPPING = {
    [UI_SET_IS6]: "is6ISharesMSCI",
    [UI_SET_IS6Pro]: "is6ProISharesMSCI",
    [UI_SET_IS5]: "is5ISharesMSCI",
    [UI_SET_IS5Pro]: "is5ProISharesMSCI",
    [UI_SET_IS7]: "is7ISharesMSCI",
    [UI_SET_IS7Pro]: "is7ProISharesMSCI",
};

export const SHAREREALESTATE_MAPPING = {
    [UI_SET_IS6]: "is6ISharesRealState",
    [UI_SET_IS6Pro]: "is6ProISharesRealState",
    [UI_SET_IS5]: "is5ISharesRealState",
    [UI_SET_IS5Pro]: "is5ProISharesRealState",
    [UI_SET_IS7]: "is7ISharesRealState",
    [UI_SET_IS7Pro]: "is7ProISharesRealState",
};

export const SPDRGOLDSHARES_MAPPING = {
    [UI_SET_IS6]: "is6SPDRGoldShares",
    [UI_SET_IS6Pro]: "is6ProSPDRGoldShares",
    [UI_SET_IS5]: "is5SPDRGoldShares",
    [UI_SET_IS5Pro]: "is5ProSPDRGoldShares",
    [UI_SET_IS7]: "is7SPDRGoldShares",
    [UI_SET_IS7Pro]: "is7ProSPDRGoldShares",
};

export const DECLAREDSTRATEGY_MAPPING = {
    [UI_SET_IS6]: "is6DeclaredRate",
    [UI_SET_IS6Pro]: "is6ProDeclaredRate",
    [UI_SET_IS5]: "is5DeclaredRate",
    [UI_SET_IS5Pro]: "is5ProDeclaredRate",
    [UI_SET_IS7]: "is7DeclaredRate",
    [UI_SET_IS7Pro]: "is7ProDeclaredRate",
};

export const PRODUCT_TYPE = {
    [UI_SET_IS6]: "INDEX_SUMMIT_6",
    [UI_SET_IS6Pro]: "INDEX_SUMMIT_6_PRO",
    [UI_SET_IS5]: "INDEX_FRONTIER_5",
    [UI_SET_IS5Pro]: "INDEX_FRONTIER_5_PRO",
    [UI_SET_IS7]: "INDEX_FRONTIER_7",
    [UI_SET_IS7Pro]: "INDEX_FRONTIER_7_PRO",
};

export const PRODUCT_CODE_TYPE = {
    [INDEX_SUMMIT_6]: "UI_SET_IS6",
    [INDEX_SUMMIT_6_PRO]: "UI_SET_IS6Pro",
    [INDEX_FRONTIER_5]: "UI_SET_IS5",
    [INDEX_FRONTIER_5_PRO]: "UI_SET_IS5Pro",
    [INDEX_FRONTIER_7]: "UI_SET_IS7",
    [INDEX_FRONTIER_7_PRO]: "UI_SET_IS7Pro",
};

export const PRODUCT_TYPE_LIST = [
    INDEX_SUMMIT_6,
    INDEX_SUMMIT_6_PRO,
    INDEX_FRONTIER_5,
    INDEX_FRONTIER_5_PRO,
    INDEX_FRONTIER_7,
    INDEX_FRONTIER_7_PRO
];

export const PRODUCT_NAME_MAPPING = {
    [INDEX_SUMMIT_6]: "Index Summit 6",
    [INDEX_SUMMIT_6_PRO]: "Index Summit 6 Pro",
    [INDEX_FRONTIER_5]: "Index Frontier 5",
    [INDEX_FRONTIER_5_PRO]: "Index Frontier 5 Pro",
    [INDEX_FRONTIER_7]: "Index Frontier 7",
    [INDEX_FRONTIER_7_PRO]: "Index Frontier 7 Pro"
};

export const TOOL_PDF_NAME_MAPPING = {
    [UI_SET_IS6]: "index-summit-6-prospectus",
    [UI_SET_IS6Pro]: "index-summit-6-pro-prospectus",
    [UI_SET_IS5]: "index-frontier-5-prospectus",
    [UI_SET_IS5Pro]: "index-frontier-5-pro-prospectus",
    [UI_SET_IS7]: "index-frontier-7-prospectus",
    [UI_SET_IS7Pro]: "index-frontier-7-pro-prospectus",
}

export const TOOL_LINK_NAME_MAPPING = {
    [UI_SET_IS6]: "https://mybusiness.massmutualascend.com/docs/default-source/default-document-library/forms/marketing-materials/index-summit-6-prospectus.pdf",
    [UI_SET_IS6Pro]: "https://mybusiness.massmutualascend.com/docs/default-source/default-document-library/forms/marketing-materials/index-summit-6-pro-prospectus.pdf",
    [UI_SET_IS5]: "https://mybusiness.massmutualascend.com/docs/default-source/default-document-library/forms/marketing-materials/index-frontier-5-prospectus.pdf",
    [UI_SET_IS5Pro]: "https://mybusiness.massmutualascend.com/docs/default-source/default-document-library/forms/marketing-materials/index-frontier-5-pro-prospectus.pdf",
    [UI_SET_IS7]: "https://mybusiness.massmutualascend.com/docs/default-source/default-document-library/forms/marketing-materials/index-frontier-7-prospectus.pdf",
    [UI_SET_IS7Pro]: "https://mybusiness.massmutualascend.com/docs/default-source/default-document-library/forms/marketing-materials/index-frontier-7-pro-prospectus.pdf",
}

export const INSTITUTION_CODE_MAP = {
    [RETAIL_FI_CODE]: RETAIL_FI,
    [RAYMOND_JAMES_CODE]: RAYMOND_JAMES,
    [MMFA_CODE]: MMFA,
    [CETERA_CODE]: CETERA,
    [HUNTINGTON_CODE]: HUNTINGTON,
    [LPL_CODE]: LPL,
    [WELLS_FARGO_CODE]: WELLS_FARGO,
}

export const TOOL_YEARS_MAPPING = {
    [UI_SET_IS6]: "six",
    [UI_SET_IS6Pro]: "six",
    [UI_SET_IS5]: "five",
    [UI_SET_IS5Pro]: "five",
    [UI_SET_IS7]: "seven",
    [UI_SET_IS7Pro]: "seven",
}

export const TOOL_PAGE_SUFFIX = {
    [UI_SET_IS6]: "S1848222NW",
    [UI_SET_IS6Pro]: "S1848322NW",
    [UI_SET_IS5]: "S1847822NW",
    [UI_SET_IS5Pro]: "S1847922NW",
    [UI_SET_IS7]: "S1848022NW",
    [UI_SET_IS7Pro]: "S1848122NW",
}