import React from 'react';
import { Segment, Grid, Label, Header } from "semantic-ui-react";
import { connect } from 'react-redux';
import NumberFormat from "react-number-format";
import { getInputClass } from "../../../../common/common";
import * as actionTypes from '../../../../reducers/actions';
import {SHAREMCSI_MAPPING} from "../../../../reducers/actions";

const shareMCSIBox5And7 = (props) => {

    let data = props.database.toJS();
    const selectedTool = props.uiReducer.toJS().selectedTool;
    let total = data[SHAREMCSI_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value + data[SHAREMCSI_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value;

    if (isNaN(total)) total = 0.0;

    return (
        <Segment raised>
            <Header as="h1">iShares MSCI EAFE ETF</Header>

            <Grid columns='equal'>

                <Grid.Row>
                    <Grid.Column></Grid.Column>
                    <Grid.Column></Grid.Column>
                    <Grid.Column>
                        <Label basic>Purchase Payments $250,000 and Over</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>Purchase Payments under $250,000</Label>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Label basic>-10% Floor Indexed Strategy</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data[SHAREMCSI_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.isValid)}>
                            <NumberFormat
                                value={data[SHAREMCSI_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateMCSI(selectedTool, "minustenPercentBufferIdxStrategy", values.value)}
                                onClick={() => props.updateMCSI(selectedTool, "minustenPercentBufferIdxStrategy", "")}
                                onBlur={() => props.validateMCSI(selectedTool)}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SHAREMCSI_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.paymentOver250K.value}%</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SHAREMCSI_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.paymentUnder250K.value}%</Label>
                    </Grid.Column>

                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Label basic>0% Floor Indexed Strategy</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data[SHAREMCSI_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.isValid)}>
                            <NumberFormat
                                value={data[SHAREMCSI_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateMCSI(selectedTool, "zeroPercentFloorIdxStrategy", values.value)}
                                onClick={() => props.updateMCSI(selectedTool, "zeroPercentFloorIdxStrategy", "")}
                                onBlur={() => props.validateMCSI(selectedTool)}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SHAREMCSI_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.paymentOver250K.value}%</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SHAREMCSI_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.paymentUnder250K.value}%</Label>
                    </Grid.Column>

                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Label basic>iShares MSCI EAFE ETF Total:</Label>
                    </Grid.Column>
                    <Grid.Column>
                        {total}%
                    </Grid.Column>
                    <Grid.Column></Grid.Column>
                    <Grid.Column></Grid.Column>
                </Grid.Row>

            </Grid>

        </Segment>
    )
};

const mapStateToProps = (state) => {
    return {
        database: state.get("databaseReducer"),
        uiReducer: state.get('uiReducer'),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateMCSI: (selectedTool, key, value) => dispatch({
            type: actionTypes.DB_UPDATE_MCSI,
            selectedTool: selectedTool,
            key: key,
            value: value
        }),
        validateMCSI: (selectedTool) => dispatch({
            type: actionTypes.DB_VALIDATE_MCSI,
            selectedTool: selectedTool
        })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(shareMCSIBox5And7);