import React, {useEffect, useState} from 'react';
import {Grid, Container, Segment, Button, Header, Icon, Accordion, Checkbox} from 'semantic-ui-react';
import './index.css';
import {connect} from 'react-redux';
import SideBar from "../SideBar/index";
import * as actionTypes from "../../../reducers/actions";
import {PRODUCT_NAME_MAPPING, PRODUCT_TYPE_LIST} from "../../../reducers/actions";
import {get_admin_institute_products, save_admin_institute_products} from "../../../api/api";

const AccessControl = (props) => {

    const [state, setState] = useState({
        activeIndex: -1,
        data: []
    });

    useEffect(() => {
        get_admin_institute_products()
            .then((response) => {
                setState({
                    ...state,
                    data: response.map(item => {
                        return {
                            ...item,
                            gaigProductTypeList: PRODUCT_TYPE_LIST.map(produce => {
                                return {name: produce, checked: item.gaigProductTypeList.includes(produce)}
                            })
                        }
                    })
                });
            });
    }, []);

    const handleClickAccordion = (e, titleProps) => {
        const { index } = titleProps;
        const newIndex = state.activeIndex === index ? -1 : index;
        setState({
            ...state,
            activeIndex: newIndex,
        });
    };

    const updateProduct = (checked, index, subIndex) => {
        let newState = {...state};
        newState.data[index].gaigProductTypeList[subIndex].checked = checked;
        setState(newState);
    };

    const saveProduct = (product) => {
        save_admin_institute_products({
            ...product,
            gaigProductTypeList: product.gaigProductTypeList
                .filter(product => product.checked)
                .map(product => {
                    return product.name;
                })
        });
    };

    return (
        <Container>
            <Grid>
                <Grid.Row className={'access-control-page'}>
                    <Grid.Column width={4}>
                        <SideBar />
                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid.Row>
                            <Grid.Column>
                                <Segment raised>
                                    <Header as="h1">Access Control</Header>
                                    <Grid columns='equal'>
                                        <Grid.Row>
                                            <Grid.Column className={"accordion-content"}>
                                                {state.data.map((item, index) => {
                                                    return (
                                                        <Accordion fluid styled>
                                                            <Accordion.Title
                                                                active={state.activeIndex === index}
                                                                index={index}
                                                                onClick={handleClickAccordion}
                                                            >
                                                                <Grid columns='equal'>
                                                                    <Grid.Row>
                                                                        <Grid.Column>
                                                                            <Icon name='dropdown'/>
                                                                            {item.name}
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                    {state.activeIndex !== index &&
                                                                        <Grid.Row>
                                                                            <Grid.Column>
                                                                                {item.gaigProductTypeList
                                                                                    .filter(product => product.checked)
                                                                                    .map((product) => {
                                                                                        return (
                                                                                            <span
                                                                                                className={"selected-tool-name"}>{PRODUCT_NAME_MAPPING[product.name]}</span>
                                                                                        );
                                                                                    })}
                                                                            </Grid.Column>
                                                                        </Grid.Row>
                                                                    }
                                                                </Grid>
                                                            </Accordion.Title>
                                                            <Accordion.Content active={state.activeIndex === index}>
                                                                <Grid columns='equal'>
                                                                    <Grid.Row>
                                                                        <Grid.Column>
                                                                            <b>URL:</b> {item.url}
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                    <Grid.Row>
                                                                        {item.gaigProductTypeList.map((product, subIndex) => {
                                                                            return (
                                                                                <Grid.Column>
                                                                                    <Checkbox
                                                                                        label={PRODUCT_NAME_MAPPING[product.name]}
                                                                                        onChange={(e, data) => updateProduct(data.checked, index, subIndex)}
                                                                                        checked={product.checked}
                                                                                    />
                                                                                </Grid.Column>
                                                                            );
                                                                        })}
                                                                    </Grid.Row>
                                                                    <Grid.Row>
                                                                        <Grid.Column>
                                                                            <Button onClick={() => saveProduct(item)}>Save</Button>
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                </Grid>
                                                            </Accordion.Content>
                                                        </Accordion>
                                                    );
                                                })}
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        uiReducer: state.get('uiReducer'),
        uxReducer: state.get("uxReducer"),
        database: state.get('databaseReducer'),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        save: () => dispatch({
            type: actionTypes.SAGA_SAVE_ADMIN_DATA
        })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessControl);