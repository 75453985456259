import React from 'react';
import {Grid, Header, Label} from 'semantic-ui-react';
import NumberFormat from 'react-number-format';
import {connect} from 'react-redux';
import './index.css';
import {getPurchaseRate, isIS6OrIS6Pro} from "../../../../common/common";
import {SHAREREALESTATE_MAPPING, TOOL_NAME_MAPPING} from "../../../../reducers/actions";

const SharesUS = (props) => {

    let adminDatabase = props.database.get('adminDatabase');
    let data = props.database.toJS();
    const selectedTool = props.uiReducer.toJS().selectedTool;
    let purchasePayment = data.annuityPurchaseAmount.value;

    return (
        <Grid columns='equal' className="low-padding-rows">
            <Grid.Row>
                <Grid.Column width={8}><Header as="h5">iShares US Real Estate</Header></Grid.Column>
                <Grid.Column><Header as="h5">Allocation %</Header></Grid.Column>
                <Grid.Column><Header as="h5">{TOOL_NAME_MAPPING[selectedTool]} Rate Applied</Header></Grid.Column>
            </Grid.Row>
            {isIS6OrIS6Pro(selectedTool) && <React.Fragment>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Label basic>1-Year 50% Downside Participation Rate with Upside Participation Rate</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>
                            <NumberFormat
                                displayType={'text'}
                                suffix={'%'}
                                value={data[SHAREREALESTATE_MAPPING[selectedTool]].oneYRTermPat.value}
                            />
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{getPurchaseRate(purchasePayment, [SHAREREALESTATE_MAPPING[selectedTool], "oneYRTermPat"], adminDatabase, selectedTool)}%</Label>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Label basic>1-yr -10% Floor with Cap</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>
                            <NumberFormat
                                displayType={'text'}
                                suffix={'%'}
                                value={data[SHAREREALESTATE_MAPPING[selectedTool]].oneYRTermCap.value}
                            />
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{getPurchaseRate(purchasePayment, [SHAREREALESTATE_MAPPING[selectedTool], "oneYRTermCap"], adminDatabase, selectedTool)}%</Label>
                    </Grid.Column>
                </Grid.Row>
                {/*<Grid.Row>*/}
                {/*    <Grid.Column width={8}>*/}
                {/*        <Label basic>2-Year 50% Downside Participation Rate with Upside Participation Rate</Label>*/}
                {/*    </Grid.Column>*/}
                {/*    <Grid.Column>*/}
                {/*        <Label basic>*/}
                {/*            <NumberFormat*/}
                {/*                displayType={'text'}*/}
                {/*                suffix={'%'}*/}
                {/*                value={data[SHAREREALESTATE_MAPPING[selectedTool]].twoYRTermPat.value}*/}
                {/*            />*/}
                {/*        </Label>*/}
                {/*    </Grid.Column>*/}
                {/*    <Grid.Column>*/}
                {/*        <Label basic>{getPurchaseRate(purchasePayment, [SHAREREALESTATE_MAPPING[selectedTool], "twoYRTermPat"], adminDatabase, selectedTool)}%</Label>*/}
                {/*    </Grid.Column>*/}
                {/*</Grid.Row>*/}
            </React.Fragment>}
            {!isIS6OrIS6Pro(selectedTool) && <React.Fragment>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Label basic>-10% Floor Indexed Strategy</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>
                            <NumberFormat
                                displayType={'text'}
                                suffix={'%'}
                                value={data[SHAREREALESTATE_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value}
                            />
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{getPurchaseRate(purchasePayment, [SHAREREALESTATE_MAPPING[selectedTool], "minustenPercentBufferIdxStrategy"], adminDatabase, selectedTool)}%</Label>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Label basic>0% Floor Indexed Strategy</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>
                            <NumberFormat
                                displayType={'text'}
                                suffix={'%'}
                                value={data[SHAREREALESTATE_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value}
                            />
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{getPurchaseRate(purchasePayment, [SHAREREALESTATE_MAPPING[selectedTool], "zeroPercentFloorIdxStrategy"], adminDatabase, selectedTool)}%</Label>
                    </Grid.Column>
                </Grid.Row>
            </React.Fragment>}
        </Grid>
    );
};


const mapStateToProps = (state) => {
    return {
        database: state.get("databaseReducer"),
        uiReducer: state.get('uiReducer')
    }
};

export default connect(mapStateToProps, null)(SharesUS);