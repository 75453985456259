import React from 'react';
import {Table} from "semantic-ui-react";

const MarketConditionsLegend = (props) => {
    return (
        <Table>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        Bear - 10th percentile of historical returns.
                    </Table.Cell>
                    <Table.Cell>
                        Assumed return of -8.19%.
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Little Bear - 25th percentile of historical returns.
                    </Table.Cell>
                    <Table.Cell>
                        Assumed return of 3.54%.
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Normal - Average of historical returns.
                    </Table.Cell>
                    <Table.Cell>
                        Assumed return of 6.88%.
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Little Bull - 75th percentile of historical returns.
                    </Table.Cell>
                    <Table.Cell>
                        Assumed return of 16.19%.
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Bull - 90th percentile of historical returns.
                    </Table.Cell>
                    <Table.Cell>
                        Assumed rate of 22.33%.
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );
};

export default MarketConditionsLegend;