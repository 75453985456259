import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {databaseReducer} from './reducers/database';
import {uxReducer} from './reducers/uxState';
import {uiReducer} from './reducers/uiState';
import {Map} from 'immutable';
import {BrowserRouter} from 'react-router-dom';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import {combineReducers} from 'redux-immutable';
import {createStore, applyMiddleware} from 'redux';
import createSagaMiddleware from 'redux-saga'
import { Provider } from 'react-redux';
import rootSaga from './reducers/sagas';

const initialState = Map();
const rootReducer = combineReducers({
    databaseReducer: databaseReducer,
    uxReducer: uxReducer,
    uiReducer: uiReducer
});


const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, initialState, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);

ReactDOM.render(<Provider store={store}><BrowserRouter><App/></BrowserRouter></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
