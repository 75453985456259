import React from 'react';
import { Segment, Container, Header } from "semantic-ui-react";
import { connect } from 'react-redux';
import Slider from 'react-rangeslider';
import './index.css';
import * as actionTypes from '../../../../reducers/actions';

// CONDITIONS OPTIONS
//  BEAR
//  LITTLE BEAR
//  NORMAL
//  LITTLE BULL
//  BULL

const conditionsSlider = (props) => {
    let labels = {
        1: "Bear",
        2: "Little Bear",
        3: "Normal",
        4: "Little Bull",
        5: "Bull"
    };

    let data = props.database.toJS();

    return (
        <Segment className="conditions__slider">
            <Container>
                <Header as="h1">Market Conditions - Years 1 - 3</Header>
                <Slider
                    min={1}
                    max={5}
                    labels={labels}
                    step={1}
                    value={data.conditions}
                    onChange={(value) => props.updateConditions(value)}
                    format={(value) => {
                        return labels[value];
                    }}
                />
            </Container>
        </Segment>
    );
};

const mapStateToProps = (state) => {
    return {
        database: state.get("databaseReducer")
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateConditions: (value) => dispatch({
            type: actionTypes.DB_UPDATE_CONDITIONS,
            value: value
        })
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(conditionsSlider);