import React from 'react';
import { Container, Button, Grid, Icon } from "semantic-ui-react";
import { connect } from 'react-redux';
import * as actionTypes from '../../../reducers/actions';
import './index.css';
import moment from "moment";
import { TOOL_NAME_MAPPING } from "../../../reducers/actions";
import {isIS5OrIS5Pro, isIS5OrIS7, isIS5ProOrIS7Pro, isIS6OrIS6Pro, isIS7OrIS7Pro, isPro} from "../../../common/common";

// it should display next / previous buttons based on the ui state
// it should be held in a container
// next button should change to "start over" on final page


// should display the previous button
// should be disabled if required.
// should update the uxState to the previous page if pressed while enabled
const PrevButton = (props) => {

    let button = (<Button secondary onClick={() => props.prevPage()}>&lt;</Button>);

    if (props.isDisabled) {
        button = (<Button disabled secondary>&lt;</Button>);
    }

    return button;
};

// show start over if required
// should move to next step if pressed
const NextButton = (props) => {

    let largeLabel = "Calculate";
    let smallLabel = '>';

    if (props.isLastStep) {
        largeLabel = "Start Over";
        smallLabel = <Icon name={'redo'} />;
    }

    return (
        <Button onClick={() => { props.nextPage(); props.keepProductSelection(props.selectedTool) }} primary>
            <span className={'next-button-label'}>{largeLabel}</span>
            <span className={'next-button-label--mobile'}>{smallLabel}</span>
        </Button>
    )
};

const navigation = (props) => {
    let uiReducer = props.uiReducer.toJS();
    const selectedTool = props.uiReducer.toJS().selectedTool;
    let adminData = props.adminDatabase.toJS();
    let lastParaStyle = {
        'fontWeight': 'bold',
        'fontStyle': 'italic'
    }

    const getContractNumbers = (tool) => {
        let contractNumbers = "";
        if (isPro(tool)) {
            if (isIS6OrIS6Pro(tool)) {
                contractNumbers = "For use with contract form P1833621ID. Contract form numbers vary by state.";
            } else if (isIS5OrIS5Pro(tool)) {
                contractNumbers = "For use with contract form P1833421ID. Contract form numbers vary by state.";
            } else if (isIS7OrIS7Pro(tool)) {
                contractNumbers = "For use with contract form P1833521ID. Contract form numbers vary by state.";
            }
        } else {
            if (isIS6OrIS6Pro(tool)) {
                contractNumbers = "For use with contract form P1825218ID. Contract form numbers vary by state.";
            } else if (isIS5OrIS5Pro(tool)) {
                contractNumbers = "For use with contract form P1822217ID. Contract form numbers vary by state.";
            } else if (isIS7OrIS7Pro(tool)) {
                contractNumbers = "For use with contract form P1822317ID. Contract form numbers vary by state.";
            }
        }
        return contractNumbers;
    }

    let bottomNormalMessage = (
        <Container className={'intro-container-footer'}>
            {!isIS6OrIS6Pro(selectedTool) ? <p>In addition to the indexed strategies covered by the tool, the {TOOL_NAME_MAPPING[selectedTool]} also offers a declared rate strategy that earns interest over 1-year terms at a fixed rate we set before each term begins. Note: The declared rate strategy is not available for contracts issued in Missouri.</p> : null}
            <p>The caps and participation rates listed above are effective for terms beginning on {moment(adminData.lastUpdateDate).format("MM/DD/YYYY")}. New terms start on the 6th and 20th days of each month. At least 10 days before the start of any term, we will post on our website the caps and upside participation rates we have set for that term. The S&P 500 6-year 10% buffer with upside participation rate strategy is only available for a term that starts in the first contract year.</p>
            <p>The {TOOL_NAME_MAPPING[selectedTool]} also offers indexed strategies linked to the First Trust Barclays Edge Index. You are not able to make hypothetical allocations to these strategies when using this web tool.</p>
            <p style={lastParaStyle}>This product can only be sold through a Broker/Dealer that is contracted with MassMutual Ascend. Any sales solicitation must be accompanied or preceded by a prospectus. To obtain a copy of the prospectus, please visit <a href='https://www.massmutualascend.com/rilarates' target="_blank">MassMutualAscend.com/RILArates</a>.</p>
            <p>{getContractNumbers(selectedTool)}</p>
            <p>This content does not apply in the state of New York.</p>
            <h4>{actionTypes.TOOL_PAGE_SUFFIX[selectedTool]}-1</h4>
        </Container>
    )

    let bottomLastPageMessage = (
        <Container className={'intro-container-footer'}>
            {isIS6OrIS6Pro(selectedTool) &&
                <p>The Tool generates a hypothetical illustration of mathematical principles. The
                    information provided above is intended to illustrate the impact of
                    the {TOOL_NAME_MAPPING[selectedTool]}'s unique combination of upside potential and
                    downside protection.</p>
            }
            {(isIS5OrIS7(selectedTool) || isIS5ProOrIS7Pro(selectedTool)) &&
                <p>The Tool generates a hypothetical illustration of mathematical principles. The
                    information provided above is intended to illustrate the impact of
                    the {TOOL_NAME_MAPPING[selectedTool]}'s combination of upside potential and
                    downside protection.</p>
            }
            <p>The Tool relies on user inputs and historical performance information to produce hypothetical Monte Carlo simulations that are not intended to be predictive. Past performance does not guarantee future results. Historical index values are not indicative of the gains and losses that may have been credited to an {TOOL_NAME_MAPPING[selectedTool]} annuity during the same time period.</p>
            <p>Please refer to the Important Information section for more information about the methods and assumptions used in producing the simulations. Different methods and assumptions would lead to different results, which could vary significantly from the returns shown above.</p>
            {isIS6OrIS6Pro(selectedTool) &&
                <p>Results produced by the Tool are likely to vary with each use, even if user inputs,
                    caps, and participation rates remain constant, because the Tool uses randomly
                    generated numbers to produce annual performance figures for each index and ETF in
                    every Monte Carlo simulation. Results produced by the Tool are also likely to vary
                    over time, because index performance information used by the Tool will be updated
                    periodically.</p>
            }
            {isIS5OrIS7(selectedTool) &&
                <p>Results produced by the Tool are likely to vary with each use, even if user inputs,
                    caps, buffers, and floors remain constant, because the Tool uses randomly generated
                    numbers to produce annual performance figures for each index and ETF in every Monte
                    Carlo simulation. Results produced by the Tool are also likely to vary
                    over time, because index performance information used by the Tool will be updated
                    periodically.
                </p>
            }
            {isIS5ProOrIS7Pro(selectedTool) &&
                <p>Results produced by the Tool are likely to vary with each use, even if user inputs,
                    caps, buffers, floors, and contract fees remain constant, because the Tool uses
                    randomly generated numbers to produce annual performance figures for each index and
                    ETF in every Monte Carlo simulation. Results produced by the Tool are also likely to vary
                    over time, because index performance information used by the Tool will be updated
                    periodically.
                </p>
            }
            <p>IMPORTANT: The projections or other information generated by the Tool regarding the likelihood of various investment outcomes are hypothetical in nature, do not reflect actual investment results and are not guarantees of future results.</p>
            <p style={lastParaStyle}>This product can only be sold through a Broker/Dealer that is contracted with MassMutual Ascend Life Insurance Company. Any sales solicitation must be accompanied or preceded by a prospectus. To obtain a copy of the prospectus, please visit <a href='https://www.massmutualascend.com/rilarates' target="_blank">MassMutualAscend.com/RILArates</a>.</p>
            <p>{getContractNumbers(selectedTool)}</p>
            <p>This content does not apply in the state of New York.</p>
            <h4>{actionTypes.TOOL_PAGE_SUFFIX[selectedTool]}-2</h4>
        </Container>
    )

    let bottomMessage = bottomNormalMessage;
    if (!uiReducer.showNextButton) {
        bottomMessage = bottomLastPageMessage;
    }

    return (
        <Container>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={2}>
                        <PrevButton
                            isDisabled={!uiReducer.showPrevButton}
                            prevPage={props.prevPage}
                        />
                    </Grid.Column>
                    <Grid.Column width={12}></Grid.Column>
                    <Grid.Column width={2}>
                        <NextButton
                            isLastStep={!uiReducer.showNextButton}
                            nextPage={props.nextPage}
                            selectedTool={selectedTool}
                            keepProductSelection={props.keepProductSelection}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        {bottomMessage}
                    </Grid.Column>
                </Grid.Row>
            </Grid>

        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        uiReducer: state.get('uiReducer'),
        adminDatabase: state.getIn(["databaseReducer", "adminDatabase"])
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        nextPage: () => dispatch({
            type: actionTypes.SAGA_NEXT_PAGE
        }),
        prevPage: () => dispatch({
            type: actionTypes.SAGA_PREV_PAGE
        }),
        keepProductSelection: (value) => dispatch({
            type: actionTypes.UI_SET_TOOL,
            value: value
        })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(navigation);
