import React from 'react';
import {Segment, Grid, Header, Label} from 'semantic-ui-react';
import { connect } from 'react-redux';
import NumberFormat from "react-number-format";
import * as actionTypes from '../../../../reducers/actions';
import { getInputClass } from "../../../../common/common";
import {ADMIN_SPINDEX_MAPPING} from "../../../../reducers/actions";

const spIndex5And7 = (props) => {
    
    let data = props.database.toJS();
    const adminSelectedTool = sessionStorage.getItem('adminSelectedTool');
    
    return (
        <Segment raised>

            <Header as="h1">S&P 500 Index</Header>

            <Grid columns='equal'>
                <Grid.Row>
                    <Grid.Column></Grid.Column>
                    <Grid.Column>
                        <Label basic>Purchase Payments $250,000 and Over</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>Purchase Payments under $250,000</Label>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Label basic>10% Buffer Indexed Strategy</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].tenPercentBufferIdxStrategy.paymentOver250K.isValid)}>
                            <NumberFormat
                                value={data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].tenPercentBufferIdxStrategy.paymentOver250K.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "tenPercentBufferIdxStrategy", "paymentOver250K"], values.value)}
                                onBlur={() => props.validate(adminSelectedTool)}
                                onClick={() => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "tenPercentBufferIdxStrategy", "paymentOver250K"], "")}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].tenPercentBufferIdxStrategy.paymentUnder250K.isValid)}>
                            <NumberFormat
                                value={data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].tenPercentBufferIdxStrategy.paymentUnder250K.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "tenPercentBufferIdxStrategy", "paymentUnder250K"], values.value)}
                                onBlur={() => props.validate(adminSelectedTool)}
                                onClick={() => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "tenPercentBufferIdxStrategy", "paymentUnder250K"],"")}
                            />
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Label basic>-10% Floor Indexed Strategy</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].minustenPercentBufferIdxStrategy.paymentOver250K.isValid)}>
                            <NumberFormat
                                value={data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].minustenPercentBufferIdxStrategy.paymentOver250K.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "minustenPercentBufferIdxStrategy", "paymentOver250K"], values.value)}
                                onBlur={() => props.validate(adminSelectedTool)}
                                onClick={() => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "minustenPercentBufferIdxStrategy", "paymentOver250K"], "")}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].minustenPercentBufferIdxStrategy.paymentUnder250K.isValid)}>
                            <NumberFormat
                                value={data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].minustenPercentBufferIdxStrategy.paymentUnder250K.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "minustenPercentBufferIdxStrategy", "paymentUnder250K"], values.value)}
                                onBlur={() => props.validate(adminSelectedTool)}
                                onClick={() => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "minustenPercentBufferIdxStrategy", "paymentUnder250K"], "")}
                            />
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Label basic>0% Floor Indexed Strategy</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].zeroPercentFloorIdxStrategy.paymentOver250K.isValid)}>
                            <NumberFormat
                                value={data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].zeroPercentFloorIdxStrategy.paymentOver250K.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "zeroPercentFloorIdxStrategy", "paymentOver250K"], values.value)}
                                onBlur={() => props.validate(adminSelectedTool)}
                                onClick={() => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "zeroPercentFloorIdxStrategy", "paymentOver250K"], "")}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].zeroPercentFloorIdxStrategy.paymentUnder250K.isValid)}>
                            <NumberFormat
                                value={data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].zeroPercentFloorIdxStrategy.paymentUnder250K.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "zeroPercentFloorIdxStrategy", "paymentUnder250K"], values.value)}
                                onBlur={() => props.validate(adminSelectedTool)}
                                onClick={() => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "zeroPercentFloorIdxStrategy", "paymentUnder250K"], "")}
                            />
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

const mapStateToProps = (state) => {
    return {
        database: state.get('databaseReducer'),
        uiReducer: state.get('uiReducer'),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateValue: (key, value) => dispatch({
            type: actionTypes.DB_ADMIN_UPDATE,
            key: key,
            value: value
        }),
        validate: (adminSelectedTool) => dispatch({
            type: actionTypes.DB_ADMIN_VALIDATE,
            adminSelectedTool: adminSelectedTool
        })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(spIndex5And7);