import React, {Component} from 'react';
import {Container, Header, Grid, Table, List, Button} from 'semantic-ui-react';
import moment from 'moment';
import {connect} from 'react-redux';
import * as actionTypes from '../../../reducers/actions';
import {
    SHAREMCSI_MAPPING,
    SHAREREALESTATE_MAPPING, SPDRGOLDSHARES_MAPPING,
    SPINDEX_MAPPING,
    TOOL_NAME_MAPPING, TOOL_YEARS_MAPPING
} from "../../../reducers/actions";
import {
    formatPercentage,
    isIS5OrIS5Pro,
    isIS5OrIS7,
    isIS5ProOrIS7Pro,
    isIS6OrIS6Pro,
    isIS7OrIS7Pro,
    isPro
} from "../../../common/common";

export class disclosures extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        let adminData = this.props.adminDatabase.toJS();
        let data = this.props.database.toJS();
        let selectedTool = sessionStorage.getItem('selectedTool');
        if (selectedTool == null) selectedTool = actionTypes.UI_SET_IS6;
        if (selectedTool) this.props.keepProductSelection(selectedTool);
        this.props.updateShowNavigationRowButtons(true);

        const getProRate = (tool) => {
            let rate = 0;
            if (isPro(tool)) {
                if (isIS6OrIS6Pro(tool)) {
                    rate = data.annuityPurchaseAmount.value > 100000 ? adminData.is6ProFees.paymentOver100K.value : adminData.is6ProFees.paymentUnder100K.value;
                } else if (isIS5OrIS5Pro(tool)) {
                    rate = data.annuityPurchaseAmount.value > 250000 ? adminData.is5ProFees.paymentOver250K.value : adminData.is5ProFees.paymentUnder250K.value;
                } else if (isIS7OrIS7Pro(tool)) {
                    rate = data.annuityPurchaseAmount.value > 250000 ? adminData.is7ProFees.paymentOver250K.value : adminData.is7ProFees.paymentUnder250K.value;
                }
            }
            return rate + "%";
        }

        const getContractNumbers = (tool) => {
            let contractNumbers = "";
            if (isPro(tool)) {
                if (isIS6OrIS6Pro(tool)) {
                    contractNumbers = "For use with contract form P1833621ID. Contract form numbers vary by state.";
                } else if (isIS5OrIS5Pro(tool)) {
                    contractNumbers = "For use with contract form P1833421ID. Contract form numbers vary by state.";
                } else if (isIS7OrIS7Pro(tool)) {
                    contractNumbers = "For use with contract form P1833521ID. Contract form numbers vary by state.";
                }
            } else {
                if (isIS6OrIS6Pro(tool)) {
                    contractNumbers = "For use with contract form P1825218ID. Contract form numbers vary by state.";
                } else if (isIS5OrIS5Pro(tool)) {
                    contractNumbers = "For use with contract form P1822217ID. Contract form numbers vary by state.";
                } else if (isIS7OrIS7Pro(tool)) {
                    contractNumbers = "For use with contract form P1822317ID. Contract form numbers vary by state.";
                }
            }
            return contractNumbers;
        }

        return (
            <Container text textAlign='left'>
                <Header as="h1">Disclosures</Header>

                <div id={'disclosures-output'}>

                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                {isIS6OrIS6Pro(selectedTool) &&
                                <p>The Tool generates a hypothetical illustration of mathematical principles. The
                                    information provided above is intended to illustrate the impact of
                                    the {TOOL_NAME_MAPPING[selectedTool]}'s unique combination of upside potential and
                                    downside protection.</p>
                                }
                                {(isIS5OrIS7(selectedTool) || isIS5ProOrIS7Pro(selectedTool)) &&
                                <p>The Tool generates a hypothetical illustration of mathematical principles. The
                                    information provided above is intended to illustrate the impact of
                                    the {TOOL_NAME_MAPPING[selectedTool]}'s combination of upside potential and
                                    downside protection.</p>
                                }
                                <p>The Tool relies on user inputs and historical performance information to produce hypothetical Monte Carlo simulations that are not intended to be predictive. Past performance does not guarantee future results. Historical index values are not indicative of the gains and losses that may have been credited to an {TOOL_NAME_MAPPING[selectedTool]} annuity during the same time period.</p>
                                <p>This section provides information about the methods and assumptions used in producing the simulations. Different methods and assumptions would lead to different results, which could vary significantly from the returns shown above.</p>
                                {isIS6OrIS6Pro(selectedTool) &&
                                <p>Results produced by the Tool are likely to vary with each use, even if user inputs,
                                    caps, and participation rates remain constant, because the Tool uses randomly
                                    generated numbers to produce annual performance figures for each index and ETF in
                                    every Monte Carlo simulation. Results produced by the Tool are also likely to vary
                                    over time, because index performance information used by the Tool will be updated
                                    periodically.</p>
                                }
                                {isIS5OrIS7(selectedTool) &&
                                <p>Results produced by the Tool are likely to vary with each use, even if user inputs,
                                    caps, buffers, and floors remain constant, because the Tool uses randomly generated
                                    numbers to produce annual performance figures for each index and ETF in every Monte
                                    Carlo simulation. Results produced by the Tool are also likely to vary
                                    over time, because index performance information used by the Tool will be updated
                                    periodically.
                                </p>
                                }
                                {isIS5ProOrIS7Pro(selectedTool) &&
                                <p>Results produced by the Tool are likely to vary with each use, even if user inputs,
                                    caps, buffers, floors, and contract fees remain constant, because the Tool uses
                                    randomly generated numbers to produce annual performance figures for each index and
                                    ETF in every Monte Carlo simulation. Results produced by the Tool are also likely to vary
                                    over time, because index performance information used by the Tool will be updated
                                    periodically.
                                </p>
                                }
                                <p>IMPORTANT: The projections or other information generated by the Tool regarding the likelihood of various investment outcomes are hypothetical in nature, do not reflect actual investment results and are not guarantees of future results.</p>
                                <p style={{"fontStyle": "italic", "fontWeight": "bold"}}>This product can only be sold through a Broker/Dealer that is contracted with MassMutual Ascend Life Insurance Company. Any sales solicitation must be accompanied or preceded by a prospectus. To obtain a copy of the prospectus, please visit <a href="https://www.massmutualascend.com/rilarates" target="_blank">MassMutualAscend.com/RILArates</a>.</p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Header as={'h3'}>1. About the {TOOL_NAME_MAPPING[selectedTool]} </Header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <p>The following information is intended to help you understand important features of
                                    the {TOOL_NAME_MAPPING[selectedTool]} that are relevant to the operation of the Tool. A complete
                                    description of the features, rights, and obligations under the {TOOL_NAME_MAPPING[selectedTool]} can be
                                    found in the prospectus.</p>
                                <p>The {TOOL_NAME_MAPPING[selectedTool]} annuity is a registered index-linked annuity contract issued by MassMutual
                                    Ascend ("we" or "us").</p>
                                <p style={{"fontStyle": "italic", "textDecoration": "underline"}}>Purchase</p>
                                <p>Initial purchase payments for an {TOOL_NAME_MAPPING[selectedTool]} annuity contract must be at least
                                    $25,000. Our prior approval is required for purchase payments exceeding $1,000,000.
                                    Additional purchase payments of at least $10,000 can be made up to two months after
                                    the contract effective date.</p>
                                {isIS6OrIS6Pro(selectedTool) &&
                                <React.Fragment>
                                    <p style={{"fontStyle": "italic", "textDecoration": "underline"}}>Indexed
                                        Strategies</p>
                                    <p>
                                        At the beginning of each term, the {TOOL_NAME_MAPPING[selectedTool]} offers the opportunity to allocate funds among multiple indexed strategies. New terms begin on the 6th and 20th of each month. The S&P 6-year 10% buffer with upside participation rate strategy is only available for a term that starts in the first contract year. If the owner does not reallocate at the end of the first term, then we will apply the ending value of that strategy to a new term of the S&P 500 1-year 50% downside participation rate with upside participation rate strategy.
                                    </p>
                                    <p className="indent-left-20" style={{"fontStyle": "italic"}}>Indexed Strategies</p>
                                    <p> Indexed strategies provide returns based, in part, on the rise of fall of the S&P 500 Index, iShares MSCI EAFE ETF, iShares U.S. Real Estate ETF, or First Trust Barclays Edge Index. Each term of the indexed strategies is either one year, two years or six years long. The method used to calculate an indexed strategy's value depends on whether the value is being calculated at the end of a term or during a term.</p>
                                    <p style={{fontWeight: "bold"}}>{isPro(selectedTool) ? "Indexed strategy values calculated before the end of a term are reduced by contract fees, option costs, and trading costs, which means that strategy values calculated before the end of a term will almost always be less than the value suggested by the rise or fall of the Index."
                                        : "Indexed strategy values calculated before the end of a term are reduced by option and trading costs, which means that those values will almost always be less than the value suggested by the rise or fall of the Index."}</p>
                                    <p>At the end of a term, the value of an indexed strategy is the investment base
                                        increased for any rise in the applicable Index over the term or decreased for
                                        any
                                        fall in the applicable Index over the term. For some strategies, any increase
                                        for
                                        the term is subject to a limit called the cap. For others, any increase for the
                                        term
                                        is subject to a limit called the upside participation rate. We set the caps and
                                        upside participation rates for each term before the first day of that term. As a
                                        result, caps and upside participation rates can vary from term to term.</p>
                                        <p>For all indexed strategies, any decrease for a Term is limited by a downside participation rate, floor, or a buffer. The downside participation rate, floor, or a buffer we set for an indexed strategy will apply to every term of that indexed strategy.</p>
                                        <p>The downside participation rate is the portion of any net fall in the index for the term taken into account to determine the strategy value at the end of the term. For each indexed strategy currently offered with a downside participation rate, the downside participation rate is 50%. This means that you can lose 50% each term.</p>
                                        <p>The floor is the portion of any net fall in the index for the term that is taken into account to determine the strategy value for a strategy at the end of the term. For some floor strategies, the floor is -10%. This means that you can lose 10% each term. For other floor strategies, the floor is 0%. This means that a fall in the index has no impact on strategy value each term.</p>
                                    <p>The buffer is the portion of any net fall in the index for the term that is disregarded to determine the strategy value for a strategy at the end of the term. For each indexed strategy currently offered with a buffer, the buffer is 10%. This means that an index fall of up to 10% would have no impact on strategy value each term, but you can lose 90% each term.</p>
                                </React.Fragment>
                                }
                                {isIS5OrIS7(selectedTool) &&
                                <React.Fragment>
                                    <p style={{"fontStyle": "italic", "textDecoration": "underline"}}>Indexed
                                        Strategies</p>
                                    <p>At the beginning of each term, the {TOOL_NAME_MAPPING[selectedTool]} offers the
                                        opportunity to allocate funds among multiple indexed strategies. New terms begin
                                        on the 6th and 20th of each month.</p>
                                    <p className="indent-left-20" style={{"fontStyle": "italic"}}>Indexed Strategies</p>
                                    <p>Indexed strategies provide returns based, in part, on the rise or fall of the S&P
                                        500 Index, iShares MSCI EAFE ETF, iShares U.S. Real Estate ETF, or SPDR Gold
                                        Shares ETF (each an "Index") over a term. Each term of the indexed strategies is
                                        one year long. The method used to calculate an indexed strategy's value depends
                                        on whether the value is being calculated at the end of a term or during a
                                        term.</p>
                                    <p>A withdrawal before the end of a term may have a significant impact on values at
                                        the end of the term. Gains on withdrawals before the end of a term will be
                                        limited by the cap and a vesting factor, while any losses before the end of a
                                        term will be limited only by the floor or buffer.</p>
                                    <p>At the end of a term, the value
                                        of an indexed strategy is the investment base increased for any rise in the
                                        applicable Index over the term or decreased for any fall in the applicable Index
                                        over the term. Any increase for the term is subject to a limit called the cap.
                                        We set the caps for each term before the first day of that term. As a result,
                                        caps can vary from term to term.</p>
                                    <p>Any decrease for the term is subject to a buffer or floor. The buffer or floor we
                                        set for an indexed strategy will apply to every term of that indexed strategy.
                                        We currently offer strategies with 10% buffers, -10% floors, and 0% floors.</p>
                                </React.Fragment>
                                }
                                {isIS5ProOrIS7Pro(selectedTool) &&
                                <React.Fragment>
                                    <p style={{"fontStyle": "italic", "textDecoration": "underline"}}>Indexed
                                        Strategies</p>
                                    <p>At the beginning of each term, the {TOOL_NAME_MAPPING[selectedTool]} offers the opportunity to
                                        allocate funds among multiple indexed strategies. New terms begin on the 6th and
                                        20th of each month.</p>
                                    <p className="indent-left-20" style={{"fontStyle": "italic"}}>Indexed Strategies</p>
                                    <p>Indexed strategies provide returns based, in part, on the rise or fall of the S&P
                                        500 Index, iShares MSCI EAFE ETF, or iShares U.S. Real Estate ETF (each an
                                        "Index") over a term. Each term of the indexed strategies is one year long.
                                        The method used to calculate an indexed strategy's value depends
                                        on whether the value is being calculated at the end of a term or during a
                                        term.</p>
                                    <p>A withdrawal before the end of a term may have a significant impact on values at
                                        the end of the term. Gains on withdrawals before the end of a term will be
                                        limited by the cap and a vesting factor, while any losses before the end of a
                                        term will be limited only by the floor or buffer.</p>
                                    <p>At the end of a term, the value
                                        of an indexed strategy is the investment base increased for any rise in the
                                        applicable Index over the term or decreased for any fall in the applicable Index
                                        over the term. Any increase for the term is subject to a limit called the cap.
                                        We set the caps for each term before the first day of that term. As a result,
                                        caps can vary from term to term.</p>
                                    <p>Any decrease for the term is subject to a buffer or floor. The buffer or floor we
                                        set for an indexed strategy will apply to every term of that indexed strategy.
                                        We currently offer strategies with 10% buffers, -10% floors and 0% floors. </p>
                                </React.Fragment>
                                }
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                {isIS6OrIS6Pro(selectedTool) &&
                                <React.Fragment>
                                    <p className="indent-top-20"
                                       style={{"marginLeft": "25px", "fontStyle": "italic"}}>Definitions:</p>
                                    <p style={{"marginLeft": "50px"}}>{isPro(selectedTool) ? "Investment base: The investment base is the amount applied to the indexed strategy at the beginning of the current term, reduced each day by the contract fee, and adjusted proportionally for any withdrawals taken during the current term and any related early withdrawal charges. During the term, the investment base remains unchanged except for the contract fees and any proportional adjustments for withdrawals."
                                        : "Investment Base: The base amount used to calculate the value of an indexed strategy. The investment base is the amount applied to an indexed strategy at the start of a current term, adjusted proportionally for any withdrawal during the term and any related early withdrawal charges."}</p>
                                    <p style={{"marginLeft": "50px"}}>Cap: For an indexed strategy with a cap, the cap
                                        is
                                        the largest rise in the Index over the term that is taken into account to
                                        determine
                                        the strategy value at the end of the term.</p>
                                    <p style={{"marginLeft": "50px"}}>Upside Participation Rate: For an indexed strategy
                                        with an upside participation rate, the upside participation rate is the share of
                                        any
                                        rise in the Index over a term taken into account to determine the strategy value
                                        at
                                        the end of the term.</p>
                                    <p style={{"marginLeft": "50px"}}>Downside Participation Rate: For an indexed strategy with a downside participation rate, the downside participation rate is the share of any fall in the Index over a term taken into account to determine the strategy value at the end of the term.</p>
                                    <p style={{"marginLeft": "50px"}}>Floor: For an indexed strategy with a floor, the floor is the share of any fall in the index over a term taken into account to determine the strategy value at the end of the term.</p>
                                    <p style={{"marginLeft": "50px"}}>Buffer: For an indexed strategy with a buffer, the buffer is the share of any fall in the index over a term that is disregarded  to determine the strategy value at the end of the term. </p>
                                </React.Fragment>
                                }
                                {isIS5OrIS7(selectedTool) &&
                                <React.Fragment>
                                    <p className="indent-top-20"
                                       style={{"marginLeft": "25px", "fontStyle": "italic"}}>Definitions:</p>
                                    <p style={{"marginLeft": "50px"}}>Investment Base: The base amount used to calculate
                                        the value of an indexed strategy. The investment base is the amount applied to
                                        an indexed strategy at the start of a current term, adjusted proportionally for
                                        any withdrawal during the term and any related early withdrawal charges.</p>
                                    <p style={{"marginLeft": "50px"}}>Cap: For an indexed strategy with a cap, the cap
                                        is the largest rise in the Index over the term that is taken into account to
                                        determine the strategy value at the end of the term.</p>
                                    <p style={{"marginLeft": "50px"}}>Buffer: A buffer is the portion of a negative
                                        index change that is disregarded when determining a vested loss. The buffer
                                        varies depending on the day of the term. The buffer at the end of a term is 10%
                                        and provides protection against the first 10% of index losses. Before the end of
                                        a term, the buffer is calculated daily as a prorated share of the annual 10%
                                        buffer and provides less protection against index losses.</p>
                                    <p style={{"marginLeft": "50px"}}>Floor: A floor is the largest negative index
                                        change used to determine a vested loss each term. -10% floor and 0% floor
                                        strategies are available.</p>
                                    <p style={{"marginLeft": "50px"}}>Overall Upside Limit: The Overall Upside Limit represents
                                        the maximum amount an {TOOL_NAME_MAPPING[selectedTool]} annuity could have grown at the end of a
                                        single term as a result of optimal index performance, using the allocations you entered.
                                        The Overall Upside Limit is a weighted average that takes into account the percentage
                                        allocated to each strategy and each strategy’s applicable cap to determine a weighted
                                        cap (allocation percentage x cap). The sum of the weighted caps equals the Overall Upside
                                        Limit.</p>
                                    <p style={{"marginLeft": "50px"}}>Overall Protection Limit: The Overall Protection
                                        represents the amount of an {TOOL_NAME_MAPPING[selectedTool]} annuity that would
                                        remain at the end of a single term as a result of the worst possible index
                                        performance, using the allocations you entered. The Overall Protection is a weighted
                                        average that takes into account the percentage allocated to each strategy and each
                                        strategy’s protection factor to determine a weighted protection (allocation
                                        percentage x protection factor). The sum of the weighted protections equals the
                                        Overall Protection.</p>
                                </React.Fragment>
                                }
                                {isIS5ProOrIS7Pro(selectedTool) &&
                                <React.Fragment>
                                    <p className="indent-top-20"
                                       style={{"marginLeft": "25px", "fontStyle": "italic"}}>Definitions:</p>
                                    <p style={{"marginLeft": "50px"}}>Investment base: The investment base is the amount applied to the
                                        indexed strategy at the beginning of the current term, reduced each day by the
                                        contract fee, and adjusted proportionally for any withdrawals taken during the
                                        current term and any related early withdrawal charges. During the term, the
                                        investment base remains unchanged except for the contract fees and any
                                        proportional adjustments for withdrawals.</p>
                                    <p style={{"marginLeft": "50px"}}>Cap: For an indexed strategy with a cap, the cap
                                        is the largest rise in the Index over the term that is taken into account to
                                        determine the strategy value at the end of the term.</p>
                                    <p style={{"marginLeft": "50px"}}>Buffer: A buffer is the portion of a negative
                                        index change that is disregarded when determining a vested loss. The buffer
                                        varies depending on the day of the term. The buffer at the end of a term is 10%
                                        and provides protection against the first 10% of index losses. Before the end of
                                        a term, the buffer is calculated daily as a prorated share of the annual 10%
                                        buffer and provides less protection against index losses.</p>
                                    <p style={{"marginLeft": "50px"}}>Floor: A floor is the largest negative index
                                        change used to determine a vested loss each term. -10% floor and 0% floor
                                        strategies are available.</p>
                                    <p style={{"marginLeft": "50px"}}>Overall Upside Limit: The Overall Upside Limit represents
                                        the maximum amount an {TOOL_NAME_MAPPING[selectedTool]} annuity could have grown at the end of a
                                        single term as a result of optimal index performance, using the allocations you entered.
                                        The Overall Upside Limit is a weighted average that takes into account the percentage
                                        allocated to each strategy and each strategy’s applicable cap to determine a weighted
                                        cap (allocation percentage x cap). The sum of the weighted caps equals the Overall Upside
                                        Limit.</p>
                                    <p style={{"marginLeft": "50px"}}>Overall Protection Limit: The Overall Protection
                                        represents the amount of an {TOOL_NAME_MAPPING[selectedTool]} annuity that would
                                        remain at the end of a single term as a result of the worst possible index
                                        performance, using the allocations you entered. The Overall Protection is a weighted
                                        average that takes into account the percentage allocated to each strategy and each
                                        strategy’s protection factor to determine a weighted protection (allocation
                                        percentage x protection factor). The sum of the weighted protections equals the
                                        Overall Protection.</p>
                                </React.Fragment>
                                }
                                {isIS5ProOrIS7Pro(selectedTool) &&
                                <p style={{"marginLeft": "50px"}}>In order to clearly illustrate how contract features
                                    impact the calculation of strategy values, the Overall Upside Limit and the Overall
                                    Protection are not reduced by contract fees. If the impact of contract fees was
                                    included, the Overall Upside Limit and the Overall Protection would both be
                                    lower.</p>
                                }
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <p className="indent-top-20"
                                   style={{"marginLeft": "25px", "fontStyle": "italic"}}>Available Indexes:</p>
                                <p style={{"marginLeft": "50px"}}>S&P 500 Price Return Index: The S&P 500® Index is designed to
                                    reflect the large-cap sector of the U.S. equity market and, due to its composition,
                                    it also represents the U.S. equity market in general. It includes 500 leading
                                    companies and captures approximately 80% coverage of available market
                                    capitalization. S&P 500 Index values do not include dividends declared by any S&P
                                    500 companies.</p>
                                <p style={{"marginLeft": "50px"}}>iShares MSCI EAFE ETF: The iShares MSCI EAFE ETF is an
                                    exchange traded fund that seeks to track the investment results of an index composed
                                    of large- and mid-capitalization developed market equities, excluding the U.S. and
                                    Canada (MSCI EAFE Index). This underlying index includes stocks from Europe,
                                    Australasia and the Far East.</p>
                                <p style={{"marginLeft": "50px"}}>iShares U.S. Real Estate ETF: The iShares U.S. Real
                                    Estate ETF is an exchange traded fund that seeks to track the investment results of
                                    an index composed of U.S. equities in the real estate sector.</p>
                                {(isIS5OrIS7(selectedTool) || isIS6OrIS6Pro(selectedTool)) &&
                                <p style={{"marginLeft": "50px"}}>SPDR Gold Shares ETF: Designed to reflect the market
                                    value of gold bullion.</p>
                                }
                                {isIS6OrIS6Pro(selectedTool) && <p style={{"marginLeft": "50px"}}>First Trust Barclays Edge Index: The First Trust Barclays Edge Index is designed to combine capital strength and value equity investment methodologies with a mix of US Treasury futures indexes for the potential to provide stable returns over time. The First Trust Barclays Edge Index consists of an equity component that combines stocks from the Capital Strength Index and the Value Line® Dividend Index. The risk free return is deducted from the equity underliers. The Index uses an optimizer to evaluate its exposure to stocks and US Treasury futures indexes on a daily basis to target a 7% volatility level by adjusting the exposure of the index constituents through a rules-based process called mean-variance optimization. </p>}
                            </Grid.Column>
                        </Grid.Row>
                        {isIS6OrIS6Pro(selectedTool) &&
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <p className="indent-top-20"
                                   style={{"marginLeft": "25px", "fontStyle": "italic"}}>Current Indexed Strategy Caps and Participation Rates for strategies available for allocation on this tool:</p>
                                <Table className={'index-strategies-table'}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell rowSpan='4'>
                                                {TOOL_NAME_MAPPING[selectedTool]} Rates
                                                Effective {moment(adminData.lastUpdateDate).format("MM/DD/YYYY")}
                                            </Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan={'13'}>
                                                Index Strategies
                                            </Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell textAlign={'center'} colSpan={'8'}>
                                                S&P 500 Index
                                            </Table.HeaderCell>
                                            <Table.HeaderCell colSpan={'2'}>
                                                iShares MSCI EAFE ETF
                                            </Table.HeaderCell>
                                            <Table.HeaderCell colSpan={'2'}>
                                                iShares U.S. Real Estate ETF
                                            </Table.HeaderCell>
                                            <Table.HeaderCell colSpan={'1'}>
                                                SPDR Gold Shares
                                            </Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell>
                                                1-year Term with Cap
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                1-year Term with Participation Rate
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                1-yr 10% Buffer with Cap
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                1-yr -10% Floor with Cap
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                1-yr 0% Floor with Cap
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                2-year Term with Cap
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                2-year Term with Participation Rate
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                6-Year Term with Participation Rate and 10% Buffer
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                1-year Term with Participation Rate
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                1-yr -10% Floor with Cap
                                            </Table.HeaderCell>
                                            {/*<Table.HeaderCell>*/}
                                            {/*    2-year Term with Participation Rate*/}
                                            {/*</Table.HeaderCell>*/}
                                            <Table.HeaderCell>
                                                1-year Term with Participation Rate
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                1-yr -10% Floor with Cap
                                            </Table.HeaderCell>
                                            {/*<Table.HeaderCell>*/}
                                            {/*    2-year Term with Participation Rate*/}
                                            {/*</Table.HeaderCell>*/}
                                            <Table.HeaderCell>
                                                1-yr -10% Floor with Cap
                                            </Table.HeaderCell>
                                        </Table.Row>

                                    </Table.Header>

                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>
                                                Purchase Payments $100,000 and Over
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SPINDEX_MAPPING[selectedTool]].oneYRTermCap.paymentOver100K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SPINDEX_MAPPING[selectedTool]].oneYRTermPat.paymentOver100K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SPINDEX_MAPPING[selectedTool]].oneYRBufferCap.paymentOver100K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SPINDEX_MAPPING[selectedTool]].oneYRMinusTenFloorCap.paymentOver100K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SPINDEX_MAPPING[selectedTool]].oneYRZeroFloorCap.paymentOver100K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SPINDEX_MAPPING[selectedTool]].twoYRTermCap.paymentOver100K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SPINDEX_MAPPING[selectedTool]].twoYRTermPat.paymentOver100K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SPINDEX_MAPPING[selectedTool]].sixYrTermPatTenBuffer.paymentOver100K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SHAREMCSI_MAPPING[selectedTool]].oneYRTermPat.paymentOver100K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SHAREMCSI_MAPPING[selectedTool]].oneYRTermCap.paymentOver100K.value}%
                                            </Table.Cell>
                                            {/*<Table.Cell>*/}
                                            {/*    {adminData[SHAREMCSI_MAPPING[selectedTool]].twoYRTermPat.paymentOver100K.value}%*/}
                                            {/*</Table.Cell>*/}
                                            <Table.Cell>
                                                {adminData[SHAREREALESTATE_MAPPING[selectedTool]].oneYRTermPat.paymentOver100K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SHAREREALESTATE_MAPPING[selectedTool]].oneYRTermCap.paymentOver100K.value}%
                                            </Table.Cell>
                                            {/*<Table.Cell>*/}
                                            {/*    {adminData[SHAREREALESTATE_MAPPING[selectedTool]].twoYRTermPat.paymentOver100K.value}%*/}
                                            {/*</Table.Cell>*/}
                                            <Table.Cell>
                                                {adminData[SPDRGOLDSHARES_MAPPING[selectedTool]].oneYRMinusTenFloorCap.paymentOver100K.value}%
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>
                                                Purchase Payments Under $100,000
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SPINDEX_MAPPING[selectedTool]].oneYRTermCap.paymentUnder100K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SPINDEX_MAPPING[selectedTool]].oneYRTermPat.paymentUnder100K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SPINDEX_MAPPING[selectedTool]].oneYRBufferCap.paymentUnder100K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SPINDEX_MAPPING[selectedTool]].oneYRMinusTenFloorCap.paymentUnder100K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SPINDEX_MAPPING[selectedTool]].oneYRZeroFloorCap.paymentUnder100K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SPINDEX_MAPPING[selectedTool]].twoYRTermCap.paymentUnder100K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SPINDEX_MAPPING[selectedTool]].twoYRTermPat.paymentUnder100K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SPINDEX_MAPPING[selectedTool]].sixYrTermPatTenBuffer.paymentUnder100K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SHAREMCSI_MAPPING[selectedTool]].oneYRTermPat.paymentUnder100K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SHAREMCSI_MAPPING[selectedTool]].oneYRTermCap.paymentUnder100K.value}%
                                            </Table.Cell>
                                            {/*<Table.Cell>*/}
                                            {/*    {adminData[SHAREMCSI_MAPPING[selectedTool]].twoYRTermPat.paymentUnder100K.value}%*/}
                                            {/*</Table.Cell>*/}
                                            <Table.Cell>
                                                {adminData[SHAREREALESTATE_MAPPING[selectedTool]].oneYRTermPat.paymentUnder100K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SHAREREALESTATE_MAPPING[selectedTool]].oneYRTermCap.paymentUnder100K.value}%
                                            </Table.Cell>
                                            {/*<Table.Cell>*/}
                                            {/*    {adminData[SHAREREALESTATE_MAPPING[selectedTool]].twoYRTermPat.paymentUnder100K.value}%*/}
                                            {/*</Table.Cell>*/}
                                            <Table.Cell>
                                                {adminData[SPDRGOLDSHARES_MAPPING[selectedTool]].oneYRMinusTenFloorCap.paymentUnder100K.value}%
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>

                                </Table>
                                {!isPro(selectedTool) && (
                                    <p>In addition to the indexed strategies covered by the Tool, the Index Summit 6 also offers a declared rate strategy that earns interest over 1-year terms at a fixed rate we set before each term begins. Note: The declared rate strategy is not available for contracts issued in Missouri.</p>
                                )}
                                {isPro(selectedTool) && (
                                    <p> For current rates on all indexed strategies, including the First Trust Barclays Edge Index strategies, please visit <a href="https://www.massmutualascend.com/rilarates" target="_blank">MassMutualAscend.com/RILArates</a>.</p>
                                )}
                            </Grid.Column>
                        </Grid.Row>
                        }

                        {!isIS6OrIS6Pro(selectedTool) &&
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <p className="indent-top-20"
                                   style={{"marginLeft": "25px", "fontStyle": "italic"}}>Current Indexed Strategy Caps:</p>
                                <Table className={'index-strategies-table'}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell rowSpan='4'>
                                                {TOOL_NAME_MAPPING[selectedTool]} Rates
                                                Effective {moment(adminData.lastUpdateDate).format("MM/DD/YYYY")}
                                            </Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan={'9'}>
                                                Index Strategies
                                            </Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan={'3'}>
                                                S&P 500 Index
                                            </Table.HeaderCell>
                                            <Table.HeaderCell colSpan={'2'}>
                                                iShares MSCI EAFE ETF
                                            </Table.HeaderCell>
                                            <Table.HeaderCell colSpan={'2'}>
                                                iShares U.S. Real Estate ETF
                                            </Table.HeaderCell>
                                            {isIS5OrIS7(selectedTool) &&
                                            <Table.HeaderCell colSpan={'2'}>
                                                SPDR Gold Shares
                                            </Table.HeaderCell>
                                            }
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell>
                                                10% Buffer Indexed Strategy
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                -10% Floor Indexed Strategy
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                0% Floor Indexed Strategy
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                -10% Floor Indexed Strategy
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                0% Floor Indexed Strategy
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                -10% Floor Indexed Strategy
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                0% Floor Indexed Strategy
                                            </Table.HeaderCell>
                                            {isIS5OrIS7(selectedTool) && <React.Fragment>
                                                <Table.HeaderCell>
                                                    -10% Floor Indexed Strategy
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    0% Floor Indexed Strategy
                                                </Table.HeaderCell>
                                            </React.Fragment>}
                                        </Table.Row>

                                    </Table.Header>

                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>
                                                Purchase Payments $250,000 and Over
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SPINDEX_MAPPING[selectedTool]].tenPercentBufferIdxStrategy.paymentOver250K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SPINDEX_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.paymentOver250K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SPINDEX_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.paymentOver250K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SHAREMCSI_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.paymentOver250K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SHAREMCSI_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.paymentOver250K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SHAREREALESTATE_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.paymentOver250K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SHAREREALESTATE_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.paymentOver250K.value}%
                                            </Table.Cell>
                                            {isIS5OrIS7(selectedTool) && <React.Fragment>
                                                <Table.Cell>
                                                    {adminData[SPDRGOLDSHARES_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.paymentOver250K.value}%
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {adminData[SPDRGOLDSHARES_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.paymentOver250K.value}%
                                                </Table.Cell>
                                            </React.Fragment>}
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>
                                                Purchase Payments Under $250,000
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SPINDEX_MAPPING[selectedTool]].tenPercentBufferIdxStrategy.paymentUnder250K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SPINDEX_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.paymentUnder250K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SPINDEX_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.paymentUnder250K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SHAREMCSI_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.paymentUnder250K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SHAREMCSI_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.paymentUnder250K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SHAREREALESTATE_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.paymentUnder250K.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {adminData[SHAREREALESTATE_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.paymentUnder250K.value}%
                                            </Table.Cell>
                                            {isIS5OrIS7(selectedTool) && <React.Fragment>
                                                <Table.Cell>
                                                    {adminData[SPDRGOLDSHARES_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.paymentUnder250K.value}%
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {adminData[SPDRGOLDSHARES_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.paymentUnder250K.value}%
                                                </Table.Cell>
                                            </React.Fragment>}
                                        </Table.Row>
                                    </Table.Body>

                                </Table>
                            </Grid.Column>
                        </Grid.Row>
                        }

                        {isPro(selectedTool)&&<Grid.Row>
                            <Grid.Column width={16}>
                                <p className="indent-top-20"
                                   style={{"fontStyle": "italic", "textDecoration": "underline"}}>Contract Fees</p>
                                <p>The {TOOL_NAME_MAPPING[selectedTool]} has a contract fee is that is deducted daily from each strategy and compounds to an effective annual rate of {getProRate(selectedTool)}. It is calculated daily as a percentage of, and is subtracted from, the then remaining investment base of each indexed strategy</p>
                            </Grid.Column>
                        </Grid.Row>}

                        <Grid.Row>
                            <Grid.Column width={16}>
                                <p className="indent-top-20"
                                   style={{"fontStyle": "italic", "textDecoration": "underline"}}>Withdrawals</p>
                                <p>Any withdrawal from an {TOOL_NAME_MAPPING[selectedTool]} contract will reduce the amount payable upon
                                    surrender, applied to the annuity payout benefit, or payable as the death
                                    benefit. </p>
                                <p>An early withdrawal charge may apply to amounts withdrawn in excess of the free
                                    withdrawal allowance during the first {TOOL_YEARS_MAPPING[selectedTool]} contract years. For the first contract
                                    year, the free withdrawal allowance is an amount equal to 10% of the total purchase
                                    payments received by us. For each subsequent contract year, the free withdrawal
                                    allowance is equal to 10% of the account value as of the most recent contract
                                    anniversary.</p>
                                <p>The early withdrawal charge rate is applied based on the following rate schedule:</p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                {isIS6OrIS6Pro(selectedTool) &&
                                <Table className="indent-top-20">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>
                                                Contract Year
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{"textDecoration": "underline"}}>
                                                1
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{"textDecoration": "underline"}}>
                                                2
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{"textDecoration": "underline"}}>
                                                3
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{"textDecoration": "underline"}}>
                                                4
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{"textDecoration": "underline"}}>
                                                5
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{"textDecoration": "underline"}}>
                                                6
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{"textDecoration": "underline"}}>
                                                7+
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>
                                                Early Withdrawal Charge
                                            </Table.Cell>
                                            <Table.Cell>
                                                {isPro(selectedTool) ? '9%' : '8%'}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {isPro(selectedTool) ? '8%' : '7%'}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {isPro(selectedTool) ? '7%' : '6%'}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {isPro(selectedTool) ? '6%' : '5%'}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {isPro(selectedTool) ? '5%' : '4%'}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {isPro(selectedTool) ? '4%' : '3%'}
                                            </Table.Cell>
                                            <Table.Cell>
                                                0%
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                                }
                                {isIS5OrIS5Pro(selectedTool) &&
                                <Table className="indent-top-20">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>
                                                Contract Year
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{"textDecoration": "underline"}}>
                                                1
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{"textDecoration": "underline"}}>
                                                2
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{"textDecoration": "underline"}}>
                                                3
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{"textDecoration": "underline"}}>
                                                4
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{"textDecoration": "underline"}}>
                                                5
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{"textDecoration": "underline"}}>
                                                6+
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>
                                                Early Withdrawal Charge
                                            </Table.Cell>
                                            <Table.Cell>
                                                8%
                                            </Table.Cell>
                                            <Table.Cell>
                                                7%
                                            </Table.Cell>
                                            <Table.Cell>
                                                6%
                                            </Table.Cell>
                                            <Table.Cell>
                                                5%
                                            </Table.Cell>
                                            <Table.Cell>
                                                4%
                                            </Table.Cell>
                                            <Table.Cell>
                                                0%
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                                }
                                {isIS7OrIS7Pro(selectedTool) &&
                                <Table className="indent-top-20">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>
                                                Contract Year
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{"textDecoration": "underline"}}>
                                                1
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{"textDecoration": "underline"}}>
                                                2
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{"textDecoration": "underline"}}>
                                                3
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{"textDecoration": "underline"}}>
                                                4
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{"textDecoration": "underline"}}>
                                                5
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{"textDecoration": "underline"}}>
                                                6
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{"textDecoration": "underline"}}>
                                                7
                                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{"textDecoration": "underline"}}>
                                                8+
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {isPro(selectedTool) && (
                                            <Table.Row>
                                                <Table.Cell>
                                                    Early Withdrawal Charge
                                                </Table.Cell>
                                                <Table.Cell>
                                                    9%
                                                </Table.Cell>
                                                <Table.Cell>
                                                    8%
                                                </Table.Cell>
                                                <Table.Cell>
                                                    7%
                                                </Table.Cell>
                                                <Table.Cell>
                                                    6%
                                                </Table.Cell>
                                                <Table.Cell>
                                                    5%
                                                </Table.Cell>
                                                <Table.Cell>
                                                    4%
                                                </Table.Cell>
                                                <Table.Cell>
                                                    2%
                                                </Table.Cell>
                                                <Table.Cell>
                                                    0%
                                                </Table.Cell>
                                            </Table.Row>
                                        )}
                                        {!isPro(selectedTool) && (
                                            <Table.Row>
                                                <Table.Cell>
                                                    Early Withdrawal Charge
                                                </Table.Cell>
                                                <Table.Cell>
                                                    8%
                                                </Table.Cell>
                                                <Table.Cell>
                                                    7%
                                                </Table.Cell>
                                                <Table.Cell>
                                                    6%
                                                </Table.Cell>
                                                <Table.Cell>
                                                    5%
                                                </Table.Cell>
                                                <Table.Cell>
                                                    4%
                                                </Table.Cell>
                                                <Table.Cell>
                                                    3%
                                                </Table.Cell>
                                                <Table.Cell>
                                                    2%
                                                </Table.Cell>
                                                <Table.Cell>
                                                    0%
                                                </Table.Cell>
                                            </Table.Row>
                                        )}
                                    </Table.Body>
                                </Table>
                                }
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <p className="indent-top-20">A withdrawal from an indexed strategy other than at the end
                                    of a term also reduces the investment base used to calculate the strategy value
                                    later in the term.</p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Header as={'h3'}>2. Criteria and Methodology Used By the Tool</Header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <p>The Tool uses a Monte Carlo simulation-based approach to illustrate how the features
                                    of the {TOOL_NAME_MAPPING[selectedTool]}'s indexed strategies can affect performance. Monte Carlo
                                    simulations are used in a variety of fields to model the probability of outcomes
                                    resulting from complex processes.</p>
                                {isIS6OrIS6Pro(selectedTool) &&
                                <p>To produce the results that appeared on the previous page, the Tool conducted up to
                                    5,000 individual Monte Carlo simulations to model returns for the Indexes. Each
                                    Monte Carlo simulation produced hypothetical annual returns for each Index, which
                                    the Tool then modified to incorporate the {TOOL_NAME_MAPPING[selectedTool]}'s
                                    contract fees caps and participation
                                    rates. The modified and unmodified Index returns in each simulation were then
                                    applied to strategy allocation information entered by the user, to produce data that
                                    demonstrates how the {TOOL_NAME_MAPPING[selectedTool]}'s unique combination of
                                    upside potential and
                                    downside protection can affect performance.</p>
                                }
                                {isIS5OrIS7(selectedTool) &&
                                <p>To produce the results that appeared on the previous page, the Tool conducted up to
                                    5,000 individual Monte Carlo simulations to model returns for the Indexes. Each
                                    Monte Carlo simulation produced hypothetical annual returns for each Index, which
                                    the Tool then modified to incorporate the {TOOL_NAME_MAPPING[selectedTool]}'s caps,
                                    buffers, and
                                    floors. The modified and unmodified Index returns in each simulation were then
                                    applied to strategy allocation information entered by the user, to produce data that
                                    demonstrates how the {TOOL_NAME_MAPPING[selectedTool]}'s combination of upside
                                    potential and downside
                                    protection can affect performance.</p>
                                }
                                {isIS5ProOrIS7Pro(selectedTool) &&
                                <p>To produce the results that appeared on the previous page, the Tool conducted up to
                                    5,000 individual Monte Carlo simulations to model returns for the Indexes. Each
                                    Monte Carlo simulation produced hypothetical annual returns for each Index, which
                                    the Tool then modified to incorporate the {TOOL_NAME_MAPPING[selectedTool]}'s caps,
                                    buffers,
                                    floors, and contract fees. The modified and unmodified Index returns in each
                                    simulation were then applied to strategy allocation information entered by the user,
                                    to produce data that demonstrates how the {TOOL_NAME_MAPPING[selectedTool]}'s
                                    combination of
                                    upside potential and downside protection can affect performance.</p>
                                }
                                <p style={{"fontStyle": "italic", "textDecoration": "underline"}}>How the Monte Carlo
                                    Simulations Were Produced</p>
                                <p>Each individual Monte Carlo simulation produced by the Tool contains hypothetical
                                    year-end values for each Index over a 10-year period.</p>
                                <p>The Tool assigned hypothetical year-end values to each simulation by generating
                                    random draws from a normal distribution based on return, variance, and correlation
                                    information compiled for each Index. The return, variance, and correlation
                                    information assigned to the Indexes was extracted from historical data. Variance and
                                    correlation information will not vary unless the historical data set is modified,
                                    but return information used can vary based on market condition inputs selected by
                                    the user.</p>
                                <p style={{"marginLeft": "25px", "fontStyle": "italic"}}>Historical Data</p>
                                <p>Monthly returns from December 2004 to December 2021 have been annualized for each Index.
                                    The Tool used those annualized returns to measure the variance of each Index and the
                                    correlation coefficient between each Index. The Tool also identified the annualized
                                    returns at the 10th, 25th, 50th, 75th, and 90th percentiles for each Index, in order
                                    to assign return information under various market conditions.</p>
                                <p style={{"marginLeft": "25px", "fontStyle": "italic"}}>Market Condition Inputs</p>
                                <p>For the first three years of each simulation, return information for each Index was
                                    assigned based on the market conditions selected by the user. Annualized returns at
                                    the 10th, 25th, 50th, 75th, and 90th percentiles for each Index are assigned when
                                    the user selects the "Bear", "Little Bear", "Normal", "Little Bull", and "Bull"
                                    market conditions, respectively.</p>
                                <p>If the user did not select a market condition, return information for the first three
                                    years of each simulation was assigned to each Index based on the "Normal" market
                                    condition.</p>
                                <p>For the final seven years of each simulation, return information was assigned to each
                                    Index based on the "Normal" market condition.</p>
                                <p>As an example, the following chart provides the return assumptions used for the S&P
                                    500 under each market condition:</p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Table className="indent-top-20">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>
                                                Market
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                Percentile
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                Return
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>
                                                Bear
                                            </Table.Cell>
                                            <Table.Cell>
                                                10th
                                            </Table.Cell>
                                            <Table.Cell>
                                                {formatPercentage(adminData[SPINDEX_MAPPING[selectedTool]].marketConditionReturns.bear)}
                                            </Table.Cell>
                                        </Table.Row>

                                        <Table.Row>
                                            <Table.Cell>
                                                Little Bear
                                            </Table.Cell>
                                            <Table.Cell>
                                                25th
                                            </Table.Cell>
                                            <Table.Cell>
                                                {formatPercentage(adminData[SPINDEX_MAPPING[selectedTool]].marketConditionReturns.littleBear)}
                                            </Table.Cell>
                                        </Table.Row>

                                        <Table.Row>
                                            <Table.Cell>
                                                Normal
                                            </Table.Cell>
                                            <Table.Cell>
                                                50th
                                            </Table.Cell>
                                            <Table.Cell>
                                                {formatPercentage(adminData[SPINDEX_MAPPING[selectedTool]].marketConditionReturns.normal)}
                                            </Table.Cell>
                                        </Table.Row>

                                        <Table.Row>
                                            <Table.Cell>
                                                Little Bull
                                            </Table.Cell>
                                            <Table.Cell>
                                                75th
                                            </Table.Cell>
                                            <Table.Cell>
                                                {formatPercentage(adminData[SPINDEX_MAPPING[selectedTool]].marketConditionReturns.littleBull)}
                                            </Table.Cell>
                                        </Table.Row>

                                        <Table.Row>
                                            <Table.Cell>
                                                Bull
                                            </Table.Cell>
                                            <Table.Cell>
                                                90th
                                            </Table.Cell>
                                            <Table.Cell>
                                                {formatPercentage(adminData[SPINDEX_MAPPING[selectedTool]].marketConditionReturns.bull)}
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                {isIS6OrIS6Pro(selectedTool) &&
                                <React.Fragment>
                                    <p className="indent-top-20"
                                       style={{"fontStyle": "italic", "textDecoration": "underline"}}>
                                        {isPro(selectedTool) ? "Application of " + TOOL_NAME_MAPPING[selectedTool] + " Contract Fees, Caps, and Participation Rates  to the Monte Carlo Simulations" :
                                            "Application of " + TOOL_NAME_MAPPING[selectedTool] + " Caps and Participation Rates to the Monte Carlo Simulations"}
                                    </p>
                                    <p>{isPro(selectedTool) ? "After generating the Monte Carlo simulations, the Tool then created two scenarios: under one scenario, the Index values in each Monte Carlo simulation were modified by the contract fees and disclosed caps and participation rates for the " + TOOL_NAME_MAPPING[selectedTool] + ", while under the other scenario, Index values in the same Monte Carlo simulations were left unmodified."
                                        : "After generating the Monte Carlo simulations, the Tool then created two scenarios: under one scenario, the Index values in each Monte Carlo simulation were modified by the disclosed caps and participation rates for the " + TOOL_NAME_MAPPING[selectedTool] + ", while under the other scenario, Index values in the same Monte Carlo simulations were left unmodified."}</p>
                                </React.Fragment>
                                }
                                {isIS5OrIS7(selectedTool) &&
                                <React.Fragment>
                                    <p className="indent-top-20"
                                       style={{"fontStyle": "italic", "textDecoration": "underline"}}>
                                        Application of {TOOL_NAME_MAPPING[selectedTool]}'s Caps, Buffers, and Floors to the
                                        Monte Carlo Simulations
                                    </p>
                                    <p>After generating the Monte Carlo simulations, the Tool then created two
                                        scenarios: under one scenario, the Index values in each Monte Carlo simulation
                                        were modified by the disclosed caps, buffers, and floors for
                                        the {TOOL_NAME_MAPPING[selectedTool]}, while under the other scenario, Index
                                        values in the same Monte Carlo
                                        simulations were left unmodified.</p>
                                </React.Fragment>
                                }
                                {isIS5ProOrIS7Pro(selectedTool) &&
                                <React.Fragment>
                                    <p className="indent-top-20"
                                       style={{"fontStyle": "italic", "textDecoration": "underline"}}>
                                        Application of {TOOL_NAME_MAPPING[selectedTool]}'s Caps, Buffers, Floors, and
                                        Contract Fees to the Monte Carlo Simulations
                                    </p>
                                    <p>After generating the Monte Carlo simulations, the Tool then created two
                                        scenarios: under one scenario, the Index values in each Monte Carlo simulation
                                        were modified by the contract fees and disclosed caps, buffers, and floors for
                                        the {TOOL_NAME_MAPPING[selectedTool]}, while under the other scenario, Index
                                        values in the same Monte Carlo simulations were left unmodified.</p>
                                </React.Fragment>
                                }
                                <p style={{"fontStyle": "italic", "textDecoration": "underline"}}>Application of User’s
                                    Strategy Allocation Information to Each Scenario</p>
                                <p>The Tool then applied the user’s purchase payment and strategy weighting inputs to
                                    the modified and unmodified Index values to create strategy values at the end of
                                    each year. Strategy values were then combined to create a hypothetical annuity value
                                    for the end of each year in each simulation.</p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Header as={'h3'}>3. Key Assumptions</Header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <p>The Tool relied on many assumptions that influenced the results of the Monte Carlo
                                    simulations. Key assumptions made by the Tool include the following:</p>
                                {isIS6OrIS6Pro(selectedTool) &&
                                <ul>
                                    <li>After the initial purchase payment, no additional purchase payments were made.
                                    </li>
                                    <li>Caps and participation rates applicable to each strategy went unchanged over
                                        every term.
                                    </li>
                                    <li>No allocations were changed after the initial allocation to the indexed strategies, other than the S&P 6-year 10% buffer with upside participation rate strategy, which was reallocated to the  S&P 500 1-year 50% downside participation rate with upside participation rate strategy.
                                    </li>
                                    <li>No withdrawals were taken at any point.</li>
                                    <li>Strategy values were calculated at the end of each year using the end of term
                                        method, even when measured in the middle of a 2-year term.
                                    </li>
                                    {isPro(selectedTool) &&
                                    <li>A contract fee of {getProRate(selectedTool)} was deducted annually from each
                                        strategy's value.</li>}
                                    {isIS6OrIS6Pro(selectedTool) && <li>No amounts were allocated to either First Trust Barclays Edge Index strategy</li> }
                                </ul>
                                }
                                {isIS5OrIS7(selectedTool) &&
                                <ul>
                                    <li>After the initial purchase payment, no additional purchase payments were made.
                                    </li>
                                    <li>Caps, buffers, and floors applicable to each strategy went unchanged over every
                                        term.
                                    </li>
                                    <li>No allocations were changed after the initial allocation to the indexed
                                        strategies.
                                    </li>
                                    <li>No withdrawals were taken at any point.</li>
                                    <li>Strategy values were calculated at the end of each year using the end of term
                                        method.
                                    </li>
                                </ul>
                                }
                                {isIS5ProOrIS7Pro(selectedTool) &&
                                <ul>
                                    <li>After the initial purchase payment, no additional purchase payments were made.
                                    </li>
                                    <li>Caps, buffers, and floors applicable to each strategy went unchanged over every
                                        term.
                                    </li>
                                    <li>No allocations were changed after the initial allocation to the indexed
                                        strategies.
                                    </li>
                                    <li>No withdrawals were taken at any point.</li>
                                    <li>Strategy values were calculated at the end of each year using the end of term
                                        method.
                                    </li>
                                    {isIS5OrIS5Pro(selectedTool) &&
                                    <li>A contract fee of {getProRate(selectedTool)} was deducted annually from each strategy's value.</li>
                                    }
                                    {isIS7OrIS7Pro(selectedTool) &&
                                    <li>A contract fee of {getProRate(selectedTool)} was deducted annually from each strategy's value.</li>
                                    }
                                </ul>
                                }
                                <p>Any changes to these key assumptions, statistical methods, historical data, market
                                    conditions, or user inputs would have a significant impact on results produced by
                                    the Tool.</p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Header as={'h3'}>4. Additional Definition</Header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <p>Standard Deviation: The measure of how much the set of simulated returns varies from its average return. A set of returns with a high standard deviation is more dispersed from its average, while a set of returns with a low standard deviation has returns that are closer to the average of the set.</p>
                                <p>Values at the end of Year 6: The 90th Percentile figure in each column was extracted from the simulation whose {isIS5ProOrIS7Pro(selectedTool) ? "6":"3"}-year value is exceeded by the relevant {isIS5ProOrIS7Pro(selectedTool) ? "6":"3"}-year value in only ten percent of all of the other simulations. As a result, returns appearing in the same row below may have been extracted from different simulations.</p>
                                <p>90th Percentile: Represents consistently better than expected investment returns. Only 10% of the simulations ended in a cumulative value higher than this value, and 90% of the simulations resulted in a cumulative value lower than this value.</p>
                                <p>50th Percentile: Half of the simulations ended in a cumulative value higher than this value, and half resulted in a cumulative value lower than this value.</p>
                                <p>10th Percentile: Represents consistently worse than expected investment returns. Only 10% of the simulations ended in a cumulative value lower than this value, and 90% of the simulations results in a cumulative value higher than this value.</p>
                                <p>Values at the end of Year 10: The 90th Percentile figure in each column was extracted from the simulation whose 10-year value is exceeded by the relevant 10-year value in only ten percent of all of the other simulations. As a result, returns appearing in the same row below may have been extracted from different simulations.</p>
                                <p>90th Percentile: Represents consistently better than expected investment returns. Only 10% of the simulations ended in a cumulative value higher than this value, and 90% of the simulations resulted in a cumulative value lower than this value.</p>
                                <p>50th Percentile: Half of the simulations ended in a cumulative value higher than this value, and half resulted in a cumulative value lower than this value.</p>
                                <p>10th Percentile: Represents consistently worse than expected investment returns. Only 10% of the simulations ended in a cumulative value lower than this value, and 90% of the simulations results in a cumulative value higher than this value.</p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Header as={'h3'}>5. Additional Disclosures</Header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <p>The information produced by the Tool is provided for illustrative purposes only and
                                    may not be used to project or predict investment results. Past performance does not
                                    guarantee future results. Historical Index returns are not indicative of the gains
                                    and losses that would have been credited to an {TOOL_NAME_MAPPING[selectedTool]}, which was first
                                    offered in May {isPro(selectedTool)?'2021':'2019'}. </p>
                                <p>When you buy a registered index-linked annuity, you own an insurance contract. You are not
                                    buying shares of any stock or index. For annuity contracts, income earned on the
                                    contract is subject to income tax as ordinary income when withdrawn. If you are
                                    under age 59½, the taxable amount may also be subject to a 10% federal penalty tax.
                                    Generally income tax rates on ordinary income are higher than capital gains tax
                                    rates on long-term capital gains and qualified dividend income. </p>
                                <p>MassMutual Ascend is not an investment advisor and the information provided in this
                                    document is not investment advice. You should consult your investment professional
                                    for advice based on your personal circumstances and financial situation.</p>
                                <p>The "S&P 500® Index" is a product of S&P Dow Jones Indices LLC or its affiliates
                                    ("SPDJI") and has been licensed for use by MassMutual Ascend Life Insurance Company.
                                    S&P®, S&P 500®, SPDR®, US 500, The 500, iBoxx®, iTraxx® and CDX® are trademarks of
                                    S&P Global, Inc. or its affiliates ("S&P"); Dow Jones® is a registered trademark of
                                    Dow Jones Trademark Holdings LLC ("Dow Jones"); MassMutual Ascend Life Insurance
                                    Company's products are not sponsored, endorsed, sold or promoted by SPDJI, Dow Jones,
                                    S&P, their respective affiliates, and none of such parties make any representation
                                    regarding the advisability of investing in such products nor do they have any liability
                                    for any errors, omissions, or interruptions of the S&P 500® Index.</p>
                                <p>The iShares MSCI EAFE ETF and the iShares U.S. Real Estate ETF are distributed by
                                    BlackRock Investments, LLC. iShares®, BLACKROCK®, and the corresponding logos are
                                    registered and unregistered trademarks of BlackRock, Inc. and its affiliates
                                    ("BlackRock"), and these trademarks have been licensed for certain purposes by
                                    MassMutual Ascend. MassMutual Ascend annuity products are not sponsored, endorsed,
                                    sold or promoted by BlackRock, and purchasers of an annuity from MassMutual Ascend
                                    do not acquire any interest in the iShares MSCI EAFE ETF or the iShares U.S. Real
                                    Estate ETF nor enter into any relationship of any kind with BlackRock. BlackRock makes
                                    no representation or warranty, express or implied, to the owners of any MassMutual Ascend
                                    annuity product or any member of the public regarding the advisability of purchasing
                                    an annuity, nor does it have any liability for any errors, omissions, interruptions
                                    or use of the iShares MSCI EAFE ETF or the iShares U.S. Real Estate ETF, or any data
                                    related thereto. </p>

                                {isIS6OrIS6Pro(selectedTool) && <>
                                <p>The First Trust Barclays Edge Index (“FTIS Index”) is a product of FT Indexing Solutions LLC (“FTIS”) and is administered and calculated by Bloomberg Index Service Limited and its affiliates (collectively, “BISL”).  FIRST TRUST® and First Trust Barclays Edge Index are trademarks of First Trust Portfolios L.P. (collectively, with FTIS and their respective affiliates, “First Trust”).  The foregoing index and trademarks have been licensed for use for certain purposes by Barclays, Bloomberg, and MassMutual Ascend Life Insurance Company (collectively, the “Licensees”) in connection with the FTIS Index and certain products utilizing the FTIS Index (collectively, the “Products”). </p>
                                    <p>The Capital Strength Index (“Nasdaq Index”) is a product of Nasdaq, Inc. (collectively, with its affiliates, “Nasdaq”).  NASDAQ®, CAPITAL STRENGTH INDEXTM, NQCAPSTTM, and NQCAPSTTTM are trademarks of Nasdaq.  The foregoing index and trademarks have been licensed for use for certain purposes by FTIS and Licensees in connection with the FTIS Index and the Products.   </p>
                                    <p>The Value Line Dividend Index (“Value Line Index”) is a product of Value Line, Inc. (“Value Line”).
                                        VALUE LINE® and VALUE LINE DIVIDEND INDEXTM are trademarks or registered trademarks of Value Line.  The foregoing index and trademarks have been licensed for use for certain purposes by FTIS and Licensees in connection with the FTIS Index and the Products.  The FTIS Index is not sponsored, endorsed, recommended, sold or promoted by Value Line and Value Line makes no representation regarding the advisability of investing in any product utilizing the FTIS Index.</p>
                                    <p>BLOOMBERG® is a trademark and service mark of Bloomberg Finance L.P.  Bloomberg Finance L.P., BISL, and their affiliates (“Bloomberg”) are not affiliated with First Trust or Barclays.  Bloomberg’s relationship to First Trust and Barclays is only (1) in the licensing of the FIRST TRUST®, BARCLAYS®, and FIRST TRUST BARCLAYS EDGE INDEXTM trademarks and (2) to act as the administrator and calculation agent of the FTIS Index, which is the property of FTIS. Bloomberg does not guarantee the timeliness, accurateness, or completeness of the FTIS Index or any data or information relating thereto and shall have no liability in connection with the FTIS Index or any data or information relating thereto.</p>
                                <p>The Products are not issued, sponsored, endorsed, sold, recommended, or promoted by First Trust, Bloomberg, Nasdaq, Value Line, or their respective affiliates (collectively, the “Companies”). The Companies do not make any representation regarding the advisability of investing in the Products or products based on the FTIS Index, Barclays Indices, Nasdaq Index, or Value Line Index, do not make any warranties or bear any liability with respect to such products, and do not make any warranties or bear any liability with respect to the Products, the FTIS Index, or another party’s index. </p>
                                <p>THE COMPANIES DO NOT GUARANTEE THE ADEQUACY, ACCURACY, TIMELINESS, COMPLETENESS, AND/OR UNINTERRUPTED CALCULATION OF THE PRODUCTS, FTIS INDEX, BARCLAYS INDICES, NASDAQ INDEX, VALUE LINE INDEX, OR ANY DATA INCLUDED THEREIN OR ANY COMMUNICATION WITH RESPECT THERETO, INCLUDING, ORAL, WRITTEN, OR ELECTRONIC COMMUNICATIONS.  THE COMPANIES SHALL HAVE NO LIABILITY FOR ANY ERRORS, OMISSIONS, OR INTERRUPTIONS IN THE PRODUCTS, FTIS INDEX, BARCLAYS INDICES, NASDAQ INDEX, OR VALUE LINE INDEX.  THE COMPANIES MAKE NO WARRANTY, EXPRESS OR IMPLIED, AS TO THE RESULTS TO BE OBTAINED BY LICENSEES, OWNERS OF THE PRODUCTS OR OF PRODUCTS BASED ON THE FTIS INDEX, BARCLAYS INDICES, NASDAQ INDEX, OR VALUE LINE INDEX, OR BY ANY OTHER PERSON OR ENTITY FROM THE USE OF THE FTIS INDEX, BARCLAYS INDICES, NASDAQ INDEX, OR VALUE LINE INDEX, OR ANY DATA INCLUDED THEREIN.</p>
                                    <p>Neither Barclays Bank PLC ('<span style={{fontWeight: "bold"}}>BB PLC</span>'') nor any of its affiliates (collectively '<span style={{fontWeight: "bold"}}>Barclays</span>') is the issuer or producer of MassMutualAscend’s products and Barclays has no responsibilities, obligations or duties to investors in MassMutualAscend’s products . The Barclays US 2Y Treasury Futures Index, Barclays US 5Y Treasury Futures Index, Barclays US 10Y Note Index, and Barclays Switch USD Signal Index (collectively, the “<span style={{fontWeight: "bold"}}>Indices</span>”), together with any Barclays indices that are components of the Indices, are trademarks owned by Barclays and, together with any component indices and index data, are licensed for use by FTIS in connection with the First Trust Barclays Edge Index.</p>
                                    <p>Barclays’ only relationship with the MassMutual Ascend in respect of the Indices is the licensing of the Indices to FTIS, which are administered, compiled and published by BB PLC in its role as the index sponsor (the '<span style={{fontWeight: "bold"}}>Index Sponsor</span>') without regard to MassMutualAscend’s products  or investors in MassMutualAscend’s products . Additionally, MassMutual Ascend as issuer or producer of MassMutualAscend’s products  may for itself execute transaction(s) with Barclays in or relating to the Indices in connection with  MassMutualAscend’s products. Investors acquire MassMutualAscend’s products from MassMutual Ascend and investors neither acquire any interest in the Indices nor enter into any relationship of any kind whatsoever with Barclays upon making an investment in MassMutualAscend’s products . MassMutualAscend’s products  are not sponsored, endorsed, sold or promoted by Barclays and Barclays makes no representation regarding the advisability of MassMutualAscend’s products  or use of the Indices or any data included therein. Barclays shall not be liable in any way to MassMutual Ascend, investors or to other third parties in respect of the use or accuracy of the Indices or any data included therein. </p>

                                    </>}

                                <p>At the end of a term, we may eliminate a particular strategy in our discretion. We
                                    are not obligated to offer any one particular indexed
                                    strategy. We reserve the right to stop offering any of the strategies temporarily.
                                    We can add or stop offering any indexed strategy at our discretion. All strategies
                                    may not be available in all states.</p>
                                <p>We have the right to replace an Index if it is discontinued or we are no longer able
                                    to use it, its calculation changes substantially, or we determine that hedging
                                    instruments are difficult to acquire or the cost of hedging becomes excessive. The
                                    performance of the new Index may not be as good as the performance of the old Index.
                                    As a result, funds allocated to an indexed strategy may earn a return that is lower
                                    than the return they would have earned if there had been no replacement.</p>
                                <p style={{"fontWeight": "bold"}}>Principal Underwriter/Distributor: MM Ascend Life Investor Services,
                                    LLC, member FINRA and an affiliate of MassMutual Ascend Life Insurance Company.</p>
                                <p>Products issued by MassMutual Ascend, (Cincinnati, Ohio), a wholly owned subsidiary of MassMutual.</p>
                                <p>{getContractNumbers(selectedTool)}</p>
                                <p>This content does not apply in the state of New York.</p>
                                <p>All guarantees subject to the claims-paying ability of the issuing insurance
                                    company.</p>
                                <h4>{actionTypes.TOOL_PAGE_SUFFIX[selectedTool]}-3</h4>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </Container>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        adminDatabase: state.getIn(["databaseReducer", "adminDatabase"]),
        database: state.get("databaseReducer"),
        uiReducer: state.get('uiReducer')
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateShowNavigationRowButtons: (value)=>dispatch({
            type: actionTypes.UI_SET_NAVIGATION_ROW_BUTTONS_VISIBILITY,
            value: value
        }),
        keepProductSelection: (value)=>dispatch({
            type: actionTypes.UI_SET_TOOL,
            value: value
        })

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(disclosures);
