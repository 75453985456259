import React from 'react';
import {Segment, Grid, Header, Label} from 'semantic-ui-react';
import { connect } from 'react-redux';
import NumberFormat from "react-number-format";
import * as actionTypes from '../../../../reducers/actions';
import { getInputClass } from "../../../../common/common";
import {ADMIN_SPINDEX_MAPPING} from "../../../../reducers/actions";

const spIndex = (props) => {
    
    let data = props.database.toJS();
    const adminSelectedTool = sessionStorage.getItem('adminSelectedTool');
    
    return (
        <Segment raised>

            <Header as="h1">S&P 500 Index</Header>

            <Grid columns='equal'>
                <Grid.Row>
                    <Grid.Column></Grid.Column>
                    <Grid.Column>
                        <Label basic>Purchase Payments $100,000 and Over</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>Purchase Payments under $100,000</Label>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Label basic>1-Year 50% Downside Participation Rate with Upside Participation Rate</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].oneYRTermPat.paymentOver100K.isValid)}>
                            <NumberFormat
                                value={data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].oneYRTermPat.paymentOver100K.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "oneYRTermPat", "paymentOver100K"], values.value)}
                                onBlur={() => props.validate(adminSelectedTool)}
                                onClick={() => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "oneYRTermPat", "paymentOver100K"], "")}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].oneYRTermPat.paymentUnder100K.isValid)}>
                            <NumberFormat
                                value={data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].oneYRTermPat.paymentUnder100K.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "oneYRTermPat", "paymentUnder100K"], values.value)}
                                onBlur={() => props.validate(adminSelectedTool)}
                                onClick={() => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "oneYRTermPat", "paymentUnder100K"],"")}
                            />
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Label basic>1-Year 50% Downside Participation Rate with Cap</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].oneYRTermCap.paymentOver100K.isValid)}>
                            <NumberFormat
                                value={data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].oneYRTermCap.paymentOver100K.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "oneYRTermCap", "paymentOver100K"], values.value)}
                                onBlur={() => props.validate(adminSelectedTool)}
                                onClick={() => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "oneYRTermCap", "paymentOver100K"], "")}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].oneYRTermCap.paymentUnder100K.isValid)}>
                            <NumberFormat
                                value={data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].oneYRTermCap.paymentUnder100K.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "oneYRTermCap", "paymentUnder100K"], values.value)}
                                onBlur={() => props.validate(adminSelectedTool)}
                                onClick={() => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "oneYRTermCap", "paymentUnder100K"], "")}
                            />
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Label basic>1-yr 10% Buffer with Cap</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].oneYRBufferCap.paymentOver100K.isValid)}>
                            <NumberFormat
                                value={data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].oneYRBufferCap.paymentOver100K.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "oneYRBufferCap", "paymentOver100K"], values.value)}
                                onBlur={() => props.validate(adminSelectedTool)}
                                onClick={() => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "oneYRBufferCap", "paymentOver100K"], "")}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].oneYRBufferCap.paymentUnder100K.isValid)}>
                            <NumberFormat
                                value={data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].oneYRBufferCap.paymentUnder100K.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "oneYRBufferCap", "paymentUnder100K"], values.value)}
                                onBlur={() => props.validate(adminSelectedTool)}
                                onClick={() => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "oneYRBufferCap", "paymentUnder100K"], "")}
                            />
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Label basic>1-yr -10% Floor with Cap</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].oneYRMinusTenFloorCap.paymentOver100K.isValid)}>
                            <NumberFormat
                                value={data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].oneYRMinusTenFloorCap.paymentOver100K.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "oneYRMinusTenFloorCap", "paymentOver100K"], values.value)}
                                onBlur={() => props.validate(adminSelectedTool)}
                                onClick={() => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "oneYRMinusTenFloorCap", "paymentOver100K"], "")}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].oneYRMinusTenFloorCap.paymentUnder100K.isValid)}>
                            <NumberFormat
                                value={data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].oneYRMinusTenFloorCap.paymentUnder100K.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "oneYRMinusTenFloorCap", "paymentUnder100K"], values.value)}
                                onBlur={() => props.validate(adminSelectedTool)}
                                onClick={() => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "oneYRMinusTenFloorCap", "paymentUnder100K"], "")}
                            />
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Label basic>1-yr 0% Floor with Cap</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].oneYRZeroFloorCap.paymentOver100K.isValid)}>
                            <NumberFormat
                                value={data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].oneYRZeroFloorCap.paymentOver100K.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "oneYRZeroFloorCap", "paymentOver100K"], values.value)}
                                onBlur={() => props.validate(adminSelectedTool)}
                                onClick={() => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "oneYRZeroFloorCap", "paymentOver100K"], "")}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].oneYRZeroFloorCap.paymentUnder100K.isValid)}>
                            <NumberFormat
                                value={data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].oneYRZeroFloorCap.paymentUnder100K.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "oneYRZeroFloorCap", "paymentUnder100K"], values.value)}
                                onBlur={() => props.validate(adminSelectedTool)}
                                onClick={() => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "oneYRZeroFloorCap", "paymentUnder100K"], "")}
                            />
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Label basic>2-Year 50% Downside Participation Rate with Upside Participation Rate</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].twoYRTermPat.paymentOver100K.isValid)}>
                            <NumberFormat
                                value={data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].twoYRTermPat.paymentOver100K.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "twoYRTermPat", "paymentOver100K"], values.value)}
                                onBlur={() => props.validate(adminSelectedTool)}
                                onClick={() => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "twoYRTermPat", "paymentOver100K"], "")}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].twoYRTermPat.paymentUnder100K.isValid)}>
                            <NumberFormat
                                value={data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].twoYRTermPat.paymentUnder100K.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "twoYRTermPat", "paymentUnder100K"], values.value)}
                                onBlur={() => props.validate(adminSelectedTool)}
                                onClick={() => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "twoYRTermPat", "paymentUnder100K"], "")}
                            />
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Label basic>2-Year 50% Downside Participation Rate with Cap</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].twoYRTermCap.paymentOver100K.isValid)}>
                            <NumberFormat
                                value={data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].twoYRTermCap.paymentOver100K.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "twoYRTermCap", "paymentOver100K"], values.value)}
                                onBlur={() => props.validate(adminSelectedTool)}
                                onClick={() => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "twoYRTermCap", "paymentOver100K"], "")}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].twoYRTermCap.paymentUnder100K.isValid)}>
                            <NumberFormat
                                value={data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].twoYRTermCap.paymentUnder100K.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "twoYRTermCap", "paymentUnder100K"], values.value)}
                                onBlur={() => props.validate(adminSelectedTool)}
                                onClick={() => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "twoYRTermCap", "paymentUnder100K"], "")}
                            />
                        </div>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Label basic>6-Year 10% Buffer with Upside Participation Rate</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].sixYrTermPatTenBuffer.paymentOver100K.isValid)}>
                            <NumberFormat
                                value={data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].sixYrTermPatTenBuffer.paymentOver100K.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "sixYrTermPatTenBuffer", "paymentOver100K"], values.value)}
                                onBlur={() => props.validate(adminSelectedTool)}
                                onClick={() => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "sixYrTermPatTenBuffer", "paymentOver100K"], "")}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].sixYrTermPatTenBuffer.paymentUnder100K.isValid)}>
                            <NumberFormat
                                value={data.adminDatabase[ADMIN_SPINDEX_MAPPING[adminSelectedTool]].sixYrTermPatTenBuffer.paymentUnder100K.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "sixYrTermPatTenBuffer", "paymentUnder100K"], values.value)}
                                onBlur={() => props.validate(adminSelectedTool)}
                                onClick={() => props.updateValue([ADMIN_SPINDEX_MAPPING[adminSelectedTool], "sixYrTermPatTenBuffer", "paymentUnder100K"], "")}
                            />
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

const mapStateToProps = (state) => {
    return {
        database: state.get('databaseReducer'),
        uiReducer: state.get('uiReducer'),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateValue: (key, value) => dispatch({
            type: actionTypes.DB_ADMIN_UPDATE,
            key: key,
            value: value
        }),
        validate: (adminSelectedTool) => dispatch({
            type: actionTypes.DB_ADMIN_VALIDATE,
            adminSelectedTool: adminSelectedTool
        })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(spIndex);