import React from 'react';
import {Segment, Grid, Header, Label} from 'semantic-ui-react';
import { connect } from 'react-redux';
import NumberFormat from "react-number-format";
import * as actionTypes from '../../../../reducers/actions';
import {getInputClass} from "../../../../common/common";

//this is a component only for Admin IS6Pro 
const is6ProFees = (props) => {

    let data = props.database.toJS();
    const adminSelectedTool = sessionStorage.getItem('adminSelectedTool');

    return (
        <Segment raised>

            <Header as="h1">Index Summit 6 Pro Fees</Header>

            <Grid columns='equal'>
                <Grid.Row>
                    <Grid.Column></Grid.Column>
                    <Grid.Column>
                        <Label basic>Purchase Payments $100,000 and Over</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>Purchase Payments under $100,000</Label>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Label basic>Fees</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data.adminDatabase.is6ProFees.paymentOver100K.isValid)}>
                            <NumberFormat
                                value={data.adminDatabase.is6ProFees.paymentOver100K.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateValue(["is6ProFees", "paymentOver100K"], values.value)}
                                onBlur={() => props.validate(adminSelectedTool)}
                                onClick={() => props.updateValue(["is6ProFees", "paymentOver100K"], "")}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data.adminDatabase.is6ProFees.paymentUnder100K.isValid)}>
                            <NumberFormat
                                value={data.adminDatabase.is6ProFees.paymentUnder100K.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateValue(["is6ProFees", "paymentUnder100K"], values.value)}
                                onBlur={() => props.validate(adminSelectedTool)}
                                onClick={() => props.updateValue(["is6ProFees", "paymentUnder100K"], "")}
                            />
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

const mapStateToProps = (state) => {
    return {
        database: state.get('databaseReducer')
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateValue: (key, value) => dispatch({
            type: actionTypes.DB_ADMIN_UPDATE,
            key: key,
            value: value
        }),
        validate: (adminSelectedTool) => dispatch({
            type: actionTypes.DB_ADMIN_VALIDATE,
            adminSelectedTool: adminSelectedTool
        })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(is6ProFees);