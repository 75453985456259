import React from 'react';
import { Grid, Header, Label } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';
import {connect} from 'react-redux';
import './index.css';
import moment from "moment";

const AnnuityPayment = (props) => {

    let labels = {
        1: "Bear",
        2: "Little Bear",
        3: "Normal",
        4: "Little Bull",
        5: "Bull"
    };

    let data = props.database.toJS();

    return (
        <Grid columns='equal' className="low-padding-rows">
            <Grid.Row>
                <Grid.Column width={16}>
                    <Label basic>The caps and participation rates listed below are effective for terms beginning on {moment(data.adminDatabase.lastUpdateDate).format("MM/DD/YYYY")}.</Label>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Label basic>Client Full Name: </Label>{data.clientsName.value}                
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16}>                    
                    <Label basic>Annuity Purchase Payment:</Label>
                    <NumberFormat
                        value={data.annuityPurchaseAmount.value}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                    />
                    <Label basic>Market Condition:</Label>
                    <span>{labels[data.conditions]}</span>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Header>Allocation Summary</Header>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};


const mapStateToProps = (state) => {
    return {
        database: state.get("databaseReducer")
    }
};

export default connect(mapStateToProps, null)(AnnuityPayment);