import {fromJS} from 'immutable';
import * as actionTypes from './actions';
import {
    ADMIN_FEES_MAPPING,
    ADMIN_SHAREMCSI_MAPPING,
    ADMIN_SHAREREALESTATE_MAPPING,
    ADMIN_SPDRGOLDSHARES_MAPPING,
    ADMIN_SPINDEX_MAPPING,
    SHAREMCSI_MAPPING,
    SHAREREALESTATE_MAPPING,
    SPDRGOLDSHARES_MAPPING,
    SPINDEX_MAPPING,
} from "../reducers/actions";
import {isIS5OrIS7, isIS6OrIS6Pro} from "../common/common";

export const MIN_ANNUITY_PURCHASE = 25000;
export const MAX_ANNUITY_PURCHASE = 1500000;

export const MIN_PERCENTAGE = 0;
export const MAX_PERCENTAGE = 100;
export const MAX_PERCENTAGE_ADMIN = 150;

export const DEFAULT_CONDITIONS = 3;

export const initialState = fromJS({
    userId: null,
    clientsName: {
        value: "",
        isValid: true
    },
    annuityPurchaseAmount: {
        value: 0.0,
        isValid: true
    },
    is6SpIndex: {
        oneYRTermCap: {
            value: 0.0,
            isValid: true
        },
        oneYRTermPat: {
            value: 0.0,
            isValid: true
        },
        oneYRBufferCap: {
            value: 0.0,
            isValid: true
        },
        oneYRMinusTenFloorCap: {
            value: 0.0,
            isValid: true
        },
        oneYRZeroFloorCap: {
            value: 0.0,
            isValid: true
        },
        twoYRTermCap: {
            value: 0.0,
            isValid: true
        },
        twoYRTermPat: {
            value: 0.0,
            isValid: true
        },
        sixYrTermPatTenBuffer: {
            value: 0.0,
            isValid: true
        }
    },
    is6ProSpIndex: {
        oneYRTermCap: {
            value: 0.0,
            isValid: true
        },
        oneYRTermPat: {
            value: 0.0,
            isValid: true
        },
        oneYRBufferCap: {
            value: 0.0,
            isValid: true
        },
        oneYRMinusTenFloorCap: {
            value: 0.0,
            isValid: true
        },
        oneYRZeroFloorCap: {
            value: 0.0,
            isValid: true
        },
        twoYRTermCap: {
            value: 0.0,
            isValid: true
        },
        twoYRTermPat: {
            value: 0.0,
            isValid: true
        },
        sixYrTermPatTenBuffer: {
            value: 0.0,
            isValid: true
        }
    },
    is5SpIndex: {
        tenPercentBufferIdxStrategy: {
            value: 0.0,
            isValid: true
        },
        minustenPercentBufferIdxStrategy: {
            value: 0.0,
            isValid: true
        },
        zeroPercentFloorIdxStrategy: {
            value: 0.0,
            isValid: true
        }
    },
    is5ProSpIndex: {
        tenPercentBufferIdxStrategy: {
            value: 0.0,
            isValid: true
        },
        minustenPercentBufferIdxStrategy: {
            value: 0.0,
            isValid: true
        },
        zeroPercentFloorIdxStrategy: {
            value: 0.0,
            isValid: true
        }
    },
    is7SpIndex: {
        tenPercentBufferIdxStrategy: {
            value: 0.0,
            isValid: true
        },
        minustenPercentBufferIdxStrategy: {
            value: 0.0,
            isValid: true
        },
        zeroPercentFloorIdxStrategy: {
            value: 0.0,
            isValid: true
        }
    },
    is7ProSpIndex: {
        tenPercentBufferIdxStrategy: {
            value: 0.0,
            isValid: true
        },
        minustenPercentBufferIdxStrategy: {
            value: 0.0,
            isValid: true
        },
        zeroPercentFloorIdxStrategy: {
            value: 0.0,
            isValid: true
        }
    },
    is6ISharesMSCI: {
        oneYRTermPat: {
            value: 0.0,
            isValid: true
        },
        oneYRTermCap: {
            value: 0.0,
            isValid: true
        }
        // twoYRTermPat: {
        //     value: 0.0,
        //     isValid: true
        // }
    },
    is6ProISharesMSCI: {
        oneYRTermPat: {
            value: 0.0,
            isValid: true
        },
        oneYRTermCap: {
            value: 0.0,
            isValid: true
        }
        // twoYRTermPat: {
        //     value: 0.0,
        //     isValid: true
        // }
    },
    is5ISharesMSCI: {
        minustenPercentBufferIdxStrategy: {
            value: 0.0,
            isValid: true
        },
        zeroPercentFloorIdxStrategy: {
            value: 0.0,
            isValid: true
        }
    },
    is5ProISharesMSCI: {
        minustenPercentBufferIdxStrategy: {
            value: 0.0,
            isValid: true
        },
        zeroPercentFloorIdxStrategy: {
            value: 0.0,
            isValid: true
        }
    },
    is7ISharesMSCI: {
        minustenPercentBufferIdxStrategy: {
            value: 0.0,
            isValid: true
        },
        zeroPercentFloorIdxStrategy: {
            value: 0.0,
            isValid: true
        }
    },
    is7ProISharesMSCI: {
        minustenPercentBufferIdxStrategy: {
            value: 0.0,
            isValid: true
        },
        zeroPercentFloorIdxStrategy: {
            value: 0.0,
            isValid: true
        }
    },
    is6ISharesRealState: {
        oneYRTermPat: {
            value: 0.0,
            isValid: true
        },
        oneYRTermCap: {
            value: 0.0,
            isValid: true
        }
        // twoYRTermPat: {
        //     value: 0.0,
        //     isValid: true
        // }
    },
    is6ProISharesRealState: {
        oneYRTermPat: {
            value: 0.0,
            isValid: true
        },
        oneYRTermCap: {
            value: 0.0,
            isValid: true
        }
        // twoYRTermPat: {
        //     value: 0.0,
        //     isValid: true
        // }
    },
    is5ISharesRealState: {
        minustenPercentBufferIdxStrategy: {
            value: 0.0,
            isValid: true
        },
        zeroPercentFloorIdxStrategy: {
            value: 0.0,
            isValid: true
        }
    },
    is5ProISharesRealState: {
        minustenPercentBufferIdxStrategy: {
            value: 0.0,
            isValid: true
        },
        zeroPercentFloorIdxStrategy: {
            value: 0.0,
            isValid: true
        }
    },
    is7ISharesRealState: {
        minustenPercentBufferIdxStrategy: {
            value: 0.0,
            isValid: true
        },
        zeroPercentFloorIdxStrategy: {
            value: 0.0,
            isValid: true
        }
    },
    is7ProISharesRealState: {
        minustenPercentBufferIdxStrategy: {
            value: 0.0,
            isValid: true
        },
        zeroPercentFloorIdxStrategy: {
            value: 0.0,
            isValid: true
        }
    },
    is6SPDRGoldShares: {
        oneYRMinusTenFloorCap: {
            value: 0.0,
            isValid: true
        }
    },
    is6ProSPDRGoldShares: {
        oneYRMinusTenFloorCap: {
            value: 0.0,
            isValid: true
        }
    },
    is5SPDRGoldShares: {
        minustenPercentBufferIdxStrategy: {
            value: 0.0,
            isValid: true
        },
        zeroPercentFloorIdxStrategy: {
            value: 0.0,
            isValid: true
        }
    },
    is7SPDRGoldShares: {
        minustenPercentBufferIdxStrategy: {
            value: 0.0,
            isValid: true
        },
        zeroPercentFloorIdxStrategy: {
            value: 0.0,
            isValid: true
        }
    },
    is6DeclaredRate: {
        value: 0.0,
        isValid: true
    },
    is6ProDeclaredRate: {
        value: 0.0,
        isValid: true
    },
    is5DeclaredRate: {
        value: 0.0,
        isValid: true
    },
    is5ProDeclaredRate: {
        value: 0.0,
        isValid: true
    },
    is7DeclaredRate: {
        value: 0.0,
        isValid: true
    },
    is7ProDeclaredRate: {
        value: 0.0,
        isValid: true
    },
    conditions: DEFAULT_CONDITIONS,
    results: {
        planA: {
            planId: null,
            beginingPortfolioValue: 0.0,
            medianEndingValue: 0.0,
            averageReturn: 0.0,
            standardDeviation: 0.0,
            threeYearCumWealth: {
                percentile90: 0.0,
                percentile50: 0.0,
                percentile10: 0.0
            },
            tenYearCumWealth: {
                percentile90: 0.0,
                percentile50: 0.0,
                percentile10: 0.0
            },
            wealthGraph: []
        },
        planB: {
            planId: null,
            beginingPortfolioValue: 0.0,
            medianEndingValue: 0.0,
            averageReturn: 0.0,
            standardDeviation: 0.0,
            threeYearCumWealth: {
                percentile90: 0.0,
                percentile50: 0.0,
                percentile10: 0.0
            },
            tenYearCumWealth: {
                percentile90: 0.0,
                percentile50: 0.0,
                percentile10: 0.0
            },
            wealthGraph: []
        }
    },
    adminDatabase: {
        login: {
            isValid: true,
            username: {
                value: "",
                isValid: true
            },
            password: {
                value: "",
                isValid: true
            }
        },
        lastUpdateDate: 0,
        isValid: true,
        hasBlank: false,
        is6SpIndex: {
            oneYRTermCap: {
                paymentUnder100K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver100K: {
                    value: 0.0,
                    isValid: true
                }
            },
            oneYRTermPat: {
                paymentUnder100K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver100K: {
                    value: 0.0,
                    isValid: true
                }
            },
            oneYRBufferCap: {
                paymentUnder100K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver100K: {
                    value: 0.0,
                    isValid: true
                }
            },
            oneYRMinusTenFloorCap: {
                paymentUnder100K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver100K: {
                    value: 0.0,
                    isValid: true
                }
            },
            oneYRZeroFloorCap: {
                paymentUnder100K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver100K: {
                    value: 0.0,
                    isValid: true
                }
            },
            twoYRTermCap: {
                paymentUnder100K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver100K: {
                    value: 0.0,
                    isValid: true
                }
            },
            twoYRTermPat: {
                paymentUnder100K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver100K: {
                    value: 0.0,
                    isValid: true
                }
            },
            sixYrTermPatTenBuffer: {
                paymentUnder100K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver100K: {
                    value: 0.0,
                    isValid: true
                }
            }
        },
        is6ISharesMSCI: {
            oneYRTermPat: {
                paymentUnder100K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver100K: {
                    value: 0.0,
                    isValid: true
                }
            },
            oneYRTermCap: {
                paymentUnder100K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver100K: {
                    value: 0.0,
                    isValid: true
                }
            }
            // twoYRTermPat: {
            //     paymentUnder100K: {
            //         value: 0.0,
            //         isValid: true
            //     },
            //     paymentOver100K: {
            //         value: 0.0,
            //         isValid: true
            //     }
            // }
        },
        is6ISharesRealState: {
            oneYRTermPat: {
                paymentUnder100K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver100K: {
                    value: 0.0,
                    isValid: true
                }
            },
            oneYRTermCap: {
                paymentUnder100K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver100K: {
                    value: 0.0,
                    isValid: true
                }
            }
            // twoYRTermPat: {
            //     paymentUnder100K: {
            //         value: 0.0,
            //         isValid: true
            //     },
            //     paymentOver100K: {
            //         value: 0.0,
            //         isValid: true
            //     }
            // }
        },
        is6SPDRGoldShares: {
            oneYRMinusTenFloorCap: {
                paymentUnder100K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver100K: {
                    value: 0.0,
                    isValid: true
                }
            }
        },
        is6DeclaredRate: {
            paymentUnder100K: {
                value: 0.0,
                isValid: true
            },
            paymentOver100K: {
                value: 0.0,
                isValid: true
            }
        },
        is6ProFees:{
            paymentUnder100K: {
                value: 1.0,
                isValid: true
            },
            paymentOver100K: {
                value: 2.0,
                isValid: true
            }
        },
        is6ProSpIndex: {
            oneYRTermCap: {
                paymentUnder100K: {
                    value: 3.0,
                    isValid: true
                },
                paymentOver100K: {
                    value: 4.0,
                    isValid: true
                }
            },
            oneYRTermPat: {
                paymentUnder100K: {
                    value: 7.0,
                    isValid: true
                },
                paymentOver100K: {
                    value: 8.0,
                    isValid: true
                }
            },
            oneYRBufferCap: {
                paymentUnder100K: {
                    value: 7.0,
                    isValid: true
                },
                paymentOver100K: {
                    value: 8.0,
                    isValid: true
                }
            },
            oneYRMinusTenFloorCap: {
                paymentUnder100K: {
                    value: 7.0,
                    isValid: true
                },
                paymentOver100K: {
                    value: 8.0,
                    isValid: true
                }
            },
            oneYRZeroFloorCap: {
                paymentUnder100K: {
                    value: 7.0,
                    isValid: true
                },
                paymentOver100K: {
                    value: 8.0,
                    isValid: true
                }
            },
            twoYRTermCap: {
                paymentUnder100K: {
                    value: 5.0,
                    isValid: true
                },
                paymentOver100K: {
                    value: 6.0,
                    isValid: true
                }
            },
            twoYRTermPat: {
                paymentUnder100K: {
                    value: 9.0,
                    isValid: true
                },
                paymentOver100K: {
                    value: 10.0,
                    isValid: true
                }
            },
            sixYrTermPatTenBuffer: {
                paymentUnder100K: {
                    value: 9.0,
                    isValid: true
                },
                paymentOver100K: {
                    value: 10.0,
                    isValid: true
                }
            }
        },
        is6ProISharesMSCI: {
            oneYRTermPat: {
                paymentUnder100K: {
                    value: 11.0,
                    isValid: true
                },
                paymentOver100K: {
                    value: 12.0,
                    isValid: true
                }
            },
            oneYRTermCap: {
                paymentUnder100K: {
                    value: 13.0,
                    isValid: true
                },
                paymentOver100K: {
                    value: 14.0,
                    isValid: true
                }
            }
            // twoYRTermPat: {
            //     paymentUnder100K: {
            //         value: 13.0,
            //         isValid: true
            //     },
            //     paymentOver100K: {
            //         value: 14.0,
            //         isValid: true
            //     }
            // }
        },
        is6ProISharesRealState: {
            oneYRTermPat: {
                paymentUnder100K: {
                    value: 15.0,
                    isValid: true
                },
                paymentOver100K: {
                    value: 16.0,
                    isValid: true
                }
            },
            oneYRTermCap: {
                paymentUnder100K: {
                    value: 17.0,
                    isValid: true
                },
                paymentOver100K: {
                    value: 18.0,
                    isValid: true
                }
            }
            // twoYRTermPat: {
            //     paymentUnder100K: {
            //         value: 17.0,
            //         isValid: true
            //     },
            //     paymentOver100K: {
            //         value: 18.0,
            //         isValid: true
            //     }
            // }
        },
        is6ProSPDRGoldShares: {
            oneYRMinusTenFloorCap: {
                paymentUnder100K: {
                    value: 15.0,
                    isValid: true
                },
                paymentOver100K: {
                    value: 16.0,
                    isValid: true
                }
            }
        },
        is6ProDeclaredRate: {
            paymentUnder100K: {
                value: 19.0,
                isValid: true
            },
            paymentOver100K: {
                value: 20.0,
                isValid: true
            }
        },
        is5SpIndex: {
            tenPercentBufferIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            },
            minustenPercentBufferIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            },
            zeroPercentFloorIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            }
        },
        is5ISharesMSCI: {
            minustenPercentBufferIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            },
            zeroPercentFloorIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            }
        },
        is5ISharesRealState: {
            minustenPercentBufferIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            },
            zeroPercentFloorIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            }
        },
        is5SPDRGoldShares: {
            minustenPercentBufferIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            },
            zeroPercentFloorIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            }
        },
        is5DeclaredRate: {
            paymentUnder250K: {
                value: 0.0,
                isValid: true
            },
            paymentOver250K: {
                value: 0.0,
                isValid: true
            }
        },
        is5ProFees:{
            paymentUnder250K: {
                value: 1.0,
                isValid: true
            },
            paymentOver250K: {
                value: 2.0,
                isValid: true
            }
        },
        is5ProSpIndex: {
            tenPercentBufferIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            },
            minustenPercentBufferIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            },
            zeroPercentFloorIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            }
        },
        is5ProISharesMSCI: {
            minustenPercentBufferIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            },
            zeroPercentFloorIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            }
        },
        is5ProISharesRealState: {
            minustenPercentBufferIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            },
            zeroPercentFloorIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            }
        },
        is5ProDeclaredRate: {
            paymentUnder100K: {
                value: 19.0,
                isValid: true
            },
            paymentOver100K: {
                value: 20.0,
                isValid: true
            }
        },
        is7SpIndex: {
            tenPercentBufferIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            },
            minustenPercentBufferIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            },
            zeroPercentFloorIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            }
        },
        is7ISharesMSCI: {
            minustenPercentBufferIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            },
            zeroPercentFloorIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            }
        },
        is7ISharesRealState: {
            minustenPercentBufferIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            },
            zeroPercentFloorIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            }
        },
        is7SPDRGoldShares: {
            minustenPercentBufferIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            },
            zeroPercentFloorIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            }
        },
        is7DeclaredRate: {
            paymentUnder250K: {
                value: 0.0,
                isValid: true
            },
            paymentOver250K: {
                value: 0.0,
                isValid: true
            }
        },
        is7ProFees:{
            paymentUnder250K: {
                value: 1.0,
                isValid: true
            },
            paymentOver250K: {
                value: 2.0,
                isValid: true
            }
        },
        is7ProSpIndex: {
            tenPercentBufferIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            },
            minustenPercentBufferIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            },
            zeroPercentFloorIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            }
        },
        is7ProISharesMSCI: {
            minustenPercentBufferIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            },
            zeroPercentFloorIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            }
        },
        is7ProISharesRealState: {
            minustenPercentBufferIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            },
            zeroPercentFloorIdxStrategy: {
                paymentUnder250K: {
                    value: 0.0,
                    isValid: true
                },
                paymentOver250K: {
                    value: 0.0,
                    isValid: true
                }
            }
        },
        is7ProDeclaredRate: {
            paymentUnder100K: {
                value: 19.0,
                isValid: true
            },
            paymentOver100K: {
                value: 20.0,
                isValid: true
            }
        },
    },
    assetClasses: []
});

const resetData = () => {
    return initialState;
};

const resetNaNValue = (value) => {
    if (isNaN(value)) return 0.0;
    return value;
};

const updateAnnuityPurchaseAmount = (state, action) => {
    return state
        .updateIn(["annuityPurchaseAmount", "value"], (value) => {
            if (isNaN(action.value)) return value;
            return parseFloat(action.value);
        })
        .updateIn(["annuityPurchaseAmount", "isValid"], () => true);
};

const validateAnnuityPurchaseAmount = (state, action) => {
    return state
        .updateIn(["annuityPurchaseAmount", "value"], resetNaNValue)
        .updateIn(["annuityPurchaseAmount", "isValid"], () => {
            let currentValue = state.getIn(["annuityPurchaseAmount", "value"]);
            return !(currentValue < MIN_ANNUITY_PURCHASE || currentValue > MAX_ANNUITY_PURCHASE || currentValue === "");
        });
};

const updateSP500Value = (state, action) => {
    return state
        .updateIn([SPINDEX_MAPPING[action.selectedTool], action.key, "value"], () => parseFloat(action.value))
        .updateIn([SPINDEX_MAPPING[action.selectedTool], action.key, "isValid"], () => true);
};

const isValueInRange = (value, min, max) => {
    return value >= min && value <= max;
};

const validateSP500Values = (state, action) => {
    if (isIS6OrIS6Pro(action.selectedTool)) {
        return state
            .updateIn([SPINDEX_MAPPING[action.selectedTool], "oneYRTermCap", "value"], resetNaNValue)
            .updateIn([SPINDEX_MAPPING[action.selectedTool], "oneYRTermPat", "value"], resetNaNValue)
            .updateIn([SPINDEX_MAPPING[action.selectedTool], "oneYRBufferCap", "value"], resetNaNValue)
            .updateIn([SPINDEX_MAPPING[action.selectedTool], "oneYRMinusTenFloorCap", "value"], resetNaNValue)
            .updateIn([SPINDEX_MAPPING[action.selectedTool], "oneYRZeroFloorCap", "value"], resetNaNValue)
            .updateIn([SPINDEX_MAPPING[action.selectedTool], "twoYRTermCap", "value"], resetNaNValue)
            .updateIn([SPINDEX_MAPPING[action.selectedTool], "twoYRTermPat", "value"], resetNaNValue)
            .updateIn([SPINDEX_MAPPING[action.selectedTool], "sixYrTermPatTenBuffer", "value"], resetNaNValue)
            .updateIn([SPINDEX_MAPPING[action.selectedTool], "oneYRTermCap", "isValid"], () => {
                let value = state.getIn([SPINDEX_MAPPING[action.selectedTool], "oneYRTermCap", "value"]);
                return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE);
            })
            .updateIn([SPINDEX_MAPPING[action.selectedTool], "oneYRTermPat", "isValid"], () => {
                let value = state.getIn([SPINDEX_MAPPING[action.selectedTool], "oneYRTermPat", "value"]);
                return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE);
            })
            .updateIn([SPINDEX_MAPPING[action.selectedTool], "oneYRBufferCap", "isValid"], () => {
                let value = state.getIn([SPINDEX_MAPPING[action.selectedTool], "oneYRBufferCap", "value"]);
                return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE);
            })
            .updateIn([SPINDEX_MAPPING[action.selectedTool], "oneYRMinusTenFloorCap", "isValid"], () => {
                let value = state.getIn([SPINDEX_MAPPING[action.selectedTool], "oneYRMinusTenFloorCap", "value"]);
                return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE);
            })
            .updateIn([SPINDEX_MAPPING[action.selectedTool], "oneYRZeroFloorCap", "isValid"], () => {
                let value = state.getIn([SPINDEX_MAPPING[action.selectedTool], "oneYRZeroFloorCap", "value"]);
                return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE);
            })
            .updateIn([SPINDEX_MAPPING[action.selectedTool], "twoYRTermCap", "isValid"], () => {
                let value = state.getIn([SPINDEX_MAPPING[action.selectedTool], "twoYRTermCap", "value"]);
                return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE);
            })
            .updateIn([SPINDEX_MAPPING[action.selectedTool], "twoYRTermPat", "isValid"], () => {
                let value = state.getIn([SPINDEX_MAPPING[action.selectedTool], "twoYRTermPat", "value"]);
                return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE);
            })
            .updateIn([SPINDEX_MAPPING[action.selectedTool], "sixYrTermPatTenBuffer", "isValid"], () => {
                let value = state.getIn([SPINDEX_MAPPING[action.selectedTool], "sixYrTermPatTenBuffer", "value"]);
                return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE);
            });
    } else {
        return state
            .updateIn([SPINDEX_MAPPING[action.selectedTool], "tenPercentBufferIdxStrategy", "value"], resetNaNValue)
            .updateIn([SPINDEX_MAPPING[action.selectedTool], "minustenPercentBufferIdxStrategy", "value"], resetNaNValue)
            .updateIn([SPINDEX_MAPPING[action.selectedTool], "zeroPercentFloorIdxStrategy", "value"], resetNaNValue)
            .updateIn([SPINDEX_MAPPING[action.selectedTool], "tenPercentBufferIdxStrategy", "isValid"], () => {
                let value = state.getIn([SPINDEX_MAPPING[action.selectedTool], "tenPercentBufferIdxStrategy", "value"]);
                return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE);
            })
            .updateIn([SPINDEX_MAPPING[action.selectedTool], "minustenPercentBufferIdxStrategy", "isValid"], () => {
                let value = state.getIn([SPINDEX_MAPPING[action.selectedTool], "minustenPercentBufferIdxStrategy", "value"]);
                return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE);
            })
            .updateIn([SPINDEX_MAPPING[action.selectedTool], "zeroPercentFloorIdxStrategy", "isValid"], () => {
                let value = state.getIn([SPINDEX_MAPPING[action.selectedTool], "zeroPercentFloorIdxStrategy", "value"]);
                return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE);
            });
    }
};

const updateMCSI = (state, action) => {
    return state
        .updateIn([SHAREMCSI_MAPPING[action.selectedTool], action.key, "value"], () => parseFloat(action.value))
        .updateIn([SHAREMCSI_MAPPING[action.selectedTool], action.key, "isValid"], () => true);
};

const validateMCSI = (state, action) => {
    if (isIS6OrIS6Pro(action.selectedTool)) {
        return state
            .updateIn([SHAREMCSI_MAPPING[action.selectedTool], "oneYRTermPat", "value"], resetNaNValue)
            .updateIn([SHAREMCSI_MAPPING[action.selectedTool], "oneYRTermCap", "value"], resetNaNValue)
            // .updateIn([SHAREMCSI_MAPPING[action.selectedTool], "twoYRTermPat", "value"], resetNaNValue)
            .updateIn([SHAREMCSI_MAPPING[action.selectedTool], "oneYRTermPat", "isValid"], () => {
                let value = state.getIn([SHAREMCSI_MAPPING[action.selectedTool], "oneYRTermPat", "value"]);
                return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE);
            })
            .updateIn([SHAREMCSI_MAPPING[action.selectedTool], "oneYRTermCap", "isValid"], () => {
                let value = state.getIn([SHAREMCSI_MAPPING[action.selectedTool], "oneYRTermCap", "value"]);
                return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE);
            });
        // .updateIn([SHAREMCSI_MAPPING[action.selectedTool], "twoYRTermPat", "isValid"], () => {
        //     let value = state.getIn([SHAREMCSI_MAPPING[action.selectedTool], "twoYRTermPat", "value"]);
        //     return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE);
        // });
    } else {
        return state
            .updateIn([SHAREMCSI_MAPPING[action.selectedTool], "minustenPercentBufferIdxStrategy", "value"], resetNaNValue)
            .updateIn([SHAREMCSI_MAPPING[action.selectedTool], "zeroPercentFloorIdxStrategy", "value"], resetNaNValue)
            .updateIn([SHAREMCSI_MAPPING[action.selectedTool], "minustenPercentBufferIdxStrategy", "isValid"], () => {
                let value = state.getIn([SHAREMCSI_MAPPING[action.selectedTool], "minustenPercentBufferIdxStrategy", "value"]);
                return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE);
            })
            .updateIn([SHAREMCSI_MAPPING[action.selectedTool], "zeroPercentFloorIdxStrategy", "isValid"], () => {
                let value = state.getIn([SHAREMCSI_MAPPING[action.selectedTool], "zeroPercentFloorIdxStrategy", "value"]);
                return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE);
            });
    }
};

const updateRealEstate = (state, action) => {
    return state
        .updateIn([SHAREREALESTATE_MAPPING[action.selectedTool], action.key, "value"], () => parseFloat(action.value))
        .updateIn([SHAREREALESTATE_MAPPING[action.selectedTool], action.key, "isValid"], () => true);
};

const validateRealEstate = (state, action) => {
    if (isIS6OrIS6Pro(action.selectedTool)) {
        return state
            .updateIn([SHAREREALESTATE_MAPPING[action.selectedTool], "oneYRTermPat", "value"], resetNaNValue)
            .updateIn([SHAREREALESTATE_MAPPING[action.selectedTool], "oneYRTermCap", "value"], resetNaNValue)
            // .updateIn([SHAREREALESTATE_MAPPING[action.selectedTool], "twoYRTermPat", "value"], resetNaNValue)
            .updateIn([SHAREREALESTATE_MAPPING[action.selectedTool], "oneYRTermPat", "isValid"], () => {
                let value = state.getIn([SHAREREALESTATE_MAPPING[action.selectedTool], "oneYRTermPat", "value"]);
                return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE);
            })
            .updateIn([SHAREREALESTATE_MAPPING[action.selectedTool], "oneYRTermCap", "isValid"], () => {
                let value = state.getIn([SHAREREALESTATE_MAPPING[action.selectedTool], "oneYRTermCap", "value"]);
                return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE);
            });
        // .updateIn([SHAREREALESTATE_MAPPING[action.selectedTool], "twoYRTermPat", "isValid"], () => {
        //     let value = state.getIn([SHAREREALESTATE_MAPPING[action.selectedTool], "twoYRTermPat", "value"]);
        //     return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE);
        // });
    } else {
        return state
            .updateIn([SHAREREALESTATE_MAPPING[action.selectedTool], "minustenPercentBufferIdxStrategy", "value"], resetNaNValue)
            .updateIn([SHAREREALESTATE_MAPPING[action.selectedTool], "zeroPercentFloorIdxStrategy", "value"], resetNaNValue)
            .updateIn([SHAREREALESTATE_MAPPING[action.selectedTool], "minustenPercentBufferIdxStrategy", "isValid"], () => {
                let value = state.getIn([SHAREREALESTATE_MAPPING[action.selectedTool], "minustenPercentBufferIdxStrategy", "value"]);
                return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE);
            })
            .updateIn([SHAREREALESTATE_MAPPING[action.selectedTool], "zeroPercentFloorIdxStrategy", "isValid"], () => {
                let value = state.getIn([SHAREREALESTATE_MAPPING[action.selectedTool], "zeroPercentFloorIdxStrategy", "value"]);
                return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE);
            });
    }
};

const updateSPDRGoldShares = (state, action) => {
    return state
        .updateIn([SPDRGOLDSHARES_MAPPING[action.selectedTool], action.key, "value"], () => parseFloat(action.value))
        .updateIn([SPDRGOLDSHARES_MAPPING[action.selectedTool], action.key, "isValid"], () => true);
};

const validateSPDRGoldShares = (state, action) => {
    if (isIS6OrIS6Pro(action.selectedTool)) {
        return state
            .updateIn([SPDRGOLDSHARES_MAPPING[action.selectedTool], "oneYRMinusTenFloorCap", "value"], resetNaNValue)
            .updateIn([SPDRGOLDSHARES_MAPPING[action.selectedTool], "oneYRMinusTenFloorCap", "isValid"], () => {
                let value = state.getIn([SPDRGOLDSHARES_MAPPING[action.selectedTool], "oneYRMinusTenFloorCap", "value"]);
                return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE);
            });
    } else if (isIS5OrIS7(action.selectedTool)) {
        return state
            .updateIn([SPDRGOLDSHARES_MAPPING[action.selectedTool], "minustenPercentBufferIdxStrategy", "value"], resetNaNValue)
            .updateIn([SPDRGOLDSHARES_MAPPING[action.selectedTool], "zeroPercentFloorIdxStrategy", "value"], resetNaNValue)
            .updateIn([SPDRGOLDSHARES_MAPPING[action.selectedTool], "minustenPercentBufferIdxStrategy", "isValid"], () => {
                let value = state.getIn([SPDRGOLDSHARES_MAPPING[action.selectedTool], "minustenPercentBufferIdxStrategy", "value"]);
                return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE);
            })
            .updateIn([SPDRGOLDSHARES_MAPPING[action.selectedTool], "zeroPercentFloorIdxStrategy", "isValid"], () => {
                let value = state.getIn([SPDRGOLDSHARES_MAPPING[action.selectedTool], "zeroPercentFloorIdxStrategy", "value"]);
                return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE);
            });
    }
};

const updateStrategy = (state, action) => {
    return state
        .updateIn(["declaredRate", "value"], () => parseFloat(action.value))
        .updateIn(["declaredRate", "isValid"], () => true);
};

const validateStrategy = (state, action) => {
    return state
        .updateIn(["declaredRate", "isValid"], () => {
            let value = state.getIn(["declaredRate", "value"]);
            return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE);
        });
};

const updateConditions = (state, action) => {
    return state
        .updateIn(["conditions"], () => action.value);
};

const formatNoFraction = (value) => {
    if (value)
        return value.toFixed(0);
    else
        return value;
};

const updateResults = (state, action) => {
    return state
        .setIn(["results", "planA", "beginingPortfolioValue"], formatNoFraction(action.value.basePlan.beginningPortfolioValue))
        .setIn(["results", "planA", "medianEndingValue"], formatNoFraction(action.value.basePlan.endingPortfolioValue))
        .setIn(["results", "planA", "standardDeviation"], action.value.basePlan.standardDeviation)
        .setIn(["results", "planA", "averageReturn"], action.value.basePlan.averageReturn)
        .setIn(["results", "planA", "threeYearCumWealth", "percentile90"], formatNoFraction(action.value.basePlan.percentile90thThirdYear))
        .setIn(["results", "planA", "threeYearCumWealth", "percentile50"], formatNoFraction(action.value.basePlan.percentile50thThirdYear))
        .setIn(["results", "planA", "threeYearCumWealth", "percentile10"], formatNoFraction(action.value.basePlan.percentile10thThirdYear))
        .setIn(["results", "planA", "tenYearCumWealth", "percentile90"], formatNoFraction(action.value.basePlan.percentile90th))
        .setIn(["results", "planA", "tenYearCumWealth", "percentile50"], formatNoFraction(action.value.basePlan.percentile50th))
        .setIn(["results", "planA", "tenYearCumWealth", "percentile10"], formatNoFraction(action.value.basePlan.percentile10th))
        .setIn(["results", "planA", "wealthGraph"], fromJS(action.value.basePlan.wealthOverTime))
        .setIn(["results", "planA", "allocationModel", "overallCap"], action.value.basePlan.allocationModel.overallCap)
        .setIn(["results", "planA", "allocationModel", "overallProtection"], action.value.basePlan.allocationModel.overallProtection)
        .setIn(["results", "planB", "beginingPortfolioValue"],formatNoFraction(action.value.comparePlan.beginningPortfolioValue))
        .setIn(["results", "planB", "medianEndingValue"], formatNoFraction(action.value.comparePlan.endingPortfolioValue))
        .setIn(["results", "planB", "standardDeviation"], action.value.comparePlan.standardDeviation)
        .setIn(["results", "planB", "averageReturn"], action.value.comparePlan.averageReturn)
        .setIn(["results", "planB", "threeYearCumWealth", "percentile90"], formatNoFraction(action.value.comparePlan.percentile90thThirdYear))
        .setIn(["results", "planB", "threeYearCumWealth", "percentile50"], formatNoFraction(action.value.comparePlan.percentile50thThirdYear))
        .setIn(["results", "planB", "threeYearCumWealth", "percentile10"], formatNoFraction(action.value.comparePlan.percentile10thThirdYear))
        .setIn(["results", "planB", "tenYearCumWealth", "percentile90"], formatNoFraction(action.value.comparePlan.percentile90th))
        .setIn(["results", "planB", "tenYearCumWealth", "percentile50"], formatNoFraction(action.value.comparePlan.percentile50th))
        .setIn(["results", "planB", "tenYearCumWealth", "percentile10"], formatNoFraction(action.value.comparePlan.percentile10th))
        .setIn(["results", "planB", "wealthGraph"], fromJS(action.value.comparePlan.wealthOverTime))
        .setIn(["results", "planB", "allocationModel", "overallCap"], action.value.comparePlan.allocationModel.overallCap)
        .setIn(["results", "planB", "allocationModel", "overallProtection"], action.value.comparePlan.allocationModel.overallProtection)
        .setIn(["results", "planA", "planId"], action.value.basePlan.planId)
        .setIn(["results", "planB", "planId"], action.value.comparePlan.planId);
};

const updateAdminData = (state, action) => {
    let key = ["adminDatabase"].concat(action.key);
    return state
        .setIn(key.concat(["value"]), action.value)
        .setIn(key.concat(["isValid"]), true);
};

const adminDataHasBlank = (state, action) => {
    let hasBlank;
    switch (action.adminSelectedTool) {
        case actionTypes.UI_SET_ADMIN_IS6:
            hasBlank = (
                state.getIn(["adminDatabase", "is6SpIndex", "oneYRTermCap", "paymentUnder100K", "value"])==""||
                state.getIn(["adminDatabase", "is6SpIndex", "oneYRTermCap", "paymentOver100K", "value"])==""||
                state.getIn(["adminDatabase", "is6SpIndex", "oneYRTermPat", "paymentUnder100K", "value"])==""||
                state.getIn(["adminDatabase", "is6SpIndex", "oneYRTermPat", "paymentOver100K", "value"])==""||
                state.getIn(["adminDatabase", "is6SpIndex", "oneYRBufferCap", "paymentUnder100K", "value"])==""||
                state.getIn(["adminDatabase", "is6SpIndex", "oneYRBufferCap", "paymentOver100K", "value"])==""||
                state.getIn(["adminDatabase", "is6SpIndex", "oneYRMinusTenFloorCap", "paymentUnder100K", "value"])==""||
                state.getIn(["adminDatabase", "is6SpIndex", "oneYRMinusTenFloorCap", "paymentOver100K", "value"])==""||
                state.getIn(["adminDatabase", "is6SpIndex", "oneYRZeroFloorCap", "paymentUnder100K", "value"])==""||
                state.getIn(["adminDatabase", "is6SpIndex", "oneYRZeroFloorCap", "paymentOver100K", "value"])==""||
                state.getIn(["adminDatabase", "is6SpIndex", "twoYRTermCap", "paymentUnder100K", "value"])==""||
                state.getIn(["adminDatabase", "is6SpIndex", "twoYRTermCap", "paymentOver100K", "value"])==""||
                state.getIn(["adminDatabase", "is6SpIndex", "twoYRTermPat", "paymentUnder100K", "value"])==""||
                state.getIn(["adminDatabase", "is6SpIndex", "twoYRTermPat", "paymentOver100K", "value"])==""||
                state.getIn(["adminDatabase", "is6SpIndex", "sixYrTermPatTenBuffer", "paymentUnder100K", "value"])==""||
                state.getIn(["adminDatabase", "is6SpIndex", "sixYrTermPatTenBuffer", "paymentOver100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ISharesMSCI", "oneYRTermPat", "paymentUnder100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ISharesMSCI", "oneYRTermPat", "paymentOver100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ISharesMSCI", "oneYRTermCap", "paymentUnder100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ISharesMSCI", "oneYRTermCap", "paymentOver100K", "value"])==""||
                // state.getIn(["adminDatabase", "is6ISharesMSCI", "twoYRTermPat", "paymentUnder100K", "value"])==""||
                // state.getIn(["adminDatabase", "is6ISharesMSCI", "twoYRTermPat", "paymentOver100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ISharesRealState", "oneYRTermPat", "paymentUnder100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ISharesRealState", "oneYRTermPat", "paymentOver100K", "value"])=="" ||
                state.getIn(["adminDatabase", "is6ISharesRealState", "oneYRTermCap", "paymentUnder100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ISharesRealState", "oneYRTermCap", "paymentOver100K", "value"])=="" ||
                // state.getIn(["adminDatabase", "is6ISharesRealState", "twoYRTermPat", "paymentUnder100K", "value"])==""||
                // state.getIn(["adminDatabase", "is6ISharesRealState", "twoYRTermPat", "paymentOver100K", "value"])==""
                state.getIn(["adminDatabase", "is6SPDRGoldShares", "oneYRTermCap", "paymentUnder100K", "value"])==""||
                state.getIn(["adminDatabase", "is6SPDRGoldShares", "oneYRTermCap", "paymentOver100K", "value"])==""
                // state.getIn(["adminDatabase", "is6DeclaredRate", "paymentUnder100K", "value"])==""||
                // state.getIn(["adminDatabase", "is6DeclaredRate", "paymentOver100K", "value"])=="" ||
            );
            break;
        case actionTypes.UI_SET_ADMIN_IS6Pro:
            hasBlank = (
                state.getIn(["adminDatabase", "is6ProFees", "paymentUnder100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ProFees", "paymentOver100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ProSpIndex", "oneYRTermCap", "paymentUnder100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ProSpIndex", "oneYRTermCap", "paymentOver100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ProSpIndex", "oneYRTermPat", "paymentUnder100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ProSpIndex", "oneYRTermPat", "paymentOver100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ProSpIndex", "oneYRBufferCap", "paymentUnder100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ProSpIndex", "oneYRBufferCap", "paymentOver100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ProSpIndex", "oneYRMinusTenFloorCap", "paymentUnder100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ProSpIndex", "oneYRMinusTenFloorCap", "paymentOver100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ProSpIndex", "oneYRZeroFloorCap", "paymentUnder100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ProSpIndex", "oneYRZeroFloorCap", "paymentOver100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ProSpIndex", "twoYRTermCap", "paymentUnder100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ProSpIndex", "twoYRTermCap", "paymentOver100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ProSpIndex", "twoYRTermPat", "paymentUnder100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ProSpIndex", "twoYRTermPat", "paymentOver100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ProSpIndex", "sixYrTermPatTenBuffer", "paymentUnder100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ProSpIndex", "sixYrTermPatTenBuffer", "paymentOver100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ProISharesMSCI", "oneYRTermPat", "paymentUnder100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ProISharesMSCI", "oneYRTermPat", "paymentOver100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ProISharesMSCI", "oneYRTermCap", "paymentUnder100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ProISharesMSCI", "oneYRTermCap", "paymentOver100K", "value"])==""||
                // state.getIn(["adminDatabase", "is6ProISharesMSCI", "twoYRTermPat", "paymentUnder100K", "value"])==""||
                // state.getIn(["adminDatabase", "is6ProISharesMSCI", "twoYRTermPat", "paymentOver100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ProISharesRealState", "oneYRTermPat", "paymentUnder100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ProISharesRealState", "oneYRTermPat", "paymentOver100K", "value"])=="" ||
                state.getIn(["adminDatabase", "is6ProISharesRealState", "oneYRTermCap", "paymentUnder100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ProISharesRealState", "oneYRTermCap", "paymentOver100K", "value"])=="" ||
                // state.getIn(["adminDatabase", "is6ProISharesRealState", "twoYRTermPat", "paymentUnder100K", "value"])==""||
                // state.getIn(["adminDatabase", "is6ProISharesRealState", "twoYRTermPat", "paymentOver100K", "value"])==""
                state.getIn(["adminDatabase", "is6ProSPDRGoldShares", "oneYRTermCap", "paymentUnder100K", "value"])==""||
                state.getIn(["adminDatabase", "is6ProSPDRGoldShares", "oneYRTermCap", "paymentOver100K", "value"])==""
                // state.getIn(["adminDatabase", "is6ProDeclaredRate", "paymentUnder100K", "value"])==""||
                // state.getIn(["adminDatabase", "is6ProDeclaredRate", "paymentOver100K", "value"])=="" ||
            );
            break;
        case actionTypes.UI_SET_ADMIN_IS5:
            hasBlank = (
                state.getIn(["adminDatabase", "is5SpIndex", "tenPercentBufferIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is5SpIndex", "tenPercentBufferIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is5SpIndex", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is5SpIndex", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is5SpIndex", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is5SpIndex", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is5ISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is5ISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is5ISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is5ISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is5ISharesRealState", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is5ISharesRealState", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is5ISharesRealState", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is5ISharesRealState", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is5SPDRGoldShares", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is5SPDRGoldShares", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is5SPDRGoldShares", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is5SPDRGoldShares", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"])==""
                // state.getIn(["adminDatabase", "is5DeclaredRate", "paymentUnder100K", "value"])==""||
                // state.getIn(["adminDatabase", "is5DeclaredRate", "paymentOver100K", "value"])=="" ||
            );
            break;
        case actionTypes.UI_SET_ADMIN_IS5Pro:
            hasBlank = (
                state.getIn(["adminDatabase", "is5ProFees", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is5ProFees", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is5ProSpIndex", "tenPercentBufferIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is5ProSpIndex", "tenPercentBufferIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is5ProSpIndex", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is5ProSpIndex", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is5ProSpIndex", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is5ProSpIndex", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is5ProISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is5ProISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is5ProISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is5ProISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is5ProISharesRealState", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is5ProISharesRealState", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is5ProISharesRealState", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is5ProISharesRealState", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"])==""
                // state.getIn(["adminDatabase", "is5ProDeclaredRate", "paymentUnder100K", "value"])==""||
                // state.getIn(["adminDatabase", "is5ProDeclaredRate", "paymentOver100K", "value"])=="" ||
            );
            break;
        case actionTypes.UI_SET_ADMIN_IS7:
            hasBlank = (
                state.getIn(["adminDatabase", "is7SpIndex", "tenPercentBufferIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is7SpIndex", "tenPercentBufferIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is7SpIndex", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is7SpIndex", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is7SpIndex", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is7SpIndex", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is7ISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is7ISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is7ISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is7ISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is7ISharesRealState", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is7ISharesRealState", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is7ISharesRealState", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is7ISharesRealState", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is7SPDRGoldShares", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is7SPDRGoldShares", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is7SPDRGoldShares", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is7SPDRGoldShares", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"])==""
                // state.getIn(["adminDatabase", "is7DeclaredRate", "paymentUnder100K", "value"])==""||
                // state.getIn(["adminDatabase", "is7DeclaredRate", "paymentOver100K", "value"])=="" ||
            );
            break;
        case actionTypes.UI_SET_ADMIN_IS7Pro:
            hasBlank = (
                state.getIn(["adminDatabase", "is7ProFees", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is7ProFees", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is7ProSpIndex", "tenPercentBufferIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is7ProSpIndex", "tenPercentBufferIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is7ProSpIndex", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is7ProSpIndex", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is7ProSpIndex", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is7ProSpIndex", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is7ProISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is7ProISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is7ProISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is7ProISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is7ProISharesRealState", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is7ProISharesRealState", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"])==""||
                state.getIn(["adminDatabase", "is7ProISharesRealState", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"])==""||
                state.getIn(["adminDatabase", "is7ProISharesRealState", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"])==""
                // state.getIn(["adminDatabase", "is7ProDeclaredRate", "paymentUnder100K", "value"])==""||
                // state.getIn(["adminDatabase", "is7ProDeclaredRate", "paymentOver100K", "value"])=="");
            );
            break;
    }
    return hasBlank;
};

const validateAdminData = (state, action) => {
    let hasBlank = adminDataHasBlank(state, action)

    let validatedState;
    switch (action.adminSelectedTool) {
        case actionTypes.UI_SET_ADMIN_IS6:
            validatedState = state
                // S&P
                .updateIn(["adminDatabase", "is6SpIndex", "oneYRTermCap", "paymentUnder100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6SpIndex", "oneYRTermCap", "paymentUnder100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is6SpIndex", "oneYRTermCap", "paymentOver100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6SpIndex", "oneYRTermCap", "paymentOver100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is6SpIndex", "oneYRTermPat", "paymentUnder100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6SpIndex", "oneYRTermPat", "paymentUnder100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is6SpIndex", "oneYRTermPat", "paymentOver100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6SpIndex", "oneYRTermPat", "paymentOver100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is6SpIndex", "oneYRBufferCap", "paymentUnder100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6SpIndex", "oneYRBufferCap", "paymentUnder100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is6SpIndex", "oneYRBufferCap", "paymentOver100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6SpIndex", "oneYRBufferCap", "paymentOver100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is6SpIndex", "oneYRMinusTenFloorCap", "paymentUnder100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6SpIndex", "oneYRMinusTenFloorCap", "paymentUnder100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is6SpIndex", "oneYRMinusTenFloorCap", "paymentOver100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6SpIndex", "oneYRMinusTenFloorCap", "paymentOver100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is6SpIndex", "oneYRZeroFloorCap", "paymentUnder100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6SpIndex", "oneYRZeroFloorCap", "paymentUnder100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is6SpIndex", "oneYRZeroFloorCap", "paymentOver100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6SpIndex", "oneYRZeroFloorCap", "paymentOver100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is6SpIndex", "twoYRTermCap", "paymentUnder100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6SpIndex", "twoYRTermCap", "paymentUnder100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is6SpIndex", "twoYRTermCap", "paymentOver100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6SpIndex", "twoYRTermCap", "paymentOver100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is6SpIndex", "twoYRTermPat", "paymentUnder100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6SpIndex", "twoYRTermPat", "paymentUnder100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is6SpIndex", "twoYRTermPat", "paymentOver100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6SpIndex", "twoYRTermPat", "paymentOver100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is6SpIndex", "sixYrTermPatTenBuffer", "paymentUnder100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6SpIndex", "sixYrTermPatTenBuffer", "paymentUnder100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is6SpIndex", "sixYrTermPatTenBuffer", "paymentOver100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6SpIndex", "sixYrTermPatTenBuffer", "paymentOver100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                //MCSI
                .updateIn(["adminDatabase", "is6ISharesMSCI", "oneYRTermPat", "paymentUnder100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ISharesMSCI", "oneYRTermPat", "paymentUnder100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ISharesMSCI", "oneYRTermPat", "paymentOver100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ISharesMSCI", "oneYRTermPat", "paymentOver100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ISharesMSCI", "oneYRTermCap", "paymentUnder100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ISharesMSCI", "oneYRTermCap", "paymentUnder100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ISharesMSCI", "oneYRTermCap", "paymentOver100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ISharesMSCI", "oneYRTermCap", "paymentOver100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                // .updateIn(["adminDatabase", "is6ISharesMSCI", "twoYRTermPat", "paymentUnder100K", "isValid"], () => {
                //     let value = state.getIn(["adminDatabase", "is6ISharesMSCI", "twoYRTermPat", "paymentUnder100K", "value"]);
                //     return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                // })
                // .updateIn(["adminDatabase", "is6ISharesMSCI", "twoYRTermPat", "paymentOver100K", "isValid"], () => {
                //     let value = state.getIn(["adminDatabase", "is6ISharesMSCI", "twoYRTermPat", "paymentOver100K", "value"]);
                //     return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                // })
                // Real Estate
                .updateIn(["adminDatabase", "is6ISharesRealState", "oneYRTermPat", "paymentUnder100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ISharesRealState", "oneYRTermPat", "paymentUnder100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ISharesRealState", "oneYRTermPat", "paymentOver100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ISharesRealState", "oneYRTermPat", "paymentOver100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ISharesRealState", "oneYRTermCap", "paymentUnder100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ISharesRealState", "oneYRTermCap", "paymentUnder100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ISharesRealState", "oneYRTermCap", "paymentOver100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ISharesRealState", "oneYRTermCap", "paymentOver100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                // .updateIn(["adminDatabase", "is6ISharesRealState", "twoYRTermPat", "paymentUnder100K", "isValid"], () => {
                //     let value = state.getIn(["adminDatabase", "is6ISharesRealState", "twoYRTermPat", "paymentUnder100K", "value"]);
                //     return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                // })
                // .updateIn(["adminDatabase", "is6ISharesRealState", "twoYRTermPat", "paymentOver100K", "isValid"], () => {
                //     let value = state.getIn(["adminDatabase", "is6ISharesRealState", "twoYRTermPat", "paymentOver100K", "value"]);
                //     return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                // })
                // SPDR Gold Shares
                .updateIn(["adminDatabase", "is6SPDRGoldShares", "oneYRMinusTenFloorCap", "paymentUnder100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6SPDRGoldShares", "oneYRMinusTenFloorCap", "paymentUnder100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6SPDRGoldShares", "oneYRMinusTenFloorCap", "paymentOver100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6SPDRGoldShares", "oneYRMinusTenFloorCap", "paymentOver100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                // Declared Rate
                // .updateIn(["adminDatabase", "is6DeclaredRate", "paymentUnder100K", "isValid"], () => {
                //     let value = state.getIn(["adminDatabase", "is6DeclaredRate", "paymentUnder100K", "value"]);
                //     return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                // })
                // .updateIn(["adminDatabase", "is6DeclaredRate", "paymentOver100K", "isValid"], () => {
                //     let value = state.getIn(["adminDatabase", "is6DeclaredRate", "paymentOver100K", "value"]);
                //     return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                // })
                .updateIn(["adminDatabase", "hasBlank"], () => {
                    let value = hasBlank;
                    return value;
                });
            break;
        case actionTypes.UI_SET_ADMIN_IS6Pro:
            validatedState = state
                //================IS6 PRO=================
                // Fees
                .updateIn(["adminDatabase", "is6ProFees", "paymentUnder100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProFees", "paymentUnder100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ProFees", "paymentOver100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProFees", "paymentOver100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                // S&P
                .updateIn(["adminDatabase", "is6ProSpIndex", "oneYRTermCap", "paymentUnder100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProSpIndex", "oneYRTermCap", "paymentUnder100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ProSpIndex", "oneYRTermCap", "paymentOver100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProSpIndex", "oneYRTermCap", "paymentOver100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ProSpIndex", "oneYRTermPat", "paymentUnder100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProSpIndex", "oneYRTermPat", "paymentUnder100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ProSpIndex", "oneYRTermPat", "paymentOver100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProSpIndex", "oneYRTermPat", "paymentOver100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ProSpIndex", "oneYRBufferCap", "paymentUnder100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProSpIndex", "oneYRBufferCap", "paymentUnder100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ProSpIndex", "oneYRBufferCap", "paymentOver100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProSpIndex", "oneYRBufferCap", "paymentOver100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ProSpIndex", "oneYRMinusTenFloorCap", "paymentUnder100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProSpIndex", "oneYRMinusTenFloorCap", "paymentUnder100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ProSpIndex", "oneYRMinusTenFloorCap", "paymentOver100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProSpIndex", "oneYRMinusTenFloorCap", "paymentOver100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ProSpIndex", "oneYRZeroFloorCap", "paymentUnder100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProSpIndex", "oneYRZeroFloorCap", "paymentUnder100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ProSpIndex", "oneYRZeroFloorCap", "paymentOver100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProSpIndex", "oneYRZeroFloorCap", "paymentOver100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ProSpIndex", "twoYRTermCap", "paymentUnder100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProSpIndex", "twoYRTermCap", "paymentUnder100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ProSpIndex", "twoYRTermCap", "paymentOver100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProSpIndex", "twoYRTermCap", "paymentOver100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ProSpIndex", "twoYRTermPat", "paymentUnder100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProSpIndex", "twoYRTermPat", "paymentUnder100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ProSpIndex", "twoYRTermPat", "paymentOver100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProSpIndex", "twoYRTermPat", "paymentOver100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ProSpIndex", "sixYrTermPatTenBuffer", "paymentUnder100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProSpIndex", "sixYrTermPatTenBuffer", "paymentUnder100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ProSpIndex", "sixYrTermPatTenBuffer", "paymentOver100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProSpIndex", "sixYrTermPatTenBuffer", "paymentOver100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                //MCSI
                .updateIn(["adminDatabase", "is6ProISharesMSCI", "oneYRTermPat", "paymentUnder100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProISharesMSCI", "oneYRTermPat", "paymentUnder100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ProISharesMSCI", "oneYRTermPat", "paymentOver100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProISharesMSCI", "oneYRTermPat", "paymentOver100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ProISharesMSCI", "oneYRTermCap", "paymentUnder100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProISharesMSCI", "oneYRTermCap", "paymentUnder100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ProISharesMSCI", "oneYRTermCap", "paymentOver100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProISharesMSCI", "oneYRTermCap", "paymentOver100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                // .updateIn(["adminDatabase", "is6ProISharesMSCI", "twoYRTermPat", "paymentUnder100K", "isValid"], () => {
                //     let value = state.getIn(["adminDatabase", "is6ProISharesMSCI", "twoYRTermPat", "paymentUnder100K", "value"]);
                //     return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                // })
                // .updateIn(["adminDatabase", "is6ProISharesMSCI", "twoYRTermPat", "paymentOver100K", "isValid"], () => {
                //     let value = state.getIn(["adminDatabase", "is6ProISharesMSCI", "twoYRTermPat", "paymentOver100K", "value"]);
                //     return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                // })
                // Real Estate
                .updateIn(["adminDatabase", "is6ProISharesRealState", "oneYRTermPat", "paymentUnder100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProISharesRealState", "oneYRTermPat", "paymentUnder100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ProISharesRealState", "oneYRTermPat", "paymentOver100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProISharesRealState", "oneYRTermPat", "paymentOver100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ProISharesRealState", "oneYRTermCap", "paymentUnder100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProISharesRealState", "oneYRTermCap", "paymentUnder100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ProISharesRealState", "oneYRTermCap", "paymentOver100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProISharesRealState", "oneYRTermCap", "paymentOver100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                // .updateIn(["adminDatabase", "is6ProISharesRealState", "twoYRTermPat", "paymentUnder100K", "isValid"], () => {
                //     let value = state.getIn(["adminDatabase", "is6ProISharesRealState", "twoYRTermPat", "paymentUnder100K", "value"]);
                //     return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                // })
                // .updateIn(["adminDatabase", "is6ProISharesRealState", "twoYRTermPat", "paymentOver100K", "isValid"], () => {
                //     let value = state.getIn(["adminDatabase", "is6ProISharesRealState", "twoYRTermPat", "paymentOver100K", "value"]);
                //     return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                // })
                // SPDR Gold Shares
                .updateIn(["adminDatabase", "is6ProSPDRGoldShares", "oneYRMinusTenFloorCap", "paymentUnder100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProSPDRGoldShares", "oneYRMinusTenFloorCap", "paymentUnder100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is6ProSPDRGoldShares", "oneYRMinusTenFloorCap", "paymentOver100K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is6ProSPDRGoldShares", "oneYRMinusTenFloorCap", "paymentOver100K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                // Declared Rate
                // .updateIn(["adminDatabase", "is6ProDeclaredRate", "paymentUnder100K", "isValid"], () => {
                //     let value = state.getIn(["adminDatabase", "is6ProDeclaredRate", "paymentUnder100K", "value"]);
                //     return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                // })
                // .updateIn(["adminDatabase", "is6ProDeclaredRate", "paymentOver100K", "isValid"], () => {
                //     let value = state.getIn(["adminDatabase", "is6ProDeclaredRate", "paymentOver100K", "value"]);
                //     return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                // })
                .updateIn(["adminDatabase", "hasBlank"], () => {
                    let value = hasBlank;
                    return value;
                });
            break;
        case actionTypes.UI_SET_ADMIN_IS5:
            validatedState = state
                // S&P
                .updateIn(["adminDatabase", "is5SpIndex", "tenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5SpIndex", "tenPercentBufferIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is5SpIndex", "tenPercentBufferIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5SpIndex", "tenPercentBufferIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is5SpIndex", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5SpIndex", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is5SpIndex", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5SpIndex", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is5SpIndex", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5SpIndex", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is5SpIndex", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5SpIndex", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                //MCSI
                .updateIn(["adminDatabase", "is5ISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5ISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is5ISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5ISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is5ISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5ISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is5ISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5ISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                // Real Estate
                .updateIn(["adminDatabase", "is5ISharesRealState", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5ISharesRealState", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is5ISharesRealState", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5ISharesRealState", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is5ISharesRealState", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5ISharesRealState", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is5ISharesRealState", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5ISharesRealState", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                // SPDR Gold Shares
                .updateIn(["adminDatabase", "is5SPDRGoldShares", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5SPDRGoldShares", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is5SPDRGoldShares", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5SPDRGoldShares", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is5SPDRGoldShares", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5SPDRGoldShares", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is5SPDRGoldShares", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5SPDRGoldShares", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                // Declared Rate
                // .updateIn(["adminDatabase", "is5DeclaredRate", "paymentUnder100K", "isValid"], () => {
                //     let value = state.getIn(["adminDatabase", "is5DeclaredRate", "paymentUnder100K", "value"]);
                //     return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                // })
                // .updateIn(["adminDatabase", "is5DeclaredRate", "paymentOver100K", "isValid"], () => {
                //     let value = state.getIn(["adminDatabase", "is5DeclaredRate", "paymentOver100K", "value"]);
                //     return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                // })
                .updateIn(["adminDatabase", "hasBlank"], () => {
                    let value = hasBlank;
                    return value;
                });
            break;
        case actionTypes.UI_SET_ADMIN_IS5Pro:
            validatedState = state
                //================IS5 PRO=================
                // Fees
                .updateIn(["adminDatabase", "is5ProFees", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5ProFees", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE)&& value!= "";
                })
                .updateIn(["adminDatabase", "is5ProFees", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5ProFees", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                // S&P
                .updateIn(["adminDatabase", "is5ProSpIndex", "tenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5ProSpIndex", "tenPercentBufferIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is5ProSpIndex", "tenPercentBufferIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5ProSpIndex", "tenPercentBufferIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is5ProSpIndex", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5ProSpIndex", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is5ProSpIndex", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5ProSpIndex", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is5ProSpIndex", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5ProSpIndex", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is5ProSpIndex", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5ProSpIndex", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                //MCSI
                .updateIn(["adminDatabase", "is5ProISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5ProISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is5ProISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5ProISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is5ProISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5ProISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is5ProISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5ProISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                // Real Estate
                .updateIn(["adminDatabase", "is5ProISharesRealState", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5ProISharesRealState", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is5ProISharesRealState", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5ProISharesRealState", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is5ProISharesRealState", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5ProISharesRealState", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is5ProISharesRealState", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is5ProISharesRealState", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                // Declared Rate
                // .updateIn(["adminDatabase", "is5ProDeclaredRate", "paymentUnder100K", "isValid"], () => {
                //     let value = state.getIn(["adminDatabase", "is5ProDeclaredRate", "paymentUnder100K", "value"]);
                //     return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                // })
                // .updateIn(["adminDatabase", "is5ProDeclaredRate", "paymentOver100K", "isValid"], () => {
                //     let value = state.getIn(["adminDatabase", "is5ProDeclaredRate", "paymentOver100K", "value"]);
                //     return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                // })
                .updateIn(["adminDatabase", "hasBlank"], () => {
                    let value = hasBlank;
                    return value;
                });
            break;
        case actionTypes.UI_SET_ADMIN_IS7:
            validatedState = state
                // S&P
                .updateIn(["adminDatabase", "is7SpIndex", "tenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7SpIndex", "tenPercentBufferIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is7SpIndex", "tenPercentBufferIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7SpIndex", "tenPercentBufferIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is7SpIndex", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7SpIndex", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is7SpIndex", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7SpIndex", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is7SpIndex", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7SpIndex", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is7SpIndex", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7SpIndex", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                //MCSI
                .updateIn(["adminDatabase", "is7ISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7ISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is7ISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7ISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is7ISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7ISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is7ISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7ISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                // Real Estate
                .updateIn(["adminDatabase", "is7ISharesRealState", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7ISharesRealState", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is7ISharesRealState", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7ISharesRealState", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is7ISharesRealState", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7ISharesRealState", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is7ISharesRealState", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7ISharesRealState", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                // SPDR Gold Shares
                .updateIn(["adminDatabase", "is7SPDRGoldShares", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7SPDRGoldShares", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is7SPDRGoldShares", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7SPDRGoldShares", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is7SPDRGoldShares", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7SPDRGoldShares", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is7SPDRGoldShares", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7SPDRGoldShares", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                // Declared Rate
                // .updateIn(["adminDatabase", "is7DeclaredRate", "paymentUnder100K", "isValid"], () => {
                //     let value = state.getIn(["adminDatabase", "is7DeclaredRate", "paymentUnder100K", "value"]);
                //     return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                // })
                // .updateIn(["adminDatabase", "is7DeclaredRate", "paymentOver100K", "isValid"], () => {
                //     let value = state.getIn(["adminDatabase", "is7DeclaredRate", "paymentOver100K", "value"]);
                //     return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                // })
                .updateIn(["adminDatabase", "hasBlank"], () => {
                    let value = hasBlank;
                    return value;
                });
            break;
        case actionTypes.UI_SET_ADMIN_IS7Pro:
            validatedState = state
                //================IS7 PRO=================
                // Fees
                .updateIn(["adminDatabase", "is7ProFees", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7ProFees", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE)&& value!= "";
                })
                .updateIn(["adminDatabase", "is7ProFees", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7ProFees", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                // S&P
                .updateIn(["adminDatabase", "is7ProSpIndex", "tenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7ProSpIndex", "tenPercentBufferIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is7ProSpIndex", "tenPercentBufferIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7ProSpIndex", "tenPercentBufferIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is7ProSpIndex", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7ProSpIndex", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is7ProSpIndex", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7ProSpIndex", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is7ProSpIndex", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7ProSpIndex", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                .updateIn(["adminDatabase", "is7ProSpIndex", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7ProSpIndex", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                })
                //MCSI
                .updateIn(["adminDatabase", "is7ProISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7ProISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is7ProISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7ProISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is7ProISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7ProISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is7ProISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7ProISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                // Real Estate
                .updateIn(["adminDatabase", "is7ProISharesRealState", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7ProISharesRealState", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is7ProISharesRealState", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7ProISharesRealState", "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is7ProISharesRealState", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7ProISharesRealState", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                .updateIn(["adminDatabase", "is7ProISharesRealState", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"], () => {
                    let value = state.getIn(["adminDatabase", "is7ProISharesRealState", "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"]);
                    return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN)&& value!= "";
                })
                // Declared Rate
                // .updateIn(["adminDatabase", "is7ProDeclaredRate", "paymentUnder100K", "isValid"], () => {
                //     let value = state.getIn(["adminDatabase", "is7ProDeclaredRate", "paymentUnder100K", "value"]);
                //     return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                // })
                // .updateIn(["adminDatabase", "is7ProDeclaredRate", "paymentOver100K", "isValid"], () => {
                //     let value = state.getIn(["adminDatabase", "is7ProDeclaredRate", "paymentOver100K", "value"]);
                //     return isValueInRange(value, MIN_PERCENTAGE, MAX_PERCENTAGE_ADMIN) && value!= "";
                // })
                .updateIn(["adminDatabase", "hasBlank"], () => {
                    let value = hasBlank;
                    return value;
                });
            break;
    };

    let returnObj;
    switch (action.adminSelectedTool) {
        case actionTypes.UI_SET_ADMIN_IS6:
            returnObj = validatedState
                .updateIn(["adminDatabase", "isValid"], () => {
                    const isValid = validatedState.getIn(["adminDatabase", "is6SpIndex", "oneYRTermCap", "paymentUnder100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6SpIndex", "oneYRTermCap", "paymentOver100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6SpIndex", "oneYRTermPat", "paymentUnder100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6SpIndex", "oneYRTermPat", "paymentOver100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6SpIndex", "oneYRBufferCap", "paymentUnder100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6SpIndex", "oneYRBufferCap", "paymentOver100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6SpIndex", "oneYRMinusTenFloorCap", "paymentUnder100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6SpIndex", "oneYRMinusTenFloorCap", "paymentOver100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6SpIndex", "oneYRZeroFloorCap", "paymentUnder100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6SpIndex", "oneYRZeroFloorCap", "paymentOver100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6SpIndex", "twoYRTermCap", "paymentUnder100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6SpIndex", "twoYRTermCap", "paymentOver100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6SpIndex", "twoYRTermPat", "paymentUnder100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6SpIndex", "twoYRTermPat", "paymentOver100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6SpIndex", "sixYrTermPatTenBuffer", "paymentUnder100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6SpIndex", "sixYrTermPatTenBuffer", "paymentOver100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ISharesMSCI", "oneYRTermPat", "paymentUnder100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ISharesMSCI", "oneYRTermPat", "paymentOver100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ISharesMSCI", "oneYRTermCap", "paymentUnder100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ISharesMSCI", "oneYRTermCap", "paymentOver100K", "isValid"]) &&
                        // validatedState.getIn(["adminDatabase", "is6ISharesMSCI", "twoYRTermPat", "paymentUnder100K", "isValid"]) &&
                        // validatedState.getIn(["adminDatabase", "is6ISharesMSCI", "twoYRTermPat", "paymentOver100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ISharesRealState", "oneYRTermPat", "paymentUnder100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ISharesRealState", "oneYRTermPat", "paymentOver100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ISharesRealState", "oneYRTermCap", "paymentUnder100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ISharesRealState", "oneYRTermCap", "paymentOver100K", "isValid"]) &&
                        // validatedState.getIn(["adminDatabase", "is6ISharesRealState", "twoYRTermPat", "paymentUnder100K", "isValid"]) &&
                        // validatedState.getIn(["adminDatabase", "is6ISharesRealState", "twoYRTermPat", "paymentOver100K", "isValid"]);
                        validatedState.getIn(["adminDatabase", "is6SPDRGoldShares", "oneYRMinusTenFloorCap", "paymentUnder100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6SPDRGoldShares", "oneYRMinusTenFloorCap", "paymentOver100K", "isValid"])
                    // validatedState.getIn(["adminDatabase", "is6DeclaredRate", "paymentUnder100K", "isValid"])&&;
                    return isValid;
                });
            break;
        case actionTypes.UI_SET_ADMIN_IS6Pro:
            returnObj = validatedState
                .updateIn(["adminDatabase", "isValid"], () => {
                    const isValid =
                        //=============IS6Pro==============
                        validatedState.getIn(["adminDatabase", "is6ProFees", "paymentUnder100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ProFees", "paymentOver100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ProSpIndex", "oneYRTermCap", "paymentUnder100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ProSpIndex", "oneYRTermCap", "paymentOver100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ProSpIndex", "oneYRTermPat", "paymentUnder100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ProSpIndex", "oneYRTermPat", "paymentOver100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ProSpIndex", "oneYRBufferCap", "paymentUnder100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ProSpIndex", "oneYRBufferCap", "paymentOver100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ProSpIndex", "oneYRMinusTenFloorCap", "paymentUnder100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ProSpIndex", "oneYRMinusTenFloorCap", "paymentOver100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ProSpIndex", "oneYRZeroFloorCap", "paymentUnder100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ProSpIndex", "oneYRZeroFloorCap", "paymentOver100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ProSpIndex", "twoYRTermCap", "paymentUnder100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ProSpIndex", "twoYRTermCap", "paymentOver100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ProSpIndex", "twoYRTermPat", "paymentUnder100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ProSpIndex", "twoYRTermPat", "paymentOver100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ProSpIndex", "sixYrTermPatTenBuffer", "paymentUnder100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ProSpIndex", "sixYrTermPatTenBuffer", "paymentOver100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ProISharesMSCI", "oneYRTermPat", "paymentUnder100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ProISharesMSCI", "oneYRTermPat", "paymentOver100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ProISharesMSCI", "oneYRTermCap", "paymentUnder100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ProISharesMSCI", "oneYRTermCap", "paymentOver100K", "isValid"]) &&
                        // validatedState.getIn(["adminDatabase", "is6ProISharesMSCI", "twoYRTermPat", "paymentUnder100K", "isValid"]) &&
                        // validatedState.getIn(["adminDatabase", "is6ProISharesMSCI", "twoYRTermPat", "paymentOver100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ProISharesRealState", "oneYRTermPat", "paymentUnder100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ProISharesRealState", "oneYRTermPat", "paymentOver100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ProISharesRealState", "oneYRTermCap", "paymentUnder100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ProISharesRealState", "oneYRTermCap", "paymentOver100K", "isValid"]) &&
                        // validatedState.getIn(["adminDatabase", "is6ProISharesRealState", "twoYRTermPat", "paymentUnder100K", "isValid"]) &&
                        // validatedState.getIn(["adminDatabase", "is6ProISharesRealState", "twoYRTermPat", "paymentOver100K", "isValid"]);
                        validatedState.getIn(["adminDatabase", "is6ProSPDRGoldShares", "oneYRMinusTenFloorCap", "paymentUnder100K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is6ProSPDRGoldShares", "oneYRMinusTenFloorCap", "paymentOver100K", "isValid"])
                    // validatedState.getIn(["adminDatabase", "is6ProDeclaredRate", "paymentUnder100K", "isValid"]) &&
                    return isValid;
                });
            break;
        case actionTypes.UI_SET_ADMIN_IS5:
            returnObj = validatedState
                .updateIn(["adminDatabase", "isValid"], () => {
                    const isValid =
                        validatedState.getIn(["adminDatabase", "is5SpIndex", "tenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5SpIndex", "tenPercentBufferIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5SpIndex", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5SpIndex", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5SpIndex", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5SpIndex", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5ISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5ISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5ISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5ISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5ISharesRealState", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5ISharesRealState", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5ISharesRealState", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5ISharesRealState", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5SPDRGoldShares", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5SPDRGoldShares", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5SPDRGoldShares", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5SPDRGoldShares", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"]);
                    // validatedState.getIn(["adminDatabase", "is5DeclaredRate", "paymentUnder100K", "isValid"])&&
                    return isValid;
                });
            break;
        case actionTypes.UI_SET_ADMIN_IS5Pro:
            returnObj = validatedState
                .updateIn(["adminDatabase", "isValid"], () => {
                    const isValid =
                        //=============IS5Pro==============
                        validatedState.getIn(["adminDatabase", "is5ProFees", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5ProFees", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5ProSpIndex", "tenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5ProSpIndex", "tenPercentBufferIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5ProSpIndex", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5ProSpIndex", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5ProSpIndex", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5ProSpIndex", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5ProISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5ProISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5ProISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5ProISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5ProISharesRealState", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5ProISharesRealState", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5ProISharesRealState", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is5ProISharesRealState", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"]);
                    // validatedState.getIn(["adminDatabase", "is5ProDeclaredRate", "paymentUnder100K", "isValid"]) &&
                    return isValid;
                });
            break;
        case actionTypes.UI_SET_ADMIN_IS7:
            returnObj = validatedState
                .updateIn(["adminDatabase", "isValid"], () => {
                    const isValid =
                        validatedState.getIn(["adminDatabase", "is7SpIndex", "tenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7SpIndex", "tenPercentBufferIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7SpIndex", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7SpIndex", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7SpIndex", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7SpIndex", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7ISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7ISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7ISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7ISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7ISharesRealState", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7ISharesRealState", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7ISharesRealState", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7ISharesRealState", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7SPDRGoldShares", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7SPDRGoldShares", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7SPDRGoldShares", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7SPDRGoldShares", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"]);
                    // validatedState.getIn(["adminDatabase", "is7DeclaredRate", "paymentUnder100K", "isValid"])&&
                    return isValid;
                });
            break;
        case actionTypes.UI_SET_ADMIN_IS7Pro:
            returnObj = validatedState
                .updateIn(["adminDatabase", "isValid"], () => {
                    const isValid =
                        //=============IS7Pro==============
                        validatedState.getIn(["adminDatabase", "is7ProFees", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7ProFees", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7ProSpIndex", "tenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7ProSpIndex", "tenPercentBufferIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7ProSpIndex", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7ProSpIndex", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7ProSpIndex", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7ProSpIndex", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7ProISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7ProISharesMSCI", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7ProISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7ProISharesMSCI", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7ProISharesRealState", "minustenPercentBufferIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7ProISharesRealState", "minustenPercentBufferIdxStrategy", "paymentOver250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7ProISharesRealState", "zeroPercentFloorIdxStrategy", "paymentUnder250K", "isValid"]) &&
                        validatedState.getIn(["adminDatabase", "is7ProISharesRealState", "zeroPercentFloorIdxStrategy", "paymentOver250K", "isValid"]);
                    // validatedState.getIn(["adminDatabase", "is7ProDeclaredRate", "paymentUnder100K", "isValid"]);
                    return isValid;
                });
            break;
    }

    return returnObj;
};

let updateAssetClasses = (state, action) => {
    return state
        .setIn(["assetClasses"], fromJS(action.value));
};

let updateUserId = (state, action) => {
    return state
        .setIn(["userId"], action.value);
};

let updateVisitId = (state, action) => {
    return state
        .setIn(["visitId"], action.value);
};

let setAdminDatabase = (state, action) => {
    switch (action.value.type) {
        case actionTypes.UI_SET_ADMIN_IS6:
            return state
                .setIn(["adminDatabase", ADMIN_SPDRGOLDSHARES_MAPPING[action.value.type], "oneYRMinusTenFloorCap", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[3].indexStrategies[0].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPDRGOLDSHARES_MAPPING[action.value.type], "oneYRMinusTenFloorCap", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[3].indexStrategies[0].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "oneYRTermCap", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[0].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "oneYRTermCap", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[0].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "oneYRTermPat", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[2].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "oneYRTermPat", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[2].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "oneYRBufferCap", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[5].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "oneYRBufferCap", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[5].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "oneYRMinusTenFloorCap", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[6].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "oneYRMinusTenFloorCap", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[6].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "oneYRZeroFloorCap", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[7].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "oneYRZeroFloorCap", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[7].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "twoYRTermCap", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[1].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "twoYRTermCap", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[1].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "twoYRTermPat", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[3].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "twoYRTermPat", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[3].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "sixYrTermPatTenBuffer", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[4].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "sixYrTermPatTenBuffer", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[4].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "marketConditionReturns"], action.value.data.assetClassIndexStrategies[0].marketConditionReturns)

                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "oneYRTermPat", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[0].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "oneYRTermPat", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[0].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "oneYRTermCap", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "oneYRTermCap", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateOver)

                // .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "twoYRTermPat", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateUnder)
                // .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "twoYRTermPat", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "marketConditionReturns"], action.value.data.assetClassIndexStrategies[1].marketConditionReturns)

                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "oneYRTermPat", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[0].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "oneYRTermPat", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[0].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "oneYRTermCap", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "oneYRTermCap", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateOver)

                // .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "twoYRTermPat", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateUnder)
                // .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "twoYRTermPat", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "marketConditionReturns"], action.value.data.assetClassIndexStrategies[2].marketConditionReturns);
            break;
        case actionTypes.UI_SET_ADMIN_IS6Pro:
            return state
                .setIn(["adminDatabase", ADMIN_SPDRGOLDSHARES_MAPPING[action.value.type], "oneYRMinusTenFloorCap", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[3].indexStrategies[0].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPDRGOLDSHARES_MAPPING[action.value.type], "oneYRMinusTenFloorCap", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[3].indexStrategies[0].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_FEES_MAPPING[action.value.type], "paymentUnder100K", "value"], action.value.data.fees.purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_FEES_MAPPING[action.value.type], "paymentOver100K", "value"], action.value.data.fees.purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "oneYRTermCap", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[0].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "oneYRTermCap", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[0].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "oneYRTermPat", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[2].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "oneYRTermPat", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[2].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "oneYRBufferCap", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[5].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "oneYRBufferCap", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[5].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "oneYRMinusTenFloorCap", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[6].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "oneYRMinusTenFloorCap", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[6].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "oneYRZeroFloorCap", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[7].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "oneYRZeroFloorCap", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[7].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "twoYRTermCap", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[1].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "twoYRTermCap", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[1].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "twoYRTermPat", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[3].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "twoYRTermPat", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[3].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "sixYrTermPatTenBuffer", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[4].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "sixYrTermPatTenBuffer", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[4].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "marketConditionReturns"], action.value.data.assetClassIndexStrategies[0].marketConditionReturns)

                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "oneYRTermPat", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[0].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "oneYRTermPat", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[0].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "oneYRTermCap", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "oneYRTermCap", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateOver)

                // .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "twoYRTermPat", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateUnder)
                // .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "twoYRTermPat", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "marketConditionReturns"], action.value.data.assetClassIndexStrategies[1].marketConditionReturns)

                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "oneYRTermPat", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[0].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "oneYRTermPat", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[0].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "oneYRTermCap", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "oneYRTermCap", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateOver)

                // .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "twoYRTermPat", "paymentUnder100K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateUnder)
                // .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "twoYRTermPat", "paymentOver100K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "marketConditionReturns"], action.value.data.assetClassIndexStrategies[2].marketConditionReturns);
            break;

        case actionTypes.UI_SET_ADMIN_IS5:
            return state
                .setIn(["adminDatabase", ADMIN_SPDRGOLDSHARES_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[3].indexStrategies[0].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPDRGOLDSHARES_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[3].indexStrategies[0].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPDRGOLDSHARES_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[3].indexStrategies[1].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPDRGOLDSHARES_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[3].indexStrategies[1].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPDRGOLDSHARES_MAPPING[action.value.type], "marketConditionReturns"], action.value.data.assetClassIndexStrategies[3].marketConditionReturns)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "tenPercentBufferIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[0].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "tenPercentBufferIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[0].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[1].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[1].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[2].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[2].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "marketConditionReturns"], action.value.data.assetClassIndexStrategies[0].marketConditionReturns)

                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[0].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[0].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "marketConditionReturns"], action.value.data.assetClassIndexStrategies[1].marketConditionReturns)

                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[0].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[0].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "marketConditionReturns"], action.value.data.assetClassIndexStrategies[2].marketConditionReturns);
            break;

        case actionTypes.UI_SET_ADMIN_IS5Pro:
            return state
                .setIn(["adminDatabase", ADMIN_FEES_MAPPING[action.value.type], "paymentUnder250K", "value"], action.value.data.fees.purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_FEES_MAPPING[action.value.type], "paymentOver250K", "value"], action.value.data.fees.purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "tenPercentBufferIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[0].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "tenPercentBufferIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[0].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[1].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[1].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[2].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[2].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "marketConditionReturns"], action.value.data.assetClassIndexStrategies[0].marketConditionReturns)

                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[0].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[0].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "marketConditionReturns"], action.value.data.assetClassIndexStrategies[1].marketConditionReturns)

                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[0].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[0].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "marketConditionReturns"], action.value.data.assetClassIndexStrategies[2].marketConditionReturns);
            break;

        case actionTypes.UI_SET_ADMIN_IS7:
            return state
                .setIn(["adminDatabase", ADMIN_SPDRGOLDSHARES_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[3].indexStrategies[0].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPDRGOLDSHARES_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[3].indexStrategies[0].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPDRGOLDSHARES_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[3].indexStrategies[1].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPDRGOLDSHARES_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[3].indexStrategies[1].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPDRGOLDSHARES_MAPPING[action.value.type], "marketConditionReturns"], action.value.data.assetClassIndexStrategies[3].marketConditionReturns)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "tenPercentBufferIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[0].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "tenPercentBufferIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[0].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[1].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[1].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[2].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[2].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "marketConditionReturns"], action.value.data.assetClassIndexStrategies[0].marketConditionReturns)

                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[0].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[0].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "marketConditionReturns"], action.value.data.assetClassIndexStrategies[1].marketConditionReturns)

                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[0].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[0].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "marketConditionReturns"], action.value.data.assetClassIndexStrategies[2].marketConditionReturns);
            break;

        case actionTypes.UI_SET_ADMIN_IS7Pro:
            return state
                .setIn(["adminDatabase", ADMIN_FEES_MAPPING[action.value.type], "paymentUnder250K", "value"], action.value.data.fees.purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_FEES_MAPPING[action.value.type], "paymentOver250K", "value"], action.value.data.fees.purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "tenPercentBufferIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[0].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "tenPercentBufferIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[0].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[1].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[1].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[2].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[0].indexStrategies[2].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SPINDEX_MAPPING[action.value.type], "marketConditionReturns"], action.value.data.assetClassIndexStrategies[0].marketConditionReturns)

                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[0].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[0].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SHAREMCSI_MAPPING[action.value.type], "marketConditionReturns"], action.value.data.assetClassIndexStrategies[1].marketConditionReturns)

                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[0].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "minustenPercentBufferIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[0].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentUnder250K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateUnder)
                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "zeroPercentFloorIdxStrategy", "paymentOver250K", "value"], action.value.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateOver)

                .setIn(["adminDatabase", ADMIN_SHAREREALESTATE_MAPPING[action.value.type], "marketConditionReturns"], action.value.data.assetClassIndexStrategies[2].marketConditionReturns);
            break;
    }
};

let setAdminEffectiveDate = (state, action) => {
    return state
        .setIn(["adminDatabase", "lastUpdateDate"], action.value);
};

let updateAdminLogin = (state, action) => {
    return state
        .setIn(["adminDatabase", "login", action.key, "value"], action.value)
        .setIn(["adminDatabase", "login", action.key, "isValid"], true);
};

let validateAdminLogin = (state, action) => {
    return state
        .updateIn(["adminDatabase", "login", "username", "isValid"], () => {
            let username = state.getIn(["adminDatabase", "login", "username", "value"]);
            return username !== "";
        })
        .updateIn(["adminDatabase", "login", "password", "isValid"], () => {
            let password = state.getIn(["adminDatabase", "login", "password", "value"]);
            return password !== "";
        })
        .updateIn(["adminDatabase", "login", "isValid"], () => {
            let username = state.getIn(["adminDatabase", "login", "username", "value"]);
            let password = state.getIn(["adminDatabase", "login", "password", "value"]);


            return (username !== "" && password !== "");
        });
};

const updateClientsName = (state, action) => {
    return state
        .updateIn(["clientsName", "value"], (value) => {
            return action.value;
        })
        .updateIn(["clientsName", "isValid"], () => true);
};

const validateClientsName = (state, action) => {
    return state
        .updateIn(["clientsName", "isValid"], () => {
            let currentValue = state.getIn(["clientsName", "value"]);
            return currentValue!="";
        });
};

let reducerFunctions = {};
reducerFunctions[actionTypes.DB_RESET] = resetData;
reducerFunctions[actionTypes.DB_SET_CLIENTS_NAME] = updateClientsName;
reducerFunctions[actionTypes.DB_VALIDATE_CLIENTS_NAME] = validateClientsName;
reducerFunctions[actionTypes.DB_SET_ANNUITY_PAYMENT] = updateAnnuityPurchaseAmount;
reducerFunctions[actionTypes.DB_VALIDATE_ANNUITY_PAYMENT] = validateAnnuityPurchaseAmount;
reducerFunctions[actionTypes.DB_UPDATE_SP_500] = updateSP500Value;
reducerFunctions[actionTypes.DB_VALIDATE_SP_500] = validateSP500Values;
reducerFunctions[actionTypes.DB_UPDATE_MCSI] = updateMCSI;
reducerFunctions[actionTypes.DB_VALIDATE_MCSI] = validateMCSI;
reducerFunctions[actionTypes.DB_UPDATE_REALESTATE] = updateRealEstate;
reducerFunctions[actionTypes.DB_VALIDATE_REALESTATE] = validateRealEstate;
reducerFunctions[actionTypes.DB_UPDATE_SPDRGOLDSHARES] = updateSPDRGoldShares;
reducerFunctions[actionTypes.DB_VALIDATE_SPDRGOLDSHARES] = validateSPDRGoldShares;
reducerFunctions[actionTypes.DB_UPDATE_STRATEGY] = updateStrategy;
reducerFunctions[actionTypes.DB_VALIDATE_STRATEGY] = validateStrategy;
reducerFunctions[actionTypes.DB_UPDATE_CONDITIONS] = updateConditions;
reducerFunctions[actionTypes.DB_UPDATE_RESULTS] = updateResults;
reducerFunctions[actionTypes.DB_ADMIN_UPDATE] = updateAdminData;
reducerFunctions[actionTypes.DB_ADMIN_VALIDATE] = validateAdminData;
reducerFunctions[actionTypes.DB_UPDATE_ASSET_CLASSES] = updateAssetClasses;
reducerFunctions[actionTypes.DB_UPDATE_USER_ID] = updateUserId;
reducerFunctions[actionTypes.DB_ADMIN_SET_DATA] = setAdminDatabase;
reducerFunctions[actionTypes.DB_ADMIN_SET_DATE] = setAdminEffectiveDate;
reducerFunctions[actionTypes.DB_UPDATE_LOGIN_DATA] = updateAdminLogin;
reducerFunctions[actionTypes.DB_VALIDATE_LOGIN_DATA] = validateAdminLogin;
reducerFunctions[actionTypes.DB_UPDATE_VISIT_ID_DATA] = updateVisitId;

export const databaseReducer = (state = initialState, action) => {
    if (reducerFunctions.hasOwnProperty(action.type)) return reducerFunctions[action.type](state, action);
    return state;
};
