import React from 'react';
import { Container, Header, Grid, Button } from "semantic-ui-react";
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actionTypes from '../../../../reducers/actions';
import '../index.css';
import {TOOL_LINK_NAME_MAPPING, TOOL_NAME_MAPPING, TOOL_PDF_NAME_MAPPING} from "../../../../reducers/actions";


// Header should go accross the entire screen
// Header should have the GAIG Logo on it.
// GAIG Logo should link to GAIG Home page.

const NavigationHeader = (props) => {
    const showNavRowButtons = props.uiReducer.toJS().showNavigationRowButtons;
    const selectedTool = props.uiReducer.toJS().selectedTool;
    const isAdminAuthorized = props.uxReducer.toJS().isAuthorized;

    const hideBackToIntroductionButton = sessionStorage.getItem('showBackToIntroductionButton') === "false";
    return (<>
        <Grid.Row className={"navigation-bar-row"}>

            {showNavRowButtons &&
                <>
                    <Grid.Column width={9} style={{ "textAlign": "left", "paddingLeft": "50px" }}>
                        {(!hideBackToIntroductionButton || hideBackToIntroductionButton === null) && <NavLink exact={true} to="/">
                            <Button>Back to Introduction</Button>
                        </NavLink>}
                    </Grid.Column>
                    <Grid.Column width={7} style={{ "textAlign": "right", "paddingRight": "50px" }}>
                        <NavLink exact={true} to="/wizard" activeClassName={'active-page'}>
                            <Button>{TOOL_NAME_MAPPING[selectedTool]}</Button>
                        </NavLink>
                        <NavLink to="/disclosures" activeClassName={'active-page'}>
                            <Button>Important Information</Button>
                        </NavLink>
                        <a href={TOOL_LINK_NAME_MAPPING[selectedTool]} target="_blank" activeClassName={'active-page'}>
                            <Button>Prospectus</Button>
                        </a>
                    </Grid.Column>
                </>
            }
            {isAdminAuthorized &&
                <>
                    <Grid.Column width={9}></Grid.Column>
                    <Grid.Column width={7} style={{ "textAlign": "right", "paddingRight": "50px" }}>
                        <Button onClick={() => { props.logoutAdmin() }}>Log out</Button>
                    </Grid.Column>
                </>
            }

        </Grid.Row>

        <Grid.Row className={"navigation-bar-row--mobile"}>
            {showNavRowButtons &&
                <>
                    <Grid.Column width={4} style={{ "textAlign": "left", "paddingLeft": "50px" }}>
                        {(!hideBackToIntroductionButton || hideBackToIntroductionButton === null) && <NavLink exact={true} to="/">
                            <Button>Back to Introduction</Button>
                        </NavLink>}
                    </Grid.Column>
                    <Grid.Column width={12} style={{ "textAlign": "right", "paddingRight": "50px" }}>
                        <NavLink exact={true} to="/wizard" activeClassName={'active-page'}>
                            <Button>{TOOL_NAME_MAPPING[selectedTool]}</Button>
                        </NavLink>
                        <NavLink to="/disclosures" activeClassName={'active-page'}>
                            <Button>Important Information</Button>
                        </NavLink>
                        <a href={TOOL_PDF_NAME_MAPPING[selectedTool]} target="_blank" activeClassName={'active-page'}>
                            <Button>Prospectuss</Button>
                        </a>
                    </Grid.Column></>}

            {isAdminAuthorized &&
                <>
                    <Grid.Column width={9}></Grid.Column>
                    <Grid.Column width={7} style={{ "textAlign": "right", "paddingRight": "50px" }}>
                        <Button onClick={() => { props.logoutAdmin() }}>Log out</Button>
                    </Grid.Column>
                </>
            }
        </Grid.Row>
    </>
    );
};


const mapStateToProps = (state) => {
    return {
        uiReducer: state.get('uiReducer'),
        uxReducer: state.get('uxReducer'),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        logoutAdmin: (value) => dispatch({
            type: actionTypes.SAGA_LOGOUT_ADMIN,
        }),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationHeader);