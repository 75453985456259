import React from 'react';
import { Segment, Grid, Label, Header } from "semantic-ui-react";
import { connect } from 'react-redux';
import NumberFormat from "react-number-format";
import { getInputClass } from "../../../../common/common";
import * as actionTypes from '../../../../reducers/actions';
import {SPINDEX_MAPPING} from "../../../../reducers/actions";

// has 1 year term with cap + hard coded values
// has 2 year term with cap + hard coded values
// has 1 year term with pat + hard coded values
// has 2 year term with pat + hard coded values
// has S&P 500 Total
// is connected to the database


const spIndexBox = (props) => {

    let data = props.database.toJS();
    const selectedTool = props.uiReducer.toJS().selectedTool;
    let spTotal = data[SPINDEX_MAPPING[selectedTool]].oneYRTermCap.value +
        data[SPINDEX_MAPPING[selectedTool]].oneYRTermPat.value +
        data[SPINDEX_MAPPING[selectedTool]].oneYRBufferCap.value +
        data[SPINDEX_MAPPING[selectedTool]].oneYRMinusTenFloorCap.value +
        data[SPINDEX_MAPPING[selectedTool]].oneYRZeroFloorCap.value +
        data[SPINDEX_MAPPING[selectedTool]].twoYRTermCap.value +
        data[SPINDEX_MAPPING[selectedTool]].twoYRTermPat.value +
        data[SPINDEX_MAPPING[selectedTool]].sixYrTermPatTenBuffer.value;

    if (isNaN(spTotal)) spTotal = 0.0;

    return (
        <Segment raised>

            <Header as="h1">S&P 500 Index</Header>

            <Grid columns='equal'>

                <Grid.Row>
                    <Grid.Column></Grid.Column>
                    <Grid.Column></Grid.Column>
                    <Grid.Column>
                        <Label basic>Purchase Payments $100,000 and Over</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>Purchase Payments under $100,000</Label>
                    </Grid.Column>
                </Grid.Row>


                <Grid.Row>
                    <Grid.Column>
                        <Label basic>1-Year 50% Downside Participation Rate with Upside Participation Rate</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data[SPINDEX_MAPPING[selectedTool]].oneYRTermPat.isValid)}>
                            <NumberFormat
                                value={data[SPINDEX_MAPPING[selectedTool]].oneYRTermPat.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateSPIndex(selectedTool, "oneYRTermPat", values.value)}
                                onClick={() => props.updateSPIndex(selectedTool, "oneYRTermPat", "")}
                                onBlur={() => props.validateSPIndex(selectedTool)}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].oneYRTermPat.paymentOver100K.value}%</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].oneYRTermPat.paymentUnder100K.value}%</Label>
                    </Grid.Column>

                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Label basic>1-Year 50% Downside Participation Rate with Cap</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data[SPINDEX_MAPPING[selectedTool]].oneYRTermCap.isValid)}>
                            <NumberFormat
                                value={data[SPINDEX_MAPPING[selectedTool]].oneYRTermCap.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateSPIndex(selectedTool, "oneYRTermCap", values.value)}
                                onClick={() => props.updateSPIndex(selectedTool, "oneYRTermCap", "")}
                                onBlur={() => props.validateSPIndex(selectedTool)}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].oneYRTermCap.paymentOver100K.value}%</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].oneYRTermCap.paymentUnder100K.value}%</Label>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Label basic>1-yr 10% Buffer with Cap</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data[SPINDEX_MAPPING[selectedTool]].oneYRBufferCap.isValid)}>
                            <NumberFormat
                                value={data[SPINDEX_MAPPING[selectedTool]].oneYRBufferCap.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateSPIndex(selectedTool, "oneYRBufferCap", values.value)}
                                onClick={() => props.updateSPIndex(selectedTool, "oneYRBufferCap", "")}
                                onBlur={() => props.validateSPIndex(selectedTool)}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].oneYRBufferCap.paymentOver100K.value}%</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].oneYRBufferCap.paymentUnder100K.value}%</Label>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Label basic>1-yr -10% Floor with Cap</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data[SPINDEX_MAPPING[selectedTool]].oneYRMinusTenFloorCap.isValid)}>
                            <NumberFormat
                                value={data[SPINDEX_MAPPING[selectedTool]].oneYRMinusTenFloorCap.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateSPIndex(selectedTool, "oneYRMinusTenFloorCap", values.value)}
                                onClick={() => props.updateSPIndex(selectedTool, "oneYRMinusTenFloorCap", "")}
                                onBlur={() => props.validateSPIndex(selectedTool)}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].oneYRMinusTenFloorCap.paymentOver100K.value}%</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].oneYRMinusTenFloorCap.paymentUnder100K.value}%</Label>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Label basic>1-yr 0% Floor with Cap</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data[SPINDEX_MAPPING[selectedTool]].oneYRZeroFloorCap.isValid)}>
                            <NumberFormat
                                value={data[SPINDEX_MAPPING[selectedTool]].oneYRZeroFloorCap.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateSPIndex(selectedTool, "oneYRZeroFloorCap", values.value)}
                                onClick={() => props.updateSPIndex(selectedTool, "oneYRZeroFloorCap", "")}
                                onBlur={() => props.validateSPIndex(selectedTool)}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].oneYRZeroFloorCap.paymentOver100K.value}%</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].oneYRZeroFloorCap.paymentUnder100K.value}%</Label>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Label basic>2-Year 50% Downside Participation Rate with Upside Participation Rate</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data[SPINDEX_MAPPING[selectedTool]].twoYRTermPat.isValid)}>
                            <NumberFormat
                                value={data[SPINDEX_MAPPING[selectedTool]].twoYRTermPat.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateSPIndex(selectedTool, "twoYRTermPat", values.value)}
                                onClick={() => props.updateSPIndex(selectedTool, "twoYRTermPat", "")}
                                onBlur={() => props.validateSPIndex(selectedTool)}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].twoYRTermPat.paymentOver100K.value}%</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].twoYRTermPat.paymentUnder100K.value}%</Label>
                    </Grid.Column>

                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Label basic>2-Year 50% Downside Participation Rate with Cap</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data[SPINDEX_MAPPING[selectedTool]].twoYRTermCap.isValid)}>
                            <NumberFormat
                                value={data[SPINDEX_MAPPING[selectedTool]].twoYRTermCap.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateSPIndex(selectedTool, "twoYRTermCap", values.value)}
                                onClick={() => props.updateSPIndex(selectedTool, "twoYRTermCap", "")}
                                onBlur={() => props.validateSPIndex(selectedTool)}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].twoYRTermCap.paymentOver100K.value}%</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].twoYRTermCap.paymentUnder100K.value}%</Label>
                    </Grid.Column>

                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Label basic>6-Year 10% Buffer with Upside Participation Rate</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data[SPINDEX_MAPPING[selectedTool]].sixYrTermPatTenBuffer.isValid)}>
                            <NumberFormat
                                value={data[SPINDEX_MAPPING[selectedTool]].sixYrTermPatTenBuffer.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateSPIndex(selectedTool, "sixYrTermPatTenBuffer", values.value)}
                                onClick={() => props.updateSPIndex(selectedTool, "sixYrTermPatTenBuffer", "")}
                                onBlur={() => props.validateSPIndex(selectedTool)}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].sixYrTermPatTenBuffer.paymentOver100K.value}%</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].sixYrTermPatTenBuffer.paymentUnder100K.value}%</Label>
                    </Grid.Column>

                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Label basic>S&P 500 Total:</Label>
                    </Grid.Column>
                    <Grid.Column>
                        {spTotal}%
                    </Grid.Column>
                    <Grid.Column></Grid.Column>
                    <Grid.Column></Grid.Column>
                </Grid.Row>

            </Grid>
        </Segment>
    );
};

const mapStateToProps = (state) => {
    return {
        database: state.get("databaseReducer"),
        uiReducer: state.get('uiReducer'),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSPIndex: (selectedTool, key, value) => dispatch({
            type: actionTypes.DB_UPDATE_SP_500,
            selectedTool: selectedTool,
            key: key,
            value: value
        }),
        validateSPIndex: (selectedTool) => dispatch({
            type: actionTypes.DB_VALIDATE_SP_500,
            selectedTool: selectedTool
        })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(spIndexBox);