import React from 'react';
import {Container, Grid, Header, Segment} from 'semantic-ui-react';
import AnnuityPaymentStep from '../AnnuityPaymentStep/index';
import ClientName from '../ClientName/index'
import SPIndexBox from './SPIndexBox/index';
import SPIndexBox5And7 from './SPIndexBox5And7/index';
import ShareMSCIBox from './ShareMCSIBox/index';
import ShareMSCIBox5And7 from './ShareMCSIBox5And7/index';
import ShareRealEstateBox from './ShareRealEstateBox/index';
import ShareRealEstateBox5And7 from './ShareRealEstateBox5And7/index';
import SpdrGoldSharesBox from './SpdrGoldSharesBox/index';
import SpdrGoldSharesBox5And7 from './SpdrGoldSharesBox5And7/index';
import StrategiesTotalBox from './StrategiesTotalBox/index';
import {Message} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {TOOL_NAME_MAPPING} from "../../../reducers/actions";
import {isIS5OrIS5Pro, isIS5OrIS7, isIS6OrIS6Pro, isIS7OrIS7Pro, isPro} from "../../../common/common";
import IndexFrontierCapAndProtection from "./IndexFrontierCapAndProtection";

// should have a S&P Index box
// should have a iShare MCSI
// should have a iShares Real Estate
// should have a declared strategy
// should have a validation message box
const indexStrategiesStep = (props) => {

    let ux = props.uxReducer.toJS();
    let database = props.database.toJS();
    let adminData = props.adminDatabase.toJS();
    let data = props.database.toJS();
    const selectedTool = props.uiReducer.toJS().selectedTool;
    let warningMessage = null;
    if (!ux.isValid) {

        let annuityWarning = null;
        let strategyWarning = null;
        let clientNameWarning = null;
        let emptyClientNameWarning = null;

        if (!database.annuityPurchaseAmount.isValid) {
            annuityWarning = (
                <div>Purchase Amount must be between $25,000 and $1,000,000</div>
            );
        }

        if (!ux.isStratTotalValid) {
            strategyWarning = (
                <div>Total of all strategies must equal 100%</div>
            );
        }

        if(!ux.isClientsNameValid){
            clientNameWarning = (
                <div>Please enter a valid name and try again</div>
            );
        }

        if(!ux.isClientsNameNotEmpty){
            emptyClientNameWarning = (
                <div>Please input your client’s name</div>
            );
        }
        warningMessage = (
            <Grid.Row>
                <Grid.Column>
                    <Message negative>
                        <Message.Content>
                        {annuityWarning}
                        {strategyWarning}
                        {clientNameWarning}
                        {emptyClientNameWarning}
                        </Message.Content>
                    </Message>
                </Grid.Column>
            </Grid.Row>
        );

    }

    const getProRate = (tool) => {
        let rate = 0;
        if (isPro(tool)) {
            if (isIS6OrIS6Pro(tool)) {
                rate = data.annuityPurchaseAmount.value > 100000 ? adminData.is6ProFees.paymentOver100K.value : adminData.is6ProFees.paymentUnder100K.value;
            } else if (isIS5OrIS5Pro(tool)) {
                rate = data.annuityPurchaseAmount.value > 250000 ? adminData.is5ProFees.paymentOver250K.value : adminData.is5ProFees.paymentUnder250K.value;
            } else if (isIS7OrIS7Pro(tool)) {
                rate = data.annuityPurchaseAmount.value > 250000 ? adminData.is7ProFees.paymentOver250K.value : adminData.is7ProFees.paymentUnder250K.value;
            }
        }
        return rate + "%";
    }

    return (
        <Grid>

            <Grid.Row>
                <Grid.Column>
                    <Container className={'intro-container'}>
                        {isIS6OrIS6Pro(selectedTool) &&
                            <p>The {TOOL_NAME_MAPPING[selectedTool]} offers indexed strategies that provide returns based, in part, on the rise or fall of a market index or ETF share prices over 1-year, 2-year or 6-year terms. Increases for each indexed strategy are limited by caps and participation rates specific to each strategy, while decreases are limited by a 50% downside participation rate, a -10% floor, a 0% floor or a 10% buffer.</p>
                        }
                        {!isIS6OrIS6Pro(selectedTool) &&
                            <p>The {TOOL_NAME_MAPPING[selectedTool]} offers indexed strategies that provide returns based, in part, on the rise or fall of a market index or ETF share prices over 1-year terms. Increases for each indexed strategy are limited by caps specific to each strategy. Decreases are limited by either a floor or a buffer. The {TOOL_NAME_MAPPING[selectedTool]} tool (the "Tool") demonstrates how the {TOOL_NAME_MAPPING[selectedTool]}'s combination of upside potential and downside protection can affect performance in a variety of market conditions.</p>
                        }
                            <p>After you enter strategy allocation information in the fields below, the Tool will perform a Monte Carlo analysis that produces up to 5,000 simulations that are based in part on historical performance data for each index and ETF you selected.</p>
                            <p>On the next page, you will see charts that summarize data from the Monte Carlo simulations.</p>
                        {(isIS6OrIS6Pro(selectedTool) && !isPro(selectedTool)) &&
                            <p>The Index Summit 6 has a 6-year early withdrawal charge schedule. See Important Information tab for more information on early withdrawal charges.</p>
                        }
                        {(isIS6OrIS6Pro(selectedTool) && isPro(selectedTool)) &&
                            <p>The Index Summit 6 Pro has a 6-year early withdrawal charge schedule and an annual fee of {getProRate(selectedTool)}. See Important Information tab for more information on early withdrawal charges and contract fees.</p>
                        }
                        {(isIS5OrIS5Pro(selectedTool) && !isPro(selectedTool)) &&
                            <p>The Index Frontier 5 has a 5-year early withdrawal charge schedule. See Important Information tab for more information on early withdrawal charges.</p>
                        }
                        {(isIS5OrIS5Pro(selectedTool) && isPro(selectedTool)) &&
                            <p>The Index Frontier 5 Pro has a 5-year early withdrawal charge schedule and an annual fee of {getProRate(selectedTool)}. See Important Information tab for more information on early withdrawal charges and contract fees.</p>
                        }
                        {(isIS7OrIS7Pro(selectedTool) && !isPro(selectedTool)) &&
                            <p>The Index Frontier 7 has a 7-year early withdrawal charge schedule. See Important Information tab for more information on early withdrawal charges.</p>
                        }
                        {(isIS7OrIS7Pro(selectedTool) && isPro(selectedTool)) &&
                            <p>The Index Frontier 7 Pro has a 7-year early withdrawal charge schedule and an annual fee of {getProRate(selectedTool)}. See Important Information tab for more information on early withdrawal charges and contract fees.</p>
                        }
                    </Container>
                </Grid.Column>
            </Grid.Row>
            {warningMessage}
            <Grid.Row>
                <Grid.Column>
                    <ClientName/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <AnnuityPaymentStep/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Segment raised>
                        <Header>How would you like to allocate your purchase payment among the available indexed
                            strategies?</Header>
                        <span>Total allocation among all strategies must equal 100%.</span>
                    </Segment>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column>
                    {isIS6OrIS6Pro(selectedTool) && <SPIndexBox/>}
                    {!isIS6OrIS6Pro(selectedTool) && <SPIndexBox5And7/>}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    {isIS6OrIS6Pro(selectedTool) && <ShareMSCIBox/>}
                    {!isIS6OrIS6Pro(selectedTool) && <ShareMSCIBox5And7/>}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    {isIS6OrIS6Pro(selectedTool) && <ShareRealEstateBox/>}
                    {!isIS6OrIS6Pro(selectedTool) && <ShareRealEstateBox5And7/>}
                </Grid.Column>
            </Grid.Row>
            {isIS6OrIS6Pro(selectedTool) &&
            <Grid.Row>
                <Grid.Column>
                    <SpdrGoldSharesBox/>
                </Grid.Column>
            </Grid.Row>}
            {isIS5OrIS7(selectedTool) &&
            <Grid.Row>
                <Grid.Column>
                    <SpdrGoldSharesBox5And7/>
                </Grid.Column>
            </Grid.Row>}
            <Grid.Row>
                <Grid.Column>
                    <StrategiesTotalBox/>
                </Grid.Column>
            </Grid.Row>
            {!isIS6OrIS6Pro(selectedTool) &&
            <Grid.Row>
                <Grid.Column>
                    <IndexFrontierCapAndProtection/>
                </Grid.Column>
            </Grid.Row>
            }
        </Grid>
    )
};

const mapStateToProps = (state) => {
    return {
        uxReducer: state.get("uxReducer"),
        database: state.get("databaseReducer"),
        adminDatabase: state.getIn(["databaseReducer", "adminDatabase"]),
        uiReducer: state.get("uiReducer"),
    }
};

export default connect(mapStateToProps, null)(indexStrategiesStep);
