import React from 'react';
import { Segment, Grid, Label, Header } from "semantic-ui-react";
import { connect } from 'react-redux';
import NumberFormat from "react-number-format";
import { getInputClass } from "../../../../common/common";
import * as actionTypes from '../../../../reducers/actions';
import {SPINDEX_MAPPING} from "../../../../reducers/actions";

const spIndexBox5And7 = (props) => {

    let data = props.database.toJS();
    const selectedTool = props.uiReducer.toJS().selectedTool;
    let spTotal = data[SPINDEX_MAPPING[selectedTool]].tenPercentBufferIdxStrategy.value +
        data[SPINDEX_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value +
        data[SPINDEX_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value;

    if (isNaN(spTotal)) spTotal = 0.0;

    return (
        <Segment raised>

            <Header as="h1">S&P 500 Index</Header>

            <Grid columns='equal'>

                <Grid.Row>
                    <Grid.Column></Grid.Column>
                    <Grid.Column></Grid.Column>
                    <Grid.Column>
                        <Label basic>Purchase Payments $250,000 and Over</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>Purchase Payments under $250,000</Label>
                    </Grid.Column>
                </Grid.Row>


                <Grid.Row>
                    <Grid.Column>
                        <Label basic>10% Buffer Indexed Strategy</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data[SPINDEX_MAPPING[selectedTool]].tenPercentBufferIdxStrategy.isValid)}>
                            <NumberFormat
                                value={data[SPINDEX_MAPPING[selectedTool]].tenPercentBufferIdxStrategy.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateSPIndex(selectedTool, "tenPercentBufferIdxStrategy", values.value)}
                                onClick={() => props.updateSPIndex(selectedTool, "tenPercentBufferIdxStrategy", "")}
                                onBlur={() => props.validateSPIndex(selectedTool)}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].tenPercentBufferIdxStrategy.paymentOver250K.value}%</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].tenPercentBufferIdxStrategy.paymentUnder250K.value}%</Label>
                    </Grid.Column>

                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Label basic>-10% Floor Indexed Strategy</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data[SPINDEX_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.isValid)}>
                            <NumberFormat
                                value={data[SPINDEX_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateSPIndex(selectedTool, "minustenPercentBufferIdxStrategy", values.value)}
                                onClick={() => props.updateSPIndex(selectedTool, "minustenPercentBufferIdxStrategy", "")}
                                onBlur={() => props.validateSPIndex(selectedTool)}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.paymentOver250K.value}%</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.paymentUnder250K.value}%</Label>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Label basic>0% Floor Indexed Strategy</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data[SPINDEX_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.isValid)}>
                            <NumberFormat
                                value={data[SPINDEX_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value}
                                displayType={'input'}
                                suffix={'%'}
                                onValueChange={(values) => props.updateSPIndex(selectedTool, "zeroPercentFloorIdxStrategy", values.value)}
                                onClick={() => props.updateSPIndex(selectedTool, "zeroPercentFloorIdxStrategy", "")}
                                onBlur={() => props.validateSPIndex(selectedTool)}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.paymentOver250K.value}%</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SPINDEX_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.paymentUnder250K.value}%</Label>
                    </Grid.Column>

                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Label basic>S&P 500 Total:</Label>
                    </Grid.Column>
                    <Grid.Column>
                        {spTotal}%
                    </Grid.Column>
                    <Grid.Column></Grid.Column>
                    <Grid.Column></Grid.Column>
                </Grid.Row>

            </Grid>
        </Segment>
    );
};

const mapStateToProps = (state) => {
    return {
        database: state.get("databaseReducer"),
        uiReducer: state.get('uiReducer'),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSPIndex: (selectedTool, key, value) => dispatch({
            type: actionTypes.DB_UPDATE_SP_500,
            selectedTool: selectedTool,
            key: key,
            value: value
        }),
        validateSPIndex: (selectedTool) => dispatch({
            type: actionTypes.DB_VALIDATE_SP_500,
            selectedTool: selectedTool
        })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(spIndexBox5And7);