import {select, put, call, takeEvery, all} from 'redux-saga/effects';
import * as actionTypes from './actions';
import {MAX_PAGES} from "./uxState";
import {
    calculate_plan,
    login_and_get_assets,
    create_user,
    create_plans,
    update_market_risk,
    generate_pdf,
    login_admin,
    logout_admin,
    get_is6pro_admin_data,
    get_is5pro_admin_data,
    get_is7pro_admin_data,
    get_is6_admin_data,
    get_is5_admin_data,
    get_is7_admin_data,
    save_is6_admin_data,
    save_is6pro_admin_data,
    save_is5pro_admin_data,
    save_is7pro_admin_data,
    save_is5_admin_data,
    save_is7_admin_data, complete_plan
} from "../api/api";
import {DEFAULT_CONDITIONS} from "./database";
import {SERVER_URL} from "../config";
import {
    ADMIN_FEES_MAPPING,
    ADMIN_SHAREMCSI_MAPPING,
    ADMIN_SHAREREALESTATE_MAPPING, ADMIN_SPDRGOLDSHARES_MAPPING,
    ADMIN_SPINDEX_MAPPING,
    DECLAREDSTRATEGY_MAPPING, PRODUCT_TYPE,
    SHAREMCSI_MAPPING,
    SHAREREALESTATE_MAPPING, SPDRGOLDSHARES_MAPPING,
    SPINDEX_MAPPING
} from "../reducers/actions";
import {isIS5OrIS7, isIS6OrIS6Pro} from "../common/common";

export function* nextPage() {

    const priorState = yield select();
    if (priorState.toJS().uxReducer.currentWizardStep === MAX_PAGES) {
        yield put({
            type: actionTypes.SAGA_RESET_DATA
        });
        window.scrollTo(0, 0);
        return;
    }

    if (priorState.toJS().uxReducer.currentWizardStep === 0) {
        yield call(validateInputs);
    }

    let validationState = yield select();
    if (!validationState.toJS().uxReducer.isValid){
        window.scrollTo(0, 0);
        return;
    }

    yield call(calculatePlanResults);

    yield put({
        type: actionTypes.UX_NEXT
    });

    // enable the previous button
    yield put({
        type: actionTypes.UI_SET_SHOW_PREV,
        value: true
    });

    const state = yield select();

    // if the current page is the last page, the we should show the start over message
    if (state.toJS().uxReducer.currentWizardStep === MAX_PAGES) {
        yield put({
            type: actionTypes.UI_SET_SHOW_NEXT,
            value: false
        });
    }

    window.scrollTo(0, 0);
}

export function* prevPage() {
    // trigger prev page action
    yield put({
        type: actionTypes.UX_PREV
    });

    // enable show next
    yield put({
        type: actionTypes.UI_SET_SHOW_NEXT,
        value: true
    });

    // if at the first page, disable showPrevious
    const state = yield select();

    if (state.toJS().uxReducer.currentWizardStep === 0) {
        yield put({
            type: actionTypes.UI_SET_SHOW_PREV,
            value: false
        });
    }

    window.scrollTo(0, 0);
}

export function* resetData() {
    yield put({
        type: actionTypes.DB_RESET
    });

    yield put({
        type: actionTypes.UX_RESET
    });

    yield put({
        type: actionTypes.UI_RESET
    });

    yield call(loadAdminData);
}



export function* calculatePlanResults() {
    // show the loading modal
    yield put({
        type: actionTypes.UI_SET_LOADING_MODAL,
        value: true
    });

    let currentState = yield select();
    let uiReducer = currentState.toJS().uiReducer;
    let selectedTool = uiReducer.selectedTool;
    const response = yield login_and_get_assets(PRODUCT_TYPE[selectedTool]);

    const assetClasses = response.data;

    yield put({
        type: actionTypes.DB_UPDATE_ASSET_CLASSES,
        data: assetClasses
    });

    let data = currentState.toJS().databaseReducer;

    // build the plan inputs

    // 1. Map Inputs to asset classes
    if (isIS6OrIS6Pro(selectedTool)) {
        assetClasses[0].value = 100;
        assetClasses[0].children[0].value =
            data[SPINDEX_MAPPING[selectedTool]].oneYRTermCap.value +
            data[SPINDEX_MAPPING[selectedTool]].oneYRTermPat.value +
            data[SPINDEX_MAPPING[selectedTool]].oneYRBufferCap.value +
            data[SPINDEX_MAPPING[selectedTool]].oneYRMinusTenFloorCap.value +
            data[SPINDEX_MAPPING[selectedTool]].oneYRZeroFloorCap.value +
            data[SPINDEX_MAPPING[selectedTool]].twoYRTermCap.value +
            data[SPINDEX_MAPPING[selectedTool]].twoYRTermPat.value +
            data[SPINDEX_MAPPING[selectedTool]].sixYrTermPatTenBuffer.value;
        assetClasses[0].children[0].indexStrategies[0].value = data[SPINDEX_MAPPING[selectedTool]].oneYRTermCap.value;
        assetClasses[0].children[0].indexStrategies[2].value = data[SPINDEX_MAPPING[selectedTool]].oneYRTermPat.value;
        assetClasses[0].children[0].indexStrategies[5].value = data[SPINDEX_MAPPING[selectedTool]].oneYRBufferCap.value;
        assetClasses[0].children[0].indexStrategies[6].value = data[SPINDEX_MAPPING[selectedTool]].oneYRMinusTenFloorCap.value;
        assetClasses[0].children[0].indexStrategies[7].value = data[SPINDEX_MAPPING[selectedTool]].oneYRZeroFloorCap.value;
        assetClasses[0].children[0].indexStrategies[1].value = data[SPINDEX_MAPPING[selectedTool]].twoYRTermCap.value;
        assetClasses[0].children[0].indexStrategies[3].value = data[SPINDEX_MAPPING[selectedTool]].twoYRTermPat.value;
        assetClasses[0].children[0].indexStrategies[4].value = data[SPINDEX_MAPPING[selectedTool]].sixYrTermPatTenBuffer.value;

        // assetClasses[0].children[1].value = data[SHAREMCSI_MAPPING[selectedTool]].oneYRTermPat.value + data[SHAREMCSI_MAPPING[selectedTool]].twoYRTermPat.value;
        assetClasses[0].children[1].value = data[SHAREMCSI_MAPPING[selectedTool]].oneYRTermPat.value + data[SHAREMCSI_MAPPING[selectedTool]].oneYRTermCap.value;
        assetClasses[0].children[1].indexStrategies[0].value = data[SHAREMCSI_MAPPING[selectedTool]].oneYRTermPat.value;
        assetClasses[0].children[1].indexStrategies[1].value = data[SHAREMCSI_MAPPING[selectedTool]].oneYRTermCap.value;
        // assetClasses[0].children[1].indexStrategies[1].value = data[SHAREMCSI_MAPPING[selectedTool]].twoYRTermPat.value;

        // assetClasses[0].children[2].value = data[SHAREREALESTATE_MAPPING[selectedTool]].oneYRTermPat.value + data[SHAREREALESTATE_MAPPING[selectedTool]].twoYRTermPat.value;
        assetClasses[0].children[2].value = data[SHAREREALESTATE_MAPPING[selectedTool]].oneYRTermPat.value + data[SHAREREALESTATE_MAPPING[selectedTool]].oneYRTermCap.value;
        assetClasses[0].children[2].indexStrategies[0].value = data[SHAREREALESTATE_MAPPING[selectedTool]].oneYRTermPat.value;
        assetClasses[0].children[2].indexStrategies[1].value = data[SHAREREALESTATE_MAPPING[selectedTool]].oneYRTermCap.value;
        // assetClasses[0].children[2].indexStrategies[1].value = data[SHAREREALESTATE_MAPPING[selectedTool]].twoYRTermPat.value;

        assetClasses[0].children[3].value = data[SPDRGOLDSHARES_MAPPING[selectedTool]].oneYRMinusTenFloorCap.value;
        assetClasses[0].children[3].indexStrategies[0].value = data[SPDRGOLDSHARES_MAPPING[selectedTool]].oneYRMinusTenFloorCap.value;
    } else {
        assetClasses[0].value = 100;
        assetClasses[0].children[0].value =
            data[SPINDEX_MAPPING[selectedTool]].tenPercentBufferIdxStrategy.value +
            data[SPINDEX_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value +
            data[SPINDEX_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value;
        assetClasses[0].children[0].indexStrategies[0].value = data[SPINDEX_MAPPING[selectedTool]].tenPercentBufferIdxStrategy.value;
        assetClasses[0].children[0].indexStrategies[1].value = data[SPINDEX_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value;
        assetClasses[0].children[0].indexStrategies[2].value = data[SPINDEX_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value;

        assetClasses[0].children[1].value = data[SHAREMCSI_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value + data[SHAREMCSI_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value;
        assetClasses[0].children[1].indexStrategies[0].value = data[SHAREMCSI_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value;
        assetClasses[0].children[1].indexStrategies[1].value = data[SHAREMCSI_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value;

        assetClasses[0].children[2].value = data[SHAREREALESTATE_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value + data[SHAREREALESTATE_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value;
        assetClasses[0].children[2].indexStrategies[0].value = data[SHAREREALESTATE_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value;
        assetClasses[0].children[2].indexStrategies[1].value = data[SHAREREALESTATE_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value;
        if (isIS5OrIS7(selectedTool)) {
            assetClasses[0].children[3].value = data[SPDRGOLDSHARES_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value + data[SPDRGOLDSHARES_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value;
            assetClasses[0].children[3].indexStrategies[0].value = data[SPDRGOLDSHARES_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value;
            assetClasses[0].children[3].indexStrategies[1].value = data[SPDRGOLDSHARES_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value;
        }
    }

    // 2. Create Plan Json.
    let basePlanJSON = {
        accountValue: data.annuityPurchaseAmount.value,
        isIP7Plan: false,
        allocationModel: {
            "allocationModelId":null,
            "allocationModelName":"Custom Allocation",
            "assets": assetClasses,
            "objectScope":"FIRM",
            "returnRate":0,
            "risk":0,
            "annualizedReturn":0,
            "historicalAnnualizedReturn":null,
            "expenseRatio":0,
            "customValues":false,
            "customReturnRate":0,
            "customRisk":0,
            "customAnnualizedReturn":0,
            "taxDrag":null,
            "planId":null,
            "createdDate":null,
            "lastUpdateDate":null,
            "createdBy":null,
            "lastUpdatedBy":null,
            "defaultForPlan":true
        },
        productType: PRODUCT_TYPE[selectedTool]
    };

    let comparePlanJSON = {
        ...basePlanJSON,
        isIP7Plan: true
    };

    // 3. Call Create User Endpoint.
    let newUserPayload = yield create_user(data.visitId);
    let userId = newUserPayload.data;

    yield put({
        type: actionTypes.DB_UPDATE_USER_ID,
        value: userId
    });

    // 4. Call Create Plan endpoint for Base Plan.
    // 5. Call Create Plan endpoint for compare Plan.
    let plans = yield create_plans(userId, basePlanJSON, comparePlanJSON);

    // reset the market conditions
    yield update_market_risk(userId, DEFAULT_CONDITIONS);

    // 6. Call Calculate Plan for Base Plan
    let basePlanResults = yield calculate_plan(userId, plans[0].data[0].autoCorrectedObject.planId, null, data.visitId);

    // 7. Call Calculate Plan for Compare Plan.
    let comparePlanResults = yield calculate_plan(userId, plans[0].data[1].autoCorrectedObject.planId, plans[0].data[0].autoCorrectedObject.planId, data.visitId);

    // 8. Call Complete Plan for adding Visit ID
    // yield complete_plan(userId, plans[0].data[1].autoCorrectedObject.planId, data.visitId);

    // 9. Create the Payload
    // map each plans results back to the data store data structure
    let results = {
        basePlan: basePlanResults.data,
        comparePlan: comparePlanResults.data
    };

    results.basePlan.planId = plans[0].data[0].autoCorrectedObject.planId;
    results.comparePlan.planId = plans[0].data[1].autoCorrectedObject.planId;


    // 10. Update the data store.
    yield put({
        type: actionTypes.DB_UPDATE_RESULTS,
        value: results
    });

    // reset market conditions
    yield put({
        type: actionTypes.DB_UPDATE_CONDITIONS,
        value: DEFAULT_CONDITIONS
    });

    yield put({
        type: actionTypes.UI_SET_LOADING_MODAL,
        value: false
    });


}

export function* recalculatePlanResults() {
    yield put({
        type: actionTypes.UI_SET_LOADING_MODAL,
        value: true
    });

    let currentState = yield select();
    let data = currentState.toJS().databaseReducer;

    let conditions = data.conditions;
    let userId = data.userId;

    yield update_market_risk(userId, conditions);

    let planAId = data.results.planA.planId;
    let planBId = data.results.planB.planId;

    // 6. Call Calculate Plan for Base Plan
    let basePlanResults = yield calculate_plan(userId, planAId, null, data.visitId);

    // 7. Call Calculate Plan for Compare Plan.
    let comparePlanResults = yield calculate_plan(userId, planBId, planAId, data.visitId);

    let results = {
        basePlan: basePlanResults.data,
        comparePlan: comparePlanResults.data
    };

    results.basePlan.planId = planAId;
    results.comparePlan.planId = planBId;

    // 9. Update the data store.
    yield put({
        type: actionTypes.DB_UPDATE_RESULTS,
        value: results
    });

    yield put({
        type: actionTypes.UI_SET_LOADING_MODAL,
        value: false
    });
}

export function* downloadPDF() {

    yield put({
        type: actionTypes.UI_SET_LOADING_MODAL,
        value: true
    });

    let currentState = yield select();
    let data = currentState.toJS().databaseReducer;

    let userId = data.userId;

    let node = document.getElementById('annuity-calculator-results');
    let pdfOutput = node.innerHTML;

    let response = yield generate_pdf(userId, pdfOutput);

    let filename = `${SERVER_URL}/gaig-calculator/plan/output/getpdffile/filename/${response.data}`
    let element = document.createElement('a');

    element.setAttribute('href', filename);
    element.setAttribute('download', "report.pdf");

    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();

    document.body.removeChild(element);


    yield put({
        type: actionTypes.UI_SET_LOADING_MODAL,
        value: false
    });

}

export function* downloadDisclosurePDF() {
    let currentState = yield select();
    let data = currentState.toJS().databaseReducer;

    let userId = data.userId;

    let node = document.getElementById('disclosures-output');
    let pdfOutput = node.innerHTML;

    let response = yield generate_pdf(userId, pdfOutput);

}

export function* loadAdminData() {
    let responseIs6AdminData = yield get_is6_admin_data();
    let responseIs6ProAdminData = yield get_is6pro_admin_data();
    let responseIs5AdminData = yield get_is5_admin_data();
    let responseIs5ProAdminData = yield get_is5pro_admin_data();
    let responseIs7AdminData = yield get_is7_admin_data();
    let responseIs7ProAdminData = yield get_is7pro_admin_data();

    yield put({
        type: actionTypes.UI_SET_LOADING_MODAL,
        value: true
    });

    yield put({
        type: actionTypes.DB_ADMIN_SET_DATA,
        value: {
            type: actionTypes.UI_SET_ADMIN_IS6,
            data: responseIs6AdminData.data
        }
    });

    yield put({
        type: actionTypes.DB_ADMIN_SET_DATA,
        value: {
            type: actionTypes.UI_SET_ADMIN_IS6Pro,
            data: responseIs6ProAdminData.data
        }
    });

    yield put({
        type: actionTypes.DB_ADMIN_SET_DATA,
        value: {
            type: actionTypes.UI_SET_ADMIN_IS5,
            data: responseIs5AdminData.data
        }
    });

    yield put({
        type: actionTypes.DB_ADMIN_SET_DATA,
        value: {
            type: actionTypes.UI_SET_ADMIN_IS5Pro,
            data: responseIs5ProAdminData.data
        }
    });

    yield put({
        type: actionTypes.DB_ADMIN_SET_DATA,
        value: {
            type: actionTypes.UI_SET_ADMIN_IS7,
            data: responseIs7AdminData.data
        }
    });

    yield put({
        type: actionTypes.DB_ADMIN_SET_DATA,
        value: {
            type: actionTypes.UI_SET_ADMIN_IS7Pro,
            data: responseIs7ProAdminData.data
        }
    });

    yield put({
        type: actionTypes.DB_ADMIN_SET_DATE,
        value: responseIs6AdminData.data.lastUpdateDate
    });

    yield put({
        type: actionTypes.UI_SET_LOADING_MODAL,
        value: false
    });
}

export function* validateStep2() {
    const state = yield select();

    let data = state.get("databaseReducer").toJS();
    let ux = state.get("uxReducer").toJS();
    const selectedTool = state.get("uiReducer").toJS().selectedTool;

    let total;
    if (isIS6OrIS6Pro(selectedTool)) {
        total = data[SPINDEX_MAPPING[selectedTool]].oneYRTermCap.value
            + data[SPINDEX_MAPPING[selectedTool]].oneYRTermPat.value
            + data[SPINDEX_MAPPING[selectedTool]].oneYRBufferCap.value
            + data[SPINDEX_MAPPING[selectedTool]].oneYRMinusTenFloorCap.value
            + data[SPINDEX_MAPPING[selectedTool]].oneYRZeroFloorCap.value
            + data[SPINDEX_MAPPING[selectedTool]].twoYRTermCap.value
            + data[SPINDEX_MAPPING[selectedTool]].twoYRTermPat.value
            + data[SPINDEX_MAPPING[selectedTool]].sixYrTermPatTenBuffer.value
            + data[SHAREMCSI_MAPPING[selectedTool]].oneYRTermPat.value
            + data[SHAREMCSI_MAPPING[selectedTool]].oneYRTermCap.value
            // + data[SHAREMCSI_MAPPING[selectedTool]].twoYRTermPat.value
            + data[SHAREREALESTATE_MAPPING[selectedTool]].oneYRTermPat.value
            + data[SHAREREALESTATE_MAPPING[selectedTool]].oneYRTermCap.value
            // + data[SHAREREALESTATE_MAPPING[selectedTool]].twoYRTermPat.value
            + data[SPDRGOLDSHARES_MAPPING[selectedTool]].oneYRMinusTenFloorCap.value
            + data[DECLAREDSTRATEGY_MAPPING[selectedTool]].value;
    } else {
        total = data[SPINDEX_MAPPING[selectedTool]].tenPercentBufferIdxStrategy.value
            + data[SPINDEX_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value
            + data[SPINDEX_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value
            + data[SHAREMCSI_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value
            + data[SHAREMCSI_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value
            + data[SHAREREALESTATE_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value
            + data[SHAREREALESTATE_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value
            + data[DECLAREDSTRATEGY_MAPPING[selectedTool]].value;
        if (isIS5OrIS7(selectedTool)) {
            total = total
                + data[SPDRGOLDSHARES_MAPPING[selectedTool]].minustenPercentBufferIdxStrategy.value
                + data[SPDRGOLDSHARES_MAPPING[selectedTool]].zeroPercentFloorIdxStrategy.value;
        }
    }

    if (total !== 100) {
        yield put({
            type: actionTypes.UX_SET_VALID,
            value: false
        });

        yield put({
            type: actionTypes.UX_SET_STRAT_VALID,
            value: false
        });

    } else {
        yield put({
            type: actionTypes.UX_SET_VALID,
            value: true && ux.isValid
        });

        yield put({
            type: actionTypes.UX_SET_STRAT_VALID,
            value: true
        });
    }

}

export function* validateStep1() {
    yield put({
        type: actionTypes.DB_VALIDATE_ANNUITY_PAYMENT
    });

    const state = yield select();

    if (!state.toJS().databaseReducer.annuityPurchaseAmount.isValid) {
        yield put({
            type: actionTypes.UX_SET_VALID,
            value: false
        });
        return;
    }

    yield put({
        type: actionTypes.UX_SET_VALID,
        value: true
    });
}

export function* validateStep4() {
    const state = yield select();

    let data = state.get("databaseReducer").toJS();
    let ux = state.get("uxReducer").toJS();

    if(data.clientsName.value==""){
        yield put({
            type: actionTypes.UX_SET_VALID,
            value: false
        });

        yield put({
            type: actionTypes.UX_SET_IS_CLIENTS_NOT_NAME_EMPTY,
            value: false
        });
    }
    else {

        yield put({
            type: actionTypes.UX_SET_IS_CLIENTS_NOT_NAME_EMPTY,
            value: true
        });

        yield put({
            type: actionTypes.UX_SET_VALID,
            value: true && ux.isValid
        });
    }
}

export function* validateStep3() {
    const state = yield select();

    let data = state.get("databaseReducer").toJS();
    let ux = state.get("uxReducer").toJS();

    var nospecial=/[$&+,:;=?[\]@#|{}'<>.123456789^*()%!-/]/;
    if(data.clientsName.value.match(nospecial)){

        yield put({
            type: actionTypes.UX_SET_VALID,
            value: false
        });

        yield put({
            type: actionTypes.UX_SET_CLIENTS_NAME_VALID,
            value: false
        });

    }
    else {
        yield put({
            type: actionTypes.UX_SET_VALID,
            value: true && ux.isValid
        });

        yield put({
            type: actionTypes.UX_SET_CLIENTS_NAME_VALID,
            value: true
        });
    }
}

export function* validateInputs() {
    yield call(validateStep1);
    yield call(validateStep2);
    yield call(validateStep3);
    yield call(validateStep4);
}

export function* loginAdmin() {
    yield put({
        type: actionTypes.UI_SET_LOADING_MODAL,
        value: true
    });

    yield put({
        type: actionTypes.DB_VALIDATE_LOGIN_DATA
    });

    const state = yield select();

    if (!state.getIn(["databaseReducer", "adminDatabase", "login", "isValid"])) {

        yield put({
            type: actionTypes.UI_SET_LOADING_MODAL,
            value: false
        });
        return;
    }

    let username = state.getIn(["databaseReducer", "adminDatabase", "login", "username", "value"]);
    let password = state.getIn(["databaseReducer", "adminDatabase", "login", "password", "value"]);

    let isLoggedIn = yield login_admin(username, password);

    if (!isLoggedIn) {
        yield put({
            type: actionTypes.UX_SET_INVALID_PASSWORD
        });
    } else {
        yield put({
            type: actionTypes.UX_SET_AUTHORIZATION,
            value: true
        });
    }

    yield put({
        type: actionTypes.UI_SET_LOADING_MODAL,
        value: false
    });
}

export function* logOutAdmin(){
    sessionStorage.removeItem('45R11');
    yield logout_admin()
    yield resetData()
    yield put({
        type: actionTypes.UX_SET_AUTHORIZATION,
        value: false
    });
    yield put({
        type: actionTypes.UX_SET_REQUEST_SUCCESSFUL,
        value: false
    });
}

export function* saveAdminData() {

    let isSessionExpired = +sessionStorage.getItem('terAt')
    if(isSessionExpired<Date.now()/1000) {
        yield logOutAdmin();
        return
    }

    yield put({
        type: actionTypes.UI_SET_LOADING_MODAL,
        value: true
    });

    yield put({
        type: actionTypes.DB_ADMIN_VALIDATE,
        adminSelectedTool: sessionStorage.getItem('adminSelectedTool')
    });

    const state = yield select();
    if (state.getIn(["databaseReducer", "adminDatabase", "hasBlank"])) {
        yield put({
            type: actionTypes.UI_SET_LOADING_MODAL,
            value: false
        });
        yield put({
            type: actionTypes.UX_SET_HAS_BLANK,
            value: true
        });
        return;
    }

    yield put({
        type: actionTypes.UX_SET_HAS_BLANK,
        value: false
    });

    if (!state.getIn(["databaseReducer", "adminDatabase", "isValid"])) {
        yield put({
            type: actionTypes.UI_SET_LOADING_MODAL,
            value: false
        });
        yield put({
            type: actionTypes.UX_SET_VALID,
            value: false
        });
        return;
    }

    yield put({
        type: actionTypes.UX_SET_VALID,
        value: true
    });

    // map to index strategies
    const adminSelectedTool = sessionStorage.getItem('adminSelectedTool');
    let adminData = state.getIn(["databaseReducer", "adminDatabase"]).toJS();

    switch (adminSelectedTool) {
        case actionTypes.UI_SET_ADMIN_IS6:
            let responseIs6AdminData = yield get_is6_admin_data();
            responseIs6AdminData.data.assetClassIndexStrategies[0].indexStrategies[0].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].oneYRTermCap.paymentUnder100K.value;
            responseIs6AdminData.data.assetClassIndexStrategies[0].indexStrategies[0].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].oneYRTermCap.paymentOver100K.value;

            responseIs6AdminData.data.assetClassIndexStrategies[0].indexStrategies[2].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].oneYRTermPat.paymentUnder100K.value;
            responseIs6AdminData.data.assetClassIndexStrategies[0].indexStrategies[2].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].oneYRTermPat.paymentOver100K.value;

            responseIs6AdminData.data.assetClassIndexStrategies[0].indexStrategies[5].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].oneYRBufferCap.paymentUnder100K.value;
            responseIs6AdminData.data.assetClassIndexStrategies[0].indexStrategies[5].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].oneYRBufferCap.paymentOver100K.value;

            responseIs6AdminData.data.assetClassIndexStrategies[0].indexStrategies[6].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].oneYRMinusTenFloorCap.paymentUnder100K.value;
            responseIs6AdminData.data.assetClassIndexStrategies[0].indexStrategies[6].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].oneYRMinusTenFloorCap.paymentOver100K.value;

            responseIs6AdminData.data.assetClassIndexStrategies[0].indexStrategies[7].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].oneYRZeroFloorCap.paymentUnder100K.value;
            responseIs6AdminData.data.assetClassIndexStrategies[0].indexStrategies[7].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].oneYRZeroFloorCap.paymentOver100K.value;

            responseIs6AdminData.data.assetClassIndexStrategies[0].indexStrategies[1].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].twoYRTermCap.paymentUnder100K.value;
            responseIs6AdminData.data.assetClassIndexStrategies[0].indexStrategies[1].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].twoYRTermCap.paymentOver100K.value;

            responseIs6AdminData.data.assetClassIndexStrategies[0].indexStrategies[3].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].twoYRTermPat.paymentUnder100K.value;
            responseIs6AdminData.data.assetClassIndexStrategies[0].indexStrategies[3].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].twoYRTermPat.paymentOver100K.value;

            responseIs6AdminData.data.assetClassIndexStrategies[0].indexStrategies[4].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].sixYrTermPatTenBuffer.paymentUnder100K.value;
            responseIs6AdminData.data.assetClassIndexStrategies[0].indexStrategies[4].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].sixYrTermPatTenBuffer.paymentOver100K.value;

            responseIs6AdminData.data.assetClassIndexStrategies[1].indexStrategies[0].purchasePaymentRateUnder = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].oneYRTermPat.paymentUnder100K.value;
            responseIs6AdminData.data.assetClassIndexStrategies[1].indexStrategies[0].purchasePaymentRateOver = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].oneYRTermPat.paymentOver100K.value;

            responseIs6AdminData.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateUnder = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].oneYRTermCap.paymentUnder100K.value;
            responseIs6AdminData.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateOver = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].oneYRTermCap.paymentOver100K.value;

            // responseIs6AdminData.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateUnder = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].twoYRTermPat.paymentUnder100K.value;
            // responseIs6AdminData.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateOver = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].twoYRTermPat.paymentOver100K.value;

            responseIs6AdminData.data.assetClassIndexStrategies[2].indexStrategies[0].purchasePaymentRateUnder = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].oneYRTermPat.paymentUnder100K.value;
            responseIs6AdminData.data.assetClassIndexStrategies[2].indexStrategies[0].purchasePaymentRateOver = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].oneYRTermPat.paymentOver100K.value;

            responseIs6AdminData.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateUnder = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].oneYRTermCap.paymentUnder100K.value;
            responseIs6AdminData.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateOver = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].oneYRTermCap.paymentOver100K.value;

            // responseIs6AdminData.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateUnder = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].twoYRTermPat.paymentUnder100K.value;
            // responseIs6AdminData.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateOver = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].twoYRTermPat.paymentOver100K.value;

            responseIs6AdminData.data.assetClassIndexStrategies[3].indexStrategies[0].purchasePaymentRateUnder = adminData[ADMIN_SPDRGOLDSHARES_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].oneYRMinusTenFloorCap.paymentUnder100K.value;
            responseIs6AdminData.data.assetClassIndexStrategies[3].indexStrategies[0].purchasePaymentRateOver = adminData[ADMIN_SPDRGOLDSHARES_MAPPING[actionTypes.UI_SET_ADMIN_IS6]].oneYRMinusTenFloorCap.paymentOver100K.value;

            yield save_is6_admin_data(responseIs6AdminData.data);
            break;

        case actionTypes.UI_SET_ADMIN_IS6Pro:
            let responseIs6ProAdminData = yield get_is6pro_admin_data();
            responseIs6ProAdminData.data.fees.purchasePaymentRateOver = adminData[ADMIN_FEES_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].paymentOver100K.value;
            responseIs6ProAdminData.data.fees.purchasePaymentRateUnder = adminData[ADMIN_FEES_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].paymentUnder100K.value;

            responseIs6ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[0].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].oneYRTermCap.paymentUnder100K.value;
            responseIs6ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[0].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].oneYRTermCap.paymentOver100K.value;

            responseIs6ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[2].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].oneYRTermPat.paymentUnder100K.value;
            responseIs6ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[2].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].oneYRTermPat.paymentOver100K.value;

            responseIs6ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[5].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].oneYRBufferCap.paymentUnder100K.value;
            responseIs6ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[5].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].oneYRBufferCap.paymentOver100K.value;

            responseIs6ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[6].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].oneYRMinusTenFloorCap.paymentUnder100K.value;
            responseIs6ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[6].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].oneYRMinusTenFloorCap.paymentOver100K.value;

            responseIs6ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[7].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].oneYRZeroFloorCap.paymentUnder100K.value;
            responseIs6ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[7].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].oneYRZeroFloorCap.paymentOver100K.value;

            responseIs6ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[1].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].twoYRTermCap.paymentUnder100K.value;
            responseIs6ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[1].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].twoYRTermCap.paymentOver100K.value;

            responseIs6ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[3].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].twoYRTermPat.paymentUnder100K.value;
            responseIs6ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[3].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].twoYRTermPat.paymentOver100K.value;

            responseIs6ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[4].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].sixYrTermPatTenBuffer.paymentUnder100K.value;
            responseIs6ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[4].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].sixYrTermPatTenBuffer.paymentOver100K.value;

            responseIs6ProAdminData.data.assetClassIndexStrategies[1].indexStrategies[0].purchasePaymentRateUnder = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].oneYRTermPat.paymentUnder100K.value;
            responseIs6ProAdminData.data.assetClassIndexStrategies[1].indexStrategies[0].purchasePaymentRateOver = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].oneYRTermPat.paymentOver100K.value;

            responseIs6ProAdminData.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateUnder = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].oneYRTermCap.paymentUnder100K.value;
            responseIs6ProAdminData.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateOver = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].oneYRTermCap.paymentOver100K.value;

            // responseIs6ProAdminData.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateUnder = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].twoYRTermPat.paymentUnder100K.value;
            // responseIs6ProAdminData.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateOver = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].twoYRTermPat.paymentOver100K.value;

            responseIs6ProAdminData.data.assetClassIndexStrategies[2].indexStrategies[0].purchasePaymentRateUnder = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].oneYRTermPat.paymentUnder100K.value;
            responseIs6ProAdminData.data.assetClassIndexStrategies[2].indexStrategies[0].purchasePaymentRateOver = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].oneYRTermPat.paymentOver100K.value;

            responseIs6ProAdminData.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateUnder = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].oneYRTermCap.paymentUnder100K.value;
            responseIs6ProAdminData.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateOver = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].oneYRTermCap.paymentOver100K.value;

            // responseIs6ProAdminData.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateUnder = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].twoYRTermPat.paymentUnder100K.value;
            // responseIs6ProAdminData.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateOver = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].twoYRTermPat.paymentOver100K.value;

            responseIs6ProAdminData.data.assetClassIndexStrategies[3].indexStrategies[0].purchasePaymentRateUnder = adminData[ADMIN_SPDRGOLDSHARES_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].oneYRMinusTenFloorCap.paymentUnder100K.value;
            responseIs6ProAdminData.data.assetClassIndexStrategies[3].indexStrategies[0].purchasePaymentRateOver = adminData[ADMIN_SPDRGOLDSHARES_MAPPING[actionTypes.UI_SET_ADMIN_IS6Pro]].oneYRMinusTenFloorCap.paymentOver100K.value;

            yield save_is6pro_admin_data(responseIs6ProAdminData.data)
            break;

        case actionTypes.UI_SET_ADMIN_IS5:
            let responseIs5AdminData = yield get_is5_admin_data();
            responseIs5AdminData.data.assetClassIndexStrategies[0].indexStrategies[0].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS5]].tenPercentBufferIdxStrategy.paymentUnder250K.value;
            responseIs5AdminData.data.assetClassIndexStrategies[0].indexStrategies[0].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS5]].tenPercentBufferIdxStrategy.paymentOver250K.value;

            responseIs5AdminData.data.assetClassIndexStrategies[0].indexStrategies[1].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS5]].minustenPercentBufferIdxStrategy.paymentUnder250K.value;
            responseIs5AdminData.data.assetClassIndexStrategies[0].indexStrategies[1].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS5]].minustenPercentBufferIdxStrategy.paymentOver250K.value;

            responseIs5AdminData.data.assetClassIndexStrategies[0].indexStrategies[2].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS5]].zeroPercentFloorIdxStrategy.paymentUnder250K.value;
            responseIs5AdminData.data.assetClassIndexStrategies[0].indexStrategies[2].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS5]].zeroPercentFloorIdxStrategy.paymentOver250K.value;

            responseIs5AdminData.data.assetClassIndexStrategies[1].indexStrategies[0].purchasePaymentRateUnder = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS5]].minustenPercentBufferIdxStrategy.paymentUnder250K.value;
            responseIs5AdminData.data.assetClassIndexStrategies[1].indexStrategies[0].purchasePaymentRateOver = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS5]].minustenPercentBufferIdxStrategy.paymentOver250K.value;

            responseIs5AdminData.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateUnder = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS5]].zeroPercentFloorIdxStrategy.paymentUnder250K.value;
            responseIs5AdminData.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateOver = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS5]].zeroPercentFloorIdxStrategy.paymentOver250K.value;

            responseIs5AdminData.data.assetClassIndexStrategies[2].indexStrategies[0].purchasePaymentRateUnder = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS5]].minustenPercentBufferIdxStrategy.paymentUnder250K.value;
            responseIs5AdminData.data.assetClassIndexStrategies[2].indexStrategies[0].purchasePaymentRateOver = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS5]].minustenPercentBufferIdxStrategy.paymentOver250K.value;

            responseIs5AdminData.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateUnder = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS5]].zeroPercentFloorIdxStrategy.paymentUnder250K.value;
            responseIs5AdminData.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateOver = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS5]].zeroPercentFloorIdxStrategy.paymentOver250K.value;

            responseIs5AdminData.data.assetClassIndexStrategies[3].indexStrategies[0].purchasePaymentRateUnder = adminData[ADMIN_SPDRGOLDSHARES_MAPPING[actionTypes.UI_SET_ADMIN_IS5]].minustenPercentBufferIdxStrategy.paymentUnder250K.value;
            responseIs5AdminData.data.assetClassIndexStrategies[3].indexStrategies[0].purchasePaymentRateOver = adminData[ADMIN_SPDRGOLDSHARES_MAPPING[actionTypes.UI_SET_ADMIN_IS5]].minustenPercentBufferIdxStrategy.paymentOver250K.value;

            responseIs5AdminData.data.assetClassIndexStrategies[3].indexStrategies[1].purchasePaymentRateUnder = adminData[ADMIN_SPDRGOLDSHARES_MAPPING[actionTypes.UI_SET_ADMIN_IS5]].zeroPercentFloorIdxStrategy.paymentUnder250K.value;
            responseIs5AdminData.data.assetClassIndexStrategies[3].indexStrategies[1].purchasePaymentRateOver = adminData[ADMIN_SPDRGOLDSHARES_MAPPING[actionTypes.UI_SET_ADMIN_IS5]].zeroPercentFloorIdxStrategy.paymentOver250K.value;

            yield save_is5_admin_data(responseIs5AdminData.data);
            break;

        case actionTypes.UI_SET_ADMIN_IS5Pro:
            let responseIs5ProAdminData = yield get_is5pro_admin_data();
            responseIs5ProAdminData.data.fees.purchasePaymentRateOver = adminData[ADMIN_FEES_MAPPING[actionTypes.UI_SET_ADMIN_IS5Pro]].paymentOver250K.value;
            responseIs5ProAdminData.data.fees.purchasePaymentRateUnder = adminData[ADMIN_FEES_MAPPING[actionTypes.UI_SET_ADMIN_IS5Pro]].paymentUnder250K.value;

            responseIs5ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[0].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS5Pro]].tenPercentBufferIdxStrategy.paymentUnder250K.value;
            responseIs5ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[0].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS5Pro]].tenPercentBufferIdxStrategy.paymentOver250K.value;

            responseIs5ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[1].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS5Pro]].minustenPercentBufferIdxStrategy.paymentUnder250K.value;
            responseIs5ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[1].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS5Pro]].minustenPercentBufferIdxStrategy.paymentOver250K.value;

            responseIs5ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[2].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS5Pro]].zeroPercentFloorIdxStrategy.paymentUnder250K.value;
            responseIs5ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[2].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS5Pro]].zeroPercentFloorIdxStrategy.paymentOver250K.value;

            responseIs5ProAdminData.data.assetClassIndexStrategies[1].indexStrategies[0].purchasePaymentRateUnder = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS5Pro]].minustenPercentBufferIdxStrategy.paymentUnder250K.value;
            responseIs5ProAdminData.data.assetClassIndexStrategies[1].indexStrategies[0].purchasePaymentRateOver = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS5Pro]].minustenPercentBufferIdxStrategy.paymentOver250K.value;

            responseIs5ProAdminData.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateUnder = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS5Pro]].zeroPercentFloorIdxStrategy.paymentUnder250K.value;
            responseIs5ProAdminData.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateOver = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS5Pro]].zeroPercentFloorIdxStrategy.paymentOver250K.value;

            responseIs5ProAdminData.data.assetClassIndexStrategies[2].indexStrategies[0].purchasePaymentRateUnder = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS5Pro]].minustenPercentBufferIdxStrategy.paymentUnder250K.value;
            responseIs5ProAdminData.data.assetClassIndexStrategies[2].indexStrategies[0].purchasePaymentRateOver = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS5Pro]].minustenPercentBufferIdxStrategy.paymentOver250K.value;

            responseIs5ProAdminData.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateUnder = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS5Pro]].zeroPercentFloorIdxStrategy.paymentUnder250K.value;
            responseIs5ProAdminData.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateOver = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS5Pro]].zeroPercentFloorIdxStrategy.paymentOver250K.value;

            yield save_is5pro_admin_data(responseIs5ProAdminData.data)
            break;

        case actionTypes.UI_SET_ADMIN_IS7:
            let responseIs7AdminData = yield get_is7_admin_data();
            responseIs7AdminData.data.assetClassIndexStrategies[0].indexStrategies[0].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS7]].tenPercentBufferIdxStrategy.paymentUnder250K.value;
            responseIs7AdminData.data.assetClassIndexStrategies[0].indexStrategies[0].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS7]].tenPercentBufferIdxStrategy.paymentOver250K.value;

            responseIs7AdminData.data.assetClassIndexStrategies[0].indexStrategies[1].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS7]].minustenPercentBufferIdxStrategy.paymentUnder250K.value;
            responseIs7AdminData.data.assetClassIndexStrategies[0].indexStrategies[1].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS7]].minustenPercentBufferIdxStrategy.paymentOver250K.value;

            responseIs7AdminData.data.assetClassIndexStrategies[0].indexStrategies[2].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS7]].zeroPercentFloorIdxStrategy.paymentUnder250K.value;
            responseIs7AdminData.data.assetClassIndexStrategies[0].indexStrategies[2].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS7]].zeroPercentFloorIdxStrategy.paymentOver250K.value;

            responseIs7AdminData.data.assetClassIndexStrategies[1].indexStrategies[0].purchasePaymentRateUnder = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS7]].minustenPercentBufferIdxStrategy.paymentUnder250K.value;
            responseIs7AdminData.data.assetClassIndexStrategies[1].indexStrategies[0].purchasePaymentRateOver = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS7]].minustenPercentBufferIdxStrategy.paymentOver250K.value;

            responseIs7AdminData.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateUnder = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS7]].zeroPercentFloorIdxStrategy.paymentUnder250K.value;
            responseIs7AdminData.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateOver = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS7]].zeroPercentFloorIdxStrategy.paymentOver250K.value;

            responseIs7AdminData.data.assetClassIndexStrategies[2].indexStrategies[0].purchasePaymentRateUnder = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS7]].minustenPercentBufferIdxStrategy.paymentUnder250K.value;
            responseIs7AdminData.data.assetClassIndexStrategies[2].indexStrategies[0].purchasePaymentRateOver = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS7]].minustenPercentBufferIdxStrategy.paymentOver250K.value;

            responseIs7AdminData.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateUnder = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS7]].zeroPercentFloorIdxStrategy.paymentUnder250K.value;
            responseIs7AdminData.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateOver = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS7]].zeroPercentFloorIdxStrategy.paymentOver250K.value;

            responseIs7AdminData.data.assetClassIndexStrategies[3].indexStrategies[0].purchasePaymentRateUnder = adminData[ADMIN_SPDRGOLDSHARES_MAPPING[actionTypes.UI_SET_ADMIN_IS7]].minustenPercentBufferIdxStrategy.paymentUnder250K.value;
            responseIs7AdminData.data.assetClassIndexStrategies[3].indexStrategies[0].purchasePaymentRateOver = adminData[ADMIN_SPDRGOLDSHARES_MAPPING[actionTypes.UI_SET_ADMIN_IS7]].minustenPercentBufferIdxStrategy.paymentOver250K.value;

            responseIs7AdminData.data.assetClassIndexStrategies[3].indexStrategies[1].purchasePaymentRateUnder = adminData[ADMIN_SPDRGOLDSHARES_MAPPING[actionTypes.UI_SET_ADMIN_IS7]].zeroPercentFloorIdxStrategy.paymentUnder250K.value;
            responseIs7AdminData.data.assetClassIndexStrategies[3].indexStrategies[1].purchasePaymentRateOver = adminData[ADMIN_SPDRGOLDSHARES_MAPPING[actionTypes.UI_SET_ADMIN_IS7]].zeroPercentFloorIdxStrategy.paymentOver250K.value;

            yield save_is7_admin_data(responseIs7AdminData.data);
            break;

        case actionTypes.UI_SET_ADMIN_IS7Pro:
            let responseIs7ProAdminData = yield get_is7pro_admin_data();
            responseIs7ProAdminData.data.fees.purchasePaymentRateOver = adminData[ADMIN_FEES_MAPPING[actionTypes.UI_SET_ADMIN_IS7Pro]].paymentOver250K.value;
            responseIs7ProAdminData.data.fees.purchasePaymentRateUnder = adminData[ADMIN_FEES_MAPPING[actionTypes.UI_SET_ADMIN_IS7Pro]].paymentUnder250K.value;

            responseIs7ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[0].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS7Pro]].tenPercentBufferIdxStrategy.paymentUnder250K.value;
            responseIs7ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[0].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS7Pro]].tenPercentBufferIdxStrategy.paymentOver250K.value;

            responseIs7ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[1].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS7Pro]].minustenPercentBufferIdxStrategy.paymentUnder250K.value;
            responseIs7ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[1].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS7Pro]].minustenPercentBufferIdxStrategy.paymentOver250K.value;

            responseIs7ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[2].purchasePaymentRateUnder = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS7Pro]].zeroPercentFloorIdxStrategy.paymentUnder250K.value;
            responseIs7ProAdminData.data.assetClassIndexStrategies[0].indexStrategies[2].purchasePaymentRateOver = adminData[ADMIN_SPINDEX_MAPPING[actionTypes.UI_SET_ADMIN_IS7Pro]].zeroPercentFloorIdxStrategy.paymentOver250K.value;

            responseIs7ProAdminData.data.assetClassIndexStrategies[1].indexStrategies[0].purchasePaymentRateUnder = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS7Pro]].minustenPercentBufferIdxStrategy.paymentUnder250K.value;
            responseIs7ProAdminData.data.assetClassIndexStrategies[1].indexStrategies[0].purchasePaymentRateOver = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS7Pro]].minustenPercentBufferIdxStrategy.paymentOver250K.value;

            responseIs7ProAdminData.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateUnder = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS7Pro]].zeroPercentFloorIdxStrategy.paymentUnder250K.value;
            responseIs7ProAdminData.data.assetClassIndexStrategies[1].indexStrategies[1].purchasePaymentRateOver = adminData[ADMIN_SHAREMCSI_MAPPING[actionTypes.UI_SET_ADMIN_IS7Pro]].zeroPercentFloorIdxStrategy.paymentOver250K.value;

            responseIs7ProAdminData.data.assetClassIndexStrategies[2].indexStrategies[0].purchasePaymentRateUnder = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS7Pro]].minustenPercentBufferIdxStrategy.paymentUnder250K.value;
            responseIs7ProAdminData.data.assetClassIndexStrategies[2].indexStrategies[0].purchasePaymentRateOver = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS7Pro]].minustenPercentBufferIdxStrategy.paymentOver250K.value;

            responseIs7ProAdminData.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateUnder = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS7Pro]].zeroPercentFloorIdxStrategy.paymentUnder250K.value;
            responseIs7ProAdminData.data.assetClassIndexStrategies[2].indexStrategies[1].purchasePaymentRateOver = adminData[ADMIN_SHAREREALESTATE_MAPPING[actionTypes.UI_SET_ADMIN_IS7Pro]].zeroPercentFloorIdxStrategy.paymentOver250K.value;

            yield save_is7pro_admin_data(responseIs7ProAdminData.data)
            break;
    }

    yield put({
        type: actionTypes.UI_SET_LOADING_MODAL,
        value: false
    });

    yield put({
        type: actionTypes.UX_SET_REQUEST_SUCCESSFUL,
        value: true
    });
}

export function* navigationWatcher() {
    yield takeEvery(actionTypes.SAGA_NEXT_PAGE, nextPage);
    yield takeEvery(actionTypes.SAGA_PREV_PAGE, prevPage);
    yield takeEvery(actionTypes.SAGA_RESET_DATA, resetData);
    yield takeEvery(actionTypes.SAGA_VALIDATE_STEP_1, validateStep1);
    yield takeEvery(actionTypes.SAGA_RECALCULATE_PLAN, recalculatePlanResults);
    yield takeEvery(actionTypes.SAGA_DOWNLOAD_PDF, downloadPDF);
    yield takeEvery(actionTypes.SAGA_DOWNLOAD_DISCLOSURE, downloadDisclosurePDF);
    yield takeEvery(actionTypes.SAGA_SET_ADMIN_DATA, loadAdminData);
    yield takeEvery(actionTypes.SAGA_LOGIN_ADMIN, loginAdmin);
    yield takeEvery(actionTypes.SAGA_LOGOUT_ADMIN, logOutAdmin);
    yield takeEvery(actionTypes.SAGA_SAVE_ADMIN_DATA, saveAdminData);
}

export default function* rootSaga() {
    yield all([
        navigationWatcher()
    ]);
}