import React from 'react';
import {Grid, Container, Segment, List} from 'semantic-ui-react';
import './index.css';
import {connect} from 'react-redux';
import * as actionTypes from '../../../reducers/actions';
import {
    UI_SET_ADMIN_IS5,
    UI_SET_ADMIN_IS5Pro,
    UI_SET_ADMIN_IS6,
    UI_SET_ADMIN_IS6Pro,
    UI_SET_ADMIN_IS7,
    UI_SET_ADMIN_IS7Pro
} from "../../../reducers/actions";
import {useHistory, useLocation} from "react-router-dom";

const SideBar = (props) => {

    const history = useHistory();
    const location = useLocation();
    const adminSelectedTool = sessionStorage.getItem('adminSelectedTool');
    props.updateAdminShowISTool(adminSelectedTool ? adminSelectedTool : actionTypes.UI_SET_ADMIN_IS6);

    const directTo = (uri) => {
        history.push(uri);
    };

    const switchTool = (tool) => {
        props.updateAdminShowISTool(tool);
        directTo("/admin");
    };

    const isAdminPage = (tool) => {
        let isAdminPage = false;
        if (location.pathname.endsWith("/admin")) isAdminPage = true;
        return isAdminPage && (adminSelectedTool === tool) ? 'active' : '';
    };

    return (
        <Container>
            <Grid.Row>
                <Grid.Column>
                    <Segment raised className={'admin-navigation-bar'}>
                        <List as='ol'>
                            <List.Item as='li' value=''>
                                <a className={'header'} onClick={() => directTo('/access-control')}>Access Control</a>
                            </List.Item>
                            <List.Item as='li' value=''>
                                <label className={'header'}>Product Settings</label>
                                <List.Item as='ol'>
                                    <List.Item as='li' value='' className={isAdminPage(UI_SET_ADMIN_IS6)}>
                                        <a className={'sub-header'} onClick={() => switchTool(UI_SET_ADMIN_IS6)}>Index Summit 6</a>
                                    </List.Item>
                                    <List.Item as='li' value='' className={isAdminPage(UI_SET_ADMIN_IS6Pro)}>
                                        <a className={'sub-header'} onClick={() => switchTool(UI_SET_ADMIN_IS6Pro)}>Index Summit 6 Pro</a>
                                    </List.Item>
                                    <List.Item as='li' value='' className={isAdminPage(UI_SET_ADMIN_IS5)}>
                                        <a className={'sub-header'} onClick={() => switchTool(UI_SET_ADMIN_IS5)}>Index Frontier 5</a>
                                    </List.Item>
                                    <List.Item as='li' value='' className={isAdminPage(UI_SET_ADMIN_IS5Pro)}>
                                        <a className={'sub-header'} onClick={() => switchTool(UI_SET_ADMIN_IS5Pro)}>Index Frontier 5 Pro</a>
                                    </List.Item>
                                    <List.Item as='li' value='' className={isAdminPage(UI_SET_ADMIN_IS7)}>
                                        <a className={'sub-header'} onClick={() => switchTool(UI_SET_ADMIN_IS7)}>Index Frontier 7</a>
                                    </List.Item>
                                    <List.Item as='li' value='' className={isAdminPage(UI_SET_ADMIN_IS7Pro)}>
                                        <a className={'sub-header'} onClick={() => switchTool(UI_SET_ADMIN_IS7Pro)}>Index Frontier 7 Pro</a>
                                    </List.Item>
                                </List.Item>
                            </List.Item>
                            <List.Item as='li' value=''>
                                <a className={'header'} onClick={() => directTo('/business-intelligence')}>Business Intelligence</a>
                            </List.Item>
                        </List>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        uiReducer: state.get('uiReducer'),
        uxReducer: state.get("uxReducer"),
        database: state.get('databaseReducer'),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAdminShowISTool: (value) => dispatch({
            type: actionTypes.UI_SET_ADMIN_TOOL,
            value: value
        })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);