import React from 'react';
import ChangeIndicator from "./ChangeIndicator/index";
import ToolTip from '../../../../common/ToolTip/ToolTip';
import {Segment, Grid, Label, Header, Container} from "semantic-ui-react";
import { connect } from 'react-redux';
import {formatCurrency} from "../../../../common/common";
import './index.css';
import {TOOL_NAME_MAPPING} from "../../../../reducers/actions";

const planCompare = (props) => {

    let data = props.database.toJS().results;
    const selectedTool = props.uiReducer.toJS().selectedTool;

    return (
        <Segment className="plan-compare">
            <Header as="h1" className="adjusted-header">Simulation Highlights</Header>

            <Container textAlign='center'>
                <Grid className="compare-grid" divided padded columns='equal'>
                    <Grid.Row>
                        <Grid.Column></Grid.Column>
                        <Grid.Column className="column-header"><Label basic>Combined Index/ETF Results</Label></Grid.Column>
                        <Grid.Column className="column-header"><Label basic>{TOOL_NAME_MAPPING[selectedTool]} Results</Label></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Label basic>Beginning Value</Label>
                        </Grid.Column>
                        <Grid.Column className="currentPlan">
                            <Label basic>{formatCurrency(data.planA.beginingPortfolioValue.toString())}</Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Label basic>{formatCurrency(data.planB.beginingPortfolioValue.toString())}</Label>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Label className="green" basic>Statistical Highlights - 10 Year Values</Label>
                        </Grid.Column>
                        <Grid.Column className="currentPlan"></Grid.Column>
                        <Grid.Column></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Label basic>Median Value</Label>
                        </Grid.Column>
                        <Grid.Column className="currentPlan">
                            <Label basic>{formatCurrency(data.planA.medianEndingValue.toString())}</Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Label basic>{formatCurrency(data.planB.medianEndingValue.toString())}</Label>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Label basic>Average Return</Label>
                        </Grid.Column>
                        <Grid.Column className="currentPlan">
                            <Label basic>{(data.planA.averageReturn * 100).toFixed(2)}%</Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Label basic>{(data.planB.averageReturn * 100).toFixed(2)}%</Label>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Label basic>Standard Deviation</Label>
                            <ToolTip>The measure of how much the set of simulated returns varies from its average return. A set of returns with a high standard deviation is more dispersed from its average, while a set of returns with a low standard deviation has returns that are closer to the average of the set.</ToolTip>
                        </Grid.Column>
                        <Grid.Column className="currentPlan">
                            <Label basic>{(data.planA.standardDeviation * 100).toFixed(2)}%</Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Label basic>{(data.planB.standardDeviation * 100).toFixed(2)}%</Label>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Label className="green" basic>6-Year Cumulative Wealth</Label>
                            <ToolTip>The 90th Percentile figure in each column was extracted from the simulation whose 6-year value is exceeded by the relevant  6-year value in only 10% of all other simulations. The 50th Percentile figure in each column was extracted from the simulation whose 6-year value is exceeded by the relevant 6-year value in 50% of all other simulations. The 10th Percentile figure in each column was extracted from the simulation whose 6-year value is exceeded by the relevant 6-year value in 90% of all other simulations. As a result, returns appearing in the same row below may have been extracted from different simulations.</ToolTip>
                        </Grid.Column>
                        <Grid.Column className="currentPlan"></Grid.Column>
                        <Grid.Column></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Label basic>90th Percentile</Label>
                            <ToolTip>Represents consistently better than expected investment returns. Only 10% of the simulations ended in a cumulative value higher than this value, and 90% of the simulations resulted in a cumulative value lower than this value.</ToolTip>
                        </Grid.Column>
                        <Grid.Column className="currentPlan">
                            <Label basic>{formatCurrency(data.planA.threeYearCumWealth.percentile90.toString())}</Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Label basic>{formatCurrency(data.planB.threeYearCumWealth.percentile90.toString())}</Label>
                            <ChangeIndicator
                                baseValue={data.planA.threeYearCumWealth.percentile90}
                                compareValue={data.planB.threeYearCumWealth.percentile90}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Label basic>50th Percentile</Label>
                            <ToolTip>Half of the simulations ended in a cumulative value higher than this value, and half resulted in a cumulative value lower than this value.</ToolTip>
                        </Grid.Column>
                        <Grid.Column className="currentPlan">
                            <Label basic>{formatCurrency(data.planA.threeYearCumWealth.percentile50.toString())}</Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Label basic>{formatCurrency(data.planB.threeYearCumWealth.percentile50.toString())}</Label>
                            <ChangeIndicator
                                baseValue={data.planA.threeYearCumWealth.percentile50}
                                compareValue={data.planB.threeYearCumWealth.percentile50}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Label basic>10th Percentile</Label>
                            <ToolTip>Represents consistently worse than expected investment returns. Only 10% of the simulations ended in a cumulative value lower than this value, and 90% of the simulations results in a cumulative value higher than this value.</ToolTip>
                        </Grid.Column>
                        <Grid.Column className="currentPlan">
                            <Label basic>{formatCurrency(data.planA.threeYearCumWealth.percentile10.toString())}</Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Label basic>{formatCurrency(data.planB.threeYearCumWealth.percentile10.toString())}</Label>
                            <ChangeIndicator
                                baseValue={data.planA.threeYearCumWealth.percentile10}
                                compareValue={data.planB.threeYearCumWealth.percentile10}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Label className="green" basic>10-Year Cumulative Wealth</Label>
                            <ToolTip>The 90th Percentile figure in each column was extracted from the simulation whose 10-year value is exceeded by the relevant  10-year value in only 10% of all other simulations. The 50th Percentile figure in each column was extracted from the simulation whose 10-year value is exceeded by the relevant 10-year value in 50% of all other simulations. The 10th Percentile figure in each column was extracted from the simulation whose 6-year value is exceeded by the relevant 6-year value in 90% of all other simulations. As a result, returns appearing in the same row below may have been extracted from different simulations. </ToolTip>
                        </Grid.Column>
                        <Grid.Column className="currentPlan"></Grid.Column>
                        <Grid.Column></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Label basic>90th Percentile</Label>
                            <ToolTip>Represents consistently better than expected investment returns. Only 10% of the simulations ended in a cumulative value higher than this value, and 90% of the simulations resulted in a cumulative value lower than this value.</ToolTip>
                        </Grid.Column>
                        <Grid.Column className="currentPlan">
                            <Label basic>{formatCurrency(data.planA.tenYearCumWealth.percentile90.toString())}</Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Label basic>{formatCurrency(data.planB.tenYearCumWealth.percentile90.toString())}</Label>
                            <ChangeIndicator
                                baseValue={data.planA.tenYearCumWealth.percentile90}
                                compareValue={data.planB.tenYearCumWealth.percentile90}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Label basic>50th Percentile</Label>
                            <ToolTip>Half of the simulations ended in a cumulative value higher than this value, and half resulted in a cumulative value lower than this value.</ToolTip>
                        </Grid.Column>
                        <Grid.Column className="currentPlan">
                            <Label basic>{formatCurrency(data.planA.tenYearCumWealth.percentile50.toString())}</Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Label basic>{formatCurrency(data.planB.tenYearCumWealth.percentile50.toString())}</Label>
                            <ChangeIndicator
                                baseValue={data.planA.tenYearCumWealth.percentile50}
                                compareValue={data.planB.tenYearCumWealth.percentile50}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Label basic>10th Percentile</Label>
                            <ToolTip>Represents consistently worse than expected investment returns. Only 10% of the simulations ended in a cumulative value lower than this value, and 90% of the simulations results in a cumulative value higher than this value.</ToolTip>
                        </Grid.Column>
                        <Grid.Column className="currentPlan">
                            <Label basic>{formatCurrency(data.planA.tenYearCumWealth.percentile10.toString())}</Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Label basic>{formatCurrency(data.planB.tenYearCumWealth.percentile10.toString())}</Label>
                            <ChangeIndicator
                                baseValue={data.planA.tenYearCumWealth.percentile10}
                                compareValue={data.planB.tenYearCumWealth.percentile10}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </Segment>
    );
};

const mapStateToProps = (state) => {
    return {
        database: state.get("databaseReducer"),
        uiReducer: state.get('uiReducer')
    }
};

export default connect(mapStateToProps, null)(planCompare);