import React from 'react';
import {Container, Header, Grid, Segment} from "semantic-ui-react";
import NumberFormat from 'react-number-format';
import {connect} from 'react-redux';
import * as actionTypes from '../../../reducers/actions';
import {TOOL_NAME_MAPPING} from "../../../reducers/actions";

// show an informational message
// show user input instructions
// allow a user input
// allow for validation signal

const annuityPaymentStep = (props) => {

    let data = props.database.toJS();

    let inputClass = "ui input";
    const selectedTool = props.uiReducer.toJS().selectedTool;
    
    if (!data.annuityPurchaseAmount.isValid) inputClass = "ui error input";

    return (
        <Segment>
            <Grid>

                <Grid.Row>
                    <Grid.Column>
                        <Container textAlign='center'>
                            <Header size='huge'>What is your anticipated annuity purchase payment?</Header>
                        </Container>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Container textAlign="center">
                            <Header size="small">Your {TOOL_NAME_MAPPING[selectedTool]} purchase payment must be between $25,000 and $1,000,000.</Header>
                        </Container>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <div className={inputClass}>
                            <NumberFormat
                                value={data.annuityPurchaseAmount.value}
                                displayType={'input'}
                                thousandSeparator={true}
                                prefix={'$'}
                                onValueChange={(values) => props.updateAnnuityPurchaseAmount(values.value)}
                                onClick={() => props.updateAnnuityPurchaseAmount("")}
                                onBlur={() => props.validateAnnuityPurchaseAmount()}
                            />
                        </div>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

const mapStateToProps = (state) => {
    return {
        database: state.get("databaseReducer"),
        uiReducer: state.get('uiReducer')
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAnnuityPurchaseAmount: (value) => dispatch({
            type: actionTypes.DB_SET_ANNUITY_PAYMENT,
            value: value
        }),
        validateAnnuityPurchaseAmount: () => dispatch({
            type: actionTypes.DB_VALIDATE_ANNUITY_PAYMENT
        })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(annuityPaymentStep);