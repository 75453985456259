import React from "react";
import {Redirect} from "react-router-dom";
import {connect} from 'react-redux';
import * as actionTypes from '../../reducers/actions';

const RedirectToISPro = (props) => {

    props.updateNavRowButtonsVisibility(false);
    props.resetData();
    props.hideBackToIntroductionButton();
    props.updateShowISPro(actionTypes.UI_SET_IS6Pro, true);

    return (
        <div>
            <Redirect to={"/wizard"} />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        uxReducer: state.get('uxReducer')
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateNavRowButtonsVisibility: (value) => dispatch({
            type: actionTypes.UI_SET_NAVIGATION_ROW_BUTTONS_VISIBILITY,
            value: value
        }),
        updateShowISPro: (type,value) => dispatch({
            type: type,
            value: value
        }),
        resetData: () => dispatch({
            type: actionTypes.SAGA_RESET_DATA
        }),
        hideBackToIntroductionButton: () => dispatch({
            type: actionTypes.UI_HIDE_BACKTOINTRODUCTION
        })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RedirectToISPro);