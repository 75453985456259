import React, {useEffect} from 'react';
import {Grid} from 'semantic-ui-react'
import Header from './Header/index';
import Navigation from './Navigation/index';
import Wizard from '../Wizard/index';
import LoadingModal from '../Modals/LoadingModal/index';
import OutputModal from '../Wizard/OutputStep/OutputModal/';
import AdminPage from '../Admin/AdminPage/index';
import AccessControl from '../Admin/AccessControl/index';
import BusinessIntelligence from '../Admin/BusinessIntelligence/index';
import Disclosures from './Disclosures/index';
import Modal from 'react-modal';
import {Route, Switch} from 'react-router-dom';
import { connect } from 'react-redux';
import * as actionTypes from '../../reducers/actions';
import './App.css';
import AdvisorAuthorization from "../../hoc/AdvisorAuthorization";
import IntroductionScreen from '../IntroductionScreen'
import RedirectToISPro from "../RedirectPages/RedirectToISPro";
import ProductLoader from "../ProductLoader";

if (process.env.NODE_ENV !== 'test')
    Modal.setAppElement('#root');


const App = (props) => {

    useEffect(() => {
        props.loadAdminData();
    }, [1]);


    const protectedAdminPage = AdvisorAuthorization(AdminPage);
    const protectedAccessControlPage = AdvisorAuthorization(AccessControl);
    const protectedBusinessIntelligencePage = AdvisorAuthorization(BusinessIntelligence);

    return (
        <div className="App">
            <Header/>
            <Grid className="app-content">
                <Grid.Row>
                    <Grid.Column>
                        <Switch>
                            <Route
                                exact
                                path="/"
                                component={IntroductionScreen}
                            />
                            <Route
                                exact
                                path="/wizard"
                                component={Wizard}
                            />
                            <Route
                                exact
                                path="/raymondjames"
                                component={ProductLoader}
                            />
                            <Route
                                exact
                                path="/mmfa"
                                component={ProductLoader}
                            />
                            <Route
                                exact
                                path="/cetera"
                                component={ProductLoader}
                            />
                            <Route
                                exact
                                path="/huntington"
                                component={ProductLoader}
                            />
                            <Route
                                exact
                                path="/lpl"
                                component={ProductLoader}
                            />
                            <Route
                                exact
                                path="/wellsfargo"
                                component={ProductLoader}
                            />
                            <Route
                                path="/disclosures"
                                component={Disclosures}
                            />
                            <Route
                                path="/admin"
                                component={protectedAdminPage}
                            />
                            <Route
                                path="/access-control"
                                component={protectedAccessControlPage}
                            />
                            <Route
                                path="/business-intelligence"
                                component={protectedBusinessIntelligencePage}
                            />
                            <Route
                                path={"/pro"}
                                component={RedirectToISPro}
                            />
                        </Switch>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Route
                        exact
                        path="/wizard"
                        component={Navigation}
                    />
                </Grid.Row>
            </Grid>
            <LoadingModal />
            <OutputModal />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadAdminData: () => dispatch({
            type: actionTypes.SAGA_SET_ADMIN_DATA
        }),
        updateValue: (key, value) => dispatch({
            type: actionTypes.DB_ADMIN_UPDATE,
            key: key,
            value: value
        }),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
