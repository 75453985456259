import React, {useEffect, useState} from 'react';
import {Container, Grid, Segment, Dropdown} from "semantic-ui-react";
import {connect} from 'react-redux';
import * as actionTypes from '../../reducers/actions';
import './index.css';
import {useHistory} from "react-router-dom";
import {
    PRODUCT_CODE_TYPE,
    PRODUCT_NAME_MAPPING,
    PRODUCT_TYPE,
    RETAIL_FI
} from "../../reducers/actions";
import {create_client_visit, get_institute_products} from "../../api/api";

const IntroductionScreen = (props) => {

    const isTools = sessionStorage.getItem('isTools') ? JSON.parse(sessionStorage.getItem('isTools')) : null;

    const [state, setState] = useState({
        isTools: []
    });

    useEffect(() => {
        if (isTools) {
            props.updateNavRowButtonsVisibility(false);
            props.updateShowISPro(actionTypes.UI_SET_IS6Pro, false);
            props.resetData();
            props.showBackToIntroductionButton();

            setState({
                ...state,
                isTools: isTools.products
            });
        } else {
            get_institute_products(RETAIL_FI)
                .then((response) => {
                    props.updateNavRowButtonsVisibility(false);
                    props.updateShowISPro(actionTypes.UI_SET_IS6Pro, false);
                    props.resetData();
                    props.showBackToIntroductionButton();

                    const products = response.gaigProductTypeList.map(item => {
                        return {
                            key: PRODUCT_CODE_TYPE[item],
                            value: PRODUCT_CODE_TYPE[item],
                            text: PRODUCT_NAME_MAPPING[item]
                        };
                    });

                    props.updateIsTools({
                        institution: RETAIL_FI,
                        products: products
                    });

                    setState({
                        ...state,
                        isTools: products
                    });
                });
        }
    }, []);

    const history = useHistory();

    const handleOnChange = (e, data) => {
        const productType = data.value;
        props.updateShowISPro(actionTypes.UI_SET_TOOL, productType);
        create_client_visit(isTools.institution, PRODUCT_TYPE[productType])
            .then((response) => {
                props.updateVisitId(response.data);
                history.push('/wizard');
            });
    };

    return ( 
        <Grid textAlign='center' columns='equal'>
            <Grid.Column width={10} className='introduction-div'>
                <Segment basic>
                    <Grid.Row>
                        <Grid.Column>
                            <Container textAlign="left" className={'intro-container'}>
                                <p >A registered index-linked annuity from MassMutual Ascend can help your clients grow their assets and reach their retirement goals. Select a product below to get started.</p>
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                </Segment>
                <Segment basic>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Container textAlign="center">
                                <Dropdown
                                    placeholder='Select Tool'
                                    options={state.isTools}
                                    selection
                                    selectOnBlur={false}
                                    onChange={handleOnChange}
                                />
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                </Segment>
                <Segment basic>
                    <Grid.Row>
                        <Container textAlign="center" className={'intro-container'}>
                            <p>If you’re not sure which product to choose, please refer to your Broker/Dealer for product availability.</p>
                        </Container>
                    </Grid.Row>
                </Segment>
            </Grid.Column>
        </Grid>
    );
};


const mapStateToProps = (state) => {
    return {
        uxReducer: state.get('uxReducer'),
        uiReducer: state.get('uiReducer')
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateNavRowButtonsVisibility: (value) => dispatch({
            type: actionTypes.UI_SET_NAVIGATION_ROW_BUTTONS_VISIBILITY,
            value: value
        }),
        updateShowISPro: (type, value) => dispatch({
            type: type,
            value: value
        }),
        resetData: () => dispatch({
            type: actionTypes.SAGA_RESET_DATA
        }),
        showBackToIntroductionButton: () => dispatch({
            type: actionTypes.UI_SHOW_BACKTOINTRODUCTION
        }),
        updateVisitId: (value) => dispatch({
            type: actionTypes.DB_UPDATE_VISIT_ID_DATA,
            value: value
        }),
        updateIsTools: (value) => dispatch({
            type: actionTypes.UI_SET_IS_TOOLS,
            value: value
        })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(IntroductionScreen);