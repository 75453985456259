import React, {useEffect} from 'react';
import {Grid, Loader} from "semantic-ui-react";
import {connect} from 'react-redux';
import * as actionTypes from '../../reducers/actions';
import './index.css';
import {useHistory} from "react-router-dom";
import {
    PRODUCT_CODE_TYPE,
    PRODUCT_NAME_MAPPING
} from "../../reducers/actions";
import {get_institute_products} from "../../api/api";
import {getInstitution} from "../../common/common";

const ProductLoader = (props) => {

    const INSTITUTION = getInstitution();
    const history = useHistory();

    useEffect(() => {
        get_institute_products(INSTITUTION)
            .then((response) => {
                props.updateIsTools({
                    institution: INSTITUTION,
                    products: response.gaigProductTypeList.map(item => {
                        return {
                            key: PRODUCT_CODE_TYPE[item],
                            value: PRODUCT_CODE_TYPE[item],
                            text: PRODUCT_NAME_MAPPING[item]
                        };
                    })
                });
                history.push('/');
            });
    }, []);

    return ( 
        <Grid textAlign='center' columns='equal'>
            <Grid.Column width={16}>
                <Loader></Loader>
            </Grid.Column>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        uxReducer: state.get('uxReducer')
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateIsTools: (value) => dispatch({
            type: actionTypes.UI_SET_IS_TOOLS,
            value: value
        })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductLoader);