import React from 'react';
import { Segment, Grid, Label, Header } from "semantic-ui-react";
import { connect } from 'react-redux';
import NumberFormat from "react-number-format";
import { getInputClass } from "../../../../common/common";
import * as actionTypes from '../../../../reducers/actions';
import {SHAREREALESTATE_MAPPING} from "../../../../reducers/actions";

const shareRealEstateBox = (props) => {

    let data = props.database.toJS();
    const selectedTool = props.uiReducer.toJS().selectedTool;
    // let total = data[SHAREREALESTATE_MAPPING[selectedTool]].oneYRTermPat.value + data[SHAREREALESTATE_MAPPING[selectedTool]].twoYRTermPat.value;
    let total = data[SHAREREALESTATE_MAPPING[selectedTool]].oneYRTermPat.value + data[SHAREREALESTATE_MAPPING[selectedTool]].oneYRTermCap.value;
    if (isNaN(total)) total = 0.0;

    return (
        <Segment raised>
            <Header as="h1">iShares US Real Estate ETF</Header>

            <Grid columns='equal'>

                <Grid.Row>
                    <Grid.Column></Grid.Column>
                    <Grid.Column></Grid.Column>
                    <Grid.Column>
                        <Label basic>Purchase Payments $100,000 and Over</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>Purchase Payments under $100,000</Label>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Label basic>1-Year 50% Downside Participation Rate with Upside Participation Rate</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data[SHAREREALESTATE_MAPPING[selectedTool]].oneYRTermPat.isValid)}>
                            <NumberFormat
                                displayType={'input'}
                                suffix={'%'}
                                value={data[SHAREREALESTATE_MAPPING[selectedTool]].oneYRTermPat.value}
                                onValueChange={(values) => props.updateRealEstate(selectedTool, "oneYRTermPat", values.value)}
                                onClick={() => props.updateRealEstate(selectedTool, "oneYRTermPat", "")}
                                onBlur={() => props.validateRealEstate(selectedTool)}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SHAREREALESTATE_MAPPING[selectedTool]].oneYRTermPat.paymentOver100K.value}%</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SHAREREALESTATE_MAPPING[selectedTool]].oneYRTermPat.paymentUnder100K.value}%</Label>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Label basic>1-yr -10% Floor with Cap</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className={getInputClass(data[SHAREREALESTATE_MAPPING[selectedTool]].oneYRTermCap.isValid)}>
                            <NumberFormat
                                displayType={'input'}
                                suffix={'%'}
                                value={data[SHAREREALESTATE_MAPPING[selectedTool]].oneYRTermCap.value}
                                onValueChange={(values) => props.updateRealEstate(selectedTool,"oneYRTermCap", values.value)}
                                onClick={() => props.updateRealEstate(selectedTool, "oneYRTermCap", "")}
                                onBlur={() => props.validateRealEstate(selectedTool)}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SHAREREALESTATE_MAPPING[selectedTool]].oneYRTermCap.paymentOver100K.value}%</Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{data.adminDatabase[SHAREREALESTATE_MAPPING[selectedTool]].oneYRTermCap.paymentUnder100K.value}%</Label>
                    </Grid.Column>
                </Grid.Row>

                {/*<Grid.Row>*/}
                {/*    <Grid.Column>*/}
                {/*        <Label basic>2-Year 50% Downside Participation Rate with Upside Participation Rate</Label>*/}
                {/*    </Grid.Column>*/}
                {/*    <Grid.Column>*/}
                {/*        <div className={getInputClass(data[SHAREREALESTATE_MAPPING[selectedTool]].twoYRTermPat.isValid)}>*/}
                {/*            <NumberFormat*/}
                {/*                displayType={'input'}*/}
                {/*                suffix={'%'}*/}
                {/*                value={data[SHAREREALESTATE_MAPPING[selectedTool]].twoYRTermPat.value}*/}
                {/*                onValueChange={(values) => props.updateRealEstate(selectedTool,"twoYRTermPat", values.value)}*/}
                {/*                onClick={() => props.updateRealEstate(selectedTool, "twoYRTermPat", "")}*/}
                {/*                onBlur={() => props.validateRealEstate(selectedTool)}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </Grid.Column>*/}
                {/*    <Grid.Column>*/}
                {/*        <Label basic>{data.adminDatabase[SHAREREALESTATE_MAPPING[selectedTool]].twoYRTermPat.paymentOver100K.value}%</Label>*/}
                {/*    </Grid.Column>*/}
                {/*    <Grid.Column>*/}
                {/*        <Label basic>{data.adminDatabase[SHAREREALESTATE_MAPPING[selectedTool]].twoYRTermPat.paymentUnder100K.value}%</Label>*/}
                {/*    </Grid.Column>*/}
                {/*</Grid.Row>*/}

                <Grid.Row>
                    <Grid.Column>
                        <Label basic>iShares US Real Estate ETF Total:</Label>
                    </Grid.Column>
                    <Grid.Column>
                        {total}%
                    </Grid.Column>
                    <Grid.Column></Grid.Column>
                    <Grid.Column></Grid.Column>
                </Grid.Row>

            </Grid>

        </Segment>
    )
};

const mapStateToProps = (state) => {
    return {
        database: state.get('databaseReducer'),
        uiReducer: state.get('uiReducer'),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateRealEstate: (selectedTool, key, value) => dispatch({
            type: actionTypes.DB_UPDATE_REALESTATE,
            selectedTool: selectedTool,
            key: key,
            value: value
        }),
        validateRealEstate: (selectedTool) => dispatch({
            type: actionTypes.DB_VALIDATE_REALESTATE,
            selectedTool: selectedTool
        })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(shareRealEstateBox);