import React from 'react';
import {Grid, Button, Segment, Container, Input, Label, Message} from "semantic-ui-react";
import { connect } from 'react-redux';
import * as actionTypes from '../../../reducers/actions';
import './index.css';

const LoginPage = (props) => {
    let invValidMessage = null;

    if (!props.isValid) {
        invValidMessage = (
            <Grid.Row>
                <Grid.Column width={16}>
                    Please enter a valid username and password.
                </Grid.Column>
            </Grid.Row>
        );
    }

    let inValidPasswordMessage = null;
    if (props.inValidPassword) {
        inValidPasswordMessage = (
            <Grid.Row>
                <Grid.Column width={16}>
                    The username and/or password you have provided is incorrect.
                </Grid.Column>
            </Grid.Row>
        );
    }

    return (
        <Container className={'login-page'}>
            <Segment raised>
                <Grid columns={'equal'}>
                    {invValidMessage}
                    {inValidPasswordMessage}
                    <Grid.Row>
                        <Grid.Column>
                            <Label>Username:</Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Input
                                type="text"
                                placeholder={'username'}
                                onChange={(event) => props.update('username', event.target.value)}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Label>Password:</Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Input
                                type="password"
                                placeholder={'password'}
                                onChange={(event) => props.update('password', event.target.value)}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Button onClick={() => props.login()} primary>Login</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        isValid: state.getIn(["databaseReducer", "adminDatabase", "login", "isValid"]),
        inValidPassword: state.getIn(["uxReducer", "inValidPassword"])
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        update: (key, value) => dispatch({
            type: actionTypes.DB_UPDATE_LOGIN_DATA,
            key: key,
            value: value
        }),
        validate: () => dispatch({
            type: actionTypes.DB_VALIDATE_LOGIN_DATA
        }),
        login: () => dispatch({
            type: actionTypes.SAGA_LOGIN_ADMIN
        })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);